// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import * as frontendpb from '../proto/frontend/frontend_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';

import { Logger } from './observability/logs';
import * as rpc from './rpc';
import { addRpcError } from './transientNotification';

const logger = new Logger('lib/customFieldNodeUtils');

export function findCustomFieldNodeById(customFields: feoutputpb.CustomFields, id: string) {
  return customFields.customFields.find((value) => value.id === id);
}

export async function validateExpressions(
  customFieldNodes: feoutputpb.CustomFields,
  setCustomFieldNodes: (newCustomFieldNodes: feoutputpb.CustomFields) => void,
  projectId: string,
  jobId: string,
  activeUrl: string,
  expressions: Map<string, string>, // Maps output ID to expression for that output
) {
  const newCustomFieldNodes = customFieldNodes.clone();
  const customFieldIndex: number = newCustomFieldNodes.customFields
    .findIndex((value) => value.id === Array.from(expressions.keys())[0]);
  newCustomFieldNodes.customFields[customFieldIndex].expression =
    Array.from(expressions.values())[0];
  const req = new frontendpb.ValidateVisCustomFieldRequest();
  req.url = activeUrl;
  req.customFields = newCustomFieldNodes;
  req.queryField = customFieldIndex;
  req.projectId = projectId;
  req.jobId = jobId;

  if (req.customFields?.customFields[customFieldIndex]?.expression.length) {
    try {
      const reply: frontendpb.ValidateVisCustomFieldReply = await rpc.callRetry(
        'validateVisCustomField',
        rpc.client.validateVisCustomField,
        req,
      );

      const newNode = newCustomFieldNodes.customFields[customFieldIndex];
      if (!reply.ok) {
        const error_string = `There are ${reply.errors.length} errors in the expression:\n` +
          `${reply.errors.map((error, index) => `${index + 1}. ${error}`).join('\n')}`;
        newNode.errors = error_string;
      } else {
        newNode.errors = '';
      }
      newCustomFieldNodes.customFields[customFieldIndex] = newNode;
      setCustomFieldNodes(newCustomFieldNodes);
    } catch (err) {
      addRpcError('Failed to get custom field validation', err);
      logger.warn(`rpc validateVisCustomField error: ${err}`);
    }
  } else {
    setCustomFieldNodes(newCustomFieldNodes);
  }
}

export function customNodesToSendToBackend(
  customFieldNodes: feoutputpb.CustomFields,
) {
  const visCustomFields = new frontendpb.CustomFields();
  // Only send fields with some expression and no known errors.
  visCustomFields.customFields = customFieldNodes.customFields
    .filter(({ name, expression, errors }) => (
      expression &&
      expression.trim() !== '' &&
      errors.length === 0
    ))
    .map(({ name, expression }) => (
      new frontendpb.CustomField({
        name,
        expression,
      })
    ));
  return visCustomFields;
}
