// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, noWait, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState, waitForAll } from 'recoil';

import { CurrentView } from '../lib/componentTypes/context';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { currentViewState } from '../state/internal/global/currentView';

import { getCheckGeometry } from './getCheckGeometry';
import { routeParamsState } from './useRouteParams';

export type nullableHealthReply = frontendpb.CheckGeometryReply_Done | null;

const geometryHealthKey = 'geometryHealth';

function serialize(val: nullableHealthReply): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): nullableHealthReply {
  return (val.length ?
    frontendpb.CheckGeometryReply_Done.fromBinary(val) :
    null);
}

const geometryHealthStateSelector = selectorFamily<nullableHealthReply, string>({
  key: `${geometryHealthKey}/rpc`,
  get: (projectId: string) => ({ get }) => {
    const [
      currentView,
      { geometryId },
    ] = get(waitForAll([currentViewState, routeParamsState]));
    if (currentView === CurrentView.GEOMETRY) {
      const getCheck = get(getCheckGeometry(projectId));
      if (!getCheck) {
        return null;
      }
      if (!geometryId) {
        return null;
      }
      // No card to be shown if the check geometry is in progress.
      if (!getCheck.finished) {
        return null;
      }
      // Mock the expected atom return type via what's returned from the backend.
      return new frontendpb.CheckGeometryReply_Done({
        ok: getCheck.ok,
        issues: getCheck.issues,
      });
    }
    return persist.getProjectState(projectId, [geometryHealthKey], deserialize);
  },
});

// This represents the state of the geometry health card. All the information for the card is
// contained in a reply received from CheckGeometry.
export const geometryHealthState = atomFamily<nullableHealthReply, string>({
  key: geometryHealthKey,
  default: selectorFamily<nullableHealthReply, string>({
    key: `${geometryHealthKey}/default`,
    get: (projectId: string) => async ({ get }) => {
      // This avoid unwanted suspenses when switching between views (since the selector
      // depends on the currentView).
      const response = get(noWait(geometryHealthStateSelector(projectId)));
      return response.state === 'hasValue' ? response.contents : null;
    },
  }),
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, geometryHealthKey, deserialize, serialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const useGeometryHealth = (projectId: string) => (
  useRecoilState(geometryHealthState(projectId))
);

export const useSetGeometryHealth = (projectId: string) => (
  useSetRecoilState(geometryHealthState(projectId))
);

export const useGeometryHealthValue = (projectId: string) => (
  useRecoilValue(geometryHealthState(projectId))
);
