// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const ThumbsUpIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="thumbsUp"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M6.08152 1
           L5.94565 1.12228
           L2.99728 4.04348
           H1
           V11
           H8.32337
           C8.93648 11 9.47147 10.5669 9.60054 9.96739
           L10.538 5.61957
           C10.7096 4.81624 10.0812 4.04348 9.26087 4.04348H6.76087
           L6.84239 3.71739
           C6.93071 3.64946 6.98675 3.62058 7.11413 3.44565
           C7.31793 3.16712 7.52174 2.73573 7.52174 2.15489
           C7.52174 1.53499 6.96128 1 6.25815 1
           H6.08152Z
           M6.39402 1.91033
           C6.57745 1.94599 6.65217 2.02072 6.65217 2.15489
           C6.65217 2.54721 6.53329 2.79008 6.4212 2.94293
           C6.3091 3.09579 6.23098 3.13315 6.23098 3.13315
           L6.08152 3.21467
           L6.02717 3.3913
           L5.76902 4.36957
           L5.63315 4.91304
           H9.26087
           C9.54789 4.91304 9.74151 5.1627 9.68207 5.44293
           L8.75815 9.79076
           C8.71399 9.99457 8.53057 10.1304 8.32337 10.1304
           H3.6087
           V4.65489
           L6.39402 1.91033Z
           M1.86957 4.91304
           H2.73913
           V10.1304
           H1.86957
           V4.91304Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
