import React from 'react';

import { colors } from '../lib/designSystem';

import { createStyles, makeStyles } from './Theme';

const ShapesSVG = () => (
  <svg fill="none" height="250" viewBox="0 0 900 250" width="900" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path
        d="
          M273 179.5C160.611 179.5 69.5 270.833 69.5 383.5
          C69.5 496.167 160.611 587.5 273 587.5
          C385.389 587.5 476.5 496.167 476.5 383.5
          C476.5 270.833 385.389 179.5 273 179.5Z
        "
        stroke="#8772F1"
      />
      <path
        d="
          M263.5 28.5C106.644 28.5 -20.5 153.644 -20.5 308
          C-20.5 462.356 106.644 587.5 263.5 587.5
          C420.356 587.5 547.5 462.356 547.5 308
          C547.5 153.644 420.356 28.5 263.5 28.5Z
        "
        stroke="#8772F1"
      />
      <path
        d="
          M269.5 -127.5C65.6919 -127.5 -99.5 32.573 -99.5 230
          C-99.5 427.427 65.6919 587.5 269.5 587.5
          C473.308 587.5 638.5 427.427 638.5 230
          C638.5 32.573 473.308 -127.5 269.5 -127.5Z
        "
        stroke="#8772F1"
      />
      <path
        d="
          M252 -283.5C3.73298 -283.5 -197.5 -88.5053 -197.5 152
          C-197.5 392.505 3.73297 587.5 252 587.5
          C500.267 587.5 701.5 392.505 701.5 152
          C701.5 -88.5052 500.267 -283.5 252 -283.5Z
        "
        stroke="#8772F1"
      />
      <path
        d="
          M234 -437.5C-57.8971 -437.5 -294.5 -208.032 -294.5 75
          C-294.5 358.032 -57.8971 587.5 234 587.5
          C525.897 587.5 762.5 358.032 762.5 75
          C762.5 -208.032 525.897 -437.5 234 -437.5Z
        "
        stroke="#8772F1"
      />
      <path
        d="
          M234 -592.5C-102.633 -592.5 -375.5 -328.333 -375.5 -2.50001
          C-375.5 323.333 -102.633 587.5 234 587.5
          C570.633 587.5 843.5 323.333 843.5 -2.5
          C843.5 -328.333 570.633 -592.5 234 -592.5Z
        "
        opacity="0.8"
        stroke="#8772F1"
      />
      <path
        d="
          M216 -751.5C-165.92 -751.5 -475.5 -451.74 -475.5 -82
          C-475.5 287.74 -165.92 587.5 216 587.5
          C597.92 587.5 907.5 287.74 907.5 -82
          C907.5 -451.74 597.92 -751.5 216 -751.5Z
        "
        opacity="0.7"
        stroke="#8772F1"
      />
      <path
        d="
          M216 -903.5C-209.551 -903.5 -554.5 -569.713 -554.5 -158
          C-554.5 253.713 -209.551 587.5 216 587.5
          C641.551 587.5 986.5 253.713 986.5 -158
          C986.5 -569.713 641.551 -903.5 216 -903.5Z
        "
        opacity="0.6"
        stroke="#8772F1"
      />
      <path
        d="
          M216 -1245.5C-307.306 -1245.5 -731.5 -835.153 -731.5 -329
          C-731.5 177.153 -307.306 587.5 216 587.5
          C739.306 587.5 1163.5 177.153 1163.5 -329
          C1163.5 -835.153 739.306 -1245.5 216 -1245.5Z
        "
        opacity="0.5"
        stroke="#8772F1"
      />
    </g>
  </svg>
);

const useStyles = makeStyles(
  () => createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '250px',
      borderRadius: '8px',
      background: colors.neutral200,
      overflow: 'hidden',
    },
    svg: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
    text: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      fontSize: '14px',
      lineHeight: '20px',
      color: colors.neutral650,
    },
  }),
  { name: 'HowTo' },
);

export const HowTo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.svg}>
        <ShapesSVG />
      </div>
      <div className={classes.text}>
        Coming Soon
      </div>
    </div>
  );
};

export default HowTo;
