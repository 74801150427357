// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const ThumbsDownIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="thumbsDown"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M6.08152 11
           L5.94565 10.8777
           L2.99728 7.95652
           H1
           V1
           H8.32337
           C8.93648 1 9.47147 1.43308 9.60054 2.03261
           L10.538 6.38043
           C10.7096 7.18376 10.0812 7.95652 9.26087 7.95652
           H6.76087
           L6.84239 8.28261
           C6.93071 8.35054 6.98675 8.37942 7.11413 8.55435
           C7.31793 8.83288 7.52174 9.26427 7.52174 9.84511
           C7.52174 10.465 6.96128 11 6.25815 11
           H6.08152Z
           M6.39402 10.0897
           C6.57745 10.054 6.65217 9.97928 6.65217 9.84511
           C6.65217 9.45279 6.53329 9.20992 6.4212 9.05707
           C6.3091 8.90421 6.23098 8.86685 6.23098 8.86685
           L6.08152 8.78533
           L6.02717 8.6087
           L5.76902 7.63043
           L5.63315 7.08696
           H9.26087
           C9.54789 7.08696 9.74151 6.8373 9.68207 6.55707
           L8.75815 2.20924
           C8.71399 2.00543 8.53057 1.86957 8.32337 1.86957
           H3.6087
           V7.34511
           L6.39402 10.0897Z
           M1.86957 7.08696
           H2.73913
           V1.86957
           H1.86957
           V7.08696Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
