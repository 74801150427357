// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { useProjectContext } from '@/components/context/ProjectContext';
import { useIsSetupGeometryUpToDate } from '@/components/hooks/useInteractiveGeometry';
import { DiskInfoIcon } from '@/components/svg/DiskInfoIcon';
import { colors } from '@/lib/designSystem';
import { useGeometryServerStatus } from '@/recoil/geometry/geometryServerStatus';
import { useGeometryHasFeaturesWithIssues, useGeometryState } from '@/recoil/geometry/geometryState';
import { useLoadToSetup } from '@/recoil/geometry/useLoadToSetup';
import { useSelectedGeometry } from '@/recoil/selectedGeometry';
import { useNoCredits } from '@/recoil/useAccountInfo';
import { pushConfirmation, useSetConfirmations } from '@/state/internal/dialog/confirmations';

const warningText = (
  <div>
    A geometry is already loaded in this project. If you load a new geometry, the existing geometry,
    along with its associated meshes and results, will become inaccessible. However, the solver
    settings will persist.
    <p />
    If you need to retain access to the current geometry and its assets, please copy the project
    before proceeding.
    <p />
    Do you wish to continue?
  </div>
);

export const useHandleLoadToSetup = () => {
  const { projectId } = useProjectContext();
  const loadToSetup = useLoadToSetup();
  const setConfirmStack = useSetConfirmations();
  const [selectedGeometry] = useSelectedGeometry(projectId);

  return async () => {
    if (selectedGeometry?.geometryId) {
      // If there is selectedGeometry, user has already loaded to setup and this will overwrite the
      // setup geometry and parameters.
      pushConfirmation(setConfirmStack, {
        onContinue: loadToSetup,
        symbol: (
          <div style={{ padding: '2px' }}>
            <DiskInfoIcon color={colors.purple800} maxHeight={16} maxWidth={16} />
          </div>
        ),
        title: 'Loading to Setup',
        children: warningText,
      });
    } else {
      // If there is no selected geometry, we have not attempted load to setup yet.
      await loadToSetup();
    }
  };
};

export const useLoadToSetupDisabled = () => {
  const noCredits = useNoCredits();
  const isUpToDate = useIsSetupGeometryUpToDate();
  const { projectId, geometryId } = useProjectContext();
  const [geoServerStatus] = useGeometryServerStatus(geometryId);
  const geoState = useGeometryState(projectId, geometryId);
  const geometryHasFeatureWithIssues = useGeometryHasFeaturesWithIssues({ projectId, geometryId });

  const { disabled, disabledReason } = useMemo(() => {
    if (geoServerStatus === 'disconnected') {
      return { disabled: true, disabledReason: 'Geometry server is not connected.' };
    }
    if (geoServerStatus === 'busy') {
      return { disabled: true, disabledReason: 'Geometry server is busy.' };
    }
    if (isUpToDate) {
      return { disabled: true, disabledReason: 'Current geometry is already loaded.' };
    }
    if (geoState?.metadata.zone.length === 0) {
      return { disabled: true, disabledReason: 'No geometry detected.' };
    }
    if (geometryHasFeatureWithIssues) {
      return {
        disabled: true,
        disabledReason: 'Modifications have errors. Fix or remove failing modifications.',
      };
    }
    if (noCredits) {
      return {
        disabled: true,
        disabledReason: 'Credits are required to perform this action.',
      };
    }
    return { disabled: false, disabledReason: undefined };
  }, [
    isUpToDate,
    geoServerStatus,
    geoState?.metadata.zone.length,
    noCredits,
    geometryHasFeatureWithIssues,
  ]);

  return { disabled, disabledReason };
};
