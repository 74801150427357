import { LCVType } from '@luminarycloudinternal/lcvis';

import { Vector3 } from '../../../../ProtoDescriptor';
import { Level } from '../../../../proto/lcstatus/levels_pb';
import { CoordinatesIssue, VisualizerError } from '../../../../recoil/useSelectedVisualizerError';
import { colors, hexToRgbList } from '../../../designSystem';
import { Logger } from '../../../observability/logs';
import { LcvIdPair, LcvModule } from '../../types';
import { LcvObjectList } from '../base/LcvObjectList';

import { LCStatus } from '@/proto/lcstatus/lcstatus_pb';

const ERROR_POINT_COLOR = hexToRgbList(colors.red500);
const HOVERED_ERROR_POINT_COLOR = hexToRgbList(colors.red300);

const WARNING_POINT_COLOR = hexToRgbList(colors.yellow500);
const HOVERED_WARNING_POINT_COLOR = hexToRgbList(colors.yellow300);

const logger = new Logger('LcvErrorList');

type ClickCallback = ((data: VisualizerError | null) => void);

export class LcvErrorList extends LcvObjectList {
  private hoveredIndex: number | null = null;
  private clickedIndex: number | null = null;
  private lastRenderedData: [Vector3, CoordinatesIssue[]][] = [];
  private clickCallback: ClickCallback | null = null;

  constructor(lcv: LcvModule, sessionHandle: number, size: number) {
    super(
      lcv,
      lcv.newAnnotation(sessionHandle, 'monitor_points', 0).annotation,
      sessionHandle,
      size,
    );

    this.setParam('enable_depth_testing', LCVType.kLCVDataTypeUint, 0);
    this.setParam('shade_flat', LCVType.kLCVDataTypeUint, 1);
  }

  private getHighestIssueLevel(index: number) {
    const DEFAULT_LEVEL = 'warning';
    const renderedItem = this.lastRenderedData.at(index);

    if (!renderedItem) {
      return DEFAULT_LEVEL;
    }

    return renderedItem[1].reduce<'error' | 'warning'>(
      (result, item) => (item.issue.level === Level.ERROR ? 'error' : result),
      DEFAULT_LEVEL,
    );
  }

  private setClickedIndex(index: number | null) {
    this.hoveredIndex = null;

    // remove click from previously hovered point
    if (this.clickedIndex !== null) {
      const highestIssueLevel = this.getHighestIssueLevel(this.clickedIndex);

      this.setParamAtIndex(
        this.clickedIndex,
        'colors',
        LCVType.kLCVDataTypeFloat3,
        highestIssueLevel === 'warning' ? WARNING_POINT_COLOR : ERROR_POINT_COLOR,
      );
    }

    this.clickedIndex = index;
  }

  private setHoveredIndex(index: number | null) {
    if (index !== null && index >= this.size) {
      logger.error('Trying to hover non-exising error point');
      return;
    }

    // remove hover from previously hovered point
    if (this.hoveredIndex !== null) {
      const highestIssueLevel = this.getHighestIssueLevel(this.hoveredIndex);

      this.setParamAtIndex(
        this.hoveredIndex,
        'colors',
        LCVType.kLCVDataTypeFloat3,
        highestIssueLevel === 'warning' ? WARNING_POINT_COLOR : ERROR_POINT_COLOR,
      );
    }

    this.hoveredIndex = index;

    // apply hover if needed
    if (index !== null) {
      const highestIssueLevel = this.getHighestIssueLevel(index);

      this.setParamAtIndex(
        index,
        'colors',
        LCVType.kLCVDataTypeFloat3,
        highestIssueLevel === 'warning' ? HOVERED_WARNING_POINT_COLOR : HOVERED_ERROR_POINT_COLOR,
      );
    }
  }

  /** The `updateHoveredIdentifiers` function changes point colors under hover and thus provides
    * a visual cue to users, indicating that error points are clickable.
    * */
  public updateHoveredIdentifiers([objectId, primitiveIndex]: LcvIdPair) {
    if (objectId !== this.objectId) {
      this.setHoveredIndex(null);
      return false;
    }

    this.setHoveredIndex(primitiveIndex);
    return true;
  }

  /** The `updateSelection` callback is triggered when user clicks some object in the visualizer.
    * It enables us to invoke a callback and retrieve details about the clicked point in the UI.
    * */
  public updateSelection(selectionTarget: LcvIdPair | null) {
    if (selectionTarget === null) {
      this.setClickedIndex(null);
      this.clickCallback?.(null);
      return;
    }

    const [objectId, primitiveIndex] = selectionTarget;
    if (objectId !== this.objectId) {
      this.setClickedIndex(null);
      this.clickCallback?.(null);
      return;
    }

    const foundPoint = this.lastRenderedData.at(primitiveIndex);

    if (foundPoint) {
      const [coordinates, issues] = foundPoint;

      this.clickCallback?.({ coordinates, issues });
      this.setClickedIndex(primitiveIndex);
    } else {
      this.clickCallback?.(null);
      this.setClickedIndex(null);
      logger.error(`Point with ${primitiveIndex} index was clicked but not recognised`);
    }
  }

  /** Ensures the list contains exactly N items, adding new items or removing existing ones
    * as necessary.
    * */
  private ensurePointsSize(expectedSize: number) {
    if (expectedSize < this.size) {
      const initialSize = this.size;
      const itemsToDelete = initialSize - expectedSize;

      for (let index = 0; index < itemsToDelete; index += 1) {
        const indexToDelete = initialSize - index - 1;

        this.deleteItemAtIndex(indexToDelete);
      }
    } else if (expectedSize > this.size) {
      const itemsToAdd = expectedSize - this.size;

      for (let index = 0; index < itemsToAdd; index += 1) {
        this.increaseSizeByOne();
      }
    }
  }

  /** The `updateProblematicCoordinates` method updates the points to reflect the latest data. */
  public updateProblematicCoordinates(problematicCoordinates: Map<Vector3, CoordinatesIssue[]>) {
    this.hoveredIndex = null;
    this.clickedIndex = null;
    this.lastRenderedData = [...problematicCoordinates.entries()];

    // when updating items we need to close the currently open panel
    this.clickCallback?.(null);
    this.ensurePointsSize(this.lastRenderedData.length);

    // if we face some performance issues we can do some optimizations here
    this.lastRenderedData.forEach(([point], index) => {
      this.setParamAtIndex(
        index,
        'points',
        LCVType.kLCVDataTypeFloat3,
        [point.x, point.y, point.z],
      );

      const highestIssueLevel = this.getHighestIssueLevel(index);
      this.setParamAtIndex(
        index,
        'colors',
        LCVType.kLCVDataTypeFloat3,
        highestIssueLevel === 'warning' ? WARNING_POINT_COLOR : ERROR_POINT_COLOR,
      );
    });
  }

  public setClickCallback(clickCallback: ClickCallback | null) {
    this.clickCallback = clickCallback;
  }

  public getCoordinateIssuesByStatusIssue(statusIssue: LCStatus) {
    return this.lastRenderedData
      .find(([, issues]) => issues.some((item) => item.issue === statusIssue));
  }
}
