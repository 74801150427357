// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { validateExpressions } from '../../../../lib/customFieldNodeUtils';
import { Logger } from '../../../../lib/observability/logs';
import * as feoutputpb from '../../../../proto/frontend/output/output_pb';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useCustomFieldNodes } from '../../../../recoil/customFieldNodes';
import { useLcVisEnabledValue } from '../../../../recoil/lcvis/lcvisEnabledState';
import { useViewStateOverflow } from '../../../../recoil/lcvis/viewStateOverflow';
import { useActiveVisUrlValue } from '../../../../recoil/vis/activeVisUrl';
import { diskDataNames, isDiskName } from '../../../Paraview/DataNameSelect';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import { BaseExpressionPanel } from '../common/BaseExpressionPanel';

const logger = new Logger('customFields/ExpressionPanel');

// Custom hook for updating expressions
const useValidateExpression = (projectId: string, jobId: string, activeUrl: string) => {
  const [customFieldNodes, setCustomFieldNodes] = useCustomFieldNodes(projectId);
  return (newExpression: string, id: string) => {
    const expressions = new Map<string, string>().set(id, newExpression);
    validateExpressions(
      customFieldNodes,
      setCustomFieldNodes,
      projectId,
      jobId,
      activeUrl,
      expressions,
    )
      .catch((error) => {
        logger.error('Error validating expressions:', error);
      });
  };
};

export interface ExpressionPanelProps {
  customFieldNodes: feoutputpb.CustomFields;
  customFieldNode: feoutputpb.CustomField;
  projectId: string;
  workflowId: string;
  jobId: string;
  error?: boolean;
}

export function ExpressionPanel(props: ExpressionPanelProps) {
  const { error, customFieldNodes, customFieldNode, projectId, workflowId, jobId } = props;

  const activeUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const handleUpdateExpression = useValidateExpression(projectId, jobId, activeUrl);

  const { viewState } = useParaviewContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const fieldList = useMemo(() => {
    const appendices = ['-X', '-Y', '-Z', '-Magnitude'];
    const ignore: string[] = [
      // TODO(LC-23138): This is only necessary until LcVis supports actuator disks and replaces
      // Paraview for the server backend.
      ...diskDataNames.map((name) => `${name} (Disk)`),

      // TODO(LC-23139): This is only necessary until support is added for custom fields to
      // reference one another.
      ...customFieldNodes.customFields.map((field) => field.name),
    ];

    return [
      ...(lcvisEnabled ? lcvisData.data : viewState?.data)
        ?.filter(({ type }) => type === ParaviewRpc.FieldAssociation.POINT)
        ?.filter(({ name }) => !ignore.includes(name) &&
          !(isDiskName(name) && ignore.includes(`${name} (Disk)`)))
        .flatMap(({ name, dim }) => {
          if (dim === 1) {
            return name;
          }
          if (dim === 3) {
            return appendices
              .map((appendix) => name + appendix);
          }
          return [];
        }) || [],
    ];
  }, [customFieldNodes, lcvisEnabled, lcvisData.data, viewState?.data]);

  return (
    <BaseExpressionPanel
      error={error}
      expression={customFieldNode.expression}
      expressionId={customFieldNode.id}
      onUpdateExpression={handleUpdateExpression}
      options={fieldList}
      placeholderText={'Use existing fields, math operators, and numeric values to create an ' +
        'expression.\nExample: max(0.0, "Pressure")'}
      tooltipText={'Define a custom field using an expression with existing fields in ' +
        'quotation marks.'}
    />
  );
}
