// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as frontendpb from '../../../proto/frontend/frontend_pb';
import { useLatestGeometryVersionid } from '../../../recoil/geometry/geometryState';
import { useGeometryHealthValue } from '../../../recoil/geometryHealth';
import { usePendingWorkOrders } from '../../../recoil/pendingWorkOrders';
import { ActionButton } from '../../Button/ActionButton';
import { useProjectContext } from '../../context/ProjectContext';

import { useLoadToSetupDisabled } from '@/components/hooks/useLoadToSetup';

export const CheckGeometryButton = () => {
  const { projectId, geometryId } = useProjectContext();
  const geometryVersionId = useLatestGeometryVersionid(projectId, geometryId);
  const { disabled, disabledReason } = useLoadToSetupDisabled();
  const [pendingWorkOrders, setPendingWorkOrders] = usePendingWorkOrders(projectId);
  const geometryHealthState = useGeometryHealthValue(projectId);
  const pending = pendingWorkOrders.workOrders[frontendpb.WorkOrderType.CHECK_GEOMETRY];
  const pendingHasSameVersionId = pending?.typ?.value?.geometryVersionId === geometryVersionId;
  const disabledHealth = disabled || geometryHealthState !== null ||
    (pending && pendingHasSameVersionId);
  const handleCheckGeometry = () => {
    setPendingWorkOrders((prev) => {
      if (geometryVersionId === '') {
        return prev;
      }
      const workOrdersMap = prev.clone();
      const workOrder = new frontendpb.PendingWorkOrder({
        typ: {
          case: 'checkGeometry',
          value: new frontendpb.CheckGeometryRequest({
            projectId,
            geometryId,
            geometryVersionId,
          }),
        },
      });

      workOrdersMap.workOrders[frontendpb.WorkOrderType.CHECK_GEOMETRY] = workOrder;
      return workOrdersMap;
    });
  };

  return (
    <ActionButton
      asBlock
      disabled={disabledHealth}
      onClick={handleCheckGeometry}
      size="medium"
      title={disabledReason}>
      Check Geometry
    </ActionButton>
  );
};
