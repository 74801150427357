// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { CurrentView } from '../../../lib/componentTypes/context';
import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { assembleMenuSections, filteredMenuItems } from '../../../lib/menuUtil';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem, visibilityToggleTreeNodeMenuItem } from '../../../lib/treeUtils';
import {
  applyVisibilityToNode,
  evaluateVisibility,
  hideAllFilters,
  isClipOrSlice,
  showAllLeaves,
} from '../../../lib/visUtils';
import { UrlType } from '../../../proto/projectstate/projectstate_pb';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcvisFilterStatusValue } from '../../../recoil/lcvis/lcvisFilterStatus';
import { useMeshUrlState } from '../../../recoil/meshState';
import { useFilterState, useResetFilterState } from '../../../recoil/vis/filterState';
import { pushConfirmation, useSetConfirmations } from '../../../state/internal/dialog/confirmations';
import { useCurrentView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useCopyVisFilter } from '../../hooks/nodeDuplication/useCopyVisFilter';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { useFilterNode } from '../../visFilter/useFilterNode';
import { TreeRow } from '../TreeRow';
import { VisualizationsActionButton } from '../VisualizationsActionButton';

import environmentState from '@/state/environment';

// A row displaying information about a clip, slice, or other filter.
export const FilterRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;
  const { name } = node;

  // == Contexts
  const {
    changeNodeVisibility,
    hideAllVisualizations,
    showAllLeafs,
    toggleLICGeometrySurfaceVisibility,
    viewState,
  } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const currentView = useCurrentView();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const [filterState, setFilterState] = useFilterState({ projectId, workflowId, jobId });
  const [meshUrlState] = useMeshUrlState(projectId);
  const lcvisFilterStatus = useLcvisFilterStatusValue();
  const setConfirmStack = useSetConfirmations();
  const { resetVisState } = useParaviewContext();
  const resetFilterState = useResetFilterState({ projectId, workflowId, jobId });

  // == Hooks
  const renaming = useNodeRenaming(node);
  // Note: Since the useFilterNode hook uses 'viewState' to look up the 'filterNode', 'filterNode'
  // and 'param' could be null, so the rest of this component treats them as if they may not be
  // defined.  Practically speaking, though, these filter rows are only displayed when viewState
  // is available; TS just can't determine that.
  const { filterNode, param, iconName } = useFilterNode(node);
  const { canDelete, deleteFilterNode, postDeleteNodeIds } = useNodeDeletion();
  const duplicateRow = useCopyVisFilter();

  // == Data
  const isGeometryView = currentView === CurrentView.GEOMETRY;
  const isMesh = meshUrlState.activeType === UrlType.MESH;

  // READER is the root Visualizations node
  const isReaderNode = useMemo(() => (param?.typ === ParaviewRpc.TreeNodeType.READER), [param]);

  const visStats = useMemo(() => {
    if (lcvisEnabled) {
      return evaluateVisibility(filterState);
    }
    return (viewState ? evaluateVisibility(viewState.root) : null);
  }, [viewState, lcvisEnabled, filterState]);

  const isVisible = useMemo(() => {
    if (isReaderNode) {
      return visStats?.anyVisible ?? false;
    }
    return filterNode?.visible ?? false;
  }, [visStats?.anyVisible, filterNode, isReaderNode]);

  const showActionButton = useMemo(
    () => {
      if (lcvisEnabled) {
        return !isGeometryView;
      }
      return viewState && !isGeometryView;
    },
    [viewState, isGeometryView, lcvisEnabled],
  );

  // Handlers
  const toggleVisibility = () => {
    if (isReaderNode) {
      if (isVisible) {
        if (lcvisEnabled) {
          let newFilterState = hideAllFilters(filterState);
          if (lcvisReady) {
            newFilterState = lcvHandler.display!.filterHandler!.maybeUpdateVisibility(
              newFilterState,
            );
          }
          setFilterState(newFilterState);
          return;
        }
        hideAllVisualizations();
      } else {
        if (lcvisEnabled) {
          let newFilterState = showAllLeaves(filterState);
          if (lcvisReady) {
            newFilterState = lcvHandler.display!.filterHandler!.maybeUpdateVisibility(
              newFilterState,
            );
          }
          setFilterState(newFilterState);
          return;
        }
        showAllLeafs();
      }
    } else if (filterNode) {
      const currVisibility = filterNode.visible;
      if (lcvisEnabled) {
        let newFilterState = applyVisibilityToNode(
          filterState,
          filterNode.id,
          isClipOrSlice(filterNode),
          !currVisibility,
          isMesh,
        );
        if (lcvisReady) {
          // since lcvisReady is true, the display and filterHandler must already exist.
          newFilterState = lcvHandler.display!.filterHandler!.maybeUpdateVisibility(
            newFilterState,
          );
        }
        setFilterState(newFilterState);
        return;
      }
      changeNodeVisibility(filterNode.id, !currVisibility);
      toggleLICGeometrySurfaceVisibility(filterNode, currVisibility);
    }
  };

  const primaryIcon = useMemo(() => (iconName ? { name: iconName } : undefined), [iconName]);

  const deleteRow = useCallback(() => {
    if (deleteFilterNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteFilterNode, node.id, postDeleteNodeIds]);

  const resetVisualizations = useCallback(() => {
    if (lcvisEnabled) {
      if (lcvisReady) {
        lcvHandler.display!.filterHandler!.deleteAllNodes();
      }
      resetFilterState();
    } else {
      resetVisState();
    }
  }, [lcvisEnabled, lcvisReady, resetFilterState, resetVisState]);

  // Handlers
  const queueReset = useCallback(() => {
    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: () => resetVisualizations(),
      title: 'Reset Visualization State',
      children: (
        <div>This will delete the visualization state. Are you sure you want to proceed?</div>
      ),
    });
  }, [resetVisualizations, setConfirmStack]);

  const visCtrlDisabled = lcvisEnabled && !lcvisReady;

  const getContextMenuItems = () => {
    // Do not show the visibility control if there are no filters. This avoid showing a disabled
    // eye icon when there are no visualizations.
    const showVizControl = !isReaderNode || filterState.child.length > 0;
    const visItems = filteredMenuItems([
      {
        itemConfig: visibilityToggleTreeNodeMenuItem(isVisible, toggleVisibility, visCtrlDisabled),
        shouldShow: showVizControl,
      },
    ]);

    const actionItems = filteredMenuItems([
      {
        itemConfig: { label: 'Reset', onClick: queueReset },
        shouldShow: !!showActionButton,
      },
    ]);

    const crudDisabled = !canDelete(node.type, node.id);
    const crudItems = filteredMenuItems([
      {
        itemConfig: duplicateTreeNodeMenuItem(() => duplicateRow(node.id), crudDisabled),
        shouldShow: !!param && !isReaderNode,
      },
      {
        itemConfig: deleteTreeNodeMenuItem(deleteRow, crudDisabled),
        shouldShow: !!param && !isReaderNode,
      },
    ]);

    return assembleMenuSections(visItems, actionItems, crudItems);
  };

  const visButton = (
    <VisibilityButton disabled={visCtrlDisabled} isVisible={isVisible} onClick={toggleVisibility} />
  );

  const status = lcvisFilterStatus.get(node.id);
  const dim = status ? !(status.status === 'completed') : false;
  const displayName = (() => {
    if (status?.status === 'completed') {
      return name;
    }
    if (status?.status === 'queued') {
      return `${name} (queued)`;
    }
    if (status?.status === 'error') {
      return `${name} (failed)`;
    }
    if (typeof status?.status === 'number') {
      return `${name} (${status.status}%)`;
    }
    return name;
  })();

  const badgeLevel = status?.status === 'error' ? 'error' : undefined;

  return (
    <TreeRow
      {...props}
      auxControl={(
        showActionButton ?
          <VisualizationsActionButton parentNodeId={node.id} /> :
          undefined
      )}
      badgeLevel={badgeLevel}
      dimmed={dim}
      // Filters can be edited in shared projects.
      forceUserCanEdit
      getContextMenuItems={getContextMenuItems}
      label={displayName}
      primaryIcon={primaryIcon}
      renaming={renaming}
      visibilityButton={visButton}
    />
  );
};
