// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { DefaultValue, atomFamily, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CurrentView } from '../../lib/componentTypes/context';
import { currentViewState } from '../../state/internal/global/currentView';
import { entitySelectionState } from '../selectionOptions';

const showSurfacesAtom = atomFamily<boolean, string>({
  key: 'showSurfacesAtom',
  default: false,
});

/**
 * Whether to show the surfaces in the geometry tree panel. If this is false, we will only
 * display volumes in the geometry tree.
 */
const showSurfacesSelector = selectorFamily<boolean, string>({
  key: 'showSurfacesSelector',
  get: (projectId: string) => ({ get }) => {
    const isGeometryView = get(currentViewState) === CurrentView.GEOMETRY;
    const showSurfacesState = get(showSurfacesAtom(projectId));
    const entitySelection = get(entitySelectionState(projectId));

    return !isGeometryView || showSurfacesState || entitySelection === 'surface' ||
      entitySelection === 'surface_no_highlight';
  },
  set: (projectId: string) => ({ set }, newValue: boolean | DefaultValue) => {
    set(showSurfacesAtom(projectId), newValue);
  },
});

export const useGeoShowSurfaces = (
  projectId: string,
) => useRecoilState(showSurfacesSelector(projectId));

export const useGeoShowSurfacesValue = (
  projectId: string,
) => useRecoilValue(showSurfacesSelector(projectId));

export const useSetGeoShowSurfaces = (
  projectId: string,
) => useSetRecoilState(showSurfacesSelector(projectId));
