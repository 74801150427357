// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useState } from 'react';

import cx from 'classnames';

import { Color, IconName } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { AnyEvent, isUnmodifiedSpaceKey } from '../../lib/event';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { FolderTriangle } from '../controls/FolderTriangle';
import Collapsible from '../transition/Collapsible';

const useStyles = makeStyles(
  () => createStyles({
    arrow: {
      display: 'flex',
      padding: '5px 8px',
      color: colors.neutral800,
      cursor: 'pointer',
    },
    card: {
      cursor: 'pointer',
      display: 'flex',
      padding: '8px 12px 8px 4px',
      transitionProperty: 'opacity, height',
      transitionDuration: '500ms',
      lineHeight: '19.5px',
      border: 'none',
      textAlign: 'start',
      color: colors.neutral800,
      background: 'none',

      '&.fullWidth': {
        width: '100%',
      },
    },
    rounded: {
      borderRadius: '4px',
    },
    cardText: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '6px',

      '&.fullWidth': {
        flex: '1 1 auto',
      },
    },
    description: {
      color: colors.inputPlaceholderText,
      paddingTop: '6px',
      lineHeight: 1.25,
    },
    selectedCard: {
      background: colors.purple100,
    },
    selectedDesc: {
      color: colors.lowEmphasisText,
    },
  }),
  { name: 'CollapsibleCard' },
);

export interface CollapsibleCardProps {
  // A CollapsibleCard may optionally have an icon preceding the title.
  iconName?: IconName;
  iconColor?: Color;
  iconTooltip?: string;
  // If selected, this card will be highlighted.
  selected?: boolean;
  // Whether the edges of the card should be slightly rounded.
  rounded?: boolean;
  // The title to be shown even when the CollapsibleCard is collapsed.
  title: string;
  // Click handler.
  onClick?: React.MouseEventHandler;
  children: ReactNode;
  fullContentWidth?: boolean;
  toggleOnClick?: boolean;
}

// CollapsibleCard is a collapsible, clickable card often used in lists.
// The collapsible contents of the CollapsibleCard are the children, and
// may be arbitrary.  When collapsed, the collapse arrow, optional icon
// and title of the card are visible.
export const CollapsibleCard = (props: CollapsibleCardProps) => {
  const {
    iconName,
    iconColor,
    iconTooltip,
    rounded,
    selected,
    title,
    onClick,
    fullContentWidth = false,
    toggleOnClick = false,
  } = props;
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const classes = useStyles();
  const icon = (
    iconName && (
    <SvgIcon
      color={iconColor}
      maxHeight={12}
      maxWidth={12}
      name={iconName}
    />
    )
  );
  const clickArrow = (ev: AnyEvent) => {
    ev.stopPropagation();
    setCollapsed(!collapsed);
  };
  return (
    <button
      className={cx(classes.card, {
        [classes.selectedCard]: selected,
        [classes.rounded]: rounded,
        fullWidth: fullContentWidth,
      })}
      onClick={(event) => {
        if (toggleOnClick) {
          setCollapsed(!collapsed);
        }

        onClick?.(event);
      }}
      tabIndex={-1}
      type="button">
      <div
        className={classes.arrow}
        onClick={clickArrow}
        onKeyUp={(event) => {
          if (isUnmodifiedSpaceKey(event)) {
            clickArrow(event);
          }
        }}
        role="button"
        tabIndex={0}>
        <FolderTriangle open={!collapsed} />
      </div>
      {iconName && (
        <div>
          <Tooltip title={iconTooltip}>
            <span>{icon}</span>
          </Tooltip>
        </div>
      )}
      <div className={cx(classes.cardText, fullContentWidth && 'fullWidth')}>
        <div>{title}</div>
        <Collapsible collapsed={collapsed}>
          <div className={cx(classes.description, { [classes.selectedDesc]: selected })}>
            {props.children}
          </div>
        </Collapsible>
      </div>
    </button>
  );
};
