import { LCVType } from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';

import { RgbColor } from '../../../designSystem';
import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { MultiSliceState } from './filterUtils';

export class LcvMultiSlice extends LcvFilter {
  parent: LcvFilter;
  state: MultiSliceState;
  color: RgbColor | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: MultiSliceState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'multi_slice',
        `multi_slice_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'multi_slice',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  /**
   * Pass the object params into LCVis
   */
  updateParams() {
    const {
      start_pos,
      end_pos,
      n_slices,
      project_vectors,
    } = this.state;

    this.setParam('start_pos', LCVType.kLCVDataTypeFloat3, start_pos);
    this.setParam('end_pos', LCVType.kLCVDataTypeFloat3, end_pos);
    this.setParam('nslices', LCVType.kLCVDataTypeUint, n_slices);
    this.setParam('project_vectors', LCVType.kLCVDataTypeUint, +project_vectors);
  }

  setState(newState: MultiSliceState) {
    if (deepEqual(this.state, newState)) {
      return;
    }
    this.state = newState;
    this.updateParams();
  }
}
