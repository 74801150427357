// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import assert from '../../../lib/assert';
import { findCustomFieldNodeById } from '../../../lib/customFieldNodeUtils';
import { colors } from '../../../lib/designSystem';
import { useCustomFieldNodes } from '../../../recoil/customFieldNodes';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { AttributesDisplay } from '../AttributesDisplay';
import PropertiesSection from '../PropertiesSection';

import { ExpressionPanel } from './customFields/ExpressionPanel';

export const CustomFieldPropPanel = () => {
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected custom field row');

  const { projectId, workflowId, jobId } = useProjectContext();

  const [customFields] = useCustomFieldNodes(projectId);

  const commonClasses = useCommonTreePropsStyles();

  const customField = findCustomFieldNodeById(customFields, node.id);
  assert(!!customField, 'No selected custom field');

  return (
    <div className={commonClasses.properties}>
      <AttributesDisplay
        attributes={[{
          label: 'Type',
          value: 'Scalar Custom Field',
        }]}
      />
      <Divider />
      <PropertiesSection>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <ExpressionPanel
            customFieldNode={customField}
            customFieldNodes={customFields}
            error={!!customField.errors.length}
            jobId={jobId}
            projectId={projectId}
            workflowId={workflowId}
          />
          {!!customField.errors.length && (
            <pre style={{ color: colors.red600, whiteSpace: 'pre-wrap' }}>
              {customField.errors}
            </pre>
          )}
        </div>
      </PropertiesSection>
    </div>
  );
};
