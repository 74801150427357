// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useMemo } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import * as flags from '../../flags';
import { FormControlSize } from '../../lib/componentTypes/form';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { BATCH_PROCESSING_URL } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { advancedAnalysisLink } from '../../lib/navigation';
import { LeveledMessage } from '../../lib/notificationUtils';
import { copyJobConfig } from '../../lib/proto';
import { getRunButtonText, getSimCount } from '../../lib/simulationTree/utils';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import { useNoCredits } from '../../recoil/useAccountInfo';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useIsBaselineMode, useIsExplorationStarterPlan } from '../../recoil/useProjectPage';
import { useBatchModeChecked, useCurrentConfig } from '../../recoil/workflowConfig';
import { analytics } from '../../services/analytics';
import { useRunSimulationPending } from '../../state/external/project/simulation/runSimulationPending';
import { useSetSetupSummaryOpened } from '../../state/external/project/simulation/setupSummaryOpened';
import { useRefreshUserQuotaRpc, useUserQuota } from '../../state/external/user/resourcesQuota';
import { useIsAdvancedAnalysisView, useIsSetupOrAdvancedView } from '../../state/internal/global/currentView';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { SplitButton, SplitKind } from '../Button/SplitButton';
import { RadioButton } from '../Form/RadioButton';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { useProjectContext } from '../context/ProjectContext';
import { useIsLMAActive } from '../hooks/useMesh';
import { useClickRunSimulationButton, useRunSimulationButtonProps } from '../hooks/useRunSimulation';
import { useRuntimeEstimate } from '../hooks/useRuntimeEstimate';
import { LeveledMessageList } from '../notification/LeveledMessageList';
import { ClockResetIcon } from '../svg/ClockResetIcon';
import { DiskInfoIcon } from '../svg/DiskInfoIcon';

const useStyles = makeStyles(
  () => createStyles({
    runControl: {
      flex: '0 0 auto',
      display: 'flex',

      '&.padding': {
        padding: '8px',
      },
    },
    runButton: {
      flex: '1 1 auto',
    },
    batchDelayIcon: {
      display: 'flex',
    },
    optionsMenuItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    radioOptionsIcon: {
      flexGrow: 1,
    },
    batchProcessingLink: {
      display: 'flex',
      alignItems: 'center',
    },
    nonIconLabel: {
      // this matches the radio button we show for the other dropdown items
      paddingLeft: '24px',
    },
  }),
  { name: 'RunSimulationButton' },
);

function updateBatchPriority(oldConfig: workflowpb.Config, batch: boolean): workflowpb.Config {
  const newConfig = copyJobConfig(oldConfig);
  const priority = new workflowpb.Priority({ batch });
  newConfig.jobConfigTemplate!.priority = priority;
  return newConfig;
}

interface RunSimulationButtonProps {
  size?: FormControlSize;
  kind?: SplitKind;
  padding?: boolean;
}

const READ_ONLY_TOOLTIP = 'Action unavailable in read-only mode.';

const RunSimulationButton = (props: RunSimulationButtonProps) => {
  // == Props
  const { size = 'small', kind = 'primary', padding } = props;

  // == Contexts
  const {
    projectId,
    workflowId,
    jobId,
    onNewWorkflowConfig,
    readOnly,
  } = useProjectContext();

  // == Hooks
  const classes = useStyles();
  const navigate = useNavigate();

  // == State
  const { disabled, messages } = useRunSimulationButtonProps();
  const [runSimulationPending] = useRunSimulationPending();
  const setSummaryOpened = useSetSetupSummaryOpened();
  const currentConfig = useCurrentConfig(projectId, workflowId, jobId);
  const experimentConfig = useEnabledExperiments();
  const runtimeEstimate = useRuntimeEstimate();
  const userQuota = useUserQuota();
  const isAdvancedAnalysisView = useIsAdvancedAnalysisView();
  const isSetupOrAdvancedView = useIsSetupOrAdvancedView();
  const workflowFlag = useWorkflowFlagValue();
  const clickRunSimulationButton = useClickRunSimulationButton();
  const isBaselineMode = useIsBaselineMode();
  const batchModeChecked = useBatchModeChecked(projectId, workflowId, jobId);
  const isLMA = useIsLMAActive();
  const exploration = currentConfig.exploration;
  const isSensitivityAnalysis = exploration?.policy.case === 'sensitivityAnalysis';
  const simCount = getSimCount(currentConfig);

  const refreshUserQuotaState = useRefreshUserQuotaRpc();

  const runButtonText = getRunButtonText(
    isBaselineMode,
    isSensitivityAnalysis,
    currentConfig,
    isLMA,
  );

  const hasNoCredits = useNoCredits();
  const isExplorationButtonDisabled = useIsExplorationStarterPlan();

  // Handle the quota for high priority simulations to reduce the chances of the backend returning
  // an error.
  const prioritySimulationsLeft = useMemo(() => {
    const quota = userQuota.get(
      frontendpb.GetUserQuotaReply_ResourceQuotaType.FVM_HIGH_PRIORITY_UNLIMITED_PLAN,
    );
    return quota ? (quota.quota - quota.used) : undefined;
  }, [userQuota]);
  const prioritySimulationsDisabled = !batchModeChecked && prioritySimulationsLeft !== undefined &&
    (prioritySimulationsLeft - simCount) < 0;
  const prioritySimulationsLeftText = prioritySimulationsLeft !== undefined ?
    `(${prioritySimulationsLeft} left)` :
    '';

  // The setup summary should be present only when creating an individual simulation
  const hasSetupSummary = isSetupOrAdvancedView && isBaselineMode;

  let runtimeText = '';
  if (experimentConfig.includes(flags.runtimeEstimate) && simCount === 1) {
    switch (runtimeEstimate.state) {
      case 'hasValue': {
        const duration = runtimeEstimate.contents.duration;
        if (duration > 30) {
          runtimeText = 'Runtime: >30 min';
        } else if (duration > 0) {
          runtimeText = `Runtime: <${Math.ceil(duration)} min`;
        } else {
          runtimeText = 'Runtime: Not available';
        }
      }
        break;
      case 'loading':
        runtimeText = 'Estimating Runtime ...';
        break;
      case 'hasError':
      default:
        throw Error('Error estimating runtime');
    }
  }

  const messagesWithQuotaErrors = useMemo(() => {
    if (prioritySimulationsDisabled) {
      const newMessage: LeveledMessage = {
        message: 'Insufficient high priority simulations quota remaining.',
        level: 'error',
      };
      return [...messages, newMessage];
    }
    return messages;
  }, [messages, prioritySimulationsDisabled]);

  const tooltip = messagesWithQuotaErrors.length ?
    <LeveledMessageList maxToShow={10} messages={messagesWithQuotaErrors} /> :
    runtimeText;

  const menuItems: CommonMenuItem[] = [];
  if (!workflowFlag && hasSetupSummary) {
    menuItems.push({
      label: <div className={classes.nonIconLabel}>View Setup Summary</div>,
      onClick: () => {
        setSummaryOpened(true);
        analytics.track('Setup Summary Opened');
      },
    });
  }
  if (workflowFlag && !isAdvancedAnalysisView) {
    menuItems.push({
      label: <div className={classes.nonIconLabel}>Setup Design of Experiments</div>,
      disabled: readOnly,
      disabledReason: readOnly ? READ_ONLY_TOOLTIP : '',
      onClick: () => {
        navigate(
          advancedAnalysisLink(
            projectId,
            workflowId,
            jobId,
            'doe',
            'setup',
          ),
        );
        analytics.track('Setup Design of Experiments');
      },
    });
  }
  if (menuItems.length) {
    menuItems.push({ separator: true });
  }
  menuItems.push(
    {
      label: (
        <div className={classes.optionsMenuItem}>
          <RadioButton
            checked={!batchModeChecked}
            disabled={readOnly}
            name="runMode"
            onClick={() => { }}
            value="normal"
          />
          <div className={classes.radioOptionsIcon}>
            Priority {prioritySimulationsLeftText}
          </div>
          <Tooltip title="Priority jobs run immediately.">
            <a
              className={classes.batchProcessingLink}
              href={BATCH_PROCESSING_URL}
              onClick={(event) => event.stopPropagation()}
              rel="noopener noreferrer"
              target="_blank">
              <DiskInfoIcon color={colors.lowEmphasisText} maxHeight={12} maxWidth={12} />
            </a>
          </Tooltip>
        </div>
      ),
      disabled: readOnly,
      disabledReason: readOnly ? READ_ONLY_TOOLTIP : '',
      nameAttribute: 'run-option-normal',
      onClick: () => {
        onNewWorkflowConfig(updateBatchPriority(currentConfig, false));
        analytics.track('Batch Mode Toggled', { enabled: false });
        refreshUserQuotaState();
      },
    },
    {
      label: (
        <div className={classes.optionsMenuItem}>
          <RadioButton
            checked={batchModeChecked}
            disabled={readOnly}
            name="runMode"
            onClick={() => {
              refreshUserQuotaState();
            }}
            value="batch"
          />
          <div className={classes.radioOptionsIcon}>
            Standard
          </div>
          <Tooltip title="Standard jobs use batch processing.">
            <a
              className={classes.batchProcessingLink}
              href={BATCH_PROCESSING_URL}
              onClick={(event) => event.stopPropagation()}
              rel="noopener noreferrer"
              target="_blank">
              <DiskInfoIcon color={colors.lowEmphasisText} maxHeight={12} maxWidth={12} />
            </a>
          </Tooltip>
        </div>
      ),
      disabled: readOnly,
      disabledReason: readOnly ? READ_ONLY_TOOLTIP : '',
      nameAttribute: 'run-option-batch',
      onClick: () => {
        onNewWorkflowConfig(updateBatchPriority(currentConfig, true));
        analytics.track('Batch Mode Toggled', { enabled: true });
        refreshUserQuotaState();
      },
    },
  );

  return (
    <div className={cx(classes.runControl, { padding })}>
      <div className={classes.runButton}>
        <SplitButton
          asBlock
          dataLocator="runSimulationButton"
          disabled={
            runSimulationPending ||
            disabled ||
            hasNoCredits ||
            isExplorationButtonDisabled
          }
          kind={kind}
          mainButtonDisabled={readOnly || prioritySimulationsDisabled}
          menuItems={menuItems}
          onClick={() => {
            clickRunSimulationButton().catch((err) => console.error(err)).finally(() => (
              refreshUserQuotaState()
            ));
          }}
          showSpinner={runSimulationPending}
          size={size}
          splitIcon={{ name: workflowFlag ? 'gear' : 'verticalControls', maxHeight: 12 }}>
          {batchModeChecked && (
            <Tooltip title="Standard Priority">
              <span className={classes.batchDelayIcon} data-locator="runSimulationBatchDelayIcon">
                <ClockResetIcon maxHeight={12} />
              </span>
            </Tooltip>
          )}
          <Tooltip title={readOnly ? READ_ONLY_TOOLTIP : tooltip}>
            <span>{runButtonText.label}</span>
          </Tooltip>
        </SplitButton>
      </div>
    </div>
  );
};

export default RunSimulationButton;
