// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily } from 'recoil';

import { RecoilProjectKey } from '../../../../lib/persist';
import { CUSTOM_FIELD_CONTAINER_ID, NodeType, SimulationTreeNode }
  from '../../../../lib/simulationTree/node';
import * as feoutputpb from '../../../../proto/frontend/output/output_pb';
import { customFieldNodesState } from '../../../../recoil/customFieldNodes';

export const customFieldSectionSelector = selectorFamily<SimulationTreeNode, RecoilProjectKey>({
  key: 'customFieldsSection',
  get: (key: RecoilProjectKey) => async ({ get }) => {
    const customFieldNodes = get(customFieldNodesState({
      projectId: key.projectId,
      workflowId: '',
      jobId: '',
    }));

    const treeOutputNodes: SimulationTreeNode[] = [
    // Add each custom field as a separate tree node
      ...customFieldNodes.customFields.map(
        ({ id, name }: feoutputpb.CustomField) => new SimulationTreeNode(
          NodeType.CUSTOM_FIELD,
          id,
          name,
        ),
      ),
    ];

    return new SimulationTreeNode(
      NodeType.CUSTOM_FIELD_CONTAINER,
      CUSTOM_FIELD_CONTAINER_ID,
      'Custom Fields',
      treeOutputNodes,
    );
  },
  dangerouslyAllowMutability: true,
});
