// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { colors } from '../../lib/designSystem';
import { useGeometryBusyMessageSelector, useIsGeoServerCreatingFeature } from '../../recoil/geometry/geometryServerStatus';
import { analytics } from '../../services/analytics';
import { SummaryPanel } from '../Panel/SummaryPanel';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useIsInitialLoadOrImportFeature } from '../hooks/geometry/useIsInitialLoadOrImportFeature';
import { LoadingEllipsis } from '../visual/LoadingEllipsis';
import { ProgressMessage } from '../visual/ProgressMessage';

const useStyles = makeStyles(
  () => createStyles({
    stepProgressMessages: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      padding: '12px',
    },
  }),
  { name: 'GeometryProgress' },
);

const heading = 'Geometry Preparation';
const summary = `This may take a few minutes. You can modify the geometry once import is
  completed. You can keep this project open or close it for later - we will continue to process your
  file in the background.`;

export const GeometryProgress = () => {
  const classes = useStyles();
  const { projectId, geometryId } = useProjectContext();
  const isInitialLoadOrImportFeature = useIsInitialLoadOrImportFeature();
  const geometryBusyMessage = useGeometryBusyMessageSelector(geometryId);
  const isGeoServerCreatingFeature = useIsGeoServerCreatingFeature(geometryId);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [prevBusyMessage, setPrevBusyMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isGeoServerCreatingFeature) {
      analytics.track('Geometry Preparation Started', { projectId, geometryId });
    }
  }, [isGeoServerCreatingFeature, projectId, geometryId]);

  useEffect(() => {
    if (geometryBusyMessage !== prevBusyMessage) {
      setPrevBusyMessage(geometryBusyMessage);
    }
  }, [geometryBusyMessage, prevBusyMessage, projectId, geometryId]);

  const handleToggleCollapse = () => {
    setCollapsed((prev) => !prev);
    analytics.track('Geometry Progress Panel Toggled', {
      projectId,
      geometryId,
      collapsed: !collapsed,
    });
  };

  if (!isInitialLoadOrImportFeature) {
    return null;
  }

  return (
    <>
      <SummaryPanel
        collapsed={collapsed}
        heading={collapsed ? <>{heading}<LoadingEllipsis /></> : heading}
        onToggle={handleToggleCollapse}
        summary={<>{summary}</>}>
        <div className={classes.stepProgressMessages}>
          <ProgressMessage
            backgroundColor={colors.surfaceLight2}
            details={[]}
            key="geometryBusyMessage"
            message={geometryBusyMessage}
            // Indeterminate progress bar by design.
            progress={null}
          />
        </div>
      </SummaryPanel>
    </>
  );
};
