import { useCallback, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { CurrentView } from '../../lib/componentTypes/context';
import { ProjectParams } from '../../lib/routeParamTypes';
import { useSetEntitySelection } from '../../recoil/selectionOptions';
import { useCurrentView } from '../../state/internal/global/currentView';

// Returns a hook that performs some operations when changing the current view/tab.
export const useSwitchPage = () => {
  const params = useParams<ProjectParams>();
  const projectId = params.projectId || '';
  const setSelectionType = useSetEntitySelection(projectId);
  const currentView = useCurrentView();
  const refCurrentView = useRef(currentView);

  // As per LC-22869, the desired behavior is to set the selection type to 'none' when switching to
  // the analysis page. If the views are the same, the selection type should not be changed. If
  // going from Analysis into something else, switch back to surfaces unless the selection type
  // is already set to something else that's not none. This is because in the setup phases, it is
  // useful to have selections ON by default.
  const onSwitchPage = useCallback((oldView: CurrentView, newView: CurrentView) => {
    const bothViewsTheSame = oldView === newView;
    if (oldView !== CurrentView.ANALYSIS && newView === CurrentView.ANALYSIS) {
      setSelectionType('surface_no_highlight');
    } else if (!bothViewsTheSame) {
      setSelectionType((oldSelectionType) => {
        if (oldSelectionType === 'surface_no_highlight') {
          return 'surface';
        }
        return oldSelectionType;
      });
    }
    return newView;
  }, [setSelectionType]);

  // Run this only when mounting, this makes sure that we set the correct default. We could also
  // move this into recoil maybe.
  useEffect(() => {
    if (currentView === CurrentView.ANALYSIS) {
      setSelectionType('surface_no_highlight');
      return;
    }
    setSelectionType('surface');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refCurrentView.current = onSwitchPage(refCurrentView.current, currentView);
  }, [currentView, onSwitchPage]);
};
