// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { deepEqual } from 'fast-equals';

import { ClientState, ViewName, useParaviewClientState } from '../../lib/ParaviewClient';
import assert from '../../lib/assert';
import { getParaviewViewName } from '../../lib/componentTypes/context';
import { RgbColor } from '../../lib/designSystem';
import { unwrapSurfaceIdsNoEntityGroups } from '../../lib/entityGroupUtils';
import {
  deleteFarFieldImposter,
  getImposterId,
  updateFarFieldImposter,
  updateImposters,
} from '../../lib/imposterFilteringUtils';
import { MeshMetadata } from '../../lib/mesh';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { Logger } from '../../lib/observability/logs';
import {
  boundsMaxLength,
  boundsScale,
  findNode,
  newNode,
  traverseTreeNodes,
  updateTreeNodes,
} from '../../lib/paraviewUtils';
import Renderer from '../../lib/renderer';
import * as status from '../../lib/status';
import { addInfo, addPvRpcError } from '../../lib/transientNotification';
import {
  ASYNC_VIS_NODES,
  AsyncVisNodeType,
  EditState,
  addChildNode,
  hideAllAncestorClips,
  hideAllDescendantClips,
  isClipOrSlice,
  isCurrentVisUrl,
  isFilterNode,
  renameTreeNode,
} from '../../lib/visUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as lcmeshpb from '../../proto/lcn/lcmesh_pb';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useMeshMetadata } from '../../recoil/meshState';
import {
  CameraMode,
  useCameraMode,
  useSetEditState,
  useSetParaviewInitialSettings,
} from '../../recoil/paraviewState';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useCadModifier } from '../../recoil/useCadModifier';
import { useViewState } from '../../recoil/useViewState';
import { useActiveVisUrlValue } from '../../recoil/vis/activeVisUrl';
import { useCurrentView } from '../../state/internal/global/currentView';
import { OverlayMode, useSetOverlayMode } from '../../state/internal/vis/overlayMode';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import ImageRenderer from './ImageRenderer';

const logger = new Logger('ParaviewManager');

export type ParaviewContextType = {
  // Following six fields are copies of the field in prop.
  // The project ID used by the current paraview window.
  paraviewProjectId: string;
  // The metadata of paraviewMeshURL.
  paraviewMeshMetadata: MeshMetadata | null;
  // The ID of the rendering window.
  paraviewViewName: ViewName,
  // Url of the file to load into paraview
  paraviewActiveUrl: string;

  // Connection to the paraview server. The connection may not have
  // been established, in which case paraviewClientState.client=null.
  paraviewClientState: ClientState;
  // Manages scene rendering and mouse interaction.
  paraviewRenderer: Renderer;
  setSyncing: (value: boolean) => void,
  resetViewState: () => void,

  backgroundColor: RgbColor,
  setBackgroundColor: (color: RgbColor) => void,

  syncing: boolean;
  cameraMode: CameraMode;
  colorMapsVisibility: Map<ParaviewRpc.DisplayPvVariable, boolean>;
  setCameraMode: (newMode: CameraMode) => void;

  setNodeName: (id: string, newName: string) => void;

  applyEdit: (param: EditState) => string;
  addNode: (parentNodeId: string, node: ParaviewRpc.TreeNode) => void;
  updateNodes: (callback: (node: ParaviewRpc.TreeNode) => ParaviewRpc.TreeNode) => void;
  activeEdit: (nodeId: string, param: ParaviewRpc.TreeNodeParam) => void;
  cancelEditState: () => void;
  changeNodeVisibility: (nodeId: string, wantVisible: boolean) => void;
  hideAllVisualizations: () => void;
  showAllLeafs: () => void;
  toggleLICGeometrySurfaceVisibility: (
    filterNode: ParaviewRpc.TreeNode,
    wantVisible: boolean
  ) => void;
  changeNodeDisplayProps: (nodeId: string, displayProps: ParaviewRpc.DisplayProps) => void;
  deleteNode: (nodeId: string) => void;

  setViewAttrs: (attrs: ParaviewRpc.ViewAttrs) => void;
  setGlobalViewState: (attrs: ParaviewRpc.ViewState) => void;
  getGlobalScalarDataRange: (
    displayVariable: ParaviewRpc.DisplayPvVariable,
    fieldType: ParaviewRpc.FieldAssociation,
  ) => [number, number] | null;
  viewState: ParaviewRpc.ViewState | null;
  getDataVisibilityBounds: (meshMetadata: lcmeshpb.MeshFileMetadata,
    customScaleFactor?: number) => ParaviewRpc.Bounds;
  getColorMap: (displayVariable: ParaviewRpc.DisplayPvVariable) => ParaviewRpc.ColorMap | null;
  updateColorMap: (
    displayVariable: ParaviewRpc.DisplayPvVariable,
    newCmap: ParaviewRpc.ColorMap,
  ) => void;
  displayVariableComponents: (displayVariable: ParaviewRpc.DisplayPvVariable) => number | null;
  displayVariableToText: (displayVariable: ParaviewRpc.DisplayPvVariable) => string;
  setViewStateDisplayVariable: (
    displayVariable: ParaviewRpc.DisplayPvVariable,
    changingComponent: boolean,
    node: ParaviewRpc.TreeNode | null,
  ) => void;
  visibilityMap: Map<string, boolean>;
  setVisibility: (newVisibility: Map<string, boolean>) => void;
  // Function that must called on successful paraview RPC resolution.
  // It updates viewState.
  onRpcSuccess: (label: string, result: ParaviewRpc.RpcResult) => void;
  impostersUpdate: (param: simulationpb.SimulationParam) => void;
  resetVisState: () => void;
  syncOnlyMeshSoln: (
    root: ParaviewRpc.TreeNode | null,
    attrs: ParaviewRpc.ViewAttrs | null,
  ) => void;

  fixGeometryTagsInViewState(
    viewStateIn: ParaviewRpc.ViewState,
    refRoot: ParaviewRpc.TreeNode,
  ): ParaviewRpc.TreeNode;
};

const X_PLUS: ParaviewRpc.Vector3 = { x: 1.0, y: 0.0, z: 0.0 };
const Y_PLUS: ParaviewRpc.Vector3 = { x: 0.0, y: 1.0, z: 0.0 };
const Z_PLUS: ParaviewRpc.Vector3 = { x: 0.0, y: 0.0, z: 1.0 };
const X_MINUS: ParaviewRpc.Vector3 = { x: -1.0, y: 0.0, z: 0.0 };
const Y_MINUS: ParaviewRpc.Vector3 = { x: 0.0, y: -1.0, z: 0.0 };
const Z_MINUS: ParaviewRpc.Vector3 = { x: 0.0, y: 0.0, z: -1.0 };
const NE_VIEW: ParaviewRpc.Vector3 = { x: 1.0, y: 1.0, z: 1.0 };
const NW_VIEW: ParaviewRpc.Vector3 = { x: -1.0, y: 1.0, z: 1.0 };
const SE_VIEW: ParaviewRpc.Vector3 = { x: 1.0, y: -1.0, z: 1.0 };
const SW_VIEW: ParaviewRpc.Vector3 = { x: -1.0, y: -1.0, z: 1.0 };

const ParaviewContext = createContext<ParaviewContextType | null>(null);

export function useParaviewContext(): ParaviewContextType {
  return useContext(ParaviewContext)!;
}

interface Props {
  children: ReactNode;
}

// Maps the view name to the paraview renderer.
const rendererMap: { [viewName: string]: Renderer } = {};

const ParaviewManager = (props: Props) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const paraviewActiveUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });

  const currentView = useCurrentView();
  const viewName = getParaviewViewName(currentView);
  const meshMetadata = useMeshMetadata(projectId, paraviewActiveUrl);
  const geometryTags = useGeometryTags(projectId);
  const paraviewClientState = useParaviewClientState(projectId, workflowId, jobId);
  const paraviewClient = paraviewClientState.client;

  let paraviewRenderer = rendererMap[viewName];
  if (!paraviewRenderer) {
    paraviewRenderer = new ImageRenderer();
    rendererMap[viewName] = paraviewRenderer;
  }

  // State to track when we are waiting for syncnodes to complete.
  // This is included in the context because syncnodes can take quite a while.
  const [syncing, setSyncing] = useState<boolean>(false);

  // Node edit session or new node creation session.
  const setEditState = useSetEditState();

  // Contents of the render view.
  const [viewState, setViewState] = useViewState(projectId);
  // A reference to the viewstate that will be updated, unlike all other
  // variable types.  We need the updated state when crafting new rpc calls so
  // we don't clobber the changes of other rpcs. useRef is the only hook that
  // allows us to keep track of what the current value is and not some state
  // state. Saying it another way, the viewState is a slightly older version of
  // currViewState.current.  We use currViewState.current when crafting new RPC
  // calls, so we have the most up-to-date version of the tree.
  const currViewState = useRef(viewState);

  // Helper function which allows us to restore the geometry tags after they have been unrolled.
  const fixGeometryTagsInViewState = useCallback(
    (viewStateIn: ParaviewRpc.ViewState, refRoot: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => (
      updateTreeNodes(
        viewStateIn.root,
        (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
          if (node.param.typ === 'ExtractSurfaces') {
            const origNode = findNode(refRoot, node.id);
            if (origNode) {
              const origParam = origNode.param as ParaviewRpc.ExtractSurfacesParam;
              const newParam = { ...node.param, surfaces: origParam.surfaces };
              return { ...node, param: newParam };
            }
          }
          return node;
        },
      )
    ),
    [],
  );

  // Helper function to update both the reference and the recoil value.
  const updateViewState = useCallback((newState: ParaviewRpc.ViewState | null): void => {
    if (newState && currViewState.current) {
      const newStateRoot = fixGeometryTagsInViewState(newState, currViewState.current.root);
      newState = { ...newState, root: newStateRoot };
    }
    // In some cases, this function is called both before and after an rpc call, resulting in
    // multiple updates to the viewState, even when it hasn't changed deeply.  Using deepEqual here
    // saves a big chunk (1/3 to 1/2) of re-renders when interacting with the simulation tree.
    if (!deepEqual(currViewState.current, newState)) {
      currViewState.current = newState;
      setViewState(newState);
    }
  }, [fixGeometryTagsInViewState, setViewState]);

  const resetViewState = () => updateViewState(null);

  // Track the color bars we currently need to display in the UI.
  const colorMapsVisibility = useMemo<Map<ParaviewRpc.DisplayPvVariable, boolean>>(() => {
    const newState = new Map<ParaviewRpc.DisplayPvVariable, boolean>();
    if (viewState) {
      if (viewState.attrs!.colorMaps) {
        const { colorMaps } = viewState!.attrs;
        colorMaps.forEach(
          (keyAndValue: [ParaviewRpc.DisplayPvVariable, ParaviewRpc.ColorMap]) => {
            newState.set(keyAndValue[0], keyAndValue[1].visible);
          },
        );
      }
    }
    return newState;
  }, [viewState]);

  const setOverlayMode = useSetOverlayMode();
  useEffect(() => setOverlayMode(OverlayMode.NONE), [setOverlayMode]);

  const setParaviewInitialSettings = useSetParaviewInitialSettings(
    projectId,
    paraviewActiveUrl,
    meshMetadata,
  );

  // The persisted camera mode setting (perspective, top, etc.)
  const [cameraMode, setCameraModeState] = useCameraMode(
    projectId,
    paraviewActiveUrl,
    meshMetadata,
  );

  const {
    selectedNodeIds,
    setSelection,
    setActiveNodeTable,
  } = useSelectionContext();

  const onRpcSuccessInternal = useCallback((label: string, result: ParaviewRpc.RpcResult) => {
    logger.debug(`${label}: rpc success internal:`, JSON.stringify(result));
    if (result.viewState) {
      // Paraview populates metadata from filter results such as spatial bounds
      // and field ranges. The frontend uses these values to set default params, so
      // we have to update those values from the response.

      // Its possible we get a response back from a old rpc after we switch urls.
      const valid = isCurrentVisUrl(result.viewState?.path);
      if (!valid) {
        logger.warn('onRpcSuccessInternal: Paraview response url does not match the current url.');
        logger.warn(`ActiveUrl: ${paraviewActiveUrl} and result url: ${result.viewState?.path}`);
      }

      if (currViewState.current && valid) {
        // merge the fitler metadata populated by paraview with the frontend
        // viewState.
        const newRoot = updateTreeNodes(
          currViewState.current.root,
          (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
            if (result.viewState) {
              // Find the matching node in the result, and if found, merge
              // the point data and bounds.
              const resultNode = findNode(result.viewState.root, node.id);
              if (resultNode) {
                return {
                  ...node,
                  bounds: resultNode.bounds,
                  pointData: resultNode.pointData,
                };
              }
            }
            return node;
          },
        );
        // We also need the color maps, since we determine on the paraview side
        // what color maps is visible.
        const attrs = {
          ...currViewState.current.attrs,
          colorMaps:
            result.viewState.attrs.colorMaps,
        };

        const newViewState = {
          ...currViewState.current,
          path: result.viewState.path,
          root: newRoot,
          attrs,
          data: result.viewState.data,
          surfaceData: result.viewState.surfaceData,
        };
        updateViewState(newViewState);
        // If there is no root, something probably went terribly wrong, so don't
        // overwrite good state with bad in recoil/kvstore.
        if (result.viewState.root) {
          setParaviewInitialSettings({
            filters: newViewState.root.child,
            attrs: result.viewState.attrs,
          });
        } else {
          logger.error('onRcpSuccessInternal: received null root.');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setParaviewInitialSettings, setViewState, paraviewActiveUrl]);
  // This version of onRpcSuccess is for use outside of the paraview context.
  // Its often used for syncnodes, where we need to make sure the paraview state is
  // adopted, such as when when we switch time steps/ projects.
  const onRpcSuccess = useCallback((label: string, result: ParaviewRpc.RpcResult) => {
    logger.debug(`${label}: rpc success:`, JSON.stringify(result));
    const valid = paraviewActiveUrl === result.viewState?.path;
    if (valid) {
      updateViewState(result.viewState);
    } else {
      logger.warn('onRpcSuccess: Paraview response url does not match the current url.');
      logger.warn(`ActiveUrl: ${paraviewActiveUrl} and result url: ${result.viewState?.path}`);
    }
    onRpcSuccessInternal(label, result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRpcSuccessInternal, setViewState, paraviewActiveUrl]);

  // Handle failures: we have already set the updated view state before sending the
  // rpc to paraview. When something like a setnodes calls fails, we have to revert
  // the state back to some 'last known good state', which paraview keeps track of.
  // In case of a failure, we retrieve the last known good state via an rpc, and set
  // the view state to match it.
  const onRpcFailureInternal = useCallback((label: string) => {
    if (paraviewClient) {
      ParaviewRpc.getlastknowngoodstate(paraviewClient, label)
        .then((result: ParaviewRpc.RpcResult) => {
          if (result.viewState) {
            updateViewState(result.viewState);
            logger.info(
              'recovering from paraview error with last known state: ',
              result.viewState,
            );
          }
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Failed to recover paraview last known state in Paraview', err);
        });
    }
  }, [paraviewClient, updateViewState]);

  // Issue an RPC to delete the current widget
  const cancelEditState = () => {
    setActiveNodeTable({ type: NodeTableType.NONE });
    setEditState(null);
    paraviewRenderer.deleteWidget();
  };

  const setViewAttrs = useCallback((attrs: ParaviewRpc.ViewAttrs) => {
    // Do nothing if Paraview has crashed.
    if (!currViewState.current || !paraviewClient || !viewState) {
      return;
    }
    const valid = paraviewActiveUrl === viewState.path;
    if (!valid) {
      return;
    }

    const newAttrs: ParaviewRpc.ViewAttrs = { ...currViewState.current.attrs, ...attrs };
    const newViewState = {
      ...currViewState.current,
      attrs: newAttrs,
    };
    updateViewState(newViewState);

    ParaviewRpc.setviewattrs(paraviewClient, attrs)
      .then((result: ParaviewRpc.RpcResult) => {
        // Setting the view attrs does not need to update array info, since
        // its not creating any new filters.
        onRpcSuccessInternal('setViewAttrs', result);
      }).catch((err: status.ParaviewError) => {
        addPvRpcError('Could not change Paraview view attributes', err);
        onRpcFailureInternal('setViewAttrs');
      });
  }, [
    onRpcFailureInternal,
    onRpcSuccessInternal,
    paraviewClient,
    paraviewActiveUrl,
    updateViewState,
    viewState,
  ]);

  // Keep viewState highlighted surfaces and selected nodes in sync (e.g. after reloading the page)
  // TODO: Make this an atom effect to remove 'flicker' where surface is highlighted before
  // deselecting. Requires moving other Paraview states to Recoil first: LC-8179
  useEffect(() => {
    if (viewState?.attrs.blockHighlighted &&
      Object.keys(viewState?.attrs.blockHighlighted).length &&
      !selectedNodeIds.length) {
      setViewAttrs({
        blockHighlighted: {},
      });
    }
  }, [viewState, selectedNodeIds, setViewAttrs]);

  // Unrolls the surfaces of some of the PV tree nodes to account for the presence of tags in the
  // client-facing stored view state tree. This allows us to keep tags in the settings without
  // having to unroll them when we store the view state.
  const unrollSurfacesTree = (root: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => (
    updateTreeNodes(
      root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
        if (node.param.typ === 'ExtractSurfaces') {
          // Unroll the surfaces to account for the present of tags. Unfortunately the backend is
          // not involved in this process and so the clients must do it.
          const extractSurfaces = { ...node.param } as ParaviewRpc.ExtractSurfacesParam;
          // Clients should not sture entity group IDs in the view state.
          extractSurfaces.surfaces = unwrapSurfaceIdsNoEntityGroups(
            extractSurfaces.surfaces,
            geometryTags,
          );
          return { ...node, param: extractSurfaces };
        }
        return node;
      },
    )
  );

  const setGlobalViewState = (state: ParaviewRpc.ViewState) => {
    // Do nothing if Paraview has crashed.
    if (!state || !viewState || !paraviewClient) {
      return;
    }
    updateViewState(state);
    const newState = { ...state, root: unrollSurfacesTree(state.root) };
    ParaviewRpc.setglobalviewstate(paraviewClient, newState)
      .then((result: ParaviewRpc.RpcResult) => {
        onRpcSuccess('setGlobalViewState', result);
      }).catch((err: status.ParaviewError) => {
        addPvRpcError('Could not change Paraview global view state', err);
        onRpcFailureInternal('setGlobalViewState');
      });
  };

  const backgroundColor = viewState?.attrs.backgroundColor ?? [0, 0, 0];
  const setBackgroundColor = (color: [number, number, number]) => {
    setViewAttrs({
      backgroundColor: color,
    });
  };

  /**
   * Iterate over the viewState data to fetch the global
   * cell data range for a specific displayVariable. If
   * the field name isn't found return false for the third
   * entry.
   */
  const getGlobalScalarDataRange = (
    displayVariable: ParaviewRpc.DisplayPvVariable,
    fieldType: ParaviewRpc.FieldAssociation,
  ): [number, number] | null => {
    if (!viewState) {
      return null;
    }
    for (let i = 0; i < viewState.data.length; i += 1) {
      if (viewState.data[i].name === displayVariable.displayDataName &&
        viewState.data[i].type === fieldType) {
        const range = viewState.data[i].range[displayVariable.displayDataNameComponent];
        return range;
      }
    }
    return null;
  };

  /**
   * Update the node tree structure and the node parameters.
   * Arg "label" is just for debug-logging.
   */
  const setNodes = (
    label: string,
    newRoot: ParaviewRpc.TreeNode,
  ): void => {
    if (!paraviewClient) {
      logger.debug(`setNodes ${label}: paraview disconnected`);
    } else {
      if (currViewState.current) {
        const newViewState = { ...currViewState.current, root: newRoot };
        updateViewState(newViewState);
      }
      logger.debug(`setNodes ${label}: ${JSON.stringify(newRoot)}`);
      ParaviewRpc.setnodes(paraviewClient, unrollSurfacesTree(newRoot))
        .then((result: ParaviewRpc.RpcResult) => {
          onRpcSuccessInternal(label, result);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError(`Could not create filter ${label} in Paraview`, err);
          onRpcFailureInternal(label);
        });
    }
  };

  // Map containing the visibility state of all nodes (surfaces and imposters) keyed by
  // the simulation tree node id.
  const visibilityMap = useMemo(() => {
    const blockVisiblity = currViewState.current?.attrs.blockVisibility;
    const visMap = new Map<string, boolean>(blockVisiblity ? Object.entries(blockVisiblity) : []);
    // Block visibility only contains surfaces so we have to find all imposters and add the current
    // visibility along with the ids of the corresponding simulation tree nodes to the map
    if (currViewState.current?.root) {
      traverseTreeNodes(currViewState.current!.root, (node) => {
        const imposterId = getImposterId(node);
        if (imposterId) {
          visMap.set(imposterId, node.visible);
        }
      });
    }
    return visMap;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currViewState.current]);

  // Set the new visibility for all nodes. newVisibility is a map containing the new
  // visibility keyed by the simulation tree node id.
  const setVisibility = (newVisibility: Map<string, boolean>) => {
    if (!currViewState.current || !paraviewClient || !viewState) {
      return;
    }
    const blockVisibility: { [blockName: string]: boolean } = {};
    const imposters: string[] = [];

    // Change the visibility of imposters.
    const newRoot = updateTreeNodes(
      currViewState.current.root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
        // We need the id of the simulation tree node representing the imposter.
        const imposterId = getImposterId(node);
        if (imposterId) {
          imposters.push(imposterId);
          return { ...node, visible: newVisibility.get(imposterId) ?? node.visible };
        }
        return node;
      },
    );

    // Change visibility of all surfaces
    newVisibility.forEach((value, key) => {
      // Everything that is not an imposter is considered a surface
      if (!imposters.includes(key)) {
        blockVisibility[key] = value;
      }
    });

    // Merge the new visibility with current view attributes.
    const newAttrs: ParaviewRpc.ViewAttrs = { ...currViewState.current.attrs, blockVisibility };
    const newViewState = {
      ...currViewState.current,
      attrs: newAttrs,
      root: newRoot,
    };
    updateViewState(newViewState);

    // Send new state to paraview
    ParaviewRpc.setglobalviewstate(paraviewClient, newViewState).then(
      (result) => onRpcSuccessInternal('setVisibility', result),
    ).catch((err: status.ParaviewError) => {
      addPvRpcError('Could not update visibility', err);
      onRpcFailureInternal('setVisibility');
    });
  };

  /**
   * Issue a `setnodes` RPC to change the visibility of a tree node.
   */
  const changeNodeVisibility = (nodeId: string, wantVisible: boolean) => {
    if (!currViewState.current?.root) {
      throw Error('no root');
    }
    let isClipSliceNode = false;
    let newRoot = updateTreeNodes(
      currViewState.current.root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
        if (node.id !== nodeId) {
          return node;
        }
        isClipSliceNode = isClipOrSlice(node);
        nodeId = node.id;
        return { ...node, visible: wantVisible };
      },
    );
    if (isClipSliceNode && wantVisible && nodeId) {
      // for clips and slices, we have a design requirement that only 1 clip/slice in the filter
      // tree can be visible at a time. This means that given a visible node,
      // none of its ancestors or descendants may be visible. Siblings and 'cousin' nodes may be
      // visible at the same time though.
      newRoot = hideAllAncestorClips(newRoot, nodeId);
      newRoot = hideAllDescendantClips(newRoot, nodeId);
    }
    setNodes('changeVisibility', newRoot);
  };

  /**
   * Issue a `setnodes` RPC to hide all visualizations.
   */
  const hideAllVisualizations = () => {
    if (!currViewState.current?.root) {
      return;
    }
    const newRoot = updateTreeNodes(
      currViewState.current.root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
        if (isFilterNode(node)) {
          return { ...node, visible: false };
        }
        return node;
      },
    );
    setNodes('hideAllVisualizations', newRoot);
  };

  /**
   * Issue a `setnodes` RPC to set the visibility of all leafs in the
   * tree to true, and everything else to false.
   */
  const showAllLeafs = () => {
    if (!currViewState.current?.root) {
      return;
    }
    const newRoot = updateTreeNodes(
      currViewState.current.root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
        if (isFilterNode(node)) {
          // Only show nodes with no children.
          const wantVisible = node.child.length === 0;
          return { ...node, visible: wantVisible };
        }
        return node;
      },
    );
    setNodes('showAllLeafs', newRoot);
  };

  /**
     * Manages the visibilities of surfaces that are inputs to surface LIC filters.
     * The desired behavior is that either the surface LIC or surface is shown at any time
     * in order to avoid blotchy visualization due to overlapping geometry being renderered.
     * Sets the visibility for a set of surfaces in the tree to desired setting.
     */
  const toggleLICGeometrySurfaceVisibility = (
    filterNode: ParaviewRpc.TreeNode,
    wantVisible: boolean,
  ) => {
    let surfaceList: string[] = [];
    if (filterNode.param.typ === ParaviewRpc.TreeNodeType.SURFACE_L_I_C) {
      const licParam = filterNode.param.seedPlacementParams as ParaviewRpc.SeedLICParam;
      if (licParam.surfaceType === ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE) {
        surfaceList = licParam.surfaces as string[];
      }
    }
    if (surfaceList.length > 0) {
      // A set of surfaces visible in other surface LIC nodes.
      const visibleLicSurfaceSet = new Set();

      // Gather information from all surface LIC tree nodes that are visible and have surfaces.
      const updateLicSurfaceSet = (nodeIn: ParaviewRpc.TreeNode) => {
        if (
          nodeIn.param.typ === ParaviewRpc.TreeNodeType.SURFACE_L_I_C &&
          nodeIn.id !== filterNode.id &&
          nodeIn.visible
        ) {
          const licParam = nodeIn.param.seedPlacementParams as ParaviewRpc.SeedLICParam;
          if (licParam.surfaceType === ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE) {
            const surfaces = licParam.surfaces as string[];
            surfaces.forEach((surfaceName) => {
              visibleLicSurfaceSet.add(surfaceName);
            });
          }
        }
      };
      if (!currViewState.current?.root) {
        throw Error('no root');
      }
      traverseTreeNodes(currViewState.current.root, updateLicSurfaceSet);

      // Filter the surface_list to toggle only the surfaces not present in visibleLicSurfaceSet
      const toggleSurfaceList = surfaceList.filter(
        (surfaceName) => !visibleLicSurfaceSet.has(surfaceName),
      );
      // Make a copy of visibilityMap
      const updatedVisibilityMap = new Map(visibilityMap);

      toggleSurfaceList.forEach((surfaceName) => {
        // Check if the name exists in the map before updating it
        if (updatedVisibilityMap.has(surfaceName)) {
          // Update the visibility in the copied map
          updatedVisibilityMap.set(surfaceName, wantVisible);
        }
      });

      // Call the setVisibility function with the new map
      setVisibility(updatedVisibilityMap);
    }
  };

  /**
   * Issue a `setnodes` RPC to change the name of a tree node.
   */
  const setNodeName = (id: string, newName: string) => {
    if (!currViewState.current?.root) {
      throw Error('no root');
    }
    const newRoot = renameTreeNode(currViewState.current.root, id, newName);
    setNodes('setNodeName', newRoot);
  };

  /**
   * Issue a `setnodes` RPC after applying the callback to each node.
   */
  const updateNodes = (callback: (node: ParaviewRpc.TreeNode) => ParaviewRpc.TreeNode) => {
    if (!currViewState.current?.root) {
      throw Error('no root');
    }
    const newRoot = updateTreeNodes(currViewState.current.root, callback);
    setNodes('updateNodes', newRoot);
  };

  /**
   * Issue a `setnodes` RPC to change the display properties of a
   * tree node.
   */
  const changeNodeDisplayProps = (nodeId: string, display: ParaviewRpc.DisplayProps) => {
    if (!currViewState.current?.root) {
      throw Error('no root');
    }
    const newRoot = updateTreeNodes(
      currViewState.current.root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
        if (node.id !== nodeId) {
          return node;
        }
        return { ...node, displayProps: display };
      },
    );
    setNodes('changeDisplayProps', newRoot);
  };

  /**
   * Issue a `setnodes` RPC to delete a tree node.
   */
  const deleteNode = (nodeId: string) => {
    if (!currViewState.current?.root) {
      throw Error('no root');
    }
    const updateTreeNode = (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
      // If we are deleting a visible node, make its parent visible if this is
      // not the root node.
      const isRoot = (node.id === currViewState.current!.root.id);
      const visibleChild = node.child.some((child) => child.id === nodeId && child.visible);
      const visible = node.visible || (!isRoot && visibleChild);
      return {
        ...node,
        child: node.child.filter((child) => child.id !== nodeId),
        visible,
      };
    };

    cancelEditState();
    const newRoot = updateTreeNodes(currViewState.current.root, updateTreeNode);
    setNodes('deleteNode', newRoot);
  };

  /**
   * Called when "Apply" button is pressed. Commits an edit of a node, or
   * creates a new node. Returns either the new node or null if none was
   * generated.
   */
  const applyEdit = (edit: EditState): string => {
    if (!currViewState.current?.root) {
      throw Error('no root');
    }
    const createdNode = edit.newNode ?
      newNode(edit.param, currViewState.current.root, true, edit.displayProps) :
      null;
    const nodeId = createdNode ? createdNode.id : edit.nodeId;
    const newRoot = updateTreeNodes(
      currViewState.current.root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
        if (node.id !== edit.nodeId) {
          return node;
        }

        if (edit.newNode) {
          return {
            ...node,
            visible: false,
            child: [
              ...node.child,
              createdNode!,
            ],
          };
        }
        return {
          ...node,
          paramSeq: node.paramSeq + 1,
          param: edit.param,
          visible: true,
          displayProps: edit.displayProps,
        };
      },
    );

    setNodes('newNode', newRoot);
    paraviewRenderer.deleteWidget();

    const visNodeAsync = ASYNC_VIS_NODES.includes(edit.param.typ as AsyncVisNodeType);
    if (createdNode && !visNodeAsync) {
      // Make sure the 'Visualization' root node is highlighted to avoid creating
      // nested visualization during the normal workflow.
      // Leave async vis nodes highlighted to show calculating message.
      setSelection([currViewState.current.root.id]);
    }
    setEditState(null);
    return nodeId;
  };

  /**
   * Add a node to the visualization tree without using applyEdit, which requires an
   * edit state. This is used to add a visualization imposter for actuator disks.
   */
  const addNode = (parentNodeId: string, node: ParaviewRpc.TreeNode): void => {
    if (!currViewState.current?.root) {
      throw Error('no root');
    }
    const newRoot = addChildNode(currViewState.current.root, parentNodeId, node);
    setNodes('addNode', newRoot);
  };

  // Handle committed changes to the far field imposter including deletion.
  const [cadModifier] = useCadModifier(projectId);
  const isGeometryPending = useIsGeometryPending(projectId);

  useEffect(() => {
    if (paraviewClientState.client) {
      const editing = cadModifier !== null && !cadModifier.farField.case;
      // only show the far field imposter if there is a modifier and the mesh
      // gen is pending.
      if (!editing && isGeometryPending && cadModifier) {
        const transparent = false;
        updateFarFieldImposter(paraviewRenderer, cadModifier, transparent, paraviewClientState);
      } else if (!editing) {
        deleteFarFieldImposter(paraviewClientState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cadModifier, isGeometryPending, paraviewClientState]);

  // TODO add far field if cadModifier != null and if getMeshUrl == ""
  // A helper function to update all the imposters associated with new params.
  const impostersUpdate = (param: simulationpb.SimulationParam) => {
    if (currViewState.current?.root) {
      const newRoot = updateImposters(
        param,
        currViewState.current.root,
        currViewState.current.attrs,
      );
      setNodes('imposterUpdate', newRoot);
    }
  };

  const setCameraMode = (mode: CameraMode) => {
    if (mode === cameraMode) {
      return;
    }
    setCameraModeState(mode);
    switch (mode) {
      case CameraMode.PERSPECTIVE:
        paraviewRenderer.setCameraAngle(false, null, null);
        break;
      case CameraMode.ORTHOGRAPHIC:
        paraviewRenderer.setCameraAngle(true, null, null);
        break;
      case CameraMode.Z_MINUS:
        paraviewRenderer.setCameraAngle(true, Y_PLUS, Z_PLUS);
        break;
      case CameraMode.Z_PLUS:
        paraviewRenderer.setCameraAngle(true, Y_MINUS, Z_MINUS);
        break;
      case CameraMode.X_PLUS:
        paraviewRenderer.setCameraAngle(true, Z_PLUS, X_MINUS);
        break;
      case CameraMode.X_MINUS:
        paraviewRenderer.setCameraAngle(true, Z_PLUS, X_PLUS);
        break;
      case CameraMode.Y_PLUS:
        paraviewRenderer.setCameraAngle(true, Z_PLUS, Y_MINUS);
        break;
      case CameraMode.Y_MINUS:
        paraviewRenderer.setCameraAngle(true, Z_PLUS, Y_PLUS);
        break;
      case CameraMode.NE:
        paraviewRenderer.setCameraAngle(true, Z_PLUS, NE_VIEW);
        break;
      case CameraMode.NW:
        paraviewRenderer.setCameraAngle(true, Z_PLUS, NW_VIEW);
        break;
      case CameraMode.SE:
        paraviewRenderer.setCameraAngle(true, Z_PLUS, SE_VIEW);
        break;
      case CameraMode.SW:
        paraviewRenderer.setCameraAngle(true, Z_PLUS, SW_VIEW);
        break;
      default:
        throw Error(`invalid camera mode ${mode}`);
    }
  };

  /**
   * Edit a visualization node directly without using the edit state. The use
   * cases for this are sending parameter updates for multi-slice (which of n
   * slices to show) and for the actuator disks which are edited from the
   * particleGroups props panel.  Note: widgets are not supported using active
   * edit.
   */
  const activeEdit = (nodeId: string, param: ParaviewRpc.TreeNodeParam): void => {
    if (!currViewState.current?.root) {
      throw Error('no root');
    }
    const newRoot = updateTreeNodes(
      currViewState.current.root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
        if (node.id !== nodeId) {
          return node;
        }
        return {
          ...node,
          paramSeq: node.paramSeq + 1,
          param,
        };
      },
    );
    setNodes('activeEdit', newRoot);
  };

  // Returns the bounds of the geometry that is present in the world space (i.e.
  // geometry and filters that have visibility on) scaled by a small scale factor.
  // The function has some countermeasures to ensure that the resulting bounding
  // box is valid in all the principal axes. For example, if none of the datasets
  // are visible, it returns the bounds of the full mesh. For more details, refer to
  // the comments inside the function.
  const getDataVisibilityBounds = (
    metadata: lcmeshpb.MeshFileMetadata,
    customScaleFactor?: number,
  ): ParaviewRpc.Bounds => {
    assert(viewState !== null, 'View state missing for visibility bounds calculation');
    const BIG_FLOATING_POINT = Number.POSITIVE_INFINITY;
    const SMALL_FLOATING_POINT = 1e-20;
    let scaleFactor = 1.2;
    if (customScaleFactor) {
      scaleFactor = customScaleFactor;
    }

    // Whether any of the geometry or filters datasets have visibility on.
    let foundBounds = false;

    // Initialize the bounds with an invalid bounding box so that it's easy
    // to update it based on the bounds of the datasets.
    let visibleBds: ParaviewRpc.Bounds = [
      BIG_FLOATING_POINT,
      -BIG_FLOATING_POINT,
      BIG_FLOATING_POINT,
      -BIG_FLOATING_POINT,
      BIG_FLOATING_POINT,
      -BIG_FLOATING_POINT,
    ];

    // Loop over the visible boundaries and update the bounds.
    metadata?.zone.forEach((zone) => {
      zone.bound.forEach((boundary) => {
        if (viewState.attrs!.blockVisibility![boundary.name]) {
          foundBounds = true;
          visibleBds[0] = Math.min(visibleBds[0], boundary.stats?.minCoord?.x!);
          visibleBds[1] = Math.max(visibleBds[1], boundary.stats?.maxCoord?.x!);
          visibleBds[2] = Math.min(visibleBds[2], boundary.stats?.minCoord?.y!);
          visibleBds[3] = Math.max(visibleBds[3], boundary.stats?.maxCoord?.y!);
          visibleBds[4] = Math.min(visibleBds[4], boundary.stats?.minCoord?.z!);
          visibleBds[5] = Math.max(visibleBds[5], boundary.stats?.maxCoord?.z!);
        }
      });
    });

    // Loop over the visible visualization filters and update the bounds.
    viewState.root.child.forEach((filter) => {
      if (filter.visible) {
        foundBounds = true;
        for (let dim = 0; dim < 3; dim += 1) {
          visibleBds[2 * dim] = Math.min(filter.bounds![2 * dim], visibleBds[2 * dim]);
          visibleBds[2 * dim + 1] = Math.max(filter.bounds![2 * dim + 1], visibleBds[2 * dim + 1]);
        }
      }
    });

    // Maximum length of the bounds principal axes.
    const maxLength = boundsMaxLength(visibleBds);

    // If there is no visible dataset or if the maximum axis length is too small,
    // then set the bounds to the mesh bounds.
    if (!foundBounds || maxLength < SMALL_FLOATING_POINT) {
      visibleBds = viewState?.root.bounds!;
    } else {
      // Scale the bounds.
      visibleBds = boundsScale(visibleBds, scaleFactor);
    }

    return visibleBds;
  };

  // Get the color map associated to a given display variable.
  const getColorMap = (
    displayVariable: ParaviewRpc.DisplayPvVariable,
  ): ParaviewRpc.ColorMap | null => {
    if (viewState && viewState.attrs!.colorMaps) {
      const { colorMaps } = viewState.attrs;
      const cmap = colorMaps.filter(
        (keyAndValue) => keyAndValue[0] === displayVariable,
      );
      if (cmap.length > 1) {
        throw Error('Error: Found multiple color maps for the same variable.');
      }
      if (cmap.length === 1) {
        return cmap[0][1];
      }
    }
    return null;
  };

  // Create a update function to pass to each color bar
  // so changes are tracked.
  const updateColorMap = (
    displayVariable: ParaviewRpc.DisplayPvVariable,
    newCmap: ParaviewRpc.ColorMap,
  ): void => {
    if (!viewState || !paraviewClient) {
      logger.debug('updateColorMap: paraview disconnected or no view state');
    } else {
      const { colorMaps } = viewState.attrs;
      let newColorMaps: [ParaviewRpc.DisplayPvVariable, ParaviewRpc.ColorMap][] = [];

      if (!colorMaps) {
        // Easy path, the color maps are empty so we can just add the tuple.
        newColorMaps.push([displayVariable, newCmap]);
      } else {
        // Search if the tuple is found in the color maps.
        newColorMaps = colorMaps.slice();
        const index = newColorMaps.findIndex(
          (keyAndValue: [ParaviewRpc.DisplayPvVariable, ParaviewRpc.ColorMap]) => (
            keyAndValue[0].displayDataName === displayVariable.displayDataName &&
            keyAndValue[0].displayDataNameComponent === displayVariable.displayDataNameComponent
          ),
        );

        // If the color map exists, update it. Else add it to the list.
        if (index !== -1) {
          newColorMaps[index] = [displayVariable, newCmap];
        } else {
          newColorMaps.push([displayVariable, newCmap]);
        }
      }

      const newAttrs = { ...viewState.attrs, colorMaps: newColorMaps };

      updateViewState({
        ...viewState,
        attrs: newAttrs,
      });

      ParaviewRpc.setviewattrs(paraviewClient, { colorMaps: newColorMaps })
        .then((result: ParaviewRpc.RpcResult) => {
          onRpcSuccessInternal('updateColorMap', result);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Update color map could not change view attributes', err);
        });
    }
  };

  // TODO: can we move this out of the ParaviewContext? Same as the other function below.
  // Returns the number of components of a display variable.
  // Returns null if the variable is not found in the viewState data.
  const displayVariableComponents = (
    displayVariable: ParaviewRpc.DisplayPvVariable,
  ): number | null => {
    const filteredArrayInfo = viewState!.data.filter(
      (arr: ParaviewRpc.ArrayInformation) => arr.type === 'Point' &&
        arr.name === displayVariable.displayDataName,
    );
    if (filteredArrayInfo.length > 1) {
      throw Error('The viewState contains multiple data values with the same name.');
    }
    if (filteredArrayInfo.length === 1) {
      return filteredArrayInfo[0].dim;
    }
    return null;
  };

  // Convert a display variable to a string.
  const displayVariableToText = (
    displayVariable: ParaviewRpc.DisplayPvVariable,
  ): string => {
    // Figure out if the displayVariable is a vector or not.
    const nComponents = displayVariableComponents(displayVariable);
    if (nComponents === 1) {
      return displayVariable.displayDataName;
    } if (nComponents === 3) {
      const componentsName = ['Magnitude', 'X', 'Y', 'Z'];
      const componentName = componentsName[displayVariable.displayDataNameComponent];
      if (displayVariable.displayDataNameComponent === 0) {
        return `${displayVariable.displayDataName!} ${componentName}`;
      }
      return `${displayVariable.displayDataName!} ${componentName}-Component`;
    } if (!nComponents) {
      return '';
    }
    throw Error('Display variable has more than three components. This is not supported');
  };

  // Changes the displayVariable in the viewState. If changingComponent is true
  // this function expects to be called upon a change in DataComponentSelect.
  // If node is null, the function expects that we are changing the display properties
  // of the root node. Else, the function supposes that we are changing the display
  // properties of the given node.
  const setViewStateDisplayVariable = (
    displayVariable: ParaviewRpc.DisplayPvVariable,
    changingComponent: boolean,
    node: ParaviewRpc.TreeNode | null,
  ): void => {
    if (!viewState?.root) {
      throw Error('no root');
    }

    // Number of components of the display variable.
    const nComponents = displayVariableComponents(displayVariable);

    // In scalar mode we just modify the viewAttrs or displayProps as needed.
    if (nComponents === 1 || displayVariable.displayDataName === 'None') {
      // Make sure to fix the component to zero in this case since there's
      // no guarantee that the caller does so.
      displayVariable.displayDataNameComponent = 0;
      if (!node) {
        // Modify the root viewAttrs.
        setViewAttrs({
          displayVariable,
        });
      } else {
        // Modify the node displayProps.
        const { displayProps } = node;
        changeNodeDisplayProps(
          node.id,
          {
            ...displayProps,
            displayVariable,
          },
        );
      }
      return;
    }

    // From now on, the variable has multiple components. ParaView does not allow to
    // have different color maps for vector components of a same variable.
    // Until we figure out how to solve this in the ParaView backend,
    // make sure that a change in the component of a vector propagates to all the nodes.

    // Create a new viewState which ensures that all nodes have the same component of
    // displayVariable.
    const newViewState = { ...viewState! };

    // Helper function used to update the display props of a node.
    const updateRoot = (nodeId: string, display: ParaviewRpc.DisplayProps) => {
      newViewState.root = updateTreeNodes(
        newViewState.root,
        (nodeIn: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
          if (nodeIn.id !== nodeId) {
            return nodeIn;
          }
          return { ...nodeIn, displayProps: display };
        },
      );
    };

    // If we are changing the component of a display variable, the change needs to propagate
    // through all the nodes which share the displayDataName.
    if (changingComponent) {
      // Modify the root viewAttrs if it uses the same variable name to make sure that
      // we use the same component.
      if (displayVariable.displayDataName === viewState!.attrs.displayVariable?.displayDataName) {
        newViewState.attrs = {
          ...newViewState.attrs,
          displayVariable,
        };
      }

      // Modify the nodes displayProps displayVariable component if they use the same variable name.
      const updateComponent = (nodeIn: ParaviewRpc.TreeNode) => {
        const { displayProps } = nodeIn;
        if (displayProps?.displayVariable) {
          const displPropsDataName = displayProps.displayVariable.displayDataName;
          if (displPropsDataName === displayVariable.displayDataName) {
            updateRoot(
              nodeIn.id,
              {
                ...displayProps,
                displayVariable,
              },
            );
          }
        }
      };
      traverseTreeNodes(newViewState.root, updateComponent);

      // Send the new viewState to the backend.
      setGlobalViewState(newViewState);
      return;
    }

    // From now on, we are just changing the displayDataName of a vector variable.
    // Search for the current displayDataNameComponent of the displayDataName and assign it
    // to the new displayVariable. It should be unique. If the displayDataName is not
    // found, then default to the 0 component.

    // If the displayDataName was not previously being used set 0 component.
    let currentComponent = 0;

    // Grab the component from the root viewAttrs if possible. Else, grab it from
    // the nodes.
    if (viewState!.attrs.displayVariable?.displayDataName === displayVariable.displayDataName) {
      currentComponent = viewState!.attrs!.displayVariable?.displayDataNameComponent;
    } else {
      const searchComponent = (nodeIn: ParaviewRpc.TreeNode) => {
        const { displayProps } = nodeIn;
        if (displayProps?.displayVariable &&
          displayProps.displayVariable.displayDataName === displayVariable.displayDataName) {
          currentComponent = displayProps.displayVariable.displayDataNameComponent;
        }
      };
      traverseTreeNodes(newViewState.root, searchComponent);
    }

    // Update the displayVariable with the new component.
    const newDisplayVariable: ParaviewRpc.DisplayPvVariable = {
      ...displayVariable,
      displayDataNameComponent: currentComponent,
    };

    if (!node) {
      // Modify the root viewAttrs if needed.
      newViewState.attrs = {
        ...newViewState.attrs,
        displayVariable: newDisplayVariable,
      };
    } else {
      // Modify the node displayProps.
      const { displayProps } = node;
      updateRoot(
        node.id,
        {
          ...displayProps,
          displayVariable: newDisplayVariable,
        },
      );
    }

    // Update the components of other nodes if needed.
    const updateComponent = (nodeIn: ParaviewRpc.TreeNode) => {
      const { displayProps } = nodeIn;
      if (displayProps?.displayVariable) {
        if (displayProps.displayVariable.displayDataName === displayVariable.displayDataName) {
          updateRoot(
            nodeIn.id,
            {
              ...displayProps,
              displayVariable: newDisplayVariable,
            },
          );
        }
      }
    };
    traverseTreeNodes(newViewState.root, updateComponent);

    // Send the new viewState to the backend.
    setGlobalViewState(newViewState);
  };

  // reset the visualization state. Called in the Toolbar or when resetting a
  // project's settings in SimulationTreeMoreMenu.
  const resetVisState = async () => {
    // if the viewState isn't present yet then do nothing.
    if (!viewState) {
      return;
    }
    const newViewState = global.structuredClone(viewState);

    // Wipe out the filters.
    newViewState.root.child = [];

    // Reset the visibility.
    assert(!!newViewState.attrs.blockVisibility, 'Block visibility not available for reset');
    Object.keys(newViewState.attrs.blockVisibility).forEach(
      (key) => {
        newViewState.attrs.blockVisibility![key] = true;
      },
    );

    // Set the view state and renderer kvstore state.
    setGlobalViewState(newViewState);

    // Trigger a rerender of the Visualizations node.
    setSelection([]);
    addInfo('Reset visualization filters');
  };

  // This method is only called if syncnodes fails.
  // If syncnodes has failed then the mesh and soln have not been loaded.
  // This is different from resetVisState because in that case, the mesh/soln have loaded but the
  // user wants to clear all filters.
  // Here, nothing has loaded yet and a filter could be causing the failure.
  // Thus, we want to syncnodes again to load the mesh/soln, but without filters/other settings.
  const syncOnlyMeshSoln = async (
    root: ParaviewRpc.TreeNode | null,
    attrs: ParaviewRpc.ViewAttrs | null,
  ) => {
    if (!paraviewClientState.client) {
      return;
    }

    const newRoot = global.structuredClone(root);
    if (newRoot) {
      newRoot.child = [];
    }

    const newAttrs = global.structuredClone(attrs);
    if (newAttrs) {
      // Reset the visibility.
      assert(newAttrs.blockVisibility !== null, 'Block visibility not available for sync');
      if (newAttrs.blockVisibility) {
        Object.keys(newAttrs.blockVisibility).forEach(
          (key) => {
            newAttrs.blockVisibility![key] = true;
          },
        );
      }
    }
    ParaviewRpc.syncnodes(paraviewClientState.client, newRoot, newAttrs, null)
      .then((result: ParaviewRpc.RpcResult) => {
        onRpcSuccess('syncnodes', result);
        addInfo('Visualization tree has been reset.');
      })
      .catch((err: status.ParaviewError) => {
        addPvRpcError('Could not reset visualization tree.', err);
      });
    // Trigger a rerender of the Visualizations node.
    setSelection([]);
  };

  // If mesh metadata or workflow config is not ready, still show viz loading for better UX.
  const meshReady = !!meshMetadata;
  const showSyncing = !meshReady || syncing;

  const value = {
    paraviewProjectId: projectId,
    paraviewMeshMetadata: meshMetadata,
    paraviewViewName: viewName,
    paraviewActiveUrl,

    // Connection state
    paraviewClientState,
    setSyncing,
    syncing: showSyncing,
    resetViewState,

    colorMapsVisibility,

    paraviewRenderer,
    cameraMode,
    setCameraMode,

    backgroundColor,
    setBackgroundColor,

    // Editing controls
    applyEdit,
    activeEdit,
    addNode,
    updateNodes,
    cancelEditState,
    setEditState,
    setNodeName,

    // Node selection
    changeNodeVisibility,
    hideAllVisualizations,
    showAllLeafs,
    toggleLICGeometrySurfaceVisibility,
    deleteNode,

    setViewAttrs,
    setGlobalViewState,
    getGlobalScalarDataRange,
    viewState,
    changeNodeDisplayProps,
    getDataVisibilityBounds,
    getColorMap,
    updateColorMap,
    displayVariableComponents,
    displayVariableToText,
    setViewStateDisplayVariable,
    visibilityMap,
    setVisibility,

    onRpcSuccess,

    impostersUpdate,
    resetVisState,
    syncOnlyMeshSoln,

    fixGeometryTagsInViewState,
  };

  return (
    <ParaviewContext.Provider value={value}>
      {props.children}
    </ParaviewContext.Provider>
  );
};

export default ParaviewManager;
