// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';
import { LcvDisplay } from '../LcvDisplay';

import { LcvWidget } from './LcvWidget';

export type LcvisProbeCallback = (
  probeCoords: [number, number, number],
  objectId: string,
  message?: string,
  fieldValue?: number,
  fieldName?: string,
) => void

export class LcvProbeWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, 'probe');
    this.setParam('probe_mode', LCVType.kLCVDataTypeInt, 1);
  }

  setCallback(callback: null | LcvisProbeCallback, display: LcvDisplay) {
    const internalCallback = callback ? (
      _lcv: LcvModule,
      _session: LCVObject,
      obj: LCVObject,
      message: string,
    ) => {
      const { workspace, simAnnotationHandler } = display;
      if (!workspace || !simAnnotationHandler) {
        return;
      }
      const probeCoords = this.getProperty('probe_coordinate', LCVType.kLCVDataTypeFloat3);
      const [objectId, primitiveIndex] = this.getProperty(
        'object_id',
        LCVType.kLCVDataTypeUint2,
      ) as [number, number];

      let surfaceId = '';
      if (workspace.hasFilter(objectId)) {
        // Only filters will have field values
        const fieldValue = this.getProperty('field_value', LCVType.kLCVDataTypeFloat);
        let fieldName = '';
        // Don't bother reading the field name if there's no field at the probe point
        if (!Number.isNaN(fieldValue)) {
          fieldName = this.getProperty('field_name', LCVType.kLCVDataTypeString);
        }

        surfaceId = workspace.getIdFromIndex(objectId, primitiveIndex);
        callback(probeCoords, surfaceId, message, fieldValue, fieldName);
      } else if (simAnnotationHandler.hasObjectId(objectId)) {
        surfaceId = simAnnotationHandler.getIdFromSelection(objectId, primitiveIndex);
        // Annotations won't have field values, so we can ignore
        callback(probeCoords, surfaceId, message);
      }
    } : null;

    this.setParam(
      'probe_updated_callback',
      LCVType.kLCVDataTypeFunction,
      internalCallback,
    );
  }
}
