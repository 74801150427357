// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ActionButton } from '../components/Button/ActionButton';
import EmptyState from '../components/EmptyState';
import { createStyles, makeStyles } from '../components/Theme';
import BaseLayout from '../components/layout/BaseLayout';
import { WarningIcon } from '../components/svg/WarningIcon';
import { landingPageLink, setupLink } from '../lib/navigation';

const useStyles = makeStyles(
  () => createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '48px',
    },
  }),
  { name: 'NotFoundPage' },
);

/**
 * Fallback page to be displayed if a given route is not found.
 */
const NotFoundPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  // This is only needed temporary to allow better fallback after removing the workflow ui sub pages
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (['physics', 'mesh', 'outputs', 'solver'].includes(pathParts[3])) {
      navigate(setupLink(pathParts[2]));
    }
  }, [location, navigate]);

  return (
    <BaseLayout centered title="Not Found">
      <div>
        <EmptyState
          title="Oops, we couldn't find that page.">
          <div className={classes.content}>
            <WarningIcon maxHeight={100} maxWidth={100} />
            <ActionButton
              kind="primary"
              onClick={() => navigate(landingPageLink())}
              size="medium">
              Go Home
            </ActionButton>
          </div>
        </EmptyState>
      </div>
    </BaseLayout>
  );
};

export default NotFoundPage;
