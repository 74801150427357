import React, { useEffect, useState } from 'react';

import { colors } from '../../../lib/designSystem';
import { getDescription, getIssueDetails, getTitle } from '../../../lib/geometryHealthUtils';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { OVERLAY_CARD_WIDTH } from '../../../lib/visUtils';
import { Level } from '../../../proto/lcstatus/levels_pb';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useSelectedVisualizerErrorValue } from '../../../recoil/useSelectedVisualizerError';
import { SvgIcon } from '../../Icon/SvgIcon';
import { createStyles, makeStyles } from '../../Theme';
import Divider from '../../Theme/Divider';
import { useProjectContext } from '../../context/ProjectContext';
import { Dialog } from '../../dialog/Base';

import { CollapsibleText } from '@/components/CollapsibleText';

const useStyles = makeStyles(() => createStyles({
  root: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    marginLeft: '40px',
  },
  content: {
    fontSize: '13px',
    paddingLeft: '20px',
  },
  header: {
    fontSize: '13px',
    fontWeight: '700',
    marginBottom: '4px',
    backgroundColor: colors.neutral150,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    lineHeight: '1.5',
    marginTop: '8px',
  },
}), { name: 'FloatingVisualizerError' });

export const FloatingVisualizerError = () => {
  const classes = useStyles();
  const { projectId, workflowId, jobId } = useProjectContext();

  const selectedVisualizerError = useSelectedVisualizerErrorValue(projectId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const [pointPosition, setPointPosition] = useState<[number, number] | null>(null);

  useEffect(() => {
    if (!selectedVisualizerError) {
      return;
    }

    lcvHandler.queueDisplayFunction('track current error position', (display) => {
      display.projectPoint?.setPoint([
        selectedVisualizerError.coordinates.x,
        selectedVisualizerError.coordinates.y,
        selectedVisualizerError.coordinates.z,
      ], setPointPosition);
    });

    return () => {
      lcvHandler.queueDisplayFunction('clear error position tracker', (display) => {
        display.projectPoint?.clearPoint();
      });
    };
  }, [selectedVisualizerError]);

  if (!selectedVisualizerError || !pointPosition) {
    return null;
  }

  const panelPosition = { left: `${pointPosition[0] * 100}%`, top: `${pointPosition[1] * 100}%` };

  return (
    <div
      className={classes.root}
      style={panelPosition}>
      <Dialog
        compact
        onClose={() => {
          lcvHandler.display?.errorList?.updateSelection(null);
        }}
        open
        title="Found Issues"
        width={OVERLAY_CARD_WIDTH}>
        <div className={classes.container}>
          {selectedVisualizerError.issues.map(({ issue }, index, array) => {
            const isLastElement = index === array.length - 1;

            return (
              <React.Fragment key={issue.code + issue.description}>
                <div>
                  <div className={classes.header}>
                    <SvgIcon
                      color={issue.level === Level.ERROR ? colors.red500 : colors.yellow500}
                      maxHeight={12}
                      maxWidth={12}
                      name={issue.level === Level.ERROR ? 'diskExclamation' : 'warning'}
                    />
                    {getTitle(issue)}
                  </div>
                  <div className={classes.content}>
                    <CollapsibleText collapseLimit={80} text={getDescription(issue)} />

                    <div className={classes.detailsContainer}>
                      {getIssueDetails(issue, entityGroupMap).map((item) => (
                        <div key={item.name}><strong>{item.name}</strong>: {item.value}</div>
                      ))}
                    </div>
                  </div>
                </div>
                {!isLastElement && <Divider />}
              </React.Fragment>
            );
          })}
        </div>
      </Dialog>
    </div>
  );
};
