import { SetterOrUpdater } from 'recoil';

import { DisplayProps, RepresentationType } from '../../pvproto/ParaviewRpc';
import { FilterStatus } from '../../recoil/lcvis/lcvisFilterStatus';

// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
export type LcvModule = any;

/**
 * The filter options to use during testing, when we can't connect to the real
 * backend.
 */
type ImportFilterTestingOptions = {
  metadataUrl: string,
  geometryUrl: string,
}
/** The filter options to use in production */
export type ImportFilterRealOptions = {
  projectId: string,
  meshUrl: string,
  meshName: string,
  isGeometry?: boolean,
  fvmParams?: string,
  customFields?: string,
}

/**
 * When setting up an LcvImportDatasetFilter, we can either pass it a
 * { projectId, meshUrl, meshName } key, or a { datasetName, serverUrl } key.
 * When using the former, the lcvis wasm will make a grpc call to the backend to
 * request render data. If we use the latter, the wasm app will instead make a
 * fetch to the specified http endpoint and dataset name. This should be used
 * during testing.
 */
export type ImportFilterSetupOptions = ImportFilterTestingOptions | ImportFilterRealOptions;

export function isTestOptions(
  options: ImportFilterSetupOptions,
): options is ImportFilterTestingOptions {
  return (options as ImportFilterTestingOptions).geometryUrl !== undefined;
}

export interface LcvisCameraStateType {
  'position': Array<number>,
  'target': Array<number>,
  'up': Array<number>,
  'look': Array<number>,
  'pan': Array<number>,
  'fov': number,
  'near_clip': number,
  'far_clip': number,
  'center_of_rotation_modifier': 0 | 1,
  'zoom_to_box_modifier': 0 | 1,
  'orthographic': boolean,

  // tells us where the most recent update came from
  'editSource'?: 'LCVis' | 'UI' | 'KVStore',

  // tells us if the camera has not been set yet.
  'isDefault'?: boolean,
}

/**
 * The default camera to use when no other is specified.
 * None of the values in the default camera matter besides orthographic and isDefault: true.
 * If isDefault is true, then we reset the camera in LCVis when the frame first renders based on
 * the model bounds. So all the other params will be overwritten.
 */
export const defaultLcvCamera = (): LcvisCameraStateType => ({
  position: [0, 5, 0],
  target: [0, 0, 0],
  up: [0, 1, 0],
  look: [0, 0, -1],
  pan: [0, 0, 0],
  fov: 50,
  near_clip: 0.001,
  far_clip: 100000.0,
  center_of_rotation_modifier: 0,
  zoom_to_box_modifier: 0,
  orthographic: true,

  editSource: 'UI',
  isDefault: true,
});

export type LcVisVisibilityMap = Map<string, boolean>;

export type FilterProgressCallback = SetterOrUpdater<FilterStatus>

export type Bounds = [number, number, number, number, number, number];

/** The types of buttons shown in the toolbar to generate new Lcvis filters. */
export type LcVisButtonType =
  'clip' |
  'slice' |
  'clipBox' |
  'probe' |
  'measure' |
  'screenshot' |
  'clipHide' |
  'threshold' |
  'contour' |
  'streamlines' |
  'surfaceLIC' |
  'glyph' |
  'intersectionLine' |
  'intersectionCurve' |
  'multislice';

/** Selected object ID pairs in LCVis: [object ID, primitive ID] */
export type LcvIdPair = [number, number];

/** The default point size to use for points in LCVis. This is relative to the current bounds. */
export const DEFAULT_POINT_RADIUS = 0.005;

export const defaultDisplayProps = (): DisplayProps => ({
  reprType: 'Surface With Edges' as RepresentationType,
  showColors: true,
  highlightNeighborEdges: false,
});

/**
 * The view properties for an individual LcvFilter. They determine how the filter is displayed,
 * e.g. as a surface or wireframe, with colors or without.
 */
export type ViewProps = {
  // whether the filter is to be edited per-surface or as a whole
  // true means per-surface, false means as a whole. Default is false.
  perSurface: boolean;
  displayProps: DisplayProps;
}
