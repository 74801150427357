// Copyright 2020-2025 Luminary Cloud, Inc. All Rights Reserved.
// Generated by quantities.py. DO NOT EDIT

// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/quantity/quantity.proto (package luminary.proto.quantity, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EnumValueOptions, proto3 } from "@bufbuild/protobuf";
import { Quantity } from "./quantity_options_pb.js";

/**
 * @generated from enum luminary.proto.quantity.QuantityType
 */
export const QuantityType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.quantity.QuantityType",
  [
    {no: 0, name: "INVALID_QUANTITY_TYPE"},
    {no: 1545, name: "MASS"},
    {no: 10240, name: "LENGTH"},
    {no: 10, name: "AREA"},
    {no: 30891, name: "VOLUME"},
    {no: 20394, name: "TIME"},
    {no: 30269, name: "ENERGY"},
    {no: 61564, name: "RADIANS"},
    {no: 42822, name: "DEGREE"},
    {no: 64408, name: "POWER"},
    {no: 51, name: "POWER_PER_UNIT_VOLUME"},
    {no: 16951, name: "THERMAL_EXPANSION_COEFFICIENT"},
    {no: 3, name: "TEMPERATURE"},
    {no: 1, name: "DENSITY"},
    {no: 2, name: "PRESSURE"},
    {no: 5, name: "VELOCITY"},
    {no: 30, name: "VELOCITY_MAGNITUDE"},
    {no: 4, name: "EDDY_VISCOSITY"},
    {no: 1424, name: "VISCOSITY"},
    {no: 6, name: "MACH"},
    {no: 7, name: "SA_VARIABLE"},
    {no: 38457, name: "TKE"},
    {no: 15375, name: "OMEGA"},
    {no: 44979, name: "GAMMA"},
    {no: 29711, name: "RE_THETA"},
    {no: 23584, name: "N_TILDE"},
    {no: 616, name: "Y_PLUS"},
    {no: 617, name: "RE_ROUGHNESS"},
    {no: 52097, name: "WALL_SHEAR_STRESS"},
    {no: 14067, name: "MASS_FLUX"},
    {no: 804, name: "Q_CRITERION"},
    {no: 12327, name: "HEAT_FLUX"},
    {no: 6995, name: "ENERGY_FLUX"},
    {no: 48065, name: "HEAT_TRANSFER_COEFFICIENT"},
    {no: 59636, name: "ABSOLUTE_PRESSURE"},
    {no: 11111, name: "DENSITY_N"},
    {no: 11112, name: "MOMENTUM_N"},
    {no: 11113, name: "ENERGY_N"},
    {no: 11114, name: "SA_VARIABLE_N"},
    {no: 11115, name: "TKE_N"},
    {no: 11116, name: "OMEGA_N"},
    {no: 11117, name: "GAMMA_N"},
    {no: 63935, name: "RE_THETA_N"},
    {no: 56446, name: "N_TILDE_N"},
    {no: 50745, name: "GRID_VELOCITY"},
    {no: 16761, name: "IBLANK"},
    {no: 1654, name: "MOLECULAR_WEIGHT"},
    {no: 31438, name: "SPECIFIC_HEAT"},
    {no: 44144, name: "THERMAL_CONDUCTIVITY"},
    {no: 12284, name: "DARCY_COEFFICIENT"},
    {no: 23339, name: "FORCHHEIMER_COEFFICIENT"},
    {no: 65107, name: "RELATIVE_VELOCITY"},
    {no: 29867, name: "RELATIVE_MACH"},
    {no: 53930, name: "PRESSURE_COEFFICIENT"},
    {no: 28, name: "TOTAL_PRESSURE"},
    {no: 48695, name: "TOTAL_PRESSURE_COEFFICIENT"},
    {no: 29, name: "TOTAL_TEMPERATURE"},
    {no: 10666, name: "SKIN_FRICTION_COEFFICIENT"},
    {no: 16866, name: "TEMPERATURE_TIME_AVERAGE"},
    {no: 17651, name: "DENSITY_TIME_AVERAGE"},
    {no: 21230, name: "PRESSURE_TIME_AVERAGE"},
    {no: 10985, name: "VELOCITY_TIME_AVERAGE"},
    {no: 10989, name: "RELATIVE_VELOCITY_TIME_AVERAGE"},
    {no: 30360, name: "VELOCITY_MAGNITUDE_TIME_AVERAGE"},
    {no: 390, name: "EDDY_VISCOSITY_TIME_AVERAGE"},
    {no: 56241, name: "VISCOSITY_TIME_AVERAGE"},
    {no: 47480, name: "MACH_TIME_AVERAGE"},
    {no: 11897, name: "SA_VARIABLE_TIME_AVERAGE"},
    {no: 43246, name: "Y_PLUS_TIME_AVERAGE"},
    {no: 37511, name: "PRESSURE_COEFFICIENT_TIME_AVERAGE"},
    {no: 60077, name: "WALL_SHEAR_STRESS_TIME_AVERAGE"},
    {no: 45362, name: "MASS_FLUX_TIME_AVERAGE"},
    {no: 15628, name: "TOTAL_PRESSURE_TIME_AVERAGE"},
    {no: 50819, name: "TOTAL_TEMPERATURE_TIME_AVERAGE"},
    {no: 30355, name: "Q_CRITERION_TIME_AVERAGE"},
    {no: 4408, name: "HEAT_FLUX_TIME_AVERAGE"},
    {no: 55273, name: "ADJOINT_CONTINUITY"},
    {no: 65042, name: "ADJOINT_MOMENTUM"},
    {no: 45988, name: "ADJOINT_ENERGY"},
    {no: 18415, name: "ADJOINT_SA_VARIABLE"},
    {no: 26333, name: "ADJOINT_TKE"},
    {no: 65253, name: "ADJOINT_OMEGA"},
    {no: 12087, name: "ADJOINT_GAMMA"},
    {no: 15544, name: "ADJOINT_RE_THETA"},
    {no: 51864, name: "ADJOINT_N_TILDE"},
    {no: 16240, name: "SENSITIVITY"},
    {no: 12832, name: "NORMAL_SENSITIVITY"},
    {no: 24648, name: "SMOOTHED_NORMAL_SENSITIVITY"},
    {no: 11, name: "MASS_FLOW"},
    {no: 31, name: "ABS_MASS_FLOW"},
    {no: 9, name: "PRESSURE_DROP"},
    {no: 8, name: "TOTAL_PRESSURE_DROP"},
    {no: 601, name: "PRESSURE_RISE"},
    {no: 602, name: "VOLUME_FLOW_RATE"},
    {no: 18713, name: "GENERIC_FORCE"},
    {no: 12, name: "LIFT"},
    {no: 18, name: "LIFT_COEFFICIENT"},
    {no: 13, name: "DRAG"},
    {no: 19, name: "DRAG_COEFFICIENT"},
    {no: 55, name: "VISCOUS_DRAG"},
    {no: 56, name: "VISCOUS_DRAG_COEFFICIENT"},
    {no: 57, name: "PRESSURE_DRAG"},
    {no: 58, name: "PRESSURE_DRAG_COEFFICIENT"},
    {no: 14, name: "SIDEFORCE"},
    {no: 20, name: "SIDEFORCE_COEFFICIENT"},
    {no: 15, name: "PRESSURE_FORCE"},
    {no: 21, name: "PRESSURE_FORCE_COEFFICIENT"},
    {no: 16, name: "FRICTION_FORCE"},
    {no: 22, name: "FRICTION_FORCE_COEFFICIENT"},
    {no: 17, name: "TOTAL_FORCE"},
    {no: 23, name: "TOTAL_FORCE_COEFFICIENT"},
    {no: 27, name: "DOWNFORCE"},
    {no: 26, name: "DOWNFORCE_COEFFICIENT"},
    {no: 24, name: "TOTAL_MOMENT"},
    {no: 25, name: "TOTAL_MOMENT_COEFFICIENT"},
    {no: 32, name: "PITCHING_MOMENT"},
    {no: 35, name: "PITCHING_MOMENT_COEFFICIENT"},
    {no: 33, name: "ROLLING_MOMENT"},
    {no: 36, name: "ROLLING_MOMENT_COEFFICIENT"},
    {no: 34, name: "YAWING_MOMENT"},
    {no: 37, name: "YAWING_MOMENT_COEFFICIENT"},
    {no: 47, name: "DISK_TORQUE"},
    {no: 48, name: "DISK_THRUST"},
    {no: 46939, name: "DISK_ROTATION_RATE"},
    {no: 38, name: "ERROR_METRIC"},
    {no: 1328, name: "RESIDUAL_DENSITY"},
    {no: 25637, name: "RESIDUAL_X_MOMENTUM"},
    {no: 4957, name: "RESIDUAL_Y_MOMENTUM"},
    {no: 17509, name: "RESIDUAL_Z_MOMENTUM"},
    {no: 54285, name: "RESIDUAL_ENERGY"},
    {no: 18929, name: "RESIDUAL_SA_VARIABLE"},
    {no: 10670, name: "RESIDUAL_TKE"},
    {no: 61590, name: "RESIDUAL_OMEGA"},
    {no: 12563, name: "RESIDUAL_GAMMA"},
    {no: 38949, name: "RESIDUAL_RE_THETA"},
    {no: 750, name: "RESIDUAL_N_TILDE"},
    {no: 58234, name: "ANGULAR_VELOCITY"},
    {no: 35777, name: "PHYSICAL_TIME"},
    {no: 30865, name: "ITERATION_INDEX"},
    {no: 4549, name: "INNER_ITERATION_COUNT"},
    {no: 3943, name: "ACCELERATION"},
    {no: 39, name: "THRUST_PROFILE"},
    {no: 40, name: "TORQUE_PROFILE"},
    {no: 41, name: "RADIAL_FORCE_PROFILE"},
    {no: 42, name: "RELATIVE_RADIUS"},
    {no: 43, name: "TWIST_ANGLE"},
    {no: 44, name: "SWEEP_ANGLE"},
    {no: 45, name: "ANHEDRAL_ANGLE"},
    {no: 46, name: "RELATIVE_CHORD"},
    {no: 49, name: "THRUST_PER_UNIT_AREA"},
    {no: 50, name: "TORQUE_PER_UNIT_AREA"},
    {no: 52, name: "BLADE_LOCAL_ANGLE_OF_ATTACK"},
    {no: 53, name: "BLADE_SECTIONAL_DRAG_COEFFICIENT"},
    {no: 54, name: "BLADE_SECTIONAL_LIFT_COEFFICIENT"},
    {no: 64831, name: "DEBUG_QUANTITY"},
    {no: 50916, name: "INTERFACE_MATCHING_ISSUES"},
    {no: 33285, name: "SOLVER_STABILITY_ISSUES"},
  ],
);

/**
 * @generated from extension: luminary.proto.quantity.Quantity quantity = 52000;
 */
export const quantity = proto3.makeExtension(
  "luminary.proto.quantity.quantity", 
  EnumValueOptions, 
  () => ({ no: 52000, kind: "message", T: Quantity }),
);

