// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as ProtoDescriptor from '../../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../../SimulationParamDescriptor';
import { findFarfield } from '../../lib/boundaryConditionUtils';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { FORCE_DISTRIBUTION_DEFAULT_NBINS } from '../../lib/constants';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import { FARFIELD_NODE_ID, diagonalLength } from '../../lib/farfieldUtils';
import { initParamGroupProto } from '../../lib/initParam';
import {
  BODY_FRAME_DESCRIPTION,
  assignDefaultBodyFrame,
  createFrame,
  findBodyFrame,
} from '../../lib/motionDataUtils';
import { prefixNameGen, uniqueSequenceName } from '../../lib/name';
import {
  changeOutputType,
  createDefaultNode,
  createOutputNode,
  defaultChoice,
  nodeSelectorChoices,
  setDefaultFrameId,
  setDefaultName,
  setIncluded,
} from '../../lib/outputNodeUtils';
import { newNodeId as newTypedNodeId } from '../../lib/paraviewUtils';
import { getOrCreateProbePointsTable, newActuatorDisk, newProbePoint } from '../../lib/particleGroupUtils';
import { appendPhysicalBehavior } from '../../lib/physicalBehaviorUtils';
import { appendPorousModel } from '../../lib/porousModelUtils';
import { newNodeId } from '../../lib/projectDataUtils';
import { ProbePointsTableModel } from '../../lib/rectilinearTable/model';
import { addBox, addCylinder, addSphere, generateRegionId } from '../../lib/refinementRegionUtils';
import { isDiscreteGeometryFile, isGeometryFile } from '../../lib/upload/uploadUtils';
import { useFluidPhysics } from '../../model/hooks/useFluidPhysics';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as entitypb from '../../proto/entitygroup/entitygroup_pb';
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import * as meshgenerationpb from '../../proto/meshgeneration/meshgeneration_pb';
import * as plotspb from '../../proto/plots/plots_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useCustomFieldNodes } from '../../recoil/customFieldNodes';
import { useEntityGroupMap } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useSetLcvisVisibilityMap } from '../../recoil/lcvis/lcvisVisibilityMap';
import { useMeshUrlState } from '../../recoil/meshState';
import { useSetPendingMonitorPlanes } from '../../recoil/monitorPlanes';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useSetPlotNodes } from '../../recoil/plotNodes';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useCadMetadata } from '../../recoil/useCadMetadata';
import { useCadModifier } from '../../recoil/useCadModifier';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useMeshReadOnly } from '../../recoil/useMeshReadOnly';
import { useSetMeshMultiPart } from '../../recoil/useMeshingMultiPart';
import { useSetRefinementRegionVisibility } from '../../recoil/useRefinementRegions';
import { useStaticVolumes } from '../../recoil/volumes';
import { analytics } from '../../services/analytics';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useSimulationParamScope } from '../../state/external/project/simulation/paramScope';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { useIsSetupOrAdvancedView } from '../../state/internal/global/currentView';
import { paramChoicesToMenuItems } from '../Menu/CommonMenu';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useGeometryStatus } from '../hooks/useGeometryStatus';
import { useSimulationConfig } from '../hooks/useSimulationConfig';

import { AddNodeMenuButton } from './AddNodeButton';

type NodePropsCase = feoutputpb.OutputNode['nodeProps']['case'];

interface PhysicsScoped {
  physicsId: string;
}

function findEntitiesByIdAndType(
  entityGroupMap: EntityGroupMap,
  ids: string[],
  types: entitypb.EntityType[],
) {
  return ids.filter((id) => {
    if (entityGroupMap.has(id)) {
      const group = entityGroupMap.get(id);
      return types.includes(group.entityType);
    }
    return false;
  });
}

// A button for adding an output.
export const AddOutputButton = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds, setSelection, setOutputGraphId } = useSelectionContext();

  // == Recoil
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, '', '');
  const setNewNodes = useSetNewNodes();
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const experimentConfig = useEnabledExperiments();
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  // == Hooks
  const { working } = useGeometryStatus();

  const nodeFromType = (type: feoutputpb.OutputNode_Type): feoutputpb.OutputNode => {
    const allowedEntities: entitypb.EntityType[] = [];
    let propsCase: NodePropsCase;
    switch (type) {
      case feoutputpb.OutputNode_Type.SURFACE_OUTPUT_TYPE:
        allowedEntities.push(
          entitypb.EntityType.SURFACE,
          entitypb.EntityType.PARTICLE_GROUP,
          entitypb.EntityType.MONITOR_PLANE,
        );
        propsCase = 'surfaceAverage';
        break;
      case feoutputpb.OutputNode_Type.POINT_OUTPUT_TYPE:
        allowedEntities.push(entitypb.EntityType.PROBE_POINTS);
        propsCase = 'pointProbe';
        break;
      case feoutputpb.OutputNode_Type.VOLUME_OUTPUT_TYPE:
        allowedEntities.push(entitypb.EntityType.VOLUME);
        propsCase = 'volumeReduction';
        break;
      default:
        return createOutputNode();
    }
    const selectedEntities = findEntitiesByIdAndType(
      entityGroupMap,
      selectedNodeIds,
      allowedEntities,
    );
    if (selectedEntities.length) {
      const newNode = createDefaultNode(
        simParam,
        propsCase,
        type,
        experimentConfig,
        geometryTags,
        staticVolumes,
      );
      newNode.inSurfaces = selectedEntities;
      return newNode;
    }
    return createOutputNode();
  };

  // Called when add output is clicked.
  const addOutput = (type: feoutputpb.OutputNode_Type) => {
    const newOutputNodes = outputNodes.clone();
    const outputNode = nodeFromType(type);

    newOutputNodes.nodes.push(outputNode);
    outputNode.type = type;
    if (type !== feoutputpb.OutputNode_Type.DERIVED_OUTPUT_TYPE) {
      const choices = nodeSelectorChoices(type, paramScope);
      changeOutputType(
        defaultChoice(type, choices, !!findFarfield(simParam)),
        type,
        outputNode,
        simParam,
        experimentConfig,
        geometryTags,
        staticVolumes,
      );
      setDefaultFrameId(outputNode, simParam);
    } else {
      outputNode.calcType = feoutputpb.CalculationType.CALCULATION_AGGREGATE;
      outputNode.nodeProps = {
        case: 'derived',
        value: new feoutputpb.DerivedNode({ elements: [] }),
      };
      setIncluded(outputNode, feoutputpb.OutputIncludes.OUTPUT_INCLUDE_BASE, true);
    }
    setDefaultName(outputNode, outputNodes);

    setOutputNodes(newOutputNodes);
    const nodeId = outputNode.id;
    setSelection([nodeId]);
    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
    setOutputGraphId({ node: nodeId, graphIndex: 0 });
  };

  const menuItems: CommonMenuItem[] = [
    {
      label: 'Point',
      description: 'Output at a point',
      onClick: () => addOutput(feoutputpb.OutputNode_Type.POINT_OUTPUT_TYPE),
    },
    {
      label: 'Surface',
      description: 'Integrated output over surfaces',
      onClick: () => addOutput(feoutputpb.OutputNode_Type.SURFACE_OUTPUT_TYPE),
    },
    {
      label: 'Global',
      description: 'Global outputs',
      onClick: () => addOutput(feoutputpb.OutputNode_Type.GLOBAL_OUTPUT_TYPE),
    },
    {
      label: 'Volume',
      description: 'Volume reduction outputs',
      onClick: () => addOutput(feoutputpb.OutputNode_Type.VOLUME_OUTPUT_TYPE),
    },
    {
      label: 'Custom',
      description: 'Calculate custom outputs with expressions',
      onClick: () => addOutput(feoutputpb.OutputNode_Type.DERIVED_OUTPUT_TYPE),
    },
  ];

  return (
    <AddNodeMenuButton disabled={working} menuItems={menuItems} />
  );
};

// A button for adding a custom field.
export const AddCustomFieldButton = () => {
  // == Contexts
  const { projectId } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil.
  const [customFieldNodes, setCustomFieldNodes] = useCustomFieldNodes(projectId);
  const setNewNodes = useSetNewNodes();

  // == Hooks
  const { working } = useGeometryStatus();

  const addCustomField = () => {
    const newCustomFields = customFieldNodes.clone();
    const customField = new feoutputpb.CustomField();
    customField.id = newNodeId();

    const names = customFieldNodes.customFields.map((field) => field.name);
    customField.name = uniqueSequenceName(
      names,
      prefixNameGen('Custom Field', true),
      { recycleNumbers: true },
    );

    newCustomFields.customFields.push(customField);

    setCustomFieldNodes(newCustomFields);
    const nodeId = customField.id;
    setSelection([nodeId]);
    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
  };

  const menuItems: CommonMenuItem[] = [
    {
      label: 'Scalar',
      description: 'Add new scalar field for visualization',
      onClick: () => addCustomField(),
    },
  ];

  return (
    <AddNodeMenuButton disabled={working} menuItems={menuItems} />
  );
};

export const AddPhysicalModel = (props: PhysicsScoped) => {
  // == Props
  const { physicsId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo } = useSelectionContext();

  // == Recoil
  const setNewNodes = useSetNewNodes();

  // == Hooks
  const { saveParamAsync } = useSimulationConfig();
  const { working } = useGeometryStatus();
  const { hasVolumes } = useFluidPhysics(projectId, workflowId, jobId, readOnly, physicsId);

  if (readOnly) {
    return null;
  }

  const addPhysicalBehavior = async (choice: ProtoDescriptor.Choice) => {
    const nodeId = await saveParamAsync(
      (newParam) => appendPhysicalBehavior(newParam, physicsId, choice).physicalBehaviorId,
    );
    setSelection([nodeId]);
    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
    setScrollTo({ node: nodeId });
  };

  const addPorousModel = async () => {
    const nodeId = await saveParamAsync(
      (newParam) => appendPorousModel(newParam, physicsId).porousBehaviorId,
    );
    setSelection([nodeId]);
    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
    setScrollTo({ node: nodeId });
  };

  // Even though all physical behavior model types are available in the params, we're only exposing
  // a subset in the UI.
  const allowedChoices = [
    simulationpb.PhysicalBehaviorModel.ACTUATOR_DISK_MODEL,
  ];

  const modelChoices = (
    paramDesc[ParamName.PhysicalBehaviorModel] as ProtoDescriptor.MultipleChoiceParam
  ).choices;
  const menuItems = paramChoicesToMenuItems(
    modelChoices.filter((choice) => allowedChoices.includes(choice.enumNumber)),
    addPhysicalBehavior,
  );

  const disabledPorousReason = hasVolumes ?
    '' :
    'Make volume selections for this physics to enable porous models';

  menuItems.push({
    label: 'Porous Model',
    onClick: addPorousModel,
    endIcon: { name: 'sparkleDouble', color: 'var(--color-primary-cta)' },
    help: 'Porous models are under early access.',
    disabledReason: disabledPorousReason,
    disabled: !!disabledPorousReason,
  });

  return (
    <AddNodeMenuButton
      disabled={working}
      menuItems={menuItems}
    />
  );
};

export const AddGeometryButton = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo } = useSelectionContext();

  // == Recoil
  const isSetupOrAdvancedView = useIsSetupOrAdvancedView();
  const [selectedGeometry] = useSelectedGeometry(projectId);
  const [cadModifier, setCadModifier] = useCadModifier(projectId);
  const [meshUrlState] = useMeshUrlState(projectId);
  const setNewNodes = useSetNewNodes();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const setLcvisVisibility = useSetLcvisVisibilityMap({ projectId, workflowId, jobId });
  const isStaff = useIsStaff();
  const setPendingMonitorPlanes = useSetPendingMonitorPlanes();

  // == Hooks
  const { saveParamAsync } = useSimulationConfig();

  if (readOnly) {
    return null;
  }

  const addParticleGroup = async (particleGroupType: ProtoDescriptor.Choice['enumNumber']) => {
    const nodeId = await saveParamAsync(
      (newParam) => {
        switch (particleGroupType) {
          case simulationpb.ParticleGroupType.ACTUATOR_DISK: {
            const disk = newActuatorDisk(newParam);
            newParam.particleGroup.push(disk);
            return disk.particleGroupId;
          }
          case simulationpb.ParticleGroupType.PROBE_POINTS: {
            const table = getOrCreateProbePointsTable(newParam);

            const tableModel = new ProbePointsTableModel(table);
            const newPoint = newProbePoint(tableModel);
            tableModel.addRecord(newPoint.id, newPoint.name, newPoint.x, newPoint.y, newPoint.z);
            return newPoint.id;
          }
          case simulationpb.ParticleGroupType.ACTUATOR_LINE:
          case simulationpb.ParticleGroupType.SOURCE_POINTS: {
            throw Error('Particle group type not yet supported');
          }
          default: {
            throw Error('Unknown particle group type');
          }
        }
      },
    );
    if (lcvisEnabled) {
      setLcvisVisibility((old) => (new Map(old).set(nodeId, true)));
    }
    setSelection([nodeId]);
    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
    setScrollTo({ node: nodeId });
  };

  const addMonitorPlane = async () => {
    const nodeId = newTypedNodeId(ParaviewRpc.TreeNodeType.MONITOR_PLANE);
    setPendingMonitorPlanes((currentValue) => {
      const result = new Set(currentValue);

      result.add(nodeId);
      return result;
    });

    await saveParamAsync(
      (newParam) => {
        const planes = newParam.monitorPlane;
        const names = planes.map((plane) => plane.monitorPlaneName);

        const newPlane = initParamGroupProto(
          new simulationpb.MonitorPlane(),
          paramGroupDesc[ParamGroupName.MonitorPlane],
        );
        newPlane.monitorPlaneId = nodeId;
        newPlane.monitorPlaneName = uniqueSequenceName(names, prefixNameGen('Plane'));

        newParam.monitorPlane.push(newPlane);
      },
    );

    if (lcvisEnabled) {
      setLcvisVisibility((old) => (new Map(old).set(nodeId, true)));
    }
    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
    setSelection([nodeId]);
    setScrollTo({ node: nodeId });
  };

  const addFarField = () => {
    setCadModifier(new meshgenerationpb.UserGeometryMod());
    setNewNodes((nodes) => [...nodes, initializeNewNode(FARFIELD_NODE_ID)]);
    setSelection([FARFIELD_NODE_ID]);
    setScrollTo({ node: FARFIELD_NODE_ID });
  };
  const isGeometry = meshUrlState.activeType === projectstatepb.UrlType.GEOMETRY;

  const menuItems: CommonMenuItem[] = [];
  if (isSetupOrAdvancedView) {
    menuItems.push(
      // Add Disk menu item
      {
        label: 'Disk',
        onClick: () => addParticleGroup(simulationpb.ParticleGroupType.ACTUATOR_DISK),
        description: 'Models rotor performance using actuator disk theory.',
      },
      // Add Point menu item
      {
        label: 'Monitor Point',
        onClick: () => addParticleGroup(simulationpb.ParticleGroupType.PROBE_POINTS),
        description: 'Monitors flow quantities at arbitrary points in space.',
      },
      // Add Plane menu item
      {
        disabled: isGeometry,
        disabledReason: (
          'Monitor planes cannot be added when viewing geometry. ' +
          'Please add monitor planes from mesh view.'
        ),
        label: 'Monitor Plane',
        onClick: () => addMonitorPlane(),
        description: 'Monitors integrated quantities on arbitrary cross sections.',
      },
    );
  }

  // We still allow to add farfields in the setup tab for old projects.
  if (isSetupOrAdvancedView && !selectedGeometry.geometryId) {
    let disabledReason = '';
    if (cadModifier) {
      disabledReason = 'A far-field already exists.';
    } else if (!isGeometryFile(meshUrlState.url)) {
      disabledReason = 'A far-field can only be used if a geometry file has been uploaded.';
    } else if (isDiscreteGeometryFile(meshUrlState.url) && !isStaff) {
      // TODO(LC-17595): activate the far-field creation for discrete geometries
      disabledReason = 'A far-field currently cannot be added to a discrete geometry.';
    }
    menuItems.push(
      {
        label: 'Far-Field',
        // far-field creation is only enabled for geometry files and if no far-field exists yet.
        disabled: !!disabledReason,
        onClick: () => addFarField(),
        description: 'Used for mesh generation in external flow simulations.',
        disabledReason,
      },
    );
  }

  return (
    <AddNodeMenuButton
      maxWidth={260}
      menuItems={menuItems}
      tooltipPlacement="right"
    />
  );
};

export function newPlotParam(): ParaviewRpc.PlotParam {
  return {
    typ: ParaviewRpc.TreeNodeType.PLOT,
    nodeIds: [''],
    param: {
      typ: 'ScatterPlot',
      // The extents of the scalar quantity.
      range: [0, 0],
      // Variable to plot.
      quantity: {
        // 'Density', etc.
        displayDataName: '',
        // Used to select vector components.
        displayDataNameComponent: 0,
      },
    },

  };
}

// The button that allows the user to add a plot
export const AddPlotButton = () => {
  // == Contexts
  const { projectId } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil
  const setPlotState = useSetPlotNodes(projectId);
  const setNewNodes = useSetNewNodes();

  // == Custom hooks
  const { working } = useGeometryStatus();

  const addXYPlot = () => {
    const nodeId = newNodeId();
    setPlotState((oldPlotState) => {
      // Select the new node and enable editing on it.
      const newPlotState = oldPlotState.clone();

      const newXyPlot = new plotspb.PlotSettings_XYPlot({
        yAxisRange: new plotspb.AxisRange({ rangeStart: -1e15, rangeEnd: 1e15 }),
        xAxisRange: new plotspb.AxisRange({ rangeStart: -1e15, rangeEnd: 1e15 }),
        yAxis: new plotspb.YAxisTuple({ displayDataName: '', displayDataNameComponent: 0n }),
      });

      const existingNames = oldPlotState.plots.map((plot) => plot.name);
      const newSettings = new plotspb.PlotSettings({
        plot: { case: 'xyPlot', value: newXyPlot },
        name: uniqueSequenceName(existingNames, (count: number) => `XY Plot ${count - 1}`),
        id: newNodeId(),
      });

      newPlotState.plots.push(newSettings);

      setSelection([newSettings.id]);
      return newPlotState;
    });

    setNewNodes((oldValue) => [...oldValue, initializeNewNode(nodeId)]);
  };

  const addForceDistribution = () => {
    const nodeId = newNodeId();
    setPlotState((oldPlotState) => {
      // Select the new node and enable editing on it.
      const newPlotState = oldPlotState.clone();

      const newXyPlot = new plotspb.PlotSettings_ForceDistribution({
        axis: plotspb.XAxisOptions.X_COORD,
        distributionType: plotspb.ForceDistributionOptions.LOCAL,
        forceDirType: plotspb.ForceDirectionOptions.VECTOR,
        forceDirX: 1,
        forceDirY: 0,
        forceDirZ: 0,
        nBins: FORCE_DISTRIBUTION_DEFAULT_NBINS,
      });

      const existingNames = oldPlotState.plots.map((plot) => plot.name);
      const newSettings = new plotspb.PlotSettings({
        plot: { case: 'forceDistribution', value: newXyPlot },
        name: uniqueSequenceName(
          existingNames,
          (count: number) => `Force Distribution ${count - 1}`,
        ),
        id: newNodeId(),
      });

      newPlotState.plots.push(newSettings);

      setSelection([newSettings.id]);
      return newPlotState;
    });

    setNewNodes((oldValue) => [...oldValue, initializeNewNode(nodeId)]);
  };

  const menuItems: CommonMenuItem[] = [
    {
      label: 'XY Plot',
      onClick: () => addXYPlot(),
    },
    {
      label: 'Force Distribution',
      onClick: () => addForceDistribution(),
    },
  ];

  return (
    <AddNodeMenuButton
      disabled={working}
      maxWidth={260}
      menuItems={menuItems}
    />
  );
};

export const AddMotionDataFrameButton = () => {
  // == Contexts
  const { readOnly } = useProjectContext();
  const { setSelection, setScrollTo } = useSelectionContext();

  // == Recoil
  const setNewNodes = useSetNewNodes();

  // == Hooks
  const { simParam, saveParamAsync } = useSimulationConfig();

  const addFrame = async (
    type: simulationpb.MotionType,
    bodyFrame?: boolean,
  ) => {
    const nodeId = await saveParamAsync(
      (newParam) => {
        let frameLabel: string | undefined;
        if (bodyFrame) {
          frameLabel = 'Body Frame';
        }
        const frame = createFrame(
          newParam,
          type,
          frameLabel,
        );
        if (bodyFrame) {
          assignDefaultBodyFrame(newParam, frame);
        }
        return frame.frameId;
      },
    );

    const tags = type === simulationpb.MotionType.NO_MOTION ? [] : ['motion'];

    setNewNodes((oldValue) => [...oldValue, initializeNewNode(nodeId, tags)]);
    setSelection([nodeId]);
    setScrollTo({ node: nodeId });

    analytics.track('Add Motion Data Frame', {
      frameType: type,
      isBodyFrame: bodyFrame,
      newNodeId: nodeId,
    });
  };

  if (readOnly) {
    return null;
  }

  const menuItems: CommonMenuItem[] = [
    {
      label: 'Rotation',
      onClick: () => addFrame(simulationpb.MotionType.CONSTANT_ANGULAR_MOTION),
    },
    {
      label: 'Translation',
      onClick: () => addFrame(simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION),
    },
    {
      label: 'Frame',
      onClick: () => addFrame(simulationpb.MotionType.NO_MOTION),
    },
    {
      label: 'Body Frame',
      onClick: () => addFrame(simulationpb.MotionType.NO_MOTION, true),
      disabled: !!findBodyFrame(simParam),
      disabledReason: 'There can only be one Body Frame',
      help: BODY_FRAME_DESCRIPTION,
    },
  ];

  return (
    <AddNodeMenuButton menuItems={menuItems} />
  );
};

export const AddRefinementRegionButton = () => {
  // == Contexts
  const { projectId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo } = useSelectionContext();

  // == Recoil
  const [cadMetadata] = useCadMetadata(projectId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const setRefinementRegionVis = useSetRefinementRegionVisibility(projectId);
  const meshReadOnly = useMeshReadOnly(projectId);

  // == Data
  const bBox = cadMetadata.boundingBox;
  const defaultOuterRadius = diagonalLength(bBox) / 2;
  const newId = generateRegionId();

  if (readOnly || meshReadOnly) {
    return null;
  }

  const setVisible = (id: string) => {
    setRefinementRegionVis((oldVis) => {
      const newVis = { ...oldVis };
      newVis[id] = true;
      return newVis;
    });
  };

  const menuItems: CommonMenuItem[] = [
    {
      label: 'Box',
      onClick: () => {
        addBox(setMeshMultiPart, newId, defaultOuterRadius);
        setSelection([newId]);
        setScrollTo({ node: newId });
        setVisible(newId);
      },
    },
    {
      label: 'Cylinder',
      onClick: () => {
        addCylinder(setMeshMultiPart, newId, defaultOuterRadius);
        setSelection([newId]);
        setScrollTo({ node: newId });
        setVisible(newId);
      },
    },
    {
      label: 'Sphere',
      onClick: () => {
        addSphere(setMeshMultiPart, newId, defaultOuterRadius);
        setSelection([newId]);
        setScrollTo({ node: newId });
        setVisible(newId);
      },
    },
  ];

  return (
    <AddNodeMenuButton menuItems={menuItems} />
  );
};
