// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect } from 'react';

import { useRecoilCallback } from 'recoil';

import { cameraIndexFromNodeId } from '../../../lib/cameraUtils';
import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { getAspectRatio, paraviewCameraToLcvis } from '../../../lib/lcvis/lcvisUtils';
import { assembleMenuSections, filteredMenuItems } from '../../../lib/menuUtil';
import { fromBigInt } from '../../../lib/number';
import { Logger } from '../../../lib/observability/logs';
import { RecoilProjectKey } from '../../../lib/persist';
import {
  deleteTreeNodeMenuItem,
  groupTreeNodeMenuItem,
  ungroupTreeNodeMenuItem,
} from '../../../lib/treeUtils';
import {
  CameraGroupMapAccessType,
  cameraJsonState,
  useCameraGroupData,
} from '../../../recoil/cameraState';
import { lcvisCameraSelector } from '../../../recoil/lcvis/lcvisCameraState';
import { lcVisEnabledSelector } from '../../../recoil/lcvis/lcvisEnabledState';
import ImageRenderer from '../../Paraview/ImageRenderer';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { parallelProjectionToCameraMode } from '../../Paraview/cameraUtil';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeGrouping } from '../../hooks/useNodeGrouping';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

const logger = new Logger('treePanel/Camera');

export const CameraTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { paraviewRenderer, cameraMode, setCameraMode } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const cameraKey: RecoilProjectKey = { projectId, workflowId, jobId };
  const cameraGroupData = useCameraGroupData(cameraKey, CameraGroupMapAccessType.ALL);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteCameraNode, postDeleteNodeIds } = useNodeDeletion();
  const { canGroup, canUngroup, groupCameras, ungroupCameras } = useNodeGrouping();

  const cameraIndex = cameraIndexFromNodeId(node.id);

  const isSelected = selectedNodeIds.includes(node.id);

  const openCamera = useRecoilCallback(({ snapshot, set }) => async (cameraId: number) => {
    try {
      const cameraJson = await snapshot.getPromise(cameraJsonState(cameraId));
      const lcvisEnabled = await snapshot.getPromise(lcVisEnabledSelector(projectId));
      if (lcvisEnabled) {
        const aspectRatio = await getAspectRatio(projectId);
        if (aspectRatio === 0) {
          logger.error('Could not get aspect ratio');
        }
        const lcvisCam = paraviewCameraToLcvis(cameraJson, aspectRatio);
        set(lcvisCameraSelector({ projectId, workflowId, jobId }), lcvisCam);
        return;
      }
      (paraviewRenderer as ImageRenderer).setCamera(cameraJson);

      // Update the cameraMode (and the Perspective/Orthographic dropdown selection) if it
      // doesn't match the projection / cameraMode in the saved camera that we are opening.
      const savedCameraMode = parallelProjectionToCameraMode(cameraJson.parallelProjection);
      if (cameraMode !== savedCameraMode) {
        setCameraMode(savedCameraMode);
      }
    } catch (error) {
      logger.error('Could not open camera.', error);
    }
  });

  useEffect(() => {
    // Do not try to open a camera on node/isSelected state change when > 1 camera is selected
    if (selectedNodeIds.length > 1) {
      return;
    }
    // If the node has a 0 id, that means the node has just been added to the dom with one of the
    // "save camera" buttons. We shouldn't try to open that camera because it's 1) opened anyway
    // 2) it would throw an error because the node is a transient node and the actual camera data
    // still doesn't exist in the db.
    if (node.id === 'camera-0') {
      return;
    }
    if (isSelected) {
      openCamera(fromBigInt(cameraIndex)).catch((error) => {
        logger.error('Could not set camera on isSelected/node change');
      });
    }
    // Don't add the openCamera and the selectedNodeIds dependencies as we only want this to run
    // when isSelected changes. Otherwise we'll get buggy behavior of losing the selected state or
    // opening a few cameras at once (and a flicker behavior).
  }, [isSelected, node]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteRow = useCallback(() => {
    if (deleteCameraNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteCameraNode, node.id, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => assembleMenuSections(
    filteredMenuItems([
      {
        itemConfig: groupTreeNodeMenuItem(() => groupCameras([node.id])),
        shouldShow: canGroup([node.id], cameraGroupData),
      },
      {
        itemConfig: ungroupTreeNodeMenuItem(() => ungroupCameras(node.id)),
        shouldShow: canUngroup(node, cameraGroupData),
      },
    ]),
    [deleteTreeNodeMenuItem(deleteRow, !canDelete(node.type, node.id))],
  ), [
    canDelete,
    canGroup,
    canUngroup,
    cameraGroupData,
    deleteRow,
    groupCameras,
    node,
    ungroupCameras,
  ]);

  return (
    <TreeRow
      {...props}
      canMultiSelect
      getContextMenuItems={getContextMenuItems}
      primaryIcon={getNodeTypeIcon(props.node.type)}
      renaming={renaming}
    />
  );
};
