// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { CurrentView } from '../../lib/componentTypes/context';
import { useSetNodeLinkState } from '../../recoil/useNodeLink';
import { TreeViewType, useTreeViewType } from '../../recoil/useTreeViewState';
import { useCurrentView } from '../../state/internal/global/currentView';

import { useGetNodeFromAnyTree } from './useTree';

export const useNodeSelect = () => {
  // == State
  const getNodeFromAnyTree = useGetNodeFromAnyTree();
  const setNodeLinkIds = useSetNodeLinkState();
  const [treeViewType, setTreeViewType] = useTreeViewType();
  const currentView = useCurrentView();

  return useCallback((nodeId: string) => {
    const nodeIsInTree = !!getNodeFromAnyTree(nodeId);
    // If we are in the Post-Processing view in the Analysis page, there's a chance that the
    // NodeLink is trying to link to a node that is not present in the current view and is only
    // available in the Setup Details view. If that seems to be the case, we should first open the
    // Setup Details view.
    if (
      treeViewType === TreeViewType.POST_PROCESSING &&
      currentView === CurrentView.ANALYSIS &&
      !nodeIsInTree
    ) {
      setTreeViewType(TreeViewType.SETUP);
    }

    setNodeLinkIds([nodeId]);
  }, [
    currentView,
    getNodeFromAnyTree,
    setNodeLinkIds,
    setTreeViewType,
    treeViewType,
  ]);
};
