// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.

import { IconSpec } from '../componentTypes/svgIcon';
import { iconForFeature } from '../geometryUtils';
import { getPhysicsTypeIconName } from '../physicsUtils';

import { NodeType } from './node';

import * as frontendpb from '@/proto/frontend/frontend_pb';
import { Feature } from '@/proto/geometry/geometry_pb';
import { PlotSettings } from '@/proto/plots/plots_pb';

export function getNodeTypeIcon(
  nodeType: NodeType,
  opts?: {
    nodeId?: string,
    contacts?: frontendpb.GetGeometryContactsReply_GeoContact[],
    feature?: Feature | undefined,
    isTag?: boolean,
    plot?: PlotSettings,
  },
): IconSpec | undefined {
  switch (nodeType) {
    case NodeType.CAMERA:
    case NodeType.CAMERA_CONTAINER:
      return { name: 'camera' };
    case NodeType.CAMERA_GLOBAL_CONTAINER:
      return { name: 'folderArrowUpRight' };
    case NodeType.CAMERA_GROUP:
      return { name: 'folder' };
    case NodeType.EXPLORATION_POLICY:
    case NodeType.EXPLORATION_VARIABLE:
    case NodeType.EXPLORATION_VARIABLE_CONTAINER:
      return { name: 'wavesQuadruple' };
    case NodeType.FAR_FIELD:
      return { name: 'cubeOutline' };
    case NodeType.FILTER:
      return { name: 'wand' }; // the filter row for has its own logic. This is a generic default
    case NodeType.GENERAL_SETTINGS:
      return { name: 'verticalControls' };
    case NodeType.GEOMETRY:
      return { name: 'cubeSolid' };
    case NodeType.GEOMETRY_CONTACT: {
      const contact = opts?.contacts?.find(({ id }) => id === opts.nodeId);
      if (contact && (contact.sideA.length > 1 || contact.sideB.length > 1)) {
        return { name: 'verticalPlanesFilledTriple' };
      }
      return { name: 'verticalPlanesFilledDouble' };
    }
    case NodeType.GEOMETRY_CONTACT_CONTAINER:
      return { name: 'verticalPlanesFilledOutlined' };
    case NodeType.GEOMETRY_MODIFICATION:
      return iconForFeature(opts?.feature);
    case NodeType.MATERIAL_CONTAINER:
    case NodeType.MATERIAL_FLUID:
    case NodeType.MATERIAL_SOLID:
      return { name: 'gridBlocks' };
    case NodeType.MESH:
    case NodeType.MESH_ADAPTATION_BOUNDARY:
    case NodeType.MESH_BOUNDARY:
    case NodeType.MESH_MODEL:
    case NodeType.MESH_SIZE:
      return { name: 'cubeOutline' };
    case NodeType.MONITOR_PLANE:
      return { name: 'parallelogramOutline' };
    case NodeType.MOTION_FRAME:
    case NodeType.MOTION_GLOBAL_FRAME:
      return { name: 'triAxes' };
    case NodeType.OUTPUT:
    case NodeType.OUTPUT_CONTAINER:
      return { name: 'target' };
    case NodeType.CUSTOM_FIELD:
    case NodeType.CUSTOM_FIELD_CONTAINER:
      return { name: 'sphereWithLines' };
    case NodeType.PARTICLE_GROUP:
      return { name: 'circle' };
    case NodeType.PHYSICAL_BEHAVIOR:
      return { name: 'intersectingDisks' };
    case NodeType.PHYSICS_CONTAINER:
      return { name: 'atom' };
    case NodeType.PHYSICS_FLUID:
      return { name: getPhysicsTypeIconName('fluid') };
    case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION:
      return { name: 'hash' };
    case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION_CONTAINER:
    case NodeType.PHYSICS_FLUID_INITIALIZATION:
    case NodeType.PHYSICS_FLUID_PHYSICAL_MODEL_CONTAINER:
      return { name: 'gridUneven' };
    case NodeType.PHYSICS_HEAT:
      return { name: getPhysicsTypeIconName('heat') };
    case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION:
      return { name: 'hash' };
    case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION_CONTAINER:
      return { name: 'gridUneven' };
    case NodeType.PHYSICS_HEAT_HEAT_SOURCE:
      return { name: 'verticalWaves' };
    case NodeType.PHYSICS_HEAT_HEAT_SOURCE_CONTAINER:
    case NodeType.PHYSICS_HEAT_INITIALIZATION:
      return { name: 'gridUneven' };
    case NodeType.PHYSICS_MULTI:
      return { name: 'intersectingRings' };
    case NodeType.PHYSICS_MULTI_INTERFACE:
      return { name: 'hash' };
    case NodeType.PHYSICS_PERIODIC_PAIR:
      return { name: 'gridQuadOutlined' };
    case NodeType.PHYSICS_SLIDING_INTERFACE:
      return { name: 'hash' };
    case NodeType.PHYSICS_VOLUME_SELECTION:
      return { name: 'gridUneven' };
    case NodeType.PLOT: {
      switch (opts?.plot?.plot.case) {
        case 'monitorPlot':
          return { name: 'chartArrowRight' };
        case 'xyPlot':
          return { name: 'chart' };
        case 'forceDistribution':
          return { name: 'chart' };
        default:
          return undefined;
      }
    }
    case NodeType.PLOT_CONTAINER:
      return { name: 'chart' };
    case NodeType.POINT_CONTAINER:
      return { name: 'circle', maxHeight: 4.5, maxWidth: 4.5 };
    case NodeType.POROUS_MODEL:
      return { name: 'cubeSolid' };
    case NodeType.PROBE_POINT:
      return { name: 'circle', maxHeight: 4.5, maxWidth: 4.5 };
    case NodeType.REFERENCE_VALUE:
      return { name: 'listNumbered' };
    case NodeType.REFINEMENT_REGION:
    case NodeType.REFINEMENT_REGION_CONTAINER:
      return { name: 'cubeOutline' };
    case NodeType.SOLVER_SETTINGS:
      return { name: 'pencilLines' };
    case NodeType.STOPPING_CONDITIONS:
      return { name: 'stop', maxHeight: 7.5, maxWidth: 7.5 };
    case NodeType.SURFACE:
      return { name: 'cubeOutline' };
    case NodeType.SURFACE_GROUP:
      return { name: opts?.isTag ? 'tag' : 'group' };
    case NodeType.TAGS_FACE:
      return { name: 'cubeOutline' };
    case NodeType.TAGS_BODY:
      return { name: 'cubeSolid' };
    case NodeType.TAGS_CONTAINER:
      return { name: 'tag' };
    case NodeType.TRANSIENT_SETTINGS:
      return { name: 'clock' };
    case NodeType.VOLUME:
      return { name: 'cubeSolid' };
    default: {
      return undefined;
    }
  }
}
