// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
/*
  Colors defined in
  https://www.figma.com/file/8rNGeZ9HiJ4Zl6pVaEk5Lq/Luminary-Component-Library?node-id=140%3A2814
*/
import convert from 'color-convert';

export type ColorBase = 'red' |
  'neutral' |
  'orange' |
  'yellow' |
  'citron' |
  'citron-green' |
  'green-yellow' |
  'green' |
  'teal' |
  'cyan' |
  'blue' |
  'violet' |
  'purple' |
  'purple-red' |
  'pink';

export type RgbColor = [number, number, number];
export type RgbaColor = [number, number, number, number];

const hueColors = {
  /*
    COLORS NAMED BY HUE

    ...as defined in
    https://www.figma.com/file/8rNGeZ9HiJ4Zl6pVaEk5Lq/Luminary-Component-Library?node-id=140%3A2814
  */

  // Extended Cool Neutral
  neutral900: '#f4f4f7',
  neutral850: '#e8e8eb',
  neutral800: '#c7c7ca',
  neutral750: '#bebec1',
  neutral700: '#acacb0',
  neutral650: '#88898d',
  neutral600: '#7e7f83',
  neutral550: '#75767a',
  neutral500: '#6b6c71',
  neutral450: '#626368',
  neutral400: '#595a5e',
  neutral350: '#505155',
  neutral300: '#46474c',
  neutral250: '#343539',
  neutral200: '#2f3034',
  neutral150: '#2a2b30',
  neutral100: '#212227',
  neutral50: '#18191e',
  neutral0: '#0f1215',
  transparent: 'rgb(0, 0, 0, 0)',

  // Extended Red
  red900: '#ffddd4',
  red850: '#ffcabd',
  red800: '#ffa58f',
  red700: '#ff9075',
  red600: '#ff7859',
  red500: '#e55d3c',
  red400: '#cc5133',
  red300: '#ad452b',
  red200: '#8f3924',
  red100: '#702d1c',
  red50: '#522014',

  // Extended Orange
  orange900: '#fff5eb',
  orange850: '#ffdbbd',
  orange800: '#ffc38f',
  orange700: '#ffa461',
  orange600: '#ff8d4d',
  orange500: '#f68533',
  orange400: '#e98733',
  orange300: '#c6732b',
  orange200: '#a35f24',
  orange100: '#804a1c',
  orange50: '#5d3614',

  // Extended Yellow
  yellow900: '#fffae6',
  yellow850: '#fff3c2',
  yellow800: '#ffe999',
  yellow700: '#ffd033',
  yellow600: '#ffbc33',
  yellow500: '#ffad4c',
  yellow400: '#ffa233',
  yellow300: '#d98a2b',
  yellow200: '#b37124',
  yellow100: '#8c591c',
  yellow50: '#664114',

  // Extended Citron
  citron900: '#fffbe0',
  citron850: '#fff7c6',
  citron800: '#fff2a0',
  citron700: '#ffeb7f',
  citron600: '#ffe840',
  citron500: '#ffe240',
  citron400: '#ffd540',
  citron300: '#d9b536',
  citron200: '#b3952d',
  citron100: '#8C7523',
  citron50: '#66551A',

  // Extended Citron Green
  citronGreen900: '#f8ffe6',
  citronGreen850: '#f0f7c0',
  citronGreen800: '#eef59a',
  citronGreen700: '#e2eb74',
  citronGreen600: '#d2dc40',
  citronGreen500: '#bfca40',
  citronGreen400: '#a2b240',
  citronGreen300: '#8a9736',
  citronGreen200: '#717d2d',
  citronGreen100: '#596223',
  citronGreen50: '#41471a',

  // Extended Green Yellow
  greenYellow900: '#f1ffe7',
  greenYellow850: '#cff7bc',
  greenYellow800: '#bef594',
  greenYellow700: '#a4ea78',
  greenYellow600: '#92d143',
  greenYellow500: '#7bb433',
  greenYellow400: '#718e33',
  greenYellow300: '#60792b',
  greenYellow200: '#4f6324',
  greenYellow100: '#3e4e1c',
  greenYellow50: '#2d3914',

  // Extended Green
  green900: '#e9fdf2',
  green850: '#bcf7da',
  green800: '#94f5cd',
  green700: '#79e1b5',
  green600: '#5ec298',
  green500: '#339f7b',
  green400: '#338569',
  green300: '#2b7159',
  green200: '#245d4a',
  green100: '#1c493a',
  green50: '#14352a',

  // Extended Teal
  teal900: '#ecfdff',
  teal850: '#c6f7ff',
  teal800: '#9ae9f5',
  teal700: '#40d5ec',
  teal600: '#40cae3',
  teal500: '#40bacf',
  teal400: '#40aabc',
  teal300: '#3691a0',
  teal200: '#2d7784',
  teal100: '#235e67',
  teal50: '#1a444b',

  // Extended Cyan
  cyan900: '#e3f2ff',
  cyan850: '#bee5ff',
  cyan800: '#67bfff',
  cyan700: '#47aeff',
  cyan600: '#26a2ff',
  cyan500: '#2688d4',
  cyan400: '#2c78b3',
  cyan300: '#256698',
  cyan200: '#1f547d',
  cyan100: '#184262',
  cyan50: '#123048',

  // Extended Blue
  blue900: '#deebff',
  blue850: '#c2ddff',
  blue800: '#70aeff',
  blue700: '#519dff',
  blue600: '#3384ff',
  blue500: '#3375d6',
  blue400: '#396cb8',
  blue300: '#305c9c',
  blue200: '#284c81',
  blue100: '#1f3b65',
  blue50: '#172b4a',

  // Extended Violet
  violet900: '#d9e4ff',
  violet850: '#acb8f3',
  violet800: '#92a0fa',
  violet700: '#717ade',
  violet600: '#6367d5',
  violet500: '#575eca',
  violet400: '#4750ab',
  violet300: '#3c4491',
  violet200: '#323878',
  violet100: '#272c5e',
  violet50: '#1c2044',

  // Extended Purple
  purple900: '#dbd5f6',
  purple850: '#b4a6fa',
  purple800: '#9f8efB',
  purple700: '#8772f1',
  purple600: '#6e56e6',
  purple500: '#5844bb',
  purple400: '#493ca2',
  purple350: '#393484',
  purple300: '#3a3489',
  purple200: '#322e6d',
  purple100: '#2a2750',
  purple50: '#222134',

  // Extended Purple Red
  purpleRed900: '#f0dff9',
  purpleRed850: '#d8b1ea',
  purpleRed800: '#b58dd5',
  purpleRed700: '#a472c2',
  purpleRed600: '#945fb4',
  purpleRed500: '#8555b4',
  purpleRed400: '#764b99',
  purpleRed300: '#644082',
  purpleRed200: '#53356b',
  purpleRed100: '#412954',
  purpleRed50: '#2f1e3d',

  // Extended Pink
  pink900: '#ffebfa',
  pink850: '#ffbde5',
  pink800: '#ffa3e6',
  pink700: '#ff7fd0',
  pink600: '#f363c7',
  pink500: '#e55dae',
  pink400: '#cc51a9',
  pink300: '#ad4590',
  pink200: '#8f3976',
  pink100: '#702d5d',
  pink50: '#522044',
};

const funcColors = {
  /* COLORS NAMED BY FUNCTION */

  /*
    From _Buttons_ page of new Luminary Component Library V2 Figma design
    see https://www.figma.com/file/jgngRpKr9HPeHuI8hKQfbS/Luminary-Component-Library-V2?node-id=0-1
    and https://www.figma.com/file/UVMNC5KaQbXzw3cN6r02Vt/Sign-up-flow-and-2FA?node-id=1295%3A6713&mode=dev
  */
  /* Used for the primary button and for other UI elements with very strong emphasis */
  primaryCta: hueColors.purple600,

  /* Used for other interactive elements that need a lighter emphasis (links & focused elements) */
  primaryInteractive: hueColors.purple800,

  /*
    From _Forms_ page of Figma design (see "Error message")
    see https://www.figma.com/file/8rNGeZ9HiJ4Zl6pVaEk5Lq/Luminary-Component-Library?node-id=379%3A44240
  */
  errorMessageText: hueColors.red600,

  /*
    From _Type, Color, Icons_ page of Figma design, under Extended Cool Neutral
    section
    see https://www.figma.com/file/8rNGeZ9HiJ4Zl6pVaEk5Lq/Luminary-Component-Library?node-id=140%3A2814
  */
  highEmphasisText: hueColors.neutral900,
  mediumEmphasisText: hueColors.neutral850,
  lowEmphasisText: hueColors.neutral800,
  highEmphasisBorder: hueColors.neutral750,
  inputPlaceholderText: hueColors.neutral650,
  secondaryButtonBackground: hueColors.neutral550,
  disabledType: hueColors.neutral500,
  surfaceLight1: hueColors.neutral350,
  surfaceLight2: hueColors.neutral300,
  surfaceMedium1: hueColors.neutral250,
  surfaceMedium2: hueColors.neutral200,
  surfaceDark1: hueColors.neutral150,
  surfaceDark2: hueColors.neutral100,
  surfaceDark3: hueColors.neutral50,
  surfaceBackground: hueColors.neutral0,
};

const visColors = {
  selectedSurface: '#004BFF',
  selectedVolume: '#7C00FF',
  hoveredVolume: '#3D007A',
  deselectedSurface: '#CCCCCC',
  hoveredSurface: '#00298A',
  rrSelectedColor: '#D7B0EA',
  rrDeselectedColor: '#633F82',
  probePointColor: '#FFCD1D',
  edgeColor: '#2A2B30',
  redDotColor: '#D56647',
  edgeWarningColor: '#FFCF70',
  edgeErrorColor: '#E55D3C',
  neighboringLine: '#FFFFFF',

  // Default 3D Viewer background colors
  darkBackgroundColor: '#131415', // For legacy reasons, this is not part of our standard color set.
  lightBackgroundColor: hueColors.neutral900,
};

export const colors = { ...hueColors, ...funcColors, ...visColors };

export const hexToRgb = (color: string, alpha = 1) => (
  `rgb(${convert.hex.rgb(color).join(', ')}, ${alpha})`
);

export const hexToHsl = (color: string, alpha = 1) => {
  const [hue, saturation, luminosity] = convert.hex.hsl(color);
  return `hsl(${hue}deg, ${saturation}%, ${luminosity}%, ${alpha})`;
};

/** Convert a hexcode to rgb percentages. */
export const hexToRgbList = (hexCode: string): RgbColor => {
  if (hexCode.length !== 7 || hexCode.substring(0, 1) !== '#') {
    throw Error('invalid hex code provided');
  }
  const red = parseInt(hexCode.substring(1, 3), 16) / 255.0;
  const green = parseInt(hexCode.substring(3, 5), 16) / 255.0;
  const blue = parseInt(hexCode.substring(5), 16) / 255.0;
  return [red, green, blue];
};

/** Convert rgb percentages to hex. */
export const rgbListToHex = (rgb: RgbColor): string => {
  const [red, green, blue] = rgb.map((color) => Math.round(color * 255));
  return `#${convert.rgb.hex([red, green, blue])}`;
};

// Convert an RGB color to RGBA (with alpha 1.0).
export const rgbToRgba = (color: RgbColor): RgbaColor => [...color, 1.0];

export const rgbaToRgb = (color: RgbaColor): RgbColor => color.slice(0, 3) as RgbColor;

export const defaultVisBackgroundColor = hexToRgbList(colors.darkBackgroundColor);
