// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo, useState } from 'react';

import * as Vector from '../../../../lib/Vector';
import { RadioButtonOption } from '../../../../lib/componentTypes/form';
import { lcvHandler } from '../../../../lib/lcvis/handler/LcvHandler';
import { Logger } from '../../../../lib/observability/logs';
import { addRpcError } from '../../../../lib/transientNotification';
import { debounce } from '../../../../lib/utils';
import { EditSource, newBoxParam, newPlaneParam } from '../../../../lib/visUtils';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useLcVisFilterClipHideEnabled } from '../../../../recoil/lcvis/lcvisClipHide';
import { useLcVisEnabledValue } from '../../../../recoil/lcvis/lcvisEnabledState';
import { useEditState } from '../../../../recoil/paraviewState';
import { useIsAnalysisView } from '../../../../state/internal/global/currentView';
import Form from '../../../Form';
import { RadioButtonGroup } from '../../../Form/RadioButtonGroup';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import Divider from '../../../Theme/Divider';
import { PROP_PANEL_GAP } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { LuminaryToggleSwitch } from '../../../controls/LuminaryToggleSwitch';
import { ClipSliceInput, ClipSliceInputType } from '../../../visFilter/ClipSliceInput';
import { PlaneParam } from '../../../visFilter/PlaneInput';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import PropertiesSection from '../../PropertiesSection';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

const logger = new Logger('filter/ClipSlice');

type PlaneFilterType =
  ParaviewRpc.TreeNodeType.CLIP | ParaviewRpc.TreeNodeType.SLICE;

type ClipType =
  ParaviewRpc.TreeNodeType.CLIP | ParaviewRpc.TreeNodeType.BOX_CLIP;

type FilterType = ParaviewRpc.TreeNodeType.CLIP | ParaviewRpc.TreeNodeType.SLICE |
  ParaviewRpc.TreeNodeType.BOX_CLIP;

// the viewState is an optional prop, since if lcvis is enabled, we won't use it.
type ClipSliceProps = Omit<FilterPropertiesPanelProps, 'viewState'> & {
  viewState: ParaviewRpc.ViewState | null;
}

function newClipSliceParamType(typ: FilterType): string {
  if (typ === 'Clip') {
    return 'Clip';
  } if (typ === 'BoxClip') {
    return 'BoxClip';
  }
  return 'Slice';
}

function newDefaultFilterParams(visibleBounds: ParaviewRpc.Bounds, typ: FilterType):
  ParaviewRpc.PlaneParam | ParaviewRpc.BoxClipParam {
  if (typ === 'BoxClip') {
    return newBoxParam(visibleBounds);
  }
  return newPlaneParam(visibleBounds);
}

/** Create the param filled with default values, to be used when creating a new
    filter off the given parent.
    The default Clip (w/ plane) and Slice use a plane specified using an origin + normal as input
    and are grouped together. */
export function newClipSliceParam(bounds: ParaviewRpc.Bounds, typ: PlaneFilterType):
  ParaviewRpc.ClipSliceParam {
  // The typ as PlaneFilterType works since the default is plane clip.
  return {
    typ,
    paramType: newClipSliceParamType(typ),
    filterParam: newDefaultFilterParams(bounds, typ),
    smooth: false,
    invert: false,
    projectvectors: false,
  };
}

// Panel for displaying and modifying a clip or slice filter.
export const ClipSlicePropPanel = (props: ClipSliceProps) => {
  const { displayProps, filterNode, nodeId, viewState } = props;
  const {
    getDataVisibilityBounds,
    paraviewClientState,
    paraviewMeshMetadata,
    paraviewRenderer,
  } = useParaviewContext();
  const { updateEditState } = useSelectedFilterNode();
  const { projectId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [clipHide, setClipHide] = useLcVisFilterClipHideEnabled();
  const [editState] = useEditState();
  const paraviewClient = paraviewClientState.client;
  const isAnalysisView = useIsAnalysisView();

  const [warning, setWarning] = useState(false);

  const editSource = editState ? editState.editSource : EditSource.FORM;
  const onUpdate = useMemo(() => debounce(
    (source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => {
      updateEditState({ editSource: source, param: newParam });
    },
    25,
  ), [updateEditState]);

  const param = props.param as ParaviewRpc.ClipSliceParam;
  const readOnly = !editState;

  useEffect(() => {
    if (lcvisEnabled || !editState) {
      return () => { };
    }

    let mounted = true;
    let unsubscribeFn: (() => void) | null = null;

    // Called when the user moves the widget interactively.
    const onPlaneWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION) {
        throw Error('only plane widget supported now');
      }
      if (!mounted) {
        return;
      }
      const planeParams = param.filterParam as ParaviewRpc.PlaneParam;

      if (
        !Vector.nearPv(newParam.plane.origin, planeParams.origin) ||
        !Vector.nearPv(newParam.plane.normal, planeParams.normal)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            filterParam: {
              typ: 'Plane',
              origin: newParam.plane.origin,
              normal: newParam.plane.normal,
            },
          },
        );
      }
    };

    // Called when the user moves the widget interactively.
    const onBoxWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.BOX_WIDGET_REPRESENTATION) {
        throw Error('only box widget supported now');
      }
      if (!mounted) {
        return;
      }
      // Assume the returned param of the widget state is correctly converted into boxclip format
      const boxParams = param.filterParam as ParaviewRpc.BoxClipParam;

      if (
        !Vector.nearPv(newParam.box.position, boxParams.position) ||
        !Vector.nearPv(newParam.box.length, boxParams.length) ||
        !Vector.nearPv(newParam.box.rotation, boxParams.rotation)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            filterParam: {
              typ: 'BoxClip',
              position: newParam.box.position,
              rotation: newParam.box.rotation,
              length: newParam.box.length,
            },
          },
        );
      }
    };

    const startWidget = async (): Promise<void> => {
      if (!paraviewClient || !paraviewMeshMetadata) {
        return;
      }
      if (param.paramType === 'Clip' || param.paramType === 'Slice') {
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onPlaneWidgetUpdate);
        // Activate the widget.
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
          {
            typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
            plane: param.filterParam as ParaviewRpc.PlaneParam,
            bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
          },
        );
      } else if (param.paramType === 'BoxClip') {
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onBoxWidgetUpdate);
        // Activate the widget.
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
          {
            typ: ParaviewRpc.WidgetType.BOX_WIDGET_REPRESENTATION,
            box: param.filterParam as ParaviewRpc.BoxClipParam,
          },
        );
      }
    };

    startWidget().then(() => { }).catch((err: Error) => {
      addRpcError('Could not activate widget', err);
    });
    return () => {
      mounted = false;
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, nodeId, param.paramType, lcvisEnabled]);

  useEffect(() => {
    if (lcvisEnabled) {
      return;
    }
    if (editState && editSource !== EditSource.PARAVIEW && viewState && paraviewMeshMetadata) {
      logger.debug('ClipSlice: set widget');
      // When the user manually updates the plane dialog, reflect the new plane
      // params to the widget.
      if (param.paramType === 'Clip' || param.paramType === 'Slice') {
        const ws: ParaviewRpc.ImplicitPlaneWidgetState = {
          typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
          plane: param.filterParam as ParaviewRpc.PlaneParam,
          bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
        };
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(
            paraviewMeshMetadata.meshMetadata,
          ),
          ws,
        );
      } else if (param.paramType === 'BoxClip') {
        const ws: ParaviewRpc.BoxWidgetState = {
          typ: ParaviewRpc.WidgetType.BOX_WIDGET_REPRESENTATION,
          box: param.filterParam as ParaviewRpc.BoxClipParam,
        };
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(
            paraviewMeshMetadata.meshMetadata,
          ),
          ws,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState, editSource, param, param.filterParam, nodeId, lcvisEnabled]);

  const toggleInvert = () => {
    onUpdate(EditSource.FORM, {
      ...param,
      invert: !param.invert,
    });
  };

  const clipOptions: RadioButtonOption<string>[] = [{
    disabled: readOnly,
    label: 'Plane',
    value: ParaviewRpc.TreeNodeType.CLIP,
  }, {
    disabled: readOnly,
    label: 'Box',
    value: ParaviewRpc.TreeNodeType.BOX_CLIP,
  }];

  const setClipType = (newClipType: ClipType) => {
    let visibleBounds: ParaviewRpc.Bounds = [-1, 1, -1, 1, -1, 1];
    if (lcvisEnabled) {
      const bounds = lcvHandler.display?.getCurrentDatasetBounds();
      if (bounds) {
        visibleBounds = bounds as ParaviewRpc.Bounds;
      }
    } else {
      visibleBounds = getDataVisibilityBounds(paraviewMeshMetadata!.meshMetadata);
    }

    switch (newClipType) {
      case ParaviewRpc.TreeNodeType.CLIP: {
        onUpdate(
          EditSource.FORM,
          {
            ...param,
            paramType: 'Clip',
            filterParam: newPlaneParam(visibleBounds),
            invert: false,
            smooth: false,
          },
        );
        break;
      }
      case ParaviewRpc.TreeNodeType.BOX_CLIP: {
        onUpdate(
          EditSource.FORM,
          {
            ...param,
            paramType: 'BoxClip',
            filterParam: newBoxParam(visibleBounds),
            invert: false,
            smooth: false,
          },
        );
        break;
      }
      default:
        throw Error('Invalid clip type selected.', newClipType);
    }
  };

  const validateNormal = (plane: PlaneParam): boolean => (
    plane.normal.x !== 0 ||
    plane.normal.y !== 0 ||
    plane.normal.z !== 0
  );

  const invalidNormalMsg = 'Invalid normal: at least one component must be non-zero.';
  let projectVectors = null;

  const toggleProjectVectors = () => {
    onUpdate(EditSource.FORM, {
      ...param,
      projectvectors: !param.projectvectors,
    });
  };
  if (isAnalysisView && lcvisEnabled && param.paramType === 'Slice') {
    projectVectors = (
      <>
        <Form.LabeledInput
          help="Project vector fields onto the slice plane."
          label="Project Vectors">
          <LuminaryToggleSwitch
            disabled={readOnly}
            onChange={toggleProjectVectors}
            small
            value={param.projectvectors || false}
          />
        </Form.LabeledInput>
      </>
    );
  }

  return (
    <div>
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableApply={warning}
              displayProps={displayProps}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          {(param.paramType === 'Clip' || param.paramType === 'BoxClip') && (
            <Form.LabeledInput label="Clip Type">
              <RadioButtonGroup
                disabled={readOnly}
                kind="secondary"
                name="clipSliceTypes"
                onChange={(type) => setClipType(type as ClipType)}
                options={clipOptions}
                value={param.paramType}
              />
            </Form.LabeledInput>
          )}
          <ClipSliceInput
            normalWarning={warning ? invalidNormalMsg : undefined}
            onBoxCommit={(newParam: ParaviewRpc.BoxClipParam) => {
              onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  filterParam: newParam,
                },
              );
            }}
            onPlaneChange={(newParam: PlaneParam) => {
              if (!validateNormal(newParam)) {
                setWarning(true);
              } else {
                setWarning(false);
              }
            }}
            onPlaneCommit={(newParam: PlaneParam) => {
              if (warning) {
                return;
              }
              onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  filterParam: {
                    typ: 'Plane',
                    origin: Vector.toPvProto(newParam.origin),
                    normal: Vector.toPvProto(newParam.normal),
                  },
                },
              );
            }}
            param={param.filterParam}
            readonly={readOnly}
            type={param.paramType as ClipSliceInputType}
          />
          {projectVectors}
          {(param.paramType === 'Clip' || param.paramType === 'BoxClip') && (
            <div style={{ marginTop: PROP_PANEL_GAP }}>
              <Divider />
              {lcvisEnabled && (
                <Form.LabeledInput label="Hide Clipped Surfaces" layout="favorLabel">
                  <LuminaryToggleSwitch
                    disabled={readOnly}
                    onChange={setClipHide}
                    small
                    value={clipHide}
                  />
                </Form.LabeledInput>
              )}
              <Form.LabeledInput help="Invert the clip." label="Invert" layout="favorLabel">
                <LuminaryToggleSwitch
                  disabled={readOnly}
                  onChange={toggleInvert}
                  small
                  value={param.invert}
                />
              </Form.LabeledInput>
            </div>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </div>
  );
};
