// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useMemo } from 'react';

import cx from 'classnames';
import { useLocation, useParams } from 'react-router-dom';

import { isItarEnv, isProd } from '../../../lib/RuntimeParams';
import { debugAssistant } from '../../../lib/assistant/assistantCall';
import { CreditStatus, getCreditsStatus } from '../../../lib/credits';
import { colors } from '../../../lib/designSystem';
import { INTERCOM_LAUNCHER_SELECTOR } from '../../../lib/intercom';
import { isInProject } from '../../../lib/navigation';
import { ProjectParams } from '../../../lib/routeParamTypes';
import { PageKey } from '../../../lib/transientNotification';
import { pickAnyJobId } from '../../../lib/workflowUtils';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import useAccountInfo, { useIsAdmin, useIsStarterPlan, useUserRemainingCredits } from '../../../recoil/useAccountInfo';
import { useSetRouteParams } from '../../../recoil/useRouteParams';
import { useWorkflowState } from '../../../recoil/workflowState';
import { useLeftNavExpandedValue, useLeftNavHoveredState } from '../../../state/internal/component/leftNav';
import { AppUpdateNotice } from '../../AppUpdateNotice';
import { LeftNav } from '../../LeftNav';
import { LeftNavToggle, TRANSITION_DELAY, TRANSITION_TIME } from '../../LeftNavToggle';
import { ProdStatus } from '../../ProdStatus';
import suspenseWidget from '../../SuspenseWidget';
import { createStyles, makeStyles } from '../../Theme';
import { Banner } from '../../notification/Banner';
import { ConfirmationManager } from '../../operational/ConfirmationManager';
import BaseLayout from '../BaseLayout';
import PageHeader, { HEADER_HEIGHT } from '../PageHeader';
import { TransientNotificationManager } from '../TransientNotification';
import { ITAR_BAR_LINE_HEIGHT } from '../header/ItarBar';

const LEFT_NAV_WIDTH_EXPANDED = 216;
const LEFT_NAV_WIDTH_NARROW = 64;
const NAVBAR_PART_CLASSNAME = 'navbarPart';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      '--nav-width': `${LEFT_NAV_WIDTH_NARROW}px`,
      '--nested-item-padding': '10px',
      '--nav-toggler-opacity': 0,
      '--group-arrow-padding': 0,

      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      overflow: 'hidden',
      backgroundColor: colors.surfaceDark1,

      '&:not(.inProject)': {
        // the code uses the &.hover class to prevent flickering effect when navigating between
        // pages via the navbar
        '&.hovered': {
          '--nav-toggler-opacity': 1,
        },
        '&.expanded': {
          '--nav-width': `${LEFT_NAV_WIDTH_EXPANDED}px`,
          '--nested-item-padding': '34px',
          '--group-arrow-padding': '4px',
        },
      },
    },
    appBanner: {
      flex: '0 0 auto',
    },
    appHeader: {
      flex: '0 0 auto',
      zIndex: 10,
      position: 'relative',

      '&.inProject': {
        backgroundColor: colors.surfaceDark3,
      },
    },
    nonProjectAppHeaderFiller: {
      width: 'var(--nav-width)',
      height: `${HEADER_HEIGHT + (isItarEnv ? ITAR_BAR_LINE_HEIGHT : 0)}px`,
      backgroundColor: colors.surfaceDark3,
      position: 'absolute',
      transition: `width ${TRANSITION_TIME}ms`,
      transitionDelay: `${TRANSITION_DELAY}ms`,
    },
    appMain: {
      flex: '1 1 auto',
      overflow: 'hidden',
      display: 'flex',
    },
    leftNav: {
      width: 'var(--nav-width)',
      backgroundColor: colors.surfaceDark3,
      transition: `width ${TRANSITION_TIME}ms`,
      transitionDelay: `${TRANSITION_DELAY}ms`,
      position: 'relative',
    },
    mainContent: {
      flex: '1 1 0',
      height: '100%',
      overflow: 'auto',
      backgroundColor: colors.surfaceDark1,
      '&:not(.inProject)': {
        padding: '16px 24px 0 32px',
      },
    },
    contentHeading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    contentTitle: {
      color: colors.highEmphasisText,
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 600,
    },
    contentChildren: {
      fontSize: '14px',
      height: '100%',
      '&:not(.inProject)': {
        paddingTop: '56px',
        height: 'calc(100% - 56px)',

        '&.noHeading': {
          paddingTop: '32px',
        },
      },
    },
    contentAreaFooterSpacing: {
      marginTop: '24px',
      height: '1px',
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // this positions the loading icon more or less at the vertical center of the content area
      height: 'calc(100% - 64px)',
    },
  }),
  { name: 'MainPageLayout' },
);

export interface MainPageLayoutProps {
  // The currently active project.
  projectId?: string;
  title: string;
  // The title is used for notifications so we can't easily omit it, but we can use this flag
  // if we don't want to display it. This will also hide the CTA button and will lessen the top
  // padding a bit.
  noHeading?: boolean;
  noPadding?: boolean
  children: ReactNode;
  primaryAction?: ReactNode;
  permission?: boolean;
  loading?: boolean;
}

const StarterPlanBanners = () => {
  const isStarterPlan = useIsStarterPlan();
  const creditsLeft = useUserRemainingCredits();
  const creditStatus = getCreditsStatus(creditsLeft);

  const classes = useStyles();

  if (creditStatus === CreditStatus.OK || !isStarterPlan) {
    return null;
  }

  return (
    <div className={classes.appBanner}>
      <Banner
        level={creditStatus === CreditStatus.ERROR ? 'error' : 'warning'}
        link={{
          onClick: () => { },
          label: 'Chat with us to add credits',
          className: INTERCOM_LAUNCHER_SELECTOR.replace('.', ''),
        }}
        message={
          creditStatus === CreditStatus.ERROR ?
            'You\'ve run out of credits. Add more to edit projects and run simulations.' :
            'You are running low on credits. ' +
            'If you run out of them, any actively running simulations will be aborted.'
        }
      />
    </div>
  );
};

export const MainPageLayout = (props: MainPageLayoutProps) => {
  const { projectId, title, primaryAction, permission, loading, noHeading, noPadding } = props;

  const classes = useStyles();

  const params = useParams<ProjectParams>();
  const { workflowId = '', jobId = '' } = params;

  const setRouteParams = useSetRouteParams();
  useEffect(() => {
    setRouteParams((oldParams) => {
      // Avoid re-rendering if the params haven't changed.
      if (JSON.stringify(oldParams) === JSON.stringify(params)) {
        return oldParams;
      }
      return params;
    });
  }, [params, setRouteParams]);

  const workflow = useWorkflowState(projectId || '', workflowId);
  // get the jobId if it isn't present in the url
  const trueJobId = jobId || (workflow && pickAnyJobId(workflow)) || '';

  // Identify the current page to determine which transient notifications to show
  const currPage: PageKey = (projectId === undefined) ?
    title :
    { projectId, workflowId, jobId: trueJobId };

  const location = useLocation();
  const accountInfo = useAccountInfo();
  const leftNavExpanded = useLeftNavExpandedValue();

  const [hovered, setHovered] = useLeftNavHoveredState();

  const accountDisabled = (
    accountInfo?.accountStatus === frontendpb.AccountStatus.ACCOUNT_DISABLED
  );
  const inProject = useMemo(() => isInProject(location.pathname), [location]);
  const isAdmin = useIsAdmin();

  if (isAdmin) {
    debugAssistant();
  }

  const renderChildren = () => {
    // Some pages, like Security, Users, Billing, etc. are `permission`-gated and the permission
    // depends on the accountInfo so we can't show them before the accountInfo is loaded.
    // Other pages, like My Account, are not permission-gated but may have other conditions that
    // must be met before we show them. For them, we can use `loading` flag.
    if ((permission !== undefined && !accountInfo) || loading) {
      return (
        <div className={classes.loading}>
          {suspenseWidget}
        </div>
      );
    }

    if (permission === false) {
      return "You don't have permission to view this page.";
    }

    return props.children;
  };

  return (
    <BaseLayout title={title}>
      <ConfirmationManager />
      <div className={cx(classes.root, { expanded: leftNavExpanded, inProject, hovered })}>
        {accountDisabled ? (
          <div className={classes.appBanner}>
            <Banner
              level="fatal"
              message={
                isAdmin ?
                  "Your company's account has insufficient credits to launch new simulations. " +
                  'Please contact Luminary Cloud support for help.' :
                  "Your company's account has insufficient credits to launch new simulations. " +
                  'Please contact your account admin for help.'
              }
              onDismiss={() => { }}
            />
          </div>
        ) : <StarterPlanBanners />}
        {isProd && <ProdStatus />}
        <AppUpdateNotice />
        <div className={cx(classes.appHeader, { inProject })}>
          {/* We need this element to mimic the current width of the nav when outside of a project
           page, so we can give the impression that the logo is part of the nav. */}
          {!inProject && (
            <>
              <div className={classes.nonProjectAppHeaderFiller} />
              <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                <LeftNavToggle />
              </div>
            </>
          )}
          <PageHeader projectId={projectId} title={title} />
        </div>
        <main className={classes.appMain}>
          {!inProject && (
            <>
              <div
                className={cx(classes.leftNav, NAVBAR_PART_CLASSNAME)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
                <LeftNav />
              </div>
            </>
          )}
          <div className={cx(classes.mainContent, { inProject: inProject || noPadding })}>
            {!inProject && !noHeading && (
              <div className={classes.contentHeading}>
                <div className={classes.contentTitle}>{title}</div>
                {primaryAction && primaryAction}
              </div>
            )}
            <div className={
              cx(classes.contentChildren, { inProject: inProject || noPadding, noHeading })
            }>
              <React.Suspense
                fallback={(
                  <div className={classes.loading}>
                    {suspenseWidget}
                  </div>
                )}>
                {renderChildren()}
              </React.Suspense>
              {/* The padding-bottom for the contentChildren or the mainContent doesn't work so this
              makes sure we have some spacing at the bottom. */}
              {!inProject && <div className={classes.contentAreaFooterSpacing} />}
            </div>
          </div>
        </main>
      </div>
      <TransientNotificationManager bottom={44} left={16} page={currPage} />
    </BaseLayout>
  );
};

export const useMainCommonStyles = makeStyles(
  () => createStyles({
    summaryContainer: {
      marginTop: '36px',
      height: '156px',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '&.autoHeight': {
        height: 'auto',
      },
      '&.smallerGap': {
        marginTop: '16px',
      },
    },
    summary: {
      display: 'flex',
      flexDirection: 'column',
      height: '156px',
      minWidth: '266px',
      background: colors.surfaceMedium2,
      borderRadius: '6px',
      marginRight: '16px',
      padding: '24px',

      '&.fullWidth': {
        flex: '1 1 auto',
      },
      '&.autoHeight': {
        height: 'auto',
      },
      '& span': {
        color: colors.lowEmphasisText,
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
      },
    },
    summaryMain: {
      marginTop: '8px',
      marginBottom: '22px',
      color: colors.highEmphasisText,
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 500,

      '&.noMargin': {
        marginBottom: '0',
      },
    },
    tableContainer: {
      border: `1px solid ${colors.surfaceDark3}`,
      borderRadius: '4px',
      '& .MuiTableCell-root': {
        borderColor: colors.neutral250,
      },
      marginBottom: '48px',
      marginTop: '16px',
    },
    tableHead: {
      background: colors.surfaceMedium2,
      paddingTop: '16px',
      paddingBottom: '16px',
      '& .MuiTableCell-root': {
        fontSize: '11px',
        lineHeight: '16px',
        color: colors.lowEmphasisText,
        fontWeight: 500,
      },
    },
    tableBody: {
      background: colors.surfaceDark2,
      height: '56px',
      paddingTop: '18px',
      paddingBottom: '18px',
      '& .MuiTableCell-root': {
        color: colors.highEmphasisText,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    title: {
      fontSize: '24px',
      lineHeight: '24px',
      fontWeight: 400,
      color: colors.highEmphasisText,
      marginBottom: '0.5em',
      '&:not(:first-child)': {
        marginTop: '70px',
      },
    },
    textContent: {
      maxWidth: '784px',
    },
  }),
  { name: 'useMainCommonStyles' },
);
