// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { DefaultValue, atomFamily, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { useProjectContext } from '../../../components/context/ProjectContext';
import { ChatMessage } from '../../../lib/assistant/assistant';
import { Assist } from '../../../lib/assistant/assistantCall';
import { Logger } from '../../../lib/observability/logs';

const logger = new Logger('AssistantChat');

/**
 * The state for the AI assistant chat messages. This state is keyed on the project id.
*/
async function sendToAssistant(
  projectId: string,
  userMessage: string,
  setMessages: (callback: (currMessages: Map<string, ChatMessage> | DefaultValue)
    => Map<string, ChatMessage>
  ) => void,
) {
  const id = `${Date.now()}`;
  const finalId = `${id}-response`;

  const updateMessages = (response: string) => {
    setMessages((currMessages) => {
      if (currMessages instanceof DefaultValue) {
        return new Map<string, ChatMessage>();
      }

      const existingMessage = currMessages.get(finalId);
      const newMessage: ChatMessage = {
        id: finalId,
        text: existingMessage ? existingMessage.text + response : response,
        isUser: false,
        timestamp: existingMessage ? existingMessage.timestamp : new Date(),
      };

      const updatedMessages = new Map(currMessages);
      updatedMessages.set(finalId, newMessage);

      // Convert Map to array, sort by timestamp, and convert back to Map
      const sortedMessages = Array.from(updatedMessages.entries())
        .sort((a, b) => a[1].timestamp.getTime() - b[1].timestamp.getTime());

      return new Map(sortedMessages);
    });
  };

  try {
    await Assist(projectId, id, userMessage, updateMessages);
  } catch (error) {
    logger.error('Error communicating with assistant:', error);
    updateMessages("Sorry, I couldn't process your request. Please try again.");
  }
}

const assistantMessageState = atomFamily<Map<string, ChatMessage>, string>({
  key: 'assistantMessageState',
  default: new Map<string, ChatMessage>(),
  effects: (projectId: string) => [
    ({ setSelf }) => {
      setSelf((prevMessages) => {
        const currentMessages = prevMessages instanceof DefaultValue ? new Map() : prevMessages;
        currentMessages.set('assistant-welcome', {
          id: 'assistant-welcome',
          text: 'What can I help you with today?',
          isUser: false,
          timestamp: new Date(),
        });
        return new Map(currentMessages);
      });
    },
    ({ setSelf, onSet }) => {
      onSet(async (newMessages, oldMessages) => {
        const userMessages = Array.from(newMessages.values()).filter((msg) => msg.isUser);
        const lastMessage = userMessages[userMessages.length - 1];
        if (lastMessage) {
          await sendToAssistant(projectId, lastMessage.text, setSelf);
        }
      });
    },
  ],
});

export const useResetAssistantChat = () => {
  const { projectId } = useProjectContext();
  const resetMessages = useResetRecoilState(assistantMessageState(projectId));

  return useCallback(async () => {
    try {
      await Assist(projectId, projectId, '/reset', (val: string) => {
        console.debug(`Assistant reset response: ${val}`);
      });
      resetMessages();
    } catch (error) {
      logger.error('Error resetting assistant:', error);
    }
  }, [projectId, resetMessages]);
};

export const useAssistantMessages = (
  projectId: string,
) => useRecoilState(assistantMessageState(projectId));

export const useSetAssistantMessages = (
  projectId: string,
) => useSetRecoilState(assistantMessageState(projectId));

export const useAssistantMessagesValue = (
  projectId: string,
) => useRecoilValue(assistantMessageState(projectId));
