import React, { Suspense, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import ProjectStateSync from '../components/RecoilSync/ProjectState';
import suspenseWidget from '../components/SuspenseWidget';
import Project from '../components/context/ProjectContext';
import { MainPageLayout } from '../components/layout/page/Main';
import { CurrentView } from '../lib/componentTypes/context';
import { ProjectParams } from '../lib/routeParamTypes';
import useProjectMetadata from '../recoil/useProjectMetadata';
import { useSetCurrentView } from '../state/internal/global/currentView';

import PageBody from './PageBody';

export const AdvancedAnalysisPage = () => {
  const params = useParams<ProjectParams>();
  const projectId = params.projectId || '';
  const projectMetadata = useProjectMetadata(projectId);
  const setCurrentView = useSetCurrentView();

  useEffect(() => {
    setCurrentView(CurrentView.ADVANCED_ANALYSIS);
  }, [setCurrentView]);

  // Don't display the page until all the project data has loaded.
  const projectName = projectMetadata?.summary!.name || '';

  return (
    <MainPageLayout projectId={projectId} title={projectName}>
      <Suspense fallback={suspenseWidget}>
        {projectMetadata && (
          <ProjectStateSync projectId={projectId}>
            <Project
              projectId={projectId}
              selectedJobId=""
              workflowId="">
              <PageBody isExploration={false} />
            </Project>
          </ProjectStateSync>
        )}
      </Suspense>
    </MainPageLayout>
  );
};
