// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { CommonMenuListItem } from './componentTypes/menu';
import { getHelpText } from './keyBindings';
import { SimulationTreeNode } from './simulationTree/node';

export function findAncestorIds(nodeIds: string[], treeNode: SimulationTreeNode) {
  const ids = new Set<string>();

  nodeIds.forEach((nodeId) => {
    const node = treeNode.getDescendant(nodeId);
    let ancestor = node?.parent;
    while (ancestor) {
      if (ids.has(ancestor.id)) {
        // If this ancestor is already in the list, then all of its ancestors should already be in
        // the list too, so we can stop now.
        break;
      }
      ids.add(ancestor.id);
      ancestor = ancestor.parent;
    }
  });

  return ids;
}

export function deleteTreeNodeMenuItem(
  onClick: () => void | Promise<void>,
  disabled = false,
  help = '',
): CommonMenuListItem {
  return {
    label: 'Delete',
    disabled,
    destructive: true,
    onClick,
    help: disabled ? '' : help,
    disabledReason: disabled ? help : '',
  };
}

export function visibilityToggleTreeNodeMenuItem(
  itemIsVisible: boolean,
  toggleVisibility: () => void | Promise<void>,
  disabled: boolean = false,
): CommonMenuListItem {
  if (itemIsVisible) {
    return { label: 'Hide', startIcon: { name: 'eyeOff' }, disabled, onClick: toggleVisibility };
  }
  return { label: 'Show', startIcon: { name: 'eyeOn' }, disabled, onClick: toggleVisibility };
}

export function createTagsFromRightClickTreeNodeMenuItems(
  onClick: () => void | Promise<void>,
  disabled?: boolean,
  disabledReason?: string,
): CommonMenuListItem {
  return {
    label: 'Create Tag...',
    onClick,
    disabled,
    disabledReason,
  };
}

export function createDeleteAllTagsTreeNodeMenuItems(
  onClick: () => void,
  disabled: boolean,
  disabledReason?: string,
): CommonMenuListItem {
  return {
    label: 'Delete All Tags',
    destructive: true,
    onClick,
    disabled,
    disabledReason,
  };
}

export function groupTreeNodeMenuItem(onClick: () => void): CommonMenuListItem {
  return {
    label: 'Group',
    keyboardShortcut: getHelpText('group'),
    onClick,
  };
}

export function ungroupTreeNodeMenuItem(onClick: () => void): CommonMenuListItem {
  return {
    label: 'Ungroup',
    keyboardShortcut: getHelpText('ungroup'),
    onClick,
  };
}

export function duplicateTreeNodeMenuItem(
  onClick: () => void,
  disabled = false,
): CommonMenuListItem {
  return {
    label: 'Copy',
    disabled,
    onClick,
  };
}
