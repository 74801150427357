// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef } from 'react';

import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { newNode } from '../../lib/paraviewUtils';
import { EditSource, EditState, applyEditToTreeNode, findPathToNode } from '../../lib/visUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useLcvisCancelEdit } from '../../recoil/lcvis/hooks/useLcvisCancelEdit';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { isStatusPending, useLcvisFilterStatusValue } from '../../recoil/lcvis/lcvisFilterStatus';
import { useEditState } from '../../recoil/paraviewState';
import { useControlPanelMode } from '../../recoil/useProjectPage';
import { useSetRowsOpened } from '../../recoil/useSimulationTreeState';
import { useFilterState } from '../../recoil/vis/filterState';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { EditButtons } from '../controls/EditButtons';

import environmentState from '@/state/environment';

interface FilterEditControlProps {
  /** The ID of the selected node. */
  nodeId: string;
  /** The parameters for the filter. */
  param: ParaviewRpc.TreeNodeParam;
  /** The display parameters for the filter. */
  displayProps: ParaviewRpc.DisplayProps;
  /** Conditional disable option of the Apply/Check button */
  disableApply?: boolean;
  /** Help text for the Apply/Check button when it is disabled */
  disableApplyHelp?: string;
  /** Function to execute filter using vis service */
  executeVisFilter?: (nodeId: string) => void;
  /** Optional, additional function to call when 'apply settings' is pressed */
  onSave?: () => void;
  /** Conditional disable option of the Edit button. */
  disableEdit?: boolean
}

// A set of buttons for turning on editing, applying an edit, or canceling one.
export const FilterEditControl = (props: FilterEditControlProps) => {
  const {
    applyEdit,
    cancelEditState,
    paraviewClientState,
    viewState,
  } = useParaviewContext();
  const { setSelection } = useSelectionContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const [editState, setEditState] = useEditState();
  const paraviewClient = paraviewClientState.client;
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const [filterState, setFilterState] = useFilterState({ projectId, workflowId, jobId });
  const lcvisCancelEdit = useLcvisCancelEdit();
  const prevNodeId = useRef(props.nodeId);
  const lcvisFilterStatus = useLcvisFilterStatusValue();
  const [controlPanelMode] = useControlPanelMode();
  const setNodesOpened = useSetRowsOpened(projectId, controlPanelMode);

  // Blindly cancel the edit when this component is unmounted.
  // If the user presses "Apply", cancelEditState is a noop.
  useEffect(() => () => {
    if (lcvisEnabled && !editState?.newNode) {
      lcvisCancelEdit();
      return;
    }
    if (!!editState && editState.nodeId === props.nodeId) {
      cancelEditState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if the node id changes, cancel the edit.
    if (lcvisEnabled && prevNodeId.current !== props.nodeId) {
      lcvisCancelEdit();
    }
    prevNodeId.current = props.nodeId;
  }, [props.nodeId, lcvisEnabled, lcvisCancelEdit]);

  /** Update the filterState using the editState, and update the LCVis widgets accordingly. */
  const lcvisApplyEdit = (edit: EditState) => {
    if (!lcvisReady) {
      return;
    }
    const createdNode = edit.newNode ?
      newNode(edit.param, filterState, true, edit.displayProps) :
      null;
    // if we created a new node, add it to the children of the node with id = edit.nodeId.
    // if we didn't create a new node, update the existing node with id = edit.nodeId.
    let newRoot = applyEditToTreeNode(filterState, edit, createdNode);
    // apply the edit to the LCVis widgets as well.
    lcvHandler.display?.filterHandler?.applyEdit(editState!, createdNode?.id);
    // update the visibilities in LCVis. The returned newRoot will have the visibilities
    // according to any rules LCVis may have applied. We should use that as our new source of truth.
    newRoot = lcvHandler.display?.filterHandler?.maybeUpdateVisibility(newRoot) ?? newRoot;
    setFilterState(newRoot);
    setEditState(null);
    if (createdNode) {
      setSelection([filterState.id]);
    }
    // Open the nodes in the tree that are in the path to the node that was edited
    // so that the user can see the edited node.
    setNodesOpened((prev) => {
      const newOpened = { ...prev };
      const pathToNode = findPathToNode(newRoot, createdNode?.id ?? edit.nodeId);
      if (!pathToNode.size) {
        return prev;
      }
      pathToNode.forEach((nodeId) => {
        newOpened[nodeId] = true;
      });
      return newOpened;
    });
  };

  // When Paraview is loading data and not interactable, we should disable the edit button to
  // prevent accessing data that is not yet loaded. Paraview is loading data when the client
  // has a progress message
  // LC-20219
  const paraviewReady = !!paraviewClient && !paraviewClientState.message;

  const filterStatus = lcvisFilterStatus.get(props.nodeId);
  const filterPending = filterStatus && isStatusPending(filterStatus.status);
  const lcvisEditable = lcvisReady && !filterPending;

  const disableEdit = props.disableEdit || (lcvisEnabled ? !lcvisEditable : !paraviewReady);

  return (
    <EditButtons
      disableEdit={disableEdit}
      disableSave={props.disableApply}
      editMode={!!editState}
      helpTextCancel="Cancel"
      helpTextEdit="Change the filter settings"
      helpTextSave={props.disableApply ? props.disableApplyHelp ?? '' : 'Apply these settings'}
      onCancel={lcvisEnabled ? lcvisCancelEdit : cancelEditState}
      onSave={() => {
        if (lcvisEnabled) {
          lcvisApplyEdit(editState!);
        } else {
          if (!viewState?.root) {
            throw Error('no root');
          }
          const nodeId = applyEdit(editState!);
          if (editState?.param.typ === ParaviewRpc.TreeNodeType.MULTI_SLICE ||
            editState?.param.typ === ParaviewRpc.TreeNodeType.STREAMLINES ||
            editState?.param.typ === ParaviewRpc.TreeNodeType.SURFACE_L_I_C) {
            props.executeVisFilter?.(nodeId);
          }
        }
        props.onSave?.();
      }}
      onStartEdit={() => {
        setEditState({
          newNode: false,
          nodeId: props.nodeId,
          param: props.param,
          displayProps: props.displayProps,
          editSource: EditSource.FORM,
        });
      }}
    />
  );
};
