// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const assistantThinkingState = atom<boolean>({
  key: 'assistantThinkingState',
  default: false,
});

export function useAssistantThinking() {
  return useRecoilState(assistantThinkingState);
}

export function useAssistantThinkingValue() {
  return useRecoilValue(assistantThinkingState);
}

export function useSetAssistantThinking() {
  return useSetRecoilState(assistantThinkingState);
}
