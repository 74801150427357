import React, { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';

import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import cx from 'classnames';
import { createPortal } from 'react-dom';

import { IconName } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { createNodeData, isDraggableData, isDropAreaData } from '../../lib/draggableTreeNodeUtils';
import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { useSetActiveActionNodeId, useSetDragSourceNode, useSetDropDestinationNode } from '../../recoil/lcvis/tagsDragAndDrop';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';

import { TreeRow } from './TreeRow';

const PREVIEW_NODE_OFFSET_X = 8;
const PREVIEW_NODE_OFFSET_Y = 12;

const useStyles = makeStyles(() => createStyles({
  draggablePreview: {
    display: 'flex',
    fontSize: '13px',
    borderRadius: '4px',
    padding: '4px 8px',
    color: colors.neutral900,
    alignItems: 'center',
    gap: '10px',
    backgroundColor: colors.neutral350,

    '&.destructive': {
      backgroundColor: colors.red400,
    },
  },
}), { name: 'DraggableTreeRow' });

interface DraggableTreeRowPreviewProps {
  icon?: IconName;
  name: string;
  onRemoveZone: boolean;
}

function DraggableTreeRowPreview({ icon, name, onRemoveZone }: DraggableTreeRowPreviewProps) {
  const classes = useStyles();

  return (
    <div className={cx(classes.draggablePreview, onRemoveZone && 'destructive')}>
      {onRemoveZone ? (
        <>
          <SvgIcon maxWidth={12} name="x" />
          Release to remove from tag
        </>
      ) : (
        <>
          {icon && <SvgIcon maxWidth={12} name={icon} />}
          {name}
        </>
      )}

    </div>
  );
}

type DragState =
  | { type: 'idle'; }
  | { type: 'isDragging'; onRemoveZone: boolean; position: { x: number; y: number; } }

  interface DraggableTreeRowProps extends ComponentProps<typeof TreeRow> {
    onRemoveZoneDrop?: () => Promise<void>;
    onNodeDrop?: (droppedNode: SimulationTreeNode) => Promise<void>;
    manipulationType: 'dragOnly' | 'dropOnly' | 'dragAndDrop';
    acceptsDropTypes?: NodeType[];
    isDraggable: boolean;
    dropDestinationTagNode?: SimulationTreeNode;
  }

export function DraggableTreeRow(props: DraggableTreeRowProps) {
  const treeRow = useRef<HTMLDivElement>(null);
  const {
    manipulationType,
    acceptsDropTypes,
    isDraggable,
    onRemoveZoneDrop,
    onNodeDrop,
    dropDestinationTagNode,
    ...treeRowProps
  } = props;
  const [state, setState] = useState<DragState>({ type: 'idle' });

  const setDragSourceNode = useSetDragSourceNode();
  const setDropDestinationNode = useSetDropDestinationNode();
  const setActiveActionNodeId = useSetActiveActionNodeId();

  const canDrop = useCallback((nodeToDrop: SimulationTreeNode) => {
    // don't allow to drop item on itself
    if (nodeToDrop.id === treeRowProps.node.id) {
      return false;
    }

    // and on its parent (since the item already belongs there)
    if (nodeToDrop.parent?.id === treeRowProps.node.id) {
      return false;
    }

    // if accepted types don't have node.type then skip it
    if (acceptsDropTypes && !acceptsDropTypes.includes(nodeToDrop.type)) {
      return false;
    }

    // prevent dragging items within the same parent (from tag X to tag X)
    if (treeRowProps.node.parent?.children.some((child) => (
      child.id !== treeRowProps.node.id && nodeToDrop.id === child.id))
    ) {
      return false;
    }

    return true;
  }, [acceptsDropTypes, treeRowProps.node.id, treeRowProps.node.parent?.children]);

  const isDragActive = ['dragAndDrop', 'dragOnly'].includes(manipulationType);
  const isDropActive = ['dragAndDrop', 'dropOnly'].includes(manipulationType);

  useEffect(() => {
    const element = treeRow.current;

    if (!element || !isDraggable) {
      return;
    }

    const functionQueue: CleanupFn[] = [];

    if (isDragActive) {
      functionQueue.push(draggable({
        element,
        getInitialData: () => createNodeData(treeRowProps.node),
        onGenerateDragPreview: ({ nativeSetDragImage }) => {
          // disable default behavior with image generation, we want custom node instead
          disableNativeDragPreview({ nativeSetDragImage });
        },
        onDragStart: ({ location }) => {
          preventUnhandled.start();

          setDragSourceNode(treeRowProps.node);
          setState({
            type: 'isDragging',
            onRemoveZone: false,
            position: {
              x: location.current.input.clientX,
              y: location.current.input.clientY,
            },
          });
        },
        onDrop: async ({ location }) => {
          const onRemoveZone = isDropAreaData(location.current.dropTargets.at(0)?.data || {});

          setState({ type: 'idle' });
          setDragSourceNode(null);

          if (onRemoveZone && onRemoveZoneDrop) {
            setActiveActionNodeId(treeRowProps.node.id);
            await onRemoveZoneDrop();
            setActiveActionNodeId(null);
          }
        },
        onDrag: ({ location }) => {
          const onRemoveZone = isDropAreaData(location.current.dropTargets.at(0)?.data || {});

          setState({
            type: 'isDragging',
            onRemoveZone,
            position: {
              x: location.current.input.clientX,
              y: location.current.input.clientY,
            },
          });
        },
      }));
    }

    if (isDropActive) {
      functionQueue.push(dropTargetForElements({
        element,
        getData: () => createNodeData(treeRowProps.node),
        canDrop: ({ source }) => {
          const sourceData = source.data;

          if (!isDraggableData(sourceData)) {
            return false;
          }

          return canDrop(sourceData.node);
        },
        onDropTargetChange: ({ location }) => {
          const dropTargetData = location.current.dropTargets.at(0)?.data || {};

          if (isDraggableData(dropTargetData) && dropDestinationTagNode) {
            setDropDestinationNode(dropDestinationTagNode);
          }
        },
        onDragLeave: () => {
          setDropDestinationNode(null);
        },
        onDrop: async ({ source }) => {
          if (isDraggableData(source.data) && onNodeDrop) {
            setActiveActionNodeId(source.data.node.id);
            await onNodeDrop(source.data.node);
            setActiveActionNodeId(null);
          }

          setDropDestinationNode(null);
        },
      }));
    }

    return combine(...functionQueue);
  }, [
    canDrop,
    dropDestinationTagNode,
    isDragActive,
    isDraggable,
    isDropActive,
    onNodeDrop,
    onRemoveZoneDrop,
    setDragSourceNode,
    setDropDestinationNode,
    treeRowProps.node,
    setActiveActionNodeId,
  ]);

  return (
    <>
      <TreeRow
        {...treeRowProps}
        ref={treeRow}
      />

      {state.type === 'isDragging' && (
        createPortal(
          <div
            style={{
              position: 'fixed', // fixed should be faster for rendering
              top: 0,
              left: 0,
              transform: (
                `translate(${state.position.x + PREVIEW_NODE_OFFSET_X}px` +
                `, ${state.position.y + PREVIEW_NODE_OFFSET_Y}px)`
              ),
              zIndex: 5,
              pointerEvents: 'none',
            }}>
            <DraggableTreeRowPreview
              icon={treeRowProps.primaryIcon?.name}
              name={treeRowProps.node.name}
              onRemoveZone={state.onRemoveZone}
            />
          </div>,
          document.body,
        )
      )}
    </>
  );
}
