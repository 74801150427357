// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    ellipsis: {
      display: 'inline-block',
      overflow: 'hidden',
      verticalAlign: 'bottom',
      '& span': {
        opacity: 0,
        animation: '$ellipsis-keyframes 2s infinite',
      },
      '& span:nth-child(1)': {
        animationDelay: '0s',
      },
      '& span:nth-child(2)': {
        animationDelay: '0.4s',
      },
      '& span:nth-child(3)': {
        animationDelay: '0.8s',
      },
    },
    '@keyframes ellipsis-keyframes': {
      '0%': { opacity: 0 },
      '50%': { opacity: 1 },
      '100%': { opacity: 0 },
    },
  }),
  { name: 'AnimatedEllipsis' },
);

export const AnimatedEllipsis = () => {
  const classes = useStyles();
  return (
    <span className={classes.ellipsis}>
      <span>.</span><span>.</span><span>.</span>
    </span>
  );
};
