// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import assert from '../../lib/assert';
import { addError } from '../../lib/transientNotification';
import { DEFAULT_FILTER_ROOT, EditSource, EditState } from '../../lib/visUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useEditState } from '../../recoil/paraviewState';
import { useSetPropertiesPanelVisible } from '../../recoil/propertiesPanel';
import { TreeViewType, useSetTreeViewType } from '../../recoil/useTreeViewState';
import { OverlayMode, useOverlayMode, useSetOverlayMode } from '../../state/internal/vis/overlayMode';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useSelectionContext } from '../context/SelectionManager';
import { newClipSliceParam } from '../treePanel/propPanel/filter/ClipSlice';
import { newContourParam } from '../treePanel/propPanel/filter/Contour';
import { newExtractSurfacesParam } from '../treePanel/propPanel/filter/ExtractSurfaces';
import { newGlyphParam } from '../treePanel/propPanel/filter/Glyph';
import { newIntersectionCurveParam } from '../treePanel/propPanel/filter/IntersectionCurve';
import { newLineProbeParam } from '../treePanel/propPanel/filter/LineProbe';
import { newMultiSliceParam } from '../treePanel/propPanel/filter/MultiSlice';
import { newStreamTracerParam } from '../treePanel/propPanel/filter/StreamTracer';
import { newSurfaceLICParam } from '../treePanel/propPanel/filter/SurfaceLIC';
import { newThresholdParam } from '../treePanel/propPanel/filter/Threshold';
import { newVisStreamlinesParam } from '../treePanel/propPanel/filter/VisStreamlines';

import { useVisFilterParentNode } from './useVisFilterParentNode';

type FilterType = ParaviewRpc.TreeNodeType.CLIP | ParaviewRpc.TreeNodeType.SLICE;

export const useHandleProbeClick = () => {
  // Context
  const { cancelEditState } = useParaviewContext();

  // Hooks
  const [overlayMode, setOverlayMode] = useOverlayMode();

  return () => {
    // Toggle PROBE on or off.
    if (overlayMode === OverlayMode.PROBE) {
      setOverlayMode(OverlayMode.NONE);
    } else {
      setOverlayMode(OverlayMode.PROBE);
    }
    cancelEditState();
  };
};

export const useHandlePvFilterClick = ({ parentNodeId }: {parentNodeId?: string} = {}) => {
  // Context
  const {
    paraviewMeshMetadata,
    viewState,
    cancelEditState,
    getDataVisibilityBounds,
  } = useParaviewContext();
  const { setSelection } = useSelectionContext();

  // Hooks
  const setOverlayMode = useSetOverlayMode();
  const [editState, setEditState] = useEditState();
  const setTreeViewType = useSetTreeViewType();
  const setPropertiesPanelVisible = useSetPropertiesPanelVisible();

  // Other props
  const defaultDisplayProps: ParaviewRpc.DisplayProps = {
    reprType: 'Surface',
    displayVariable: null,
  };

  // The parent to which the new node will be added as a child. If the currently selected node is a
  // filter node, return it. Otherwise, return the root.
  const parentNode = useVisFilterParentNode();

  const addNewNode = (nodeState: Partial<EditState> & { param: EditState['param'] }) => {
    const nodeId = parentNodeId ?? DEFAULT_FILTER_ROOT;
    setSelection([nodeId]);

    // there's a bug when selecting another node and then adding a new one in the READER node
    // fixed it with a cheeky requestAnimationFrame workaround, which seems to do the trick
    requestAnimationFrame(() => {
      setEditState({
        newNode: true,
        nodeId,
        displayProps: defaultDisplayProps,
        editSource: EditSource.FORM,
        ...nodeState,
      });
      setPropertiesPanelVisible(true);
    });
  };

  // Handlers
  // Called when add clip or slice is selected.
  const addClipSlice = (type: FilterType) => {
    if (!parentNode || !viewState || !paraviewMeshMetadata) {
      return;
    }
    const visibleBounds = getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata);

    // Create a new clip or slice node.
    // Select the new node and enable editing on it.
    addNewNode({ param: newClipSliceParam(visibleBounds, type) });
  };

  // Called when add contour is selected. Contours are displayed to the user as
  // "Isosurface".
  const addContour = () => {
    if (!parentNode) {
      return;
    }

    // Select the new node and enable editing on it.
    const contourDefaultField = parentNode.pointData.filter(
      (item: ParaviewRpc.ArrayInformation) => item.dim === 1 || item.dim === 3,
    )[0];
    addNewNode({ param: newContourParam(contourDefaultField) });
  };

  // Called when add threshold is selected.
  const addThreshold = () => {
    if (!parentNode) {
      return;
    }

    const thresholdDefaultField = parentNode.pointData.filter(
      (item: ParaviewRpc.ArrayInformation) => item.dim === 1 || item.dim === 3,
    ).at(0);

    assert(!!thresholdDefaultField, 'Expected default threshold field');

    addNewNode({ param: newThresholdParam(thresholdDefaultField) });
  };

  // Called when add line probe is selected.
  const addLineProbe = () => {
    if (!parentNode) {
      return;
    }

    // Select the new node and enable editing on it.
    addNewNode({ param: newLineProbeParam(parentNode) });
  };

  // Called when add intersection curve is selected.
  const addIntersectionCurve = () => {
    if (!parentNode || !viewState || !paraviewMeshMetadata) {
      return;
    }
    const visibleBounds = getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata);

    // Select the new node and enable editing on it.
    addNewNode({ param: newIntersectionCurveParam(visibleBounds) });
  };

  // Called when add glyph is selected. Glyphs are displayed to the user as
  // "Vector".
  const addGlyph = () => {
    if (!parentNode) {
      return;
    }

    const vectorField =
    parentNode.pointData.find(
      (item: ParaviewRpc.ArrayInformation) => item.name === 'Velocity (m/s)',
    ) ||
      parentNode.pointData.filter(
        (item: ParaviewRpc.ArrayInformation) => item.dim === 3,
      )[0];

    // Select the new node and enable editing on it.
    addNewNode({ param: newGlyphParam(vectorField, parentNode) });
  };

  // Called when add multislice is selected.
  const addMultiSlice = () => {
    if (!parentNode || !paraviewMeshMetadata) {
      return;
    }
    const visibleBounds = getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata);

    // Create a new multislice node.
    // Select the new node and enable editing on it.
    addNewNode({ param: newMultiSliceParam(visibleBounds) });
  };

  const addExtractSurfaces = async () => {
    if (!parentNode) {
      return;
    }
    if (viewState && (parentNode !== viewState.root)) {
      addError('Extract Surface cannot be the child of another visualization. ' +
        'Select the "Visualizations" node and then add the Extract Surface filter.');
      return;
    }
    // Create a new extract surface node.
    // Select the new node and enable editing on it.
    addNewNode({ param: newExtractSurfacesParam() });
  };

  // Called when add stream tracer is selected.
  const addStreamTracer = () => {
    if (!parentNode) {
      return;
    }

    // Select the new node and enable editing on it.
    addNewNode({ param: newStreamTracerParam(parentNode) });
  };

  // Called when add vis streamlines is selected.
  const addVisStreamlines = async () => {
    if (!parentNode || !paraviewMeshMetadata) {
      return;
    }

    const StreamlinesDefaultField =
     parentNode.pointData.find(
       (item: ParaviewRpc.ArrayInformation) => item.name === 'Velocity (m/s)',
     ) ||
      parentNode.pointData.filter(
        (item: ParaviewRpc.ArrayInformation) => item.dim === 3,
      )[0];
    const visibleBounds = getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata);

    // Select the new node and enable editing on it.
    addNewNode({ param: newVisStreamlinesParam(StreamlinesDefaultField, visibleBounds) });
  };

  // Called when add surface LIC is selected.
  const addSurfaceLIC = async () => {
    if (!parentNode || !paraviewMeshMetadata) {
      return;
    }

    // Select the new node and enable editing on it.
    addNewNode({ param: newSurfaceLICParam(parentNode) });
  };

  // 1. If editState exists and editState.param.type === type, then this is a
  //    toggle operation and we should CANCEL the current edit state.
  // 2. Otherwise, if editState exists (and the types differ), CANCEL the
  //    current edit state and call the appropriate ADD function.
  // 3. Otherwise, if editState does not exist, call the appropriate ADD
  //    function.
  return async (type: string) => {
    // If we are in the results page, make sure that the Post-processing tree view is shown
    setTreeViewType(TreeViewType.POST_PROCESSING);
    // This is admittedly verbose, but it's meant to make the logic clearer
    let shouldAdd = false;
    let shouldCancel = false;

    if (editState) {
      shouldCancel = true;
      if (editState.param.typ !== type) {
        shouldAdd = true;
      }
    } else {
      shouldAdd = true;
      setPropertiesPanelVisible(true);
    }

    if (shouldCancel) {
      cancelEditState();
    }

    if (shouldAdd) {
      switch (type) {
        case ParaviewRpc.TreeNodeType.CLIP:
          // This path includes plane and box clip.
          addClipSlice(type);
          break;
        case ParaviewRpc.TreeNodeType.SLICE:
          addClipSlice(type);
          break;
        case ParaviewRpc.TreeNodeType.CONTOUR:
          addContour();
          break;
        case ParaviewRpc.TreeNodeType.THRESHOLD:
          addThreshold();
          break;
        case ParaviewRpc.TreeNodeType.STREAM_TRACER:
          addStreamTracer();
          break;
        case ParaviewRpc.TreeNodeType.STREAMLINES:
          await addVisStreamlines();
          break;
        case ParaviewRpc.TreeNodeType.SURFACE_L_I_C:
          await addSurfaceLIC();
          break;
        case ParaviewRpc.TreeNodeType.GLYPH:
          addGlyph();
          break;
        case ParaviewRpc.TreeNodeType.MULTI_SLICE:
          addMultiSlice();
          break;
        case ParaviewRpc.TreeNodeType.EXTRACT_SURFACES:
          await addExtractSurfaces();
          break;
        case ParaviewRpc.TreeNodeType.LINE:
          addLineProbe();
          break;
        case ParaviewRpc.TreeNodeType.INTERSECTION_CURVE:
          addIntersectionCurve();
          break;
        default:
          break;
      }
      setOverlayMode(OverlayMode.NONE);
    }
  };
};
