import React from 'react';

import { colors } from '../lib/designSystem';

import { ActionButton } from './Button/ActionButton';
import { SvgIcon } from './Icon/SvgIcon';
import { createStyles, makeStyles } from './Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      width: '100%',
      borderRadius: '8px',
      background: colors.neutral250,
      overflow: 'hidden',
      height: '250px',
      transition: 'background 0.3s ease-in-out',

      '&:hover': {
        background: colors.neutral300,
      },

      '&:hover $image': {
        transform: 'scale(1.1)',
      },
    },
    content: {
      display: 'flex',
      gap: '32px',
      width: '100%',
    },
    imageWrapper: {
      display: 'flex',
      flex: 488,
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transform: 'scale(1)',
      transition: 'transform 0.8s ease',
    },
    bodyWrapper: {
      display: 'flex',
      flex: 412,
      paddingLeft: '32px',
    },
    body: {
      display: 'flex',
      gap: '8px',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      padding: '32px 0 40px',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    title: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      color: 'white',
    },
    description: {
      color: colors.neutral800,
      fontSize: '14px',
      lineHeight: '20px',
    },
    buttons: {
      display: 'flex',
      gap: '8px',
    },
    icon: {
      width: '9px',
      height: '9px',
    },
  }),
  { name: 'VandV' },
);

type VandVProps = {
  title: string;
  description: string;
  documentationUrl: string;
  image: {
    src: string, alt: string
  };
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const VandV = (props: VandVProps) => {
  const classes = useStyles();

  const { image, title, description, documentationUrl, onClick } = props;

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        <img
          alt={image.alt || ''}
          className={classes.image}
          crossOrigin="anonymous"
          src={image.src}
        />
      </div>
      <div className={classes.bodyWrapper}>
        <div className={classes.body}>
          <div className={classes.textWrapper}>
            <div className={classes.title}>
              {title}
            </div>
            <div className={classes.description}>
              {description}
            </div>
          </div>
          <div className={classes.buttons}>
            {onClick && (
              <ActionButton
                kind="primary"
                onClick={onClick}
                openInNewTab
                tabIndex={-1}>
                View Project
              </ActionButton>
            )}
            {documentationUrl && (
              <ActionButton
                href={documentationUrl}
                kind="minimal"
                openInNewTab
                tabIndex={-1}>
                Read One-Pager
                <SvgIcon maxHeight={9} maxWidth={9} name="arrowUpRight" />
              </ActionButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VandV;
