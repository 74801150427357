import { NodeType, SimulationTreeNode } from './simulationTree/node';

type DraggableData = ReturnType<typeof createNodeData>;

export const DELETABLE_NODE_TYPES = [
  NodeType.TAGS_FACE,
  NodeType.TAGS_BODY,
];

const DRAGGABLE_ITEM_SYMBOL = Symbol('DRAGGABLE_ITEM');
const DROP_AREA_SYMBOL = Symbol('DROP_AREA');

export const createNodeData = (node: SimulationTreeNode) => ({
  node,
  [DRAGGABLE_ITEM_SYMBOL]: true,
});
export const isDraggableData = (data: Record<string, unknown>): data is DraggableData => (
  DRAGGABLE_ITEM_SYMBOL in data
);

export const createDropAreaData = () => ({ [DROP_AREA_SYMBOL]: true });
export const isDropAreaData = (data: Record<string, unknown>) => DROP_AREA_SYMBOL in data;
