// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { getIconSpecDims } from '../../lib/svgIcon/utils';
import { IconButton, IconButtonProps } from '../Button/IconButton';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip, { TooltipProps } from '../Tooltip';
import { EarlyAccessLink } from '../common/EarlyAccessLink';
import { FixedSizeWrapper } from '../layout/FixedSizeWrapper';
import { ChevronDownIcon } from '../svg/ChevronDownIcon';

export const TOOLBAR_ICON_CONTAINER_SIZE = 16;
export const DEFAULT_TOOLBAR_ICON_SIZE = 14;
export const ICON_PADDING = 8;
export const DROPDOWN_ICON_SIZE = 8;
export const DROPDOWN_GAP_SIZE = 8;

export const BUTTON_SIZE = TOOLBAR_ICON_CONTAINER_SIZE + 2 * ICON_PADDING;
export const DROPDOWN_BUTTON_SIZE = BUTTON_SIZE + DROPDOWN_ICON_SIZE + DROPDOWN_GAP_SIZE;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'visible',
    },
    earlyAccess: {
      position: 'absolute',
      top: '2px',
      right: '1px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      // Ovewrite the width for the IconButton to let it grow in order to fit the dropdown caret
      // that's needed for some of the buttons.
      width: 'auto',
    },
    icon: {
      height: `${TOOLBAR_ICON_CONTAINER_SIZE}px`,
      width: `${TOOLBAR_ICON_CONTAINER_SIZE}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&.dropdown': {
        gap: `${DROPDOWN_GAP_SIZE}px`,
        width: `${TOOLBAR_ICON_CONTAINER_SIZE + DROPDOWN_GAP_SIZE + DROPDOWN_ICON_SIZE}px`,
      },
    },
  }),
  { name: 'ToolbarButton' },
);

export type ToolbarButtonProps = {
  icon: SvgIconSpec;
  title?: TooltipProps['title'];
  disabled?: boolean;
  engaged?: boolean;
  locator?: string; // populates a data-locator attribute for testing
  earlyAccess?: boolean;
  // if set to true, there will be an extra chevronDown icon after the main icon
  dropdown?: boolean;
} & Pick<IconButtonProps, 'onClick'>

export const ToolbarButton = (props: ToolbarButtonProps) => {
  const {
    disabled,
    earlyAccess = false,
    engaged,
    icon,
    locator = '',
    onClick,
    title = '',
    dropdown,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip placement="top" title={title || ''}>
        <span>
          <IconButton
            className={classes.button}
            data-locator={locator}
            disabled={disabled}
            engaged={engaged && !disabled}
            kind="minimal"
            onClick={onClick}
            size="medium">
            <div className={cx(classes.icon, { dropdown })}>
              <FixedSizeWrapper
                height={DEFAULT_TOOLBAR_ICON_SIZE}
                width={DEFAULT_TOOLBAR_ICON_SIZE}>
                <SvgIcon
                  {...getIconSpecDims(icon, DEFAULT_TOOLBAR_ICON_SIZE, DEFAULT_TOOLBAR_ICON_SIZE)}
                  name={icon.name}
                />
              </FixedSizeWrapper>
              {dropdown && <ChevronDownIcon maxWidth={DROPDOWN_ICON_SIZE} />}
            </div>
          </IconButton>
        </span>
      </Tooltip>
      {earlyAccess && (
        <div className={classes.earlyAccess}>
          <EarlyAccessLink fit size={8} />
        </div>
      )}
    </div>
  );
};
