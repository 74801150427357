// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CurrentView } from '../../lib/componentTypes/context';
import { colors } from '../../lib/designSystem';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import {
  SUPPORTED_ERROR_CODES,
  geomHealthIndexToNodeId,
  geomHealthNodeIdtoIndex,
  getDescription,
  getGeomHealthSubtitle,
  getIssueDetails,
  getIssueSubject,
  getNodeIds,
  getTitle,
} from '../../lib/geometryHealthUtils';
import { zoomAndMakeOthersTransparent } from '../../lib/lcvis/api';
import { geometryLink } from '../../lib/navigation';
import { useUserCanEdit } from '../../lib/projectRoles';
import { SelectionAction } from '../../lib/selectionUtils';
import { plural } from '../../lib/text';
import * as codespb from '../../proto/lcstatus/codes_pb';
import * as lcstatuspb from '../../proto/lcstatus/lcstatus_pb';
import * as levelspb from '../../proto/lcstatus/levels_pb';
import { useEntityGroupMap } from '../../recoil/entityGroupState';
import { useGeometryState } from '../../recoil/geometry/geometryState';
import { useGeometryHealth } from '../../recoil/geometryHealth';
import { useSetTransparencySettings } from '../../recoil/lcvis/transparencySettings';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useProjectMetadataValue } from '../../recoil/useProjectMetadata';
import { StaticVolume, useStaticVolumes } from '../../recoil/volumes';
import { useCurrentView, useIsGeometryView } from '../../state/internal/global/currentView';
import { ActionButton } from '../Button/ActionButton';
import { CollapsibleText } from '../CollapsibleText';
import { SummaryPanel } from '../Panel/SummaryPanel';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { CollapsibleCard } from '../layout/CollapsibleCard';

import MeshImportDialogCommon from './MeshImportDialogCommon';

import { lcvHandler } from '@/lib/lcvis/handler/LcvHandler';
import { useSetSelectedVisualizerError } from '@/recoil/useSelectedVisualizerError';

const useStyles = makeStyles(
  () => createStyles({
    actionButtons: {
      display: 'flex',
      gap: '8px',
    },
    groupHeading: {
      padding: '12px 12px 6px 12px',
      fontWeight: 600,
    },
    headerRight: {
      color: colors.lowEmphasisText,
      padding: '0 12px',
    },
    issuesContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      maxHeight: '60vh',
      // When using ctrl + click we do not want to select the div text
      userSelect: 'none',
    },
    details: {
      paddingBottom: '8px',
      lineHeight: '1.5',
    },
    detailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '1.5',
    },
  }),
  { name: 'GeometryHealth' },
);

// The time in ms after which a success geometry health card should be dismissed automatically.
const SUCCESS_AUTO_DISMISS = 5000;

// An issue with attached information for sorting it.
interface sortableIssue {
  status: lcstatuspb.LCStatus,
  ordering: [number, number, string],
}

interface sortableIssueWithIndex extends sortableIssue {
  sortedIndex: number;
}

// The issue are sorted based on three values in order of importance: First, the level,
// second the code, third the nodeIds.
function createSortableIssue(status: lcstatuspb.LCStatus): sortableIssue {
  const codeIndex = SUPPORTED_ERROR_CODES.indexOf(status.code);
  const nodeIds = getNodeIds(status).join(' ');
  return { status, ordering: [-status.level, codeIndex, nodeIds] };
}

// A comparison function used for sorting. Returns negative if A should appear before B. Returns
// positive if A should appear after B. Goes through the ordering data in sequence with the earliest
// entries being the most important.
function compareIssues(issueA: sortableIssue, issueB: sortableIssue) {
  for (let i = 0; i < 3; i += 1) {
    if (issueA.ordering[i] < issueB.ordering[i]) {
      return -1;
    }
    if (issueA.ordering[i] > issueB.ordering[i]) {
      return 1;
    }
  }
  return 0;
}

export interface GeometryHealthCardProps {
  // The issues to display.
  issues: lcstatuspb.LCStatus[];
  // Whether or not check geometry returned successfully.
  ok: boolean;
  // Called when dismiss is pressed.
  dismissCard: () => void;
  // Called when re-upload is pressed.
  reUpload: () => void;
  entityGroupMap: EntityGroupMap;
  // If the user cannot edit, we are in view only mode.
  userCanEdit?: boolean;
}

// A card displaying any issues related to geometry health and actions that can be performed.
// Contains just the UI elements with the control logic passed in.
export const GeometryHealthCard = (props: GeometryHealthCardProps) => {
  // == Props
  const {
    dismissCard,
    issues,
    ok,
    reUpload,
    entityGroupMap,
  } = props;

  // == Contexts
  const { projectId, geometryId } = useProjectContext();
  const { selectedNodeIds, modifySelection, setScrollTo, isTreeModal } = useSelectionContext();

  // == Recoil
  const isGeometryPending = useIsGeometryPending(projectId);
  const [selectedGeometry] = useSelectedGeometry(projectId);
  const setTransparencySettings = useSetTransparencySettings();
  const staticVolumes = useStaticVolumes(projectId);
  const isGeometryView = useIsGeometryView();
  const geoState = useGeometryState(projectId, geometryId);
  const setSelectedVisualizerError = useSetSelectedVisualizerError(projectId);

  // == Data
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isIgeoProject = !!selectedGeometry.geometryId;

  const volumeIdMap = useMemo(() => {
    const map = new Map<string, StaticVolume>();
    staticVolumes.forEach((volume) => {
      map.set(volume.id, volume);
    });
    return map;
  }, [staticVolumes]);

  // An array of selected indices. Computed from the value of selectedNodeIds.
  const selectedCards = useMemo(() => {
    const geomHealthNodeIds = selectedNodeIds.filter(
      (nodeId) => (geomHealthNodeIdtoIndex(nodeId) >= 0),
    );
    return geomHealthNodeIds.length ?
      geomHealthNodeIds.map((nodeId) => geomHealthNodeIdtoIndex(nodeId)) : [];
  }, [selectedNodeIds]);
  const classes = useStyles();

  // Count the number of errors and warnings.
  const count = issues.reduce((acc, issue) => {
    switch (issue.level) {
      case levelspb.Level.ERROR:
        acc.errors += 1;
        break;
      case levelspb.Level.WARN:
        acc.warnings += 1;
        break;
      default:
        break;
    }
    return acc;
  }, { errors: 0, warnings: 0 });

  // Creates a subtitle based on the number of errors and warnings.
  const hasErrors = (count.errors > 0);
  const hasWarnings = (count.warnings > 0);
  const subtitle = getGeomHealthSubtitle(hasWarnings, hasErrors, ok, isIgeoProject);

  const issuesByCode = useMemo(() => {
    const filteredIssues = issues.filter(
      (issue) => (SUPPORTED_ERROR_CODES.includes(issue.code)),
    );
    const sortedIssues = filteredIssues.map((issue) => createSortableIssue(issue));
    sortedIssues.sort(compareIssues);

    return sortedIssues.reduce((result, item, sortedIndex) => {
      if (isGeometryView && geoState === undefined) {
        return result;
      }

      const issueCode = item.status.code;
      const existingItems = result.get(issueCode) || [];

      result.set(issueCode, [...existingItems, { ...item, sortedIndex }]);

      return result;
    }, new Map<codespb.Code, sortableIssueWithIndex[]>());
  }, [geoState, isGeometryView, issues]);

  // A header appears on the right when the card is collapsed.
  const numIssues = count.errors + count.warnings;
  let headerRight;
  if (collapsed && numIssues > 0) {
    headerRight = (
      <div className={classes.headerRight}>
        {`${numIssues} issue${plural(numIssues)}`}
      </div>
    );
  }

  return (
    <SummaryPanel
      collapsed={collapsed}
      headerRight={headerRight}
      heading="Geometry Health"
      onToggle={() => setCollapsed(!collapsed)}
      summary={(
        <>
          {subtitle}
          <div className={classes.actionButtons}>
            {(!ok || hasWarnings) && props.userCanEdit && !isGeometryView && (
              <ActionButton
                disabled={isGeometryPending}
                kind={!ok ? 'primary' : 'secondary'}
                onClick={isIgeoProject ? () => {
                  // Force a refresh to fix some issues we've seen with crashes in LcVis.
                  window.location.href = geometryLink(projectId);
                } : reUpload}
                size="small">
                {isIgeoProject ? 'Edit Geometry' : 'Re-upload File'}
              </ActionButton>
            )}
            {ok && hasWarnings && props.userCanEdit && (
              <ActionButton
                onClick={dismissCard}
                size="small">
                Dismiss
              </ActionButton>
            )}
          </div>
        </>
      )}>
      <div className={classes.issuesContainer}>
        {[...issuesByCode.values()].map((groupedIssues) => {
          // take sample issue to get metadata for the issue with given code
          const sampleIssue = groupedIssues[0].status;
          const isWarning = (sampleIssue.level === levelspb.Level.WARN);
          const iconName = isWarning ? 'warning' : 'diskExclamation';
          const iconColor = isWarning ? colors.yellow500 : colors.red500;
          const iconTooltip = isWarning ?
            'Warnings can impact mesh quality.' :
            'Errors must be fixed before proceeding.';
          const title = `${getTitle(sampleIssue)} (${groupedIssues.length})`;

          return (
            <CollapsibleCard
              fullContentWidth
              iconColor={iconColor}
              iconName={iconName}
              iconTooltip={iconTooltip}
              key={title}
              title={title}
              toggleOnClick>
              <CollapsibleText
                className={classes.details}
                collapseLimit={80}
                text={getDescription(sampleIssue)}
              />
              {groupedIssues.map((issue, index) => {
                const key = `${index}`;

                return (
                  <CollapsibleCard
                    fullContentWidth
                    key={key}
                    onClick={(event) => {
                      event.stopPropagation();

                      const coordinatesIssue = (
                        lcvHandler.display?.errorList?.getCoordinateIssuesByStatusIssue(
                          issue.status,
                        )
                      );

                      setSelectedVisualizerError(coordinatesIssue ? {
                        coordinates: coordinatesIssue[0],
                        issues: coordinatesIssue[1],
                      } : null);

                      // If some NodeTable/NodeSubselect is active, we shouldn't modify
                      // the selection on click. In this case, the click handler in
                      // NodeTable/NodeSubselect's should exit its edit mode.
                      if (isTreeModal) {
                        return;
                      }
                      const nodeIds = getNodeIds(issue.status);
                      const action = (event.ctrlKey || event.metaKey) ?
                        SelectionAction.ADD : SelectionAction.OVERWRITE;
                      // Add a nodeId for the selected card.
                      nodeIds.push(geomHealthIndexToNodeId(issue.sortedIndex));
                      modifySelection({ action, modificationIds: nodeIds });
                      if (nodeIds.length > 0) {
                        setScrollTo({ node: nodeIds[0], fast: true });
                      }
                      if (volumeIdMap.has(nodeIds[0])) {
                        // We can't make volumes transparent, only surfaces. So we need to get
                        // the surfaces from the volumes.
                        const surfaceIds = nodeIds.reduce((result, nodeId) => {
                          const volume = volumeIdMap.get(nodeId);
                          if (volume) {
                            result.push(...volume.bounds);
                          }
                          return result;
                        }, [] as string[]);
                        zoomAndMakeOthersTransparent(surfaceIds, setTransparencySettings);
                      } else {
                        zoomAndMakeOthersTransparent(nodeIds, setTransparencySettings);
                      }
                    }}
                    selected={selectedCards.includes(issue.sortedIndex)}
                    title={getIssueSubject(issue.status, entityGroupMap)}>
                    <div className={classes.detailsContainer}>
                      {getIssueDetails(issue.status, entityGroupMap).map((detail) => (
                        <div key={detail.name}>
                          <strong>{detail.name}</strong>: {detail.value}
                        </div>
                      ))}
                    </div>
                  </CollapsibleCard>
                );
              })}
            </CollapsibleCard>
          );
        })}
      </div>
    </SummaryPanel>
  );
};

export interface GeometryHealthProps { }

// A card for display the geometry health. This specifies the control logic and passes it into
// GeometryHealthCard.
const GeometryHealth = (props: GeometryHealthProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const currentView = useCurrentView();
  const isGeometryView = useIsGeometryView();
  const [geometryHealth, setGeometryHealth] = useGeometryHealth(projectId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const autoDismissTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isGettingGeometry = useIsGeometryPending(projectId);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isDialogOpen && isGettingGeometry) {
      setIsDialogOpen(false);
    }
  }, [isGettingGeometry, isDialogOpen, setIsDialogOpen]);

  const projectMetadata = useProjectMetadataValue(projectId || '');
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);

  // Close the dialog when there is no geometry health card. This indicates that a new file was
  // imported successfully.
  useEffect(() => {
    if (!geometryHealth && isDialogOpen) {
      setIsDialogOpen(false);
    }
  }, [geometryHealth, isDialogOpen, setIsDialogOpen]);

  // Dismiss the geometry health card.
  const dismissCard = useCallback(() => {
    setGeometryHealth(null);
  }, [setGeometryHealth]);

  // Automatically dismiss a card with no issues after a short period of time.
  const issues = geometryHealth?.issues || [];
  const issueLength = issues.length;
  useEffect(() => {
    // Do not dismiss in the geometry tab, we use the geometry health to show or not the check
    // geometry button.
    if (issueLength === 0 && geometryHealth && geometryHealth.ok && !isGeometryView) {
      autoDismissTimeout.current = setTimeout(() => {
        if (issueLength === 0 && geometryHealth) {
          dismissCard();
        }
      }, SUCCESS_AUTO_DISMISS);
    }
    return (() => {
      if (autoDismissTimeout.current) {
        clearTimeout(autoDismissTimeout.current);
      }
    });
  }, [dismissCard, geometryHealth, issueLength, isGeometryView]);

  const isValidStage = [
    CurrentView.SETUP,
    CurrentView.GEOMETRY,
  ].includes(currentView);

  if (geometryHealth && isValidStage) {
    return (
      <>
        <MeshImportDialogCommon
          onClose={() => setIsDialogOpen(false)}
          open={isDialogOpen}
          type="CAD"
        />
        <GeometryHealthCard
          dismissCard={dismissCard}
          entityGroupMap={entityGroupMap}
          issues={issues}
          ok={geometryHealth.ok}
          reUpload={() => setIsDialogOpen(true)}
          userCanEdit={userCanEdit}
        />
      </>
    );
  }
  return null;
};

export default GeometryHealth;
