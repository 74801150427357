// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, noWait, selectorFamily, useSetRecoilState, waitForAll } from 'recoil';

import { CurrentView } from '../lib/componentTypes/context';
import * as rpc from '../lib/rpc';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { currentViewState } from '../state/internal/global/currentView';

import { DEFAULT_SELECTED_FEATURE, geometryLastVersionIdAtom, geometrySelectedFeature, geometryState } from './geometry/geometryState';
import { routeParamsState } from './useRouteParams';

export type nullableHealthReply = frontendpb.GetCheckGeometryReply | null;

type Key = {
  projectId: string;
  geometryId: string;
  geometryVersionId: string;
}

// Atom that can be used to avoid fetching the CheckGeometry RPC. This is useful in the geometry
// tab where we don't want to call this RPC each time we update the geometry state. This avoids
// suspenses and unnecessary RPC calls.
const shouldGetCheckGeometry = atom<boolean>({
  key: 'shouldGetCheckGeometry',
  default: true,
});

export function useSetShouldGetCheckGeometry() {
  return useSetRecoilState(shouldGetCheckGeometry);
}

// Cache the result of the GetCheckGeometry RPC for a specific geometry version. This avoid
// annoying suspense triggers due to Recoil re-evaluating the RPC whenever the geometry state
// changes.
const getCheckGeometryRpcVersionId = selectorFamily<nullableHealthReply, Key>({
  key: `getCheckGeometryRpcVersionId/default`,
  get: (key: Key) => async ({ get }) => {
    const currentView = get(currentViewState);
    if (currentView !== CurrentView.GEOMETRY || !key.geometryVersionId || !key.geometryId) {
      return null;
    }
    if (!get(shouldGetCheckGeometry)) {
      return null;
    }
    const req = new frontendpb.GetCheckGeometryRequest(
      {
        projectId: key.projectId,
        geometryId: key.geometryId,
        geometryVersionId: key.geometryVersionId,
      },
    );
    try {
      const reply = await rpc.callRetry(
        'getCheckGeometry',
        rpc.client.getCheckGeometry,
        req,
        true,
        false,
      );
      return reply;
    } catch (error) {
      // This can a NotFound, PermissionDenied or what not. We don't really care, null is fine in
      // any of those cases.
      return null;
    }
  },
});

const getCheckGeometryRpc = selectorFamily<nullableHealthReply, string>({
  key: `getCheckGeometryRpc/default`,
  get: (projectId: string) => async ({ get }) => {
    const currentView = get(currentViewState);
    if (currentView !== CurrentView.GEOMETRY) {
      return null;
    }
    const [
      { geometryId },
    ] = get(waitForAll([routeParamsState]));
    if (!geometryId) {
      return null;
    }
    const lastVersionId = get(geometryLastVersionIdAtom({ projectId, geometryId }));
    return get(
      getCheckGeometryRpcVersionId({ projectId, geometryId, geometryVersionId: lastVersionId }),
    );
  },
});

// Stores the result of GetCheckGeometry RPC for a the latest geometry version. This allows the
// frontend clients to know whether there's an ongoing health check for the geometry version or
// whether one has already finished.
export const getCheckGeometry = selectorFamily<nullableHealthReply, string>({
  key: `getCheckGeometry/default`,
  get: (projectId: string) => ({ get }) => {
    const [
      currentView,
      { geometryId },
    ] = get(waitForAll([currentViewState, routeParamsState]));
    if (currentView !== CurrentView.GEOMETRY || !geometryId) {
      return null;
    }
    const [geoState, selectedFeature] = get(waitForAll([
      geometryState({ projectId, geometryId }),
      geometrySelectedFeature(geometryId),
    ]));
    // It's not crucial to have the geometry state up-to-date for this check. If we don't put a
    // condition here, we the suspense fallback fires up.
    const getCheckLoadable = get(noWait(getCheckGeometryRpc(projectId)));
    const getCheck = getCheckLoadable.state === 'hasValue' ? getCheckLoadable.contents : null;
    // Remove the health card in case we are not looking at the last feature or in case the
    // geometry state is not up-to-date.
    if (!getCheck || !geoState || geoState.geometryHistory.length === 0 ||
      selectedFeature !== DEFAULT_SELECTED_FEATURE) {
      return null;
    }
    return getCheck;
  },
});
