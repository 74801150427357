// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { TreeRow } from '../../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const ExplorationPolicyTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    primaryIcon={getNodeTypeIcon(props.node.type)}
  />
);
