// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const CopyIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="copy"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d={`
          M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1Z
          M19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19
          C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5Z
          M19 21H8V7H19V21Z
        `}
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
