// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo } from 'react';

import * as Vector from '../../../../lib/Vector';
import { listToPv, pvToList } from '../../../../lib/Vector';
import { RadioButtonOption } from '../../../../lib/componentTypes/form';
import { expandGroupsExcludingTags, rollupGroups } from '../../../../lib/entityGroupUtils';
import { hideDragLineWidget, hideFinitePlaneWidget, showDragLineWidget, showFinitePlaneWidget, updateDragLineWidgetState, updateFinitePlaneWidgetState } from '../../../../lib/lcvis/api';
import { lcvHandler } from '../../../../lib/lcvis/handler/LcvHandler';
import { Logger } from '../../../../lib/observability/logs';
import { SURFACE_NODE_TYPES } from '../../../../lib/simulationTree/node';
import { addRpcError, addWarning } from '../../../../lib/transientNotification';
import { debounce } from '../../../../lib/utils';
import { EditSource, EditState, isSurfaceListOverset, visComputeFilterWrapper } from '../../../../lib/visUtils';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useEntityGroupData } from '../../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { useLcVisEnabledState } from '../../../../recoil/lcvis/lcvisEnabledState';
import { useViewStateOverflow } from '../../../../recoil/lcvis/viewStateOverflow';
import { useEditState } from '../../../../recoil/paraviewState';
import { useSimulationParam } from '../../../../state/external/project/simulation/param';
import Form from '../../../Form';
import CheckBox from '../../../Form/CheckBox';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberInput } from '../../../Form/NumberInput';
import { RadioButtonGroup } from '../../../Form/RadioButtonGroup';
import { Vector3Input } from '../../../Form/Vector3Input';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { SimpleSlider } from '../../../controls/slider/SimpleSlider';
import { useVisualizationSurfacesSubselect } from '../../../hooks/subselect/useVisualizationSurfaces';
import { SectionMessage } from '../../../notification/SectionMessage';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';
import { FilterDisplayPanel, defaultLineStreamlineParams } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

const logger = new Logger('filter/VisStreamlines');

const MAX_STREAMLINES = 10000;
const MIN_STREAMLINES = 1;
const MAX_GRID = 100;

const validateValueRange = (value: number, max: number, min: number): number => {
  if (value > max) {
    addWarning(`Maximum value is: ${max}`);
    return max;
  } if (value < min) {
    addWarning(`Minimum value is: ${min}`);
    return min;
  }
  return value;
};

function defaultSeedRakeParams(bounds: ParaviewRpc.Bounds):
  ParaviewRpc.SeedRakeParam {
  let xStart, xEnd, yCenter, zCenter;
  if (bounds && bounds.length > 2) {
    xStart = parseFloat((bounds[0] +
      ((bounds[1] - bounds[0]) * 0.25)).toPrecision(4));
    xEnd = parseFloat((bounds[1] -
      ((bounds[1] - bounds[0]) * 0.25)).toPrecision(4));
    yCenter = parseFloat(((bounds[2] + bounds[3]) / 2).toPrecision(4));
    zCenter = parseFloat(((bounds[4] + bounds[5]) / 2).toPrecision(4));
  } else {
    xStart = 0.0;
    xEnd = 1.0;
    yCenter = 0.0;
    zCenter = 0.0;
  }
  return {
    typ: ParaviewRpc.SeedPlacementType.RAKE,
    start: {
      x: xStart,
      y: yCenter,
      z: zCenter,
    },
    end: {
      x: xEnd,
      y: yCenter,
      z: zCenter,
    },
  };
}

function defaultSeedGridParams(bounds: ParaviewRpc.Bounds):
  ParaviewRpc.SeedGridParam {
  let xCenter, yCenter, zCenter, seedSpace, rakeSpace;
  const seedRes = 10;
  const rakeRes = 2;
  if (bounds && bounds.length > 2) {
    xCenter = parseFloat(((bounds[0] + bounds[1]) / 2).toPrecision(4));
    yCenter = parseFloat(((bounds[2] + bounds[3]) / 2).toPrecision(4));
    zCenter = parseFloat(((bounds[4] + bounds[5]) / 2).toPrecision(4));
    seedSpace = parseFloat(((Math.abs(bounds[0]) +
      Math.abs(bounds[1])) / (2 * seedRes)).toPrecision(4));
    rakeSpace = parseFloat(((Math.abs(bounds[4]) +
      Math.abs(bounds[5])) / (2 * rakeRes)).toPrecision(4));
  } else {
    xCenter = 0.0;
    yCenter = 0.0;
    zCenter = 0.0;
    seedSpace = 0.1;
    rakeSpace = 0.1;
  }

  return {
    typ: ParaviewRpc.SeedPlacementType.GRID,
    center: {
      x: xCenter,
      y: yCenter,
      z: zCenter,
    },
    u: {
      x: 1,
      y: 0,
      z: 0,
    },
    v: {
      x: 0,
      y: 0,
      z: 1,
    },
    seedSpacing: seedSpace,
    seedRes,
    rakeSpacing: rakeSpace,
    rakeRes,
  };
}

function defaultSeedGlobeParams(bounds: ParaviewRpc.Bounds):
  ParaviewRpc.SeedGlobeParam {
  let radius, xCenter, yCenter, zCenter;
  if (bounds && bounds.length > 2) {
    radius = parseFloat(((Math.abs(bounds[0]) +
      Math.abs(bounds[1])) / 10).toPrecision(4));
    xCenter = parseFloat(((bounds[0] + bounds[1]) / 2).toPrecision(4));
    yCenter = parseFloat(((bounds[2] + bounds[3]) / 2).toPrecision(4));
    zCenter = parseFloat(((bounds[4] + bounds[5]) / 2).toPrecision(4));
  } else {
    radius = 10.0;
    xCenter = 0.0;
    yCenter = 0.0;
    zCenter = 0.0;
  }
  return {
    typ: ParaviewRpc.SeedPlacementType.GLOBE,
    center: {
      x: xCenter,
      y: yCenter,
      z: zCenter,
    },
    radius,
  };
}

function defaultSeedSurfaceParams(): ParaviewRpc.SeedSurfaceParam {
  return {
    typ: ParaviewRpc.SeedPlacementType.SURFACE,
    surfaces: [],
    sampleRate: 10,
    offset: 0.0,
    projectOnSurface: false,
  };
}

function defaultSeedPlacementParams(
  type: ParaviewRpc.SeedPlacementType,
  bounds: ParaviewRpc.Bounds,
):
  ParaviewRpc.SeedPlacementParams {
  switch (type) {
    case ParaviewRpc.SeedPlacementType.RAKE:
      return defaultSeedRakeParams(bounds);
    case ParaviewRpc.SeedPlacementType.GRID:
      return defaultSeedGridParams(bounds);
    case ParaviewRpc.SeedPlacementType.GLOBE:
      return defaultSeedGlobeParams(bounds);
    case ParaviewRpc.SeedPlacementType.SURFACE:
      return defaultSeedSurfaceParams();
    default:
      throw Error('Invalid seed placement type.');
  }
}

/** Create the param filled with default values, to be used when creating a new
    filter off the given parent. */
export function newVisStreamlinesParam(
  defaultField: ParaviewRpc.ArrayInformation,
  visibleBounds: ParaviewRpc.Bounds,
):
  ParaviewRpc.VisStreamlinesParam {
  return {
    typ: ParaviewRpc.TreeNodeType.STREAMLINES,
    dataName: defaultField.name,
    integrationDirection: ParaviewRpc.IntegrationDirection.FORWARD,
    seedPlacementType: ParaviewRpc.SeedPlacementType.RAKE,
    seedPlacementParams: defaultSeedRakeParams(visibleBounds),
    streamlineRenderParams: defaultLineStreamlineParams(),
    maximumLength: 10.0,
    nstreamlines: 20,
    currentstreamlines: 0,
    showmultiple: true,
    url: '',
  };
}

interface SeedTypeRenderCommonParams {
  onUpdate: (source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => void;
  param: ParaviewRpc.VisStreamlinesParam;
  readOnly: boolean;
}

interface SeedTypeRenderSurfaceParams extends SeedTypeRenderCommonParams {
  editState: EditState | null;
  nodeId: string;
}

const SeedTypeRakeOptions = (props: SeedTypeRenderCommonParams) => {
  const { onUpdate, param, readOnly } = props;
  const rakeParams = param.seedPlacementParams as ParaviewRpc.SeedRakeParam;

  return (
    <>
      <Form.LabeledInput help="Start of the rake" label="Start">
        <Vector3Input
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...rakeParams,
                  start: Vector.toPvProto(value),
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          value={Vector.toProto(rakeParams.start)}
        />
      </Form.LabeledInput>
      <Form.LabeledInput help="End of the rake" label="End">
        <Vector3Input
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...rakeParams,
                  end: Vector.toPvProto(value),
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          value={Vector.toProto(rakeParams.end)}
        />
      </Form.LabeledInput>
      <Form.LabeledInput label="Number of Streamlines">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            value = validateValueRange(value, MAX_STREAMLINES, MIN_STREAMLINES);
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                nstreamlines: value,
                url: '',
              },
            );
          }}
          size="small"
          value={param.nstreamlines}
        />
      </Form.LabeledInput>
    </>
  );
};

const SeedTypeGridOptions = (props: SeedTypeRenderCommonParams) => {
  const { onUpdate, param, readOnly } = props;
  const gridParams = param.seedPlacementParams as ParaviewRpc.SeedGridParam;

  return (
    <>
      <Form.LabeledInput help="Center of the grid." label="Center">
        <Vector3Input
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  center: Vector.toPvProto(value),
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          value={Vector.toProto(gridParams.center)}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="The direction of grid rows."
        label="Row Orientation">
        <Vector3Input
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  u: Vector.toPvProto(value),
                },
                url: '',
              },
            );
          }}
          value={Vector.toProto(gridParams.u)}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="The direction of grid columns."
        label="Column Orientation">
        <Vector3Input
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  v: Vector.toPvProto(value),
                },
                url: '',
              },
            );
          }}
          value={Vector.toProto(gridParams.v)}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="Total number of columns in the grid."
        label="Number of Seeds">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            value = validateValueRange(value, MAX_GRID, MIN_STREAMLINES);
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  seedRes: value,
                },
                nstreamlines: value * gridParams.rakeRes,
                url: '',
              },
            );
          }}
          size="small"
          value={gridParams.seedRes}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="Total number of rows in the grid."
        label="Number of Rakes">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            value = validateValueRange(value, MAX_GRID, MIN_STREAMLINES);
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  rakeRes: value,
                },
                nstreamlines: value * gridParams.seedRes,
                url: '',
              },
            );
          }}
          size="small"
          value={gridParams.rakeRes}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="The column spacing or distance between seeds in a single rake."
        label="Seed Spacing">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  seedSpacing: value,
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          size="small"
          value={gridParams.seedSpacing}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="The row spacing or distance between parallel rakes."
        label="Rake Spacing">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  rakeSpacing: value,
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          size="small"
          value={gridParams.rakeSpacing}
        />
      </Form.LabeledInput>
    </>
  );
};

// LCVis uses rotation euler angles instead of specifying the u/v vectors
// and the width/height of the plane instead of seed/rake spacing
const LCVisSeedTypeGridOptions = (props: SeedTypeRenderCommonParams) => {
  const { onUpdate, param, readOnly } = props;
  const gridParams = param.seedPlacementParams as ParaviewRpc.SeedGridParam;

  const uAxis = Vector.normalize(Vector.toProto(gridParams.u));
  const vAxis = Vector.normalize(Vector.toProto(gridParams.v));
  const eulerAngles = Vector.axesToEuler(uAxis, vAxis);

  // We subtract 1 from the # of rakes/seeds to compute the width/height of the plane,
  // this is because the first rake/seed is at 0, the next one is at +rakeSpacing.
  // So when we have 2 rakes for example, the plane is only rakeSpacing wide,
  // not 2 * rakeSpacing
  const height = gridParams.rakeSpacing * Math.max(gridParams.rakeRes - 1, 1);
  const width = gridParams.seedSpacing * Math.max(gridParams.seedRes - 1, 1);

  return (
    <>
      <Form.LabeledInput help="Center of the grid." label="Center">
        <Vector3Input
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  center: Vector.toPvProto(value),
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          value={Vector.toProto(gridParams.center)}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="The orientation of the grid."
        label="Grid Orientation">
        <Vector3Input
          disabled={readOnly}
          onCommit={(value) => {
            // Compute new u & v vectors
            const uVector = Vector.rotate(value, Vector.newProto(1, 0, 0));
            const vVector = Vector.rotate(value, Vector.newProto(0, 1, 0));
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  u: Vector.toPvProto(uVector),
                  v: Vector.toPvProto(vVector),
                },
                url: '',
              },
            );
          }}
          value={Vector.toProto(eulerAngles)}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="The number of seed points per row in the grid."
        label="Number of Seeds per Row">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            value = validateValueRange(value, MAX_GRID, MIN_STREAMLINES);

            // Adjust seed spacing to keep grid width constant and just distribute
            // the seeds within the grid, not by adjusting the grid size like we
            // did in the past
            const seedSpacing = width / Math.max(value - 1, 1);

            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  seedRes: value,
                  seedSpacing,
                },
                nstreamlines: value * gridParams.rakeRes,
                url: '',
              },
            );
          }}
          size="small"
          value={gridParams.seedRes}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="Total number of rows in the grid."
        label="Number of Rows">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            value = validateValueRange(value, MAX_GRID, MIN_STREAMLINES);

            // Adjust row spacing to keep grid height constant and just distribute
            // the rows within the grid, not by adjusting the grid size like we
            // did in the past
            const rakeSpacing = height / Math.max(value - 1, 1);

            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  rakeRes: value,
                  rakeSpacing,
                },
                nstreamlines: value * gridParams.seedRes,
                url: '',
              },
            );
          }}
          size="small"
          value={gridParams.rakeRes}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="The width of the grid."
        label="Width">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            // Adjust seed spacing to fill the new width of the grid
            value /= Math.max(gridParams.seedRes - 1, 1);

            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  seedSpacing: value,
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          size="small"
          value={width}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="The height of the grid."
        label="Height">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            // Adjust row spacing to fill the new widgth of the grid
            value /= Math.max(gridParams.rakeRes - 1, 1);

            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...gridParams,
                  rakeSpacing: value,
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          size="small"
          value={height}
        />
      </Form.LabeledInput>
    </>
  );
};

const SeedTypeGlobeOptions = (props: SeedTypeRenderCommonParams) => {
  const { onUpdate, param, readOnly } = props;
  const globeParams = param.seedPlacementParams as ParaviewRpc.SeedGlobeParam;

  return (
    <>
      <Form.LabeledInput help="Center of the sphere." label="Center">
        <Vector3Input
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...globeParams,
                  center: Vector.toPvProto(value),
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          value={Vector.toProto(globeParams.center)}
        />
      </Form.LabeledInput>
      <Form.LabeledInput label="Radius">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...globeParams,
                  radius: value,
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          size="small"
          value={globeParams.radius}
        />
      </Form.LabeledInput>
      <Form.LabeledInput label="Number of Streamlines">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            value = validateValueRange(value, MAX_STREAMLINES, MIN_STREAMLINES);
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                nstreamlines: value,
                url: '',
              },
            );
          }}
          size="small"
          value={param.nstreamlines}
        />
      </Form.LabeledInput>
    </>
  );
};

const SUBSELECT_ID = 'visualization-streamlines-surfaces';

const SeedTypeSurfaceOptions = (props: SeedTypeRenderSurfaceParams) => {
  // == Props
  const { editState, nodeId, onUpdate, param, readOnly } = props;

  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Hooks
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);
  const { nodeFilter } = useVisualizationSurfacesSubselect(SUBSELECT_ID, geometryTags, false);
  const propClasses = useCommonTreePropsStyles();
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // == Data
  const surfaceParams = param.seedPlacementParams as ParaviewRpc.SeedSurfaceParam;
  const projectOnSurface = (param.seedPlacementType === ParaviewRpc.SeedPlacementType.SURFACE) ?
    surfaceParams.projectOnSurface : true;

  const surfaceList = useMemo(() => {
    if (param.seedPlacementType === ParaviewRpc.SeedPlacementType.SURFACE) {
      return (param.seedPlacementParams as ParaviewRpc.SeedSurfaceParam).surfaces;
    }
    return [];
  }, [param]);

  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);
  const rollupNodeIds = useMemo(() => rollup(surfaceList), [rollup, surfaceList]);

  const setSurfaces = useCallback((newSurfaceList: string[]) => {
    // Keep tag as they are so that we can reuse settings.
    const unrolledSurfaceList = expandGroupsExcludingTags(entityGroupData, newSurfaceList);
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        seedPlacementParams: {
          ...(param.seedPlacementParams as ParaviewRpc.SeedSurfaceParam),
          surfaces: unrolledSurfaceList,
        },
        url: '',
      },
    );
  }, [onUpdate, param, entityGroupData]);

  const showSurfaceOverlapWarning = isSurfaceListOverset(surfaceList, simParam);

  return (
    <>
      <div style={{ paddingTop: '8px' }}>
        <NodeSubselect
          autoStart
          id={SUBSELECT_ID}
          independentSelection
          labels={['mesh surfaces']}
          nodeFilter={nodeFilter}
          nodeIds={rollupNodeIds}
          onChange={setSurfaces}
          readOnly={readOnly}
          referenceNodeIds={[nodeId]}
          visibleTreeNodeTypes={SURFACE_NODE_TYPES}
        />
      </div>
      {showSurfaceOverlapWarning && projectOnSurface && (
        <div className={propClasses.sectionMessages}>
          <SectionMessage
            level="warning"
            message="Geometry surfaces from different volumes may overlap."
          />
        </div>
      )}
      <Form.LabeledInput
        help="Rate of sampling points on the surface."
        label="Every N Points"
        lead>
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(value) => {
            value = validateValueRange(value, 1e6, 1);
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...surfaceParams,
                  sampleRate: value,
                },
                url: '',
              },
            );
          }}
          size="small"
          value={surfaceParams.sampleRate}
        />
      </Form.LabeledInput>
      <Form.LabeledInput label="">
        <Form.ControlLabel
          help="Constrain streamlines to surface."
          label="Surface Streamlines">
          <CheckBox
            checked={surfaceParams.projectOnSurface}
            disabled={!editState}
            onChange={(checked: boolean) => {
              onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  seedPlacementParams: {
                    ...surfaceParams,
                    projectOnSurface: checked,
                  },
                  url: '',
                },
              );
            }}
          />
        </Form.ControlLabel>
      </Form.LabeledInput>
      <Form.LabeledInput
        help="Distance from the surface to seed particles"
        label="Surface Offset">
        <NumberInput
          asBlock
          disabled={readOnly || projectOnSurface}
          onCommit={(value) => {
            value = validateValueRange(value, 1e6, -1e6);
            onUpdate(
              EditSource.FORM,
              {
                ...param,
                seedPlacementParams: {
                  ...surfaceParams,
                  offset: value,
                },
                url: '',
              },
            );
          }}
          quantityType={QuantityType.LENGTH}
          size="small"
          value={projectOnSurface ? 0.0 : surfaceParams.offset}
        />
      </Form.LabeledInput>
    </>
  );
};

type VisStreamlinesPropPanelProps = Omit<FilterPropertiesPanelProps, 'viewState'> & {
  viewState: ParaviewRpc.ViewState | null;
}

// Panel for displaying and modifying a streamline filter.
export const VisStreamlinesPropPanel = (props: VisStreamlinesPropPanelProps) => {
  const { displayProps, filterNode, nodeId, parentFilterNode } = props;

  const {
    paraviewClientState,
    paraviewMeshMetadata,
    paraviewActiveUrl,
    paraviewProjectId,
    paraviewRenderer,
    activeEdit,
    getDataVisibilityBounds,
  } = useParaviewContext();

  const [editState] = useEditState();
  const { updateEditState } = useSelectedFilterNode();

  const { projectId, workflowId, jobId } = useProjectContext();
  const [lcVisEnabled] = useLcVisEnabledState(projectId);
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const paraviewClient = paraviewClientState.client;
  const editSource = editState ? editState.editSource : EditSource.FORM;

  const onUpdate = useMemo(
    () => debounce((source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => {
      updateEditState({ editSource: source, param: newParam });
    }, 25),
    [updateEditState],
  );

  const param = props.param as ParaviewRpc.VisStreamlinesParam;

  const readOnly = !editState;
  const seedType = param.seedPlacementType;
  const seedParams = param.seedPlacementParams;
  let data = lcVisEnabled ? lcvisData.data : parentFilterNode.pointData;
  // Streamlines filter works only for vector data.
  data = data.filter(
    (item: ParaviewRpc.ArrayInformation) => item.dim === 3,
  );

  const empty = !data || data.length <= 0;

  // When using surface seeded streamlines we require the user to select
  // surfaces before they can apply the filter
  const disableApply = param.seedPlacementType === 'Surface' &&
     (param.seedPlacementParams as ParaviewRpc.SeedSurfaceParam).surfaces.length === 0;

  const deleteSeedGridPreview = () => {
    if (paraviewClient) {
      ParaviewRpc.clearseedgridpreview(paraviewClient).catch((reason: any) => {
        logger.error('Failed to clearseedgridpreview: ', reason);
      });
    }
  };

  // Paraview widget effects
  useEffect(() => {
    if (!editState) {
      deleteSeedGridPreview();
      return () => { };
    }

    let mounted = true;
    let unsubscribeFn: (() => void) | null = null;
    // Called when the user moves the widget interactively.
    const onLineWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION) {
        throw Error(`only line widget supported now: ${JSON.stringify(newParam)}`);
      }
      if (!mounted) {
        return;
      }
      if (param.seedPlacementType !== ParaviewRpc.SeedPlacementType.RAKE) {
        return;
      }
      const newSeedPlacementParams: ParaviewRpc.SeedRakeParam = {
        typ: 'Rake',
        start: newParam.line.point1,
        end: newParam.line.point2,
      };

      onUpdate(
        EditSource.PARAVIEW,
        {
          ...param,
          seedPlacementParams: newSeedPlacementParams,
          url: '',
        },
      );
    };

    const onSphereWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.SPHERE_WIDGET_REPRESENTATION) {
        throw Error(`only sphere widget supported now: ${JSON.stringify(newParam)}`);
      }
      if (!mounted) {
        return;
      }
      if (param.seedPlacementType !== ParaviewRpc.SeedPlacementType.GLOBE) {
        return;
      }
      const newSeedPlacementParams: ParaviewRpc.SeedGlobeParam = {
        typ: 'Globe',
        center: newParam.sphere.center,
        radius: newParam.sphere.radius,
      };

      onUpdate(
        EditSource.PARAVIEW,
        {
          ...param,
          seedPlacementParams: newSeedPlacementParams,
          url: '',
        },
      );
    };

    const startWidget = async (): Promise<void> => {
      if (!paraviewClient) {
        return;
      }

      if (seedType === ParaviewRpc.SeedPlacementType.RAKE) {
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onLineWidgetUpdate);
        const rakeParam = param.seedPlacementParams as ParaviewRpc.SeedRakeParam;
        const lineParam: ParaviewRpc.LineParam = {
          typ: 'Line',
          point1: rakeParam.start,
          point2: rakeParam.end,
        };
        // Activate the widget.
        paraviewRenderer.activateWidget(filterNode.bounds!, {
          typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
          line: lineParam,
          resolution: 1000.0,
        });
      } else if (seedType === ParaviewRpc.SeedPlacementType.GLOBE) {
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onSphereWidgetUpdate);
        const globeParam = param.seedPlacementParams as ParaviewRpc.SeedGlobeParam;
        const sphereParam: ParaviewRpc.SphereParam = {
          typ: 'Sphere',
          center: globeParam.center,
          radius: globeParam.radius,
        };
        paraviewRenderer.activateWidget(filterNode.bounds!, {
          typ: ParaviewRpc.WidgetType.SPHERE_WIDGET_REPRESENTATION,
          sphere: sphereParam,
        });
      } else if (seedType === ParaviewRpc.SeedPlacementType.GRID) {
        // Clear any previous seed grid preview
        deleteSeedGridPreview();
        // Show a seed grid preview.
        if (paraviewClient && param.seedPlacementParams) {
          ParaviewRpc.setseedgridpreview(
            paraviewClient,
            param.seedPlacementParams as ParaviewRpc.SeedGridParam,
          )
            .catch((reason: any) => {
              logger.error('Failed to setseedgridpreview', reason);
            });
        }
      }
    };
    startWidget().then(() => {
    }).catch((err: Error) => {
      addRpcError('Could not activate streamlines widget', err);
    });
    return () => {
      mounted = false;
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, nodeId, seedType]);

  useEffect(() => {
    if (editState && editSource !== EditSource.PARAVIEW) {
      // Clear any previous seed grid preview

      logger.debug('Streamlines: set widget/preview');
      // When the user manually updates the dialog, reflect the new
      // params to the widget.
      if (param.seedPlacementType === ParaviewRpc.SeedPlacementType.RAKE) {
        const rakeParam = seedParams as ParaviewRpc.SeedRakeParam;
        const lineParam: ParaviewRpc.LineParam = {
          typ: 'Line',
          point1: rakeParam.start,
          point2: rakeParam.end,
        };
        const ws: ParaviewRpc.LineSourceWidgetState = {
          typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
          line: lineParam,
          resolution: 1000.0,
        };
        paraviewRenderer.activateWidget(filterNode.bounds!, ws);
      } else if (param.seedPlacementType === ParaviewRpc.SeedPlacementType.GLOBE) {
        const globeParam = seedParams as ParaviewRpc.SeedGlobeParam;
        const sphereParam: ParaviewRpc.SphereParam = {
          typ: 'Sphere',
          center: globeParam.center,
          radius: globeParam.radius,
        };
        const ws: ParaviewRpc.SphereWidgetState = {
          typ: ParaviewRpc.WidgetType.SPHERE_WIDGET_REPRESENTATION,
          sphere: sphereParam,
        };
        paraviewRenderer.activateWidget(filterNode.bounds!, ws);
      } else if (param.seedPlacementType === ParaviewRpc.SeedPlacementType.GRID) {
        // Update the seed grid preview.
        if (paraviewClient && seedParams) {
          deleteSeedGridPreview();
          ParaviewRpc.setseedgridpreview(
            paraviewClient,
            seedParams as ParaviewRpc.SeedGridParam,
          )
            .catch((reason: any) => {
              logger.error('Failed to setseedgridpreview', reason);
            });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, editSource, seedParams, nodeId]);

  // Create a use effect so we can clean up the grid preview when this functional component is
  // unmounted.
  useEffect(() => () => {
    paraviewRenderer.deleteWidget();
    hideDragLineWidget();
    hideFinitePlaneWidget();
    deleteSeedGridPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRakeMode = (
    editState?.param?.typ === 'Streamlines' &&
    editState.param.seedPlacementType === ParaviewRpc.SeedPlacementType.RAKE
  );

  const isGridMode = (
    editState?.param?.typ === 'Streamlines' &&
    editState.param.seedPlacementType === ParaviewRpc.SeedPlacementType.GRID
  );

  // LCVis widget effects
  useEffect(() => {
    if (!lcVisEnabled) {
      return;
    }

    if (isRakeMode) {
      showDragLineWidget({
        onChange: ({ start, end }) => {
          const newSeedPlacementParams: ParaviewRpc.SeedRakeParam = {
            typ: 'Rake',
            start: listToPv(start),
            end: listToPv(end),
          };

          onUpdate(
            EditSource.PARAVIEW,
            {
              ...param,
              seedPlacementParams: newSeedPlacementParams,
              url: '',
            },
          );
        },

      });
    } else {
      hideDragLineWidget();
    }

    if (isGridMode) {
      showFinitePlaneWidget({
        onChange: (gridState) => {
          const newParams: ParaviewRpc.SeedGridParam = {
            typ: 'Grid',
            center: listToPv(gridState.position),
            u: listToPv(gridState.xAxis),
            v: listToPv(gridState.yAxis),
            seedSpacing: gridState.width / Math.max(gridState.nSeedsPerRow - 1, 1),
            seedRes: gridState.nSeedsPerRow,
            rakeSpacing: gridState.height / Math.max(gridState.nSeedRows - 1, 1),
            rakeRes: gridState.nSeedRows,
          };

          onUpdate(
            EditSource.PARAVIEW,
            {
              ...param,
              seedPlacementParams: newParams,
              url: '',
            },
          );
        },
      });
    } else {
      hideFinitePlaneWidget();
    }
  }, [isRakeMode, isGridMode, lcVisEnabled, onUpdate, param]);

  useEffect(() => {
    if (
      !lcVisEnabled ||
      editState?.editSource === EditSource.PARAVIEW ||
      editState?.param.typ !== 'Streamlines'
    ) {
      return;
    }

    if (editState.param.seedPlacementParams?.typ === 'Rake') {
      updateDragLineWidgetState({
        start: pvToList(editState.param.seedPlacementParams.start),
        end: pvToList(editState.param.seedPlacementParams.end),
      });
    } else if (editState.param.seedPlacementParams?.typ === 'Grid') {
      const {
        center,
        rakeRes,
        seedRes,
        rakeSpacing,
        seedSpacing,
        u: uVec,
        v: vVec,
      } = editState.param.seedPlacementParams;
      const uAxis = Vector.normalize(Vector.toProto(uVec));
      const vAxis = Vector.normalize(Vector.toProto(vVec));
      updateFinitePlaneWidgetState({
        position: pvToList(center),
        rotation: pvToList(Vector.axesToEuler(uAxis, vAxis)),
        displaySeedGrid: 1,
        nSeedRows: rakeRes,
        height: rakeSpacing * Math.max(rakeRes - 1, 1),
        nSeedsPerRow: seedRes,
        width: seedSpacing * Math.max(seedRes - 1, 1),
      });
    }
  }, [editState, lcVisEnabled]);

  // Function to make asynchronous call to vis service to compute Streamlines
  const executeVisFilter = (newNodeId: string) => {
    const currentParam = editState!.param as ParaviewRpc.VisStreamlinesParam;
    visComputeFilterWrapper(
      paraviewProjectId,
      paraviewActiveUrl,
      currentParam,
      newNodeId,
      activeEdit,
    );
  };

  const seedTypeOptions: RadioButtonOption<string>[] = [{
    disabled: false,
    label: 'Rake',
    value: ParaviewRpc.SeedPlacementType.RAKE,
  },
  {
    disabled: lcVisEnabled,
    label: 'Sphere',
    value: ParaviewRpc.SeedPlacementType.GLOBE,
  },
  {
    disabled: false,
    label: 'Grid',
    value: ParaviewRpc.SeedPlacementType.GRID,
  },
  {
    disabled: false,
    label: 'Surface',
    value: ParaviewRpc.SeedPlacementType.SURFACE,
  }];

  const setSeedPlacementType = (newType: ParaviewRpc.SeedPlacementType) => {
    const visibleBounds = !lcVisEnabled ?
      getDataVisibilityBounds(paraviewMeshMetadata!.meshMetadata) :
      lcvHandler.display?.getCurrentBounds() as ParaviewRpc.Bounds;
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        seedPlacementType: newType,
        seedPlacementParams: defaultSeedPlacementParams(newType, visibleBounds),
        url: '',
      },
    );
    // Delete prior widgets / previews
    if (newType === ParaviewRpc.SeedPlacementType.GRID) {
      paraviewRenderer.deleteWidget();
    } else if (newType === ParaviewRpc.SeedPlacementType.SURFACE) {
      paraviewRenderer.deleteWidget();
      deleteSeedGridPreview();
    } else {
      deleteSeedGridPreview();
    }
  };

  const integrationDirectionOptions: RadioButtonOption<string>[] = [{
    disabled: false,
    label: 'Forward',
    value: ParaviewRpc.IntegrationDirection.FORWARD,
  }, {
    disabled: false,
    label: 'Backward',
    value: ParaviewRpc.IntegrationDirection.BACKWARD,
  }, {
    disabled: false,
    label: 'Both',
    value: ParaviewRpc.IntegrationDirection.BOTH,
  }];

  const setIntegrationDirection = (newDirection: ParaviewRpc.IntegrationDirection) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        integrationDirection: newDirection,
        url: '',
      },
    );
  };

  const setDataName = (newName: string) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        dataName: newName,
        url: '',
      },
    );
  };

  const toggleAllStreamlines = (checked: boolean) => {
    const newParam = { ...param, showmultiple: checked };
    activeEdit(nodeId, newParam);
  };

  // Set the streamline set using slider and not in edit state
  const setStreamlines = (newValue: number) => {
    const newParam = { ...param, currentstreamlines: newValue };
    activeEdit(nodeId, newParam);
  };

  const updateFilterRepresentation = (val: ParaviewRpc.StreamlineRenderParams) => {
    if (!editState) {
      const newParam = {
        ...param,
        streamlineRenderParams: val,
      };
      activeEdit(nodeId, newParam);
    } else {
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          streamlineRenderParams: val,
        },
      );
    }
  };

  const sliderUsage = useMemo(() => {
    let maxValue = 0;
    let show = false;

    if (param.seedPlacementType === ParaviewRpc.SeedPlacementType.GRID) {
      maxValue = (param.seedPlacementParams as ParaviewRpc.SeedGridParam).rakeRes - 1;
      show = maxValue > 0;
    }
    if (param.seedPlacementType === ParaviewRpc.SeedPlacementType.SURFACE) {
      maxValue = (param.seedPlacementParams as ParaviewRpc.SeedSurfaceParam).surfaces.length - 1;
      if (!(param.seedPlacementParams as ParaviewRpc.SeedSurfaceParam).projectOnSurface) {
        show = maxValue > 0;
      }
    }

    return { maxValue, show };
  }, [param]);

  const renderSeedTypeOptionsSwitch = () => {
    const commonProps = { onUpdate, param, readOnly };
    switch (param.seedPlacementType) {
      case ParaviewRpc.SeedPlacementType.RAKE:
        return (<SeedTypeRakeOptions {...commonProps} />);
      case ParaviewRpc.SeedPlacementType.GRID:
        return lcVisEnabled ? (<LCVisSeedTypeGridOptions {...commonProps} />) :
          (<SeedTypeGridOptions {...commonProps} />);
      case ParaviewRpc.SeedPlacementType.GLOBE:
        return (<SeedTypeGlobeOptions {...commonProps} />);
      case ParaviewRpc.SeedPlacementType.SURFACE:
        return (
          <SeedTypeSurfaceOptions
            {...commonProps}
            editState={editState}
            nodeId={nodeId}
          />
        );
      default:
        throw Error('Invalid seed placement type.');
    }
  };

  return (
    <div>
      <FilterDisplayPanel
        filterNode={filterNode}
        updateFilterRepresentation={updateFilterRepresentation}
      />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableApply={disableApply}
              disableApplyHelp="Surface seeded streamlines require a list of surfaces."
              disableEdit={empty}
              displayProps={displayProps}
              executeVisFilter={executeVisFilter}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <Form.LabeledInput label="Streamline Field">
            <DataSelect
              asBlock
              disabled={readOnly}
              onChange={setDataName}
              options={data.map(({ name }) => ({
                name,
                value: name,
                selected: name === param.dataName,
              }))}
              size="small"
            />
          </Form.LabeledInput>
          <Form.LabeledInput label="Integration Direction">
            <RadioButtonGroup
              disabled={readOnly}
              kind="secondary"
              name="streamlinesIntegrationDirection"
              onChange={(type) => {
                setIntegrationDirection(type as ParaviewRpc.IntegrationDirection);
              }}
              options={integrationDirectionOptions}
              value={param.integrationDirection}
            />
          </Form.LabeledInput>
          <Form.LabeledInput label="Maximum Length">
            <NumberInput
              asBlock
              disabled={readOnly}
              onCommit={(value) => {
                onUpdate(
                  EditSource.FORM,
                  {
                    ...param,
                    maximumLength: value,
                    url: '',
                  },
                );
              }}
              quantityType={QuantityType.LENGTH}
              size="small"
              value={param.maximumLength}
            />
          </Form.LabeledInput>
          <Form.LabeledInput label="Seed Type">
            <RadioButtonGroup
              disabled={readOnly}
              kind="secondary"
              name="streamlinesSeedType"
              onChange={(type) => setSeedPlacementType(type as ParaviewRpc.SeedPlacementType)}
              options={seedTypeOptions}
              value={param.seedPlacementType}
            />
          </Form.LabeledInput>
          {renderSeedTypeOptionsSwitch()}
          {!editState && sliderUsage.show && (
            <div style={{ padding: '8px 8px 0' }}>
              <SimpleSlider
                disabled={!!editState || param.showmultiple}
                max={sliderUsage.maxValue}
                min={0}
                onChange={setStreamlines}
                onCommit={setStreamlines}
                showStops
                stopCount={sliderUsage.maxValue + 1}
                value={param.currentstreamlines}
              />
              <Form.LabeledInput label="">
                <Form.ControlLabel
                  label="Show All">
                  <CheckBox
                    checked={param.showmultiple}
                    disabled={lcVisEnabled}
                    onChange={(checked) => {
                      toggleAllStreamlines(checked);
                    }}
                  />
                </Form.ControlLabel>
              </Form.LabeledInput>
            </div>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </div>
  );
};
