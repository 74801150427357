// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo, useState } from 'react';

import { CommonMenuSeparator } from '../../lib/componentTypes/menu';
import { globalDisabledReason } from '../../lib/geometryUtils';
import { boldEscaped } from '../../lib/html';
import { getMaterialName } from '../../lib/materialUtils';
import { assembleMenuSections, filteredMenuItems } from '../../lib/menuUtil';
import { getPhysicsName } from '../../lib/physicsUtils';
import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { visibilityToggleTreeNodeMenuItem } from '../../lib/treeUtils';
import { getMaterialAssignmentTooltip, getPhysicsAssignmentTooltip } from '../../lib/volumeUtils';
import { usePhysicsSet } from '../../model/hooks/usePhysicsSet';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useIsGeometryServerActive } from '../../recoil/geometry/geometryServerStatus';
import { useGeometrySelectedFeature } from '../../recoil/geometry/geometryState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useGeometryUsesTags } from '../../recoil/geometry/geometryUsesTags';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { useTagsInteractiveGeometry } from './useInteractiveGeometry';
import { useVolumeNode } from './useVolumeNode';

function createTagOnFacesDisabledReasonFunc(
  defaultTagDisabledReason: string | undefined,
  selectedNodeIds: string[],
  isSelected: boolean,
) {
  if (defaultTagDisabledReason) {
    return defaultTagDisabledReason;
  }
  // We have multiple selected items and we are trying to add their faces to a tag. We don't support
  // this operation yet.
  if (selectedNodeIds.length > 1 && isSelected) {
    return 'Cannot create tag on faces when there are multiple selected items.';
  }
  return '';
}

export const useVolumeNodeRowMenu = (
  volumeId: string,
  node: SimulationTreeNode | null,
  isVisible: boolean,
  visControlsDisabled: boolean,
  toggleVis: () => void,
) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly, geometryId } = useProjectContext();
  const { selectedNodeIds, deselectNodeIds } = useSelectionContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  // == Hooks
  const {
    staticVolume,

    assignedMaterial,
    materialsMenuItems,
    unassignMaterial,

    assignedPhysicsId,
    assignedPhysics,
    physicsMenuItems,
    unassignPhysics,
  } = useVolumeNode(volumeId);
  const {
    getVolumePhysicsAssignmentDisabledReason,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);
  const {
    isCreateTagDisabled,
    addVolumesToExistingTag,
    removeItemsFromTag,
  } = useTagsInteractiveGeometry();
  const isGeometryView = useIsGeometryView();
  const geometryTags = useGeometryTags(projectId);
  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const isGeoServerActive = useIsGeometryServerActive(geometryId);
  const geoUsesTags = useGeometryUsesTags(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // With tag creation, we request the users to provide a name for the tag via a dialog.
  const [isCreateTagDialogOpen, setIsCreateTagDialogOpen] = useState(false);
  const [isCreateTagFacesDialogOpen, setIsCreateTagFacesDialogOpen] = useState(false);

  const selectedCoreNodeIds = selectedNodeIds.map((id) => geometryTags.getCoreNodeIdentifier(id));

  // The isVolumeSelected function checks whether the selection includes a volume.
  // It also unwraps core identifiers, meaning that NodeType.TAGS_BODY
  // is treated as a volume in this context.
  const isVolumeSelected = useMemo(() => (
    selectedCoreNodeIds.includes(volumeId)
  ), [selectedCoreNodeIds, volumeId]);

  // The isNodeSelected function checks whether the current node is selected.
  // Unlike isVolumeSelected, it does not unwrap core identifiers.
  // This function is primarily used for removing items from tags.
  const isNodeSelected = useMemo(() => (
    selectedNodeIds.includes(node?.id || '')
  ), [node?.id, selectedNodeIds]);

  const defaultTagDisabledReason = globalDisabledReason(
    selectedFeature,
    readOnly,
    isGeoServerActive,
  );
  const createTagOnFacesDisabledReason = createTagOnFacesDisabledReasonFunc(
    defaultTagDisabledReason,
    selectedCoreNodeIds,
    isVolumeSelected,
  );
  const removeTagChildDisabledReason = useMemo(() => {
    if (defaultTagDisabledReason) {
      return defaultTagDisabledReason;
    }
    return '';
  }, [defaultTagDisabledReason]);

  const taggingEnabled = !isCreateTagDisabled && geoUsesTags;
  const isTagChildren = node?.type === NodeType.TAGS_BODY;

  const visibilityContextMenuItems = filteredMenuItems([
    {
      itemConfig: visibilityToggleTreeNodeMenuItem(isVisible, toggleVis, visControlsDisabled),
      shouldShow: !!staticVolume,
    },
  ]);

  const contextMenuItemsForGeometryView = assembleMenuSections(
    visibilityContextMenuItems,
    filteredMenuItems([
      {
        itemConfig: {
          label: 'Add to Tag',
          disabled: !!defaultTagDisabledReason,
          disabledReason: defaultTagDisabledReason,
          items: [
            {
              label: 'New Tag for Volume...',
              onClick: () => {
                setIsCreateTagDialogOpen(true);
              },
            },
            {
              label: 'New Tag for Surfaces...',
              onClick: () => {
                setIsCreateTagFacesDialogOpen(true);
              },
              disabled: !!createTagOnFacesDisabledReason,
              disabledReason: createTagOnFacesDisabledReason,
            },
            { separator: true } as CommonMenuSeparator,
            ...(geometryTags.tags.map((tag) => ({
              label: tag.name,
              onClick: async () => {
                const nodeIds = isVolumeSelected ? selectedCoreNodeIds : [volumeId];
                await addVolumesToExistingTag(tag.id, nodeIds);
              },
            }))),
          ],
          startIcon: { name: 'tag' },
        },
        shouldShow: taggingEnabled &&
          (selectedCoreNodeIds.length === 0 || selectedCoreNodeIds.includes(volumeId)),
      },
      {
        itemConfig: {
          label: 'Remove from Tag',
          onClick: async () => {
            if (node === null) {
              return;
            }
            const nodeIds = isNodeSelected ? selectedNodeIds : [node.id];
            // The tag child may come from different tag containers, so we need to group them by
            // tag container ID. This happens when selecting multiple tags.
            const tagIds = new Map();
            nodeIds.forEach((volId) => {
              const parent = entityGroupData.groupMap.get(volId).parentId;
              if (!parent) {
                return;
              }
              if (!tagIds.has(parent)) {
                tagIds.set(parent, []);
              }
              tagIds.get(parent).push(geometryTags.domainFromTagEntityGroupId(volId));
            });
            tagIds.forEach(async (volIds, tagIdSpecific) => {
              await removeItemsFromTag(tagIdSpecific, [], volIds);
            });
            deselectNodeIds(nodeIds);
          },
          disabled: !!removeTagChildDisabledReason,
          disabledReason: removeTagChildDisabledReason,
        },
        shouldShow: taggingEnabled && isTagChildren,
      },
    ]),
  );

  const getContextMenuItems = () => {
    if (isGeometryView) {
      return contextMenuItemsForGeometryView;
    }

    const assignmentMenuItems = [];

    // in theory, `!!assignedPhysics` is sufficient, but the body of the conditional that branches
    // on `physicsIsAssigned` needs `assignedPhysicsId` and `staticVolume` to be defined, so
    // including those in this boolean value will narrow their types to defined ones inside those
    // conditionals. it's a convenience, not a correctness issue.
    const physicsIsAssigned = !!(assignedPhysics && assignedPhysicsId && staticVolume);

    if (!assignedMaterial && materialsMenuItems.length > 0) {
      assignmentMenuItems.push({
        label: 'Assign Material',
        items: materialsMenuItems,
      });
    }

    if (assignedMaterial) {
      const materialLocked = !!(assignedPhysics && assignedMaterial);
      const tooltip = getMaterialAssignmentTooltip(readOnly, !!assignedPhysics, !!assignedMaterial);
      assignmentMenuItems.push({
        label: `Unassign ${boldEscaped(getMaterialName(assignedMaterial, simParam))}`,
        help: materialLocked ? '' : tooltip,
        onClick: unassignMaterial,
        disabled: materialLocked,
        disabledReason: materialLocked ? tooltip : '',
      });
    }

    if (assignedMaterial && !physicsIsAssigned && physicsMenuItems.length > 0) {
      assignmentMenuItems.push({
        label: 'Assign Physics',
        items: physicsMenuItems,
      });
    }

    if (assignedMaterial && physicsIsAssigned) {
      const tooltip = getPhysicsAssignmentTooltip(readOnly, !!assignedPhysics, !!assignedMaterial);
      const unassignPhysicsDisabledReason = getVolumePhysicsAssignmentDisabledReason(
        assignedPhysicsId,
        staticVolume.domain,
      );
      assignmentMenuItems.push({
        label: `Unassign ${boldEscaped(getPhysicsName(assignedPhysics, simParam))}`,
        help: tooltip,
        onClick: unassignPhysics,
        disabled: !!unassignPhysicsDisabledReason,
        disabledReason: unassignPhysicsDisabledReason || tooltip,
      });
    }

    return assembleMenuSections(
      visibilityContextMenuItems,
      assignmentMenuItems,
    );
  };

  return {
    getContextMenuItems,
    isCreateTagDialogOpen,
    isCreateTagFacesDialogOpen,
    setIsCreateTagDialogOpen,
    setIsCreateTagFacesDialogOpen,
    staticVolume,
  };
};
