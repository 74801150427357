// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { createStyles, makeStyles } from '../../../Theme';

import { SvgIcon } from '@/components/Icon/SvgIcon';
import { useSelectionContext } from '@/components/context/SelectionManager';
import { useNodeSelect } from '@/components/hooks/useNodeSelect';
import { colors } from '@/lib/designSystem';
import { NodeType } from '@/lib/simulationTree/node';
import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';
import { useSetPropertiesPanelVisible } from '@/recoil/propertiesPanel';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      padding: '16px',
    },
    nodeLink: {
      minHeight: '28px',
      padding: '5px 12px 6px 24px',
      display: 'flex',
      gap: '8px',
      width: '100%',
      background: 'none',
      margin: '0',
      border: '0',
      borderTop: `1px solid ${colors.neutral250}`,
      alignItems: 'center',
      color: colors.highEmphasisText,
      cursor: 'pointer',

      '&:last-of-type': {
        borderBottom: `1px solid ${colors.neutral250}`,
      },

      '&:hover': {
        backgroundColor: colors.neutral250,
      },

      '&.active': {
        backgroundColor: colors.purple400,
      },

      '& > label': {
        flex: 1,
        textAlign: 'left',
        fontSize: '13px',
        cursor: 'inherit',
      },
    },

  }),
  { name: 'NestedNodeLinks' },
);

export type NestedNodeLink = {
  node_id: string,
  text: string,
  type: NodeType,
}
export type NestedNodeLinksData = { links: NestedNodeLink[] };

type NestedNodeLinksProps = {
  links: NestedNodeLink[];
}

export const NestedNodeLinks = (props: NestedNodeLinksProps) => {
  // == Props
  const { links } = props;

  // == Contexts
  const { setScrollTo, selectedNodeIds } = useSelectionContext();

  // == Hooks
  const classes = useStyles();
  const select = useNodeSelect();
  const setPropertiesPanelVisible = useSetPropertiesPanelVisible();

  return (
    <div className={classes.root}>
      {links.map((link) => {
        const icon = getNodeTypeIcon(link.type);

        return (
          <button
            className={cx(classes.nodeLink, {
              active: selectedNodeIds[0] === link.node_id,
            })}
            key={link.node_id}
            onClick={() => {
              setScrollTo({ node: link.node_id });
              select(link.node_id);
              setPropertiesPanelVisible(true);
            }}
            type="button">
            {icon && (
              <SvgIcon
                color={colors.primaryInteractive}
                maxHeight={12}
                maxWidth={12}
                name={icon.name}
              />
            )}
            <label>{link.text}</label>
            <SvgIcon
              color={colors.neutral700}
              maxHeight={10}
              name="chevronRight"
            />
          </button>
        );
      })}
    </div>
  );
};
