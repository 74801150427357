import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const TagIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="tag"
      fill="none"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        clipRule="evenodd"
        d="M0.75 2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H5.68819C6.08601 0.75 6.46754 0.908035
        6.74885 1.18934L10.8084 5.24885C11.3941 5.83463 11.3941 6.78438 10.8084 7.37017L7.37017
        10.8084C6.78438 11.3941 5.83463 11.3941 5.24885 10.8084L1.18934 6.74885C0.908035 6.46754
        0.75 6.08601 0.75 5.68819V2.25ZM3.36524 2.24454C2.74623 2.24454 2.24442 2.74635 2.24442
        3.36537C2.24442 3.98438 2.74623 4.48619 3.36524 4.48619C3.98426 4.48619 4.48607 3.98438
        4.48607 3.36537C4.48607 2.74635 3.98426 2.24454 3.36524 2.24454Z"
        fillRule="evenodd"
        style={{ fill: getColor(0) }}
      />

    </svg>
  );
});
