// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { CommonMenuListItem } from '../../lib/componentTypes/menu';
import { ConfigurableMaterialType } from '../../lib/materialUtils';
import { ConfigurablePhysicsType, getPhysicsId, getPhysicsName, getPhysicsTypeIconName } from '../../lib/physicsUtils';
import { usePhysicsSet } from '../../model/hooks/usePhysicsSet';
import { useVolume } from '../../model/hooks/useVolume';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useSimulationMaterials } from '../../state/external/project/simulation/param/materials';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { ModelData } from '../controls/ModelSelector';

export const useVolumeNode = (nodeId: string) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setScrollTo, setSelection } = useSelectionContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const materialData = useSimulationMaterials(projectId, workflowId, jobId);

  // == Hooks
  const {
    staticVolume,
    surfaceOrGroupIds,
    physics,
    fluidPhysics,
    heatPhysics,
    attachNewPorousModel,
    attachedPorousModelIds,
    attachPorousModel,
    detachPorousModel,

    attachNewHeatSource,
    attachedHeatSourceIds,
    attachHeatSource,
    detachHeatSource,

    assignedMaterialId,
    assignedMaterial,
    assignMaterial,
    assignNewMaterial,
    unassignMaterial,

    assignedPhysicsId,
    assignedPhysics,
    assignPhysics,
    assignNewPhysics,
    unassignPhysics,
    availablePhysicsTypes,
  } = useVolume(projectId, workflowId, jobId, readOnly, nodeId);
  const {
    allPhysics,
    getVolumePhysicsAssignmentDisabledReason,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);

  // Porous model options available in the ModelSelector
  const porousModelData = useMemo(() => {
    if (fluidPhysics) {
      return fluidPhysics.porousBehavior.map((model) => ({
        model,
        id: model.porousBehaviorId,
        label: model.porousBehaviorName,
      } as ModelData<simulationpb.PorousBehavior>));
    }
    return [];
  }, [fluidPhysics]);

  // Heat source options available in the ModelSelector
  const heatSourceData = useMemo(() => {
    if (physics && heatPhysics) {
      return heatPhysics.heatSource.map((model, i) => ({
        model,
        id: model.heatSourceId,
        label: model.heatSourceName,
      } as ModelData<simulationpb.HeatSource>));
    }
    return [];
  }, [heatPhysics, physics]);

  // Create a new porous model and attach this volume to it
  const handleNewPorousModel = async () => {
    const newId = await attachNewPorousModel();
    if (newId) {
      setSelection([newId]);
      setScrollTo({ node: newId });
    }
  };

  // Create a new heat source and attach this volume to it
  const handleNewHeatSource = async () => {
    const newId = await attachNewHeatSource();
    if (newId) {
      setSelection([newId]);
      setScrollTo({ node: newId });
    }
  };

  // Map materials to ModelData[] (used for ModelSelector) and return the result
  const materialsModelData = useMemo(() => materialData.map(({ id, name, model }) => ({
    model,
    id,
    label: name,
  }) as ModelData<simulationpb.MaterialEntity>), [materialData]);

  const materialsMenuItems = useMemo(() => materialData.map(({ name, model }) => ({
    label: name,
    onClick: () => assignMaterial(model),
  }) as CommonMenuListItem), [assignMaterial, materialData]);

  // Create a new material and assign this volume (domain) to it (used by ModelSelector)
  const createAndAssignMaterial = useCallback(async (type: ConfigurableMaterialType) => {
    const newId = await assignNewMaterial(type);
    if (newId) {
      setSelection([newId]);
      setScrollTo({ node: newId });
    }
  }, [assignNewMaterial, setScrollTo, setSelection]);

  // Create a new physics and assign this volume (domain) to it (used by ModelSelector)
  const createAndAssignPhysics = useCallback(async (type: ConfigurablePhysicsType) => {
    const newId = await assignNewPhysics(type);
    if (newId) {
      setSelection([newId]);
      setScrollTo({ node: newId });
    }
  }, [assignNewPhysics, setScrollTo, setSelection]);

  const physicsModelData = useMemo(() => {
    if (staticVolume) {
      return allPhysics.map((model) => {
        const id = getPhysicsId(model);
        const disabledReason = getVolumePhysicsAssignmentDisabledReason(id, staticVolume.domain);
        return {
          model,
          id: getPhysicsId(model),
          label: getPhysicsName(model, simParam),
          disabled: !!disabledReason,
          disabledReason,
          icon: { name: getPhysicsTypeIconName(model.params.case) },
        } as ModelData<simulationpb.Physics>;
      });
    }
    return [];
  }, [allPhysics, getVolumePhysicsAssignmentDisabledReason, simParam, staticVolume]);

  const physicsMenuItems = useMemo(() => {
    const menuItems: CommonMenuListItem[] = [];
    if (staticVolume) {
      allPhysics.forEach((item) => {
        const id = getPhysicsId(item);
        const disabledReason = getVolumePhysicsAssignmentDisabledReason(id, staticVolume.domain);
        menuItems.push({
          label: getPhysicsName(item, simParam),
          disabled: !!disabledReason,
          disabledReason,
          onClick: () => assignPhysics(item),
        });
      });
    }
    return menuItems;
  }, [allPhysics, assignPhysics, getVolumePhysicsAssignmentDisabledReason, simParam, staticVolume]);

  return {
    fluidPhysics,
    heatPhysics,
    staticVolume,
    handleNewPorousModel,
    porousModelData,
    attachPorousModel,
    detachPorousModel,
    attachedPorousModelIds,
    surfaceOrGroupIds,

    handleNewHeatSource,
    heatSourceData,
    attachHeatSource,
    detachHeatSource,
    attachedHeatSourceIds,

    assignedMaterialId,
    assignedMaterial,
    materialsModelData,
    materialsMenuItems,
    assignMaterial,
    createAndAssignMaterial,
    unassignMaterial,

    assignedPhysicsId,
    assignedPhysics,
    physicsModelData,
    physicsMenuItems,
    assignPhysics,
    createAndAssignPhysics,
    unassignPhysics,
    availablePhysicsTypes,
  };
};
