// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { isGroupVisible } from '../../../lib/entityGroupUtils';
import { assembleMenuSections } from '../../../lib/menuUtil';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem, visibilityToggleTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useCopySimAnnotation } from '../../hooks/nodeDuplication/useCopySimAnnotation';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';
import environmentState from '@/state/environment';

// A row in the simulation tree representing a probe point
export const ProbePointTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { viewState, visibilityMap } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const isSelected = selectedNodeIds.includes(node.id);
  const toggleIds = new Set(isSelected ? selectedNodeIds : [node.id]);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const isVisible = lcvisEnabled ? (
    isGroupVisible(visibilityV2, entityGroupMap, node.id)
  ) : (
    isGroupVisible(visibilityMap, entityGroupMap, node.id)
  );

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteProbePointNode, postDeleteNodeIds } = useNodeDeletion();
  const duplicateRow = useCopySimAnnotation();

  const visControlsDisabled = lcvisEnabled ? !lcvisReady : !viewState;
  const toggleVis = useToggleVisibility(toggleIds, isVisible);

  const deleteRow = useCallback(async () => {
    if (await deleteProbePointNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteProbePointNode, node.id, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => {
    const visItems = [visibilityToggleTreeNodeMenuItem(isVisible, toggleVis, visControlsDisabled)];

    const crudDisabled = !canDelete(node.type, node.id);
    const crudItems = [
      duplicateTreeNodeMenuItem(() => duplicateRow(node.id, 'point'), crudDisabled),
      deleteTreeNodeMenuItem(deleteRow, crudDisabled),
    ];

    return assembleMenuSections(visItems, crudItems);
  }, [
    isVisible,
    toggleVis,
    visControlsDisabled,
    canDelete,
    deleteRow,
    node.id,
    node.type,
    duplicateRow,
  ]);

  const visButton = (
    <VisibilityButton disabled={visControlsDisabled} isVisible={isVisible} onClick={toggleVis} />
  );

  return (
    <TreeRow
      {...props}
      canMultiSelect
      getContextMenuItems={getContextMenuItems}
      primaryIcon={getNodeTypeIcon(props.node.type)}
      propertiesControl
      renaming={renaming}
      visibilityButton={visButton}
    />
  );
};
