// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from 'react';

import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import useResizeObserver from '../../lib/useResizeObserver';
import { VIEWER_PADDING } from '../../lib/visUtils';
import { useDragSourceNode } from '../../recoil/lcvis/tagsDragAndDrop';
import { GeometryTreePositionType, useGeometryTreePosition } from '../../recoil/useGeometryTreePosition';
import { useLeftOverlayCardsWidth } from '../../state/internal/component/leftOverlayCards';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { GeometryProgress } from '../geometry/GeometryProgress';
import { useCanModifyTags } from '../hooks/useCanModifyTags';
import MeshingStatus from '../meshing/MeshingStatus';

import { ExplorationPanel } from './ExplorationPanel';
import GeometryHealth from './GeometryHealth';
import { GeometryTreePanel } from './GeometryTreePanel';
import { RunStatus } from './RunStatus';
import { TagDropZone } from './TagDropZone';
import { VisToolsPanel } from './VisToolsPanel';

const useStyles = makeStyles(
  () => createStyles({
    dropArea: {
      position: 'absolute',
      inset: 0,
      zIndex: 3,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: `${VIEWER_PADDING}px`,
      left: `${VIEWER_PADDING}px`,
      gap: `${VIEWER_PADDING}px`,
      zIndex: 3,
      overflow: 'auto',
      maxHeight: `calc(100% - ${VIEWER_PADDING}px)`,
    },
    nonTreeCards: {
      display: 'flex',
      flexDirection: 'column',
      gap: `${VIEWER_PADDING}px`,
    },
    dragger: {
      position: `absolute`,
      left: `${VIEWER_PADDING}px`,
      top: `${VIEWER_PADDING}px`,
      width: '8px',
      cursor: 'col-resize',
      zIndex: 4,
    },
  }),
  { name: 'LeftOverlayCards' },
);

/**
 * This is a floating panel that is shown on the left side of the 3D viewer.
 * It is used both in Paraview and LcVis and includes multiple cards that can be toggled or closed.
 */
export const LeftOverlayCards = () => {
  // == Hooks
  const classes = useStyles();
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const [width, setWidth] = useLeftOverlayCardsWidth();
  const geometryTreePosition = useGeometryTreePosition({ projectId, workflowId, jobId });
  const workflowFlag = useWorkflowFlagValue();
  const dragSourceNode = useDragSourceNode();

  // == Data
  const cardsRef = useRef<HTMLDivElement>(null);
  const cardsSize = useResizeObserver(cardsRef);
  const nonTreeCardsRef = useRef<HTMLDivElement>(null);
  const nonTreeCardsSize = useResizeObserver(nonTreeCardsRef);
  const [nonTreeCardsOffset, setNonTreeCardsOffset] = useState(0);
  const canModifyTags = useCanModifyTags();

  const showGeometryTree = geometryTreePosition === GeometryTreePositionType.FLOATING;

  // == Handlers
  const handleDrag = (event: DraggableEvent, ui: DraggableData) => {
    setWidth(ui.x);
  };

  useEffect(() => {
    setNonTreeCardsOffset(nonTreeCardsSize.height ? 0 : -VIEWER_PADDING);
  }, [nonTreeCardsSize.height]);

  // == Render
  return (
    <>
      {!!dragSourceNode && canModifyTags && (
        <TagDropZone className={classes.dropArea} />
      )}

      <div className={classes.root} ref={cardsRef} style={{ width }}>
        <div
          className={classes.nonTreeCards}
          ref={nonTreeCardsRef}
          style={{ marginBottom: nonTreeCardsOffset }}>
          <MeshingStatus />
          <GeometryProgress />
          <GeometryHealth />
          <ExplorationPanel />
          <RunStatus />
        </div>
        {showGeometryTree && (
          <GeometryTreePanel nonTreeCardsHeight={nonTreeCardsSize.height} />
        )}
        {!workflowFlag && <VisToolsPanel />}
      </div>
      <Draggable
        axis="x"
        // This is the minimum/maximum allowed width for the panel
        bounds={{ left: 220, right: 360 }}
        onDrag={handleDrag}
        position={{ x: width, y: 0 }}>
        <div className={classes.dragger} style={{ height: cardsSize.height }} />
      </Draggable>
    </>
  );
};
