// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useState } from 'react';

import { RadioButtonOption, SelectOption, SelectOptionGroup } from '../../../../lib/componentTypes/form';
import { FaultInfo } from '../../../../lib/inputValidationUtils';
import { clamp } from '../../../../lib/number';
import { updateTreeNodes } from '../../../../lib/paraviewUtils';
import { UrlType } from '../../../../proto/projectstate/projectstate_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useCustomFieldNodes } from '../../../../recoil/customFieldNodes';
import { useLcVisEnabledValue } from '../../../../recoil/lcvis/lcvisEnabledState';
import { isStatusPending, useLcvisFilterStatusValue } from '../../../../recoil/lcvis/lcvisFilterStatus';
import { useViewStateOverflow } from '../../../../recoil/lcvis/viewStateOverflow';
import { useMeshUrlState } from '../../../../recoil/meshState';
import { useEditState } from '../../../../recoil/paraviewState';
import { useFilterState } from '../../../../recoil/vis/filterState';
import { useIsAnalysisView } from '../../../../state/internal/global/currentView';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberInput } from '../../../Form/NumberInput';
import { RadioButtonGroup } from '../../../Form/RadioButtonGroup';
import { ValidNumberInput } from '../../../Form/ValidatedInputs/ValidNumberInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import DataComponentSelect, { LcvisDataComponent } from '../../../Paraview/DataComponentSelect';
import DataNameSelect from '../../../Paraview/DataNameSelect';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import { TextAdornment } from '../../../TextAdornment';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { NumberSliderCombo } from '../../../controls/NumberSliderCombo';
import PropertiesSection from '../../PropertiesSection';

const { STREAMLINES, SURFACE_L_I_C } = ParaviewRpc.TreeNodeType;
const { LINE_STREAMLINE, TUBE_STREAMLINE } = ParaviewRpc.StreamlineRendering;

interface FilterDisplayPanelProps {
  filterNode: ParaviewRpc.TreeNode;
  /** Functions to execute filter specific representation elements */
  // Surface LIC requires texture control
  updateFilterRepresentation?: (...args: any[]) => void;
}

export function defaultLineStreamlineParams():
  ParaviewRpc.LineStreamline {
  return {
    typ: LINE_STREAMLINE,
    width: 1.0,
  };
}
function defaultTubeStreamlineParams():
  ParaviewRpc.TubeStreamline {
  return {
    typ: TUBE_STREAMLINE,
    radius: 0.01,
  };
}

function defaultStreamlineRenderParams(type: ParaviewRpc.StreamlineRendering):
  ParaviewRpc.StreamlineRenderParams {
  switch (type) {
    case LINE_STREAMLINE:
      return defaultLineStreamlineParams();
    case TUBE_STREAMLINE:
      return defaultTubeStreamlineParams();
    default:
      throw Error('Invalid streamline rendering option.');
  }
}

// Panel for editing the display properties of a FilterTreeNode.
export const FilterDisplayPanel = (props: FilterDisplayPanelProps) => {
  const { filterNode } = props;
  const { projectId, workflowId, jobId } = useProjectContext();

  const { viewState, changeNodeDisplayProps, setViewStateDisplayVariable } = useParaviewContext();

  const [editState, setEditState] = useEditState();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [filterState, setFilterState] = useFilterState({ projectId, workflowId, jobId });
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const [meshUrlState] = useMeshUrlState(projectId);
  const [customFields] = useCustomFieldNodes(projectId);
  const isGeometry = meshUrlState.activeType === UrlType.GEOMETRY;
  const filterStatus = useLcvisFilterStatusValue();
  const filterInProgress = (
    lcvisEnabled ?
      isStatusPending(filterStatus.get(filterNode.id)?.status) : false
  );
  const isAnalysisView = useIsAnalysisView();
  // We want to disable the representation type dropdown when viewing geometry in the setup tab,
  // but keep it always enabled when viewing simulation results.
  const disableRepresentation = isGeometry && !isAnalysisView;

  const changeDisplayProps = useCallback((
    nodeId: string,
    newDisplayProps: ParaviewRpc.DisplayProps,
  ) => {
    if (lcvisEnabled) {
      setFilterState((oldFilterState) => {
        const newFilterState = updateTreeNodes(oldFilterState, (node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              displayProps: newDisplayProps,
            };
          }
          return node;
        });
        return newFilterState;
      });
    } else {
      changeNodeDisplayProps(nodeId, newDisplayProps);
    }
  }, [changeNodeDisplayProps, lcvisEnabled, setFilterState]);

  const onUpdate = useCallback((newDisplayProps: ParaviewRpc.DisplayProps) => {
    editState && setEditState({
      ...editState,
      displayProps: newDisplayProps,
    });
  }, [editState, setEditState]);

  const nodeId = filterNode.id;
  const initDisplayProps = (editState ? editState.displayProps : filterNode.displayProps);
  const displayProps = initDisplayProps as ParaviewRpc.DisplayProps;
  const hasNodeDisplayProps = !!(displayProps) && !editState;
  const hasViewAttrs = !!(viewState?.attrs);

  const activeEditParam = editState?.param ?? filterNode.param;
  const initSliderValues: [number, number] = (activeEditParam?.typ === SURFACE_L_I_C) ?
    (activeEditParam.textureContrastControl ?? [0, 0]) :
    [0, 0];
  const [sliderValue, setSliderValue] = useState<[number, number]>(initSliderValues);

  // The default empty option will be used only if there's no displayValue in the viewState.attrs.
  const emptyDisplayVariable: ParaviewRpc.DisplayPvVariable = {
    displayDataName: 'None', displayDataNameComponent: 0,
  };

  let initDisplayVariable = viewState?.attrs.displayVariable || emptyDisplayVariable;
  // Use the displayProps config if available. This restores any value that was previosly set.
  if (displayProps?.displayVariable) {
    initDisplayVariable = displayProps.displayVariable;
  }
  useEffect(() => {
    // If we are creating a new filter, we must update the initial display variable so
    // that it is not set to null, which causes it to track the global colorby setting
    if (editState?.displayProps.displayVariable === null) {
      onUpdate({
        ...displayProps,
        displayVariable: initDisplayVariable,
      });
    }
  }, [onUpdate, editState, displayProps, initDisplayProps, initDisplayVariable]);

  let reprType = 'Surface';
  // Use the displayProps config if available. Else, use the viewAttrs config.
  if (displayProps?.reprType) {
    reprType = displayProps.reprType;
  } else if (hasViewAttrs && viewState!.attrs!.reprType) {
    reprType = viewState!.attrs!.reprType;
  }

  const onRepresentationChanged = (newReprType: ParaviewRpc.RepresentationType) => {
    changeDisplayProps(
      nodeId,
      {
        ...displayProps,
        reprType: newReprType,
      },
    );
  };
  const hasData = viewState?.data?.length && viewState.attrs;

  // TODO: currently all filters have the same arrays as the data source, that may not be true
  // in the future. In that case we need to change stuff here. We are assuming that all the data
  // on the filters is currently the same as the data on the import dataset filter.
  const fieldOptions = (lcvisEnabled) ?
    lcvisData.data.map(({ name }) => name) :
    viewState?.data
      .filter(({ type }) => type === ParaviewRpc.FieldAssociation.POINT)
      .map(({ name }) => name) ?? [];

  const onUpdateDisplayDataName = (displayDataName: string) => {
    if (lcvisEnabled) {
      const newFilterState = updateTreeNodes(filterState, (node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            displayProps: {
              ...node.displayProps,
              displayVariable: {
                displayDataNameComponent: (
                  lcvisData.attrs.displayVariable?.displayDataNameComponent ?? 0
                ),
                displayDataName,
              },
            },
          };
        }
        return node;
      });
      setFilterState(newFilterState);
    } else {
      const newDisplayVariable: ParaviewRpc.DisplayPvVariable = {
        ...initDisplayVariable,
        displayDataName,
      };
      // displayDataNameComponent may be null when we are in the Mesh tab.
      if (!newDisplayVariable.displayDataNameComponent) {
        newDisplayVariable.displayDataNameComponent = 0;
      }
      onUpdate({
        ...displayProps,
        displayVariable: newDisplayVariable,
      });
      hasNodeDisplayProps && setViewStateDisplayVariable(newDisplayVariable, false, filterNode);
    }
  };

  const showDataNameSelect = lcvisEnabled ? (
    isAnalysisView && lcvisData.attrs && lcvisData.data.length
  ) : hasData && isAnalysisView;

  const dataNameSelect = (showDataNameSelect) ? (
    <DataNameSelect
      asBlock
      customFields={customFields}
      fullRow
      innerMenuPosition="left-down"
      innerMenuPositionTransform={{ left: -4 }}
      onChange={onUpdateDisplayDataName}
      options={fieldOptions}
      position="left-down"
      positionTransform={{ left: -3 }}
      value={initDisplayVariable.displayDataName}
    />
  ) : null;

  // TODO(matt): the lcvisDatacomponent only knows the main variable.
  // We need to give it knowledge of the filters.
  const dataComponentSelect = (lcvisEnabled) ?
    (
      <LcvisDataComponent
        filterNodeId={nodeId}
        fullRow
        kind="minimal"
        onChange={(component: number) => {
          const newFilterState = updateTreeNodes(filterState, (node) => {
            if (node.id === nodeId) {
              return {
                ...node,
                displayProps: {
                  ...node.displayProps,
                  displayVariable: {
                    displayDataNameComponent: component,
                    displayDataName: node.displayProps?.displayVariable?.displayDataName || 'None',
                  },
                },
              };
            }
            return node;
          });
          setFilterState(newFilterState);
        }}
        size="medium"
      />
    ) :
    (
      <DataComponentSelect
        displayVariable={initDisplayVariable}
        fullRow
        onChange={(component: number) => {
          const newDisplayVariable: ParaviewRpc.DisplayPvVariable = {
            ...initDisplayVariable,
            displayDataNameComponent: component,
          };
          onUpdate({
            ...displayProps,
            displayVariable: newDisplayVariable,
          });
          if (hasNodeDisplayProps) {
            setViewStateDisplayVariable(newDisplayVariable, true, filterNode);
          }
        }}
        viewState={viewState}
      />
    );

  const representations: ParaviewRpc.RepresentationType[] = [
    'Surface',
    'Surface With Edges',
    'Wireframe',
  ];
  if (!lcvisEnabled) {
    representations.unshift('Points');
  }
  const representationOptions: (
    SelectOption<ParaviewRpc.RepresentationType> |
    SelectOptionGroup<ParaviewRpc.RepresentationType>
  )[] =
    (
      representations.map((value) => ({
        name: value,
        value,
        selected: reprType === value,
      }))
    );
  const representationGroup = lcvisEnabled ? [{
    options: representationOptions,
  }] as SelectOptionGroup<ParaviewRpc.RepresentationType>[] : representationOptions;
  if (lcvisEnabled) {
    representationGroup.push({
      name: 'Show Colors',
      value: 'Wireframe', // This value is not used.
      toggleNotSelect: true,
      toggledTrue: displayProps?.showColors || false,
      onClick: () => {
        changeDisplayProps(nodeId, {
          ...displayProps,
          showColors: !displayProps.showColors,
        });
      },
      disabled: displayProps.reprType === 'Wireframe',
      disabledReason: 'Surfaces are hidden when wireframe is selected',
    });
  }

  const isOnlySurfaceRepresentationType = (
    activeEditParam?.typ === SURFACE_L_I_C ||
    activeEditParam?.typ === STREAMLINES
  );

  const streamlineRenderOptions: RadioButtonOption<string>[] = [{
    disabled: false,
    label: 'Line',
    value: LINE_STREAMLINE,
  }, {
    disabled: false,
    label: 'Tube',
    value: TUBE_STREAMLINE,
  }];

  const validateLineStreamline = useCallback((value: number): FaultInfo | undefined => {
    if (!Number.isInteger(value)) {
      return {
        type: 'error',
        message: 'Pixel width must be a whole number.',
      };
    }
    if (value < 1) {
      return {
        type: 'error',
        message: 'Pixel width must be greater than 0.',
      };
    }
    if (value > 10) {
      return {
        type: 'error',
        message: 'Pixel width must be in the range 1 to 10',
      };
    }
    return undefined;
  }, []);

  return (
    <>
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Display"
          nodeId={nodeId}
          panelName="display">
          {dataNameSelect}
          {dataComponentSelect}
          {!isOnlySurfaceRepresentationType && (
            <Form.LabeledInput
              label="Representation">
              <DataSelect
                asBlock
                disabled={disableRepresentation || filterInProgress}
                onChange={(newReprType: ParaviewRpc.RepresentationType) => {
                  if (lcvisEnabled) {
                    onUpdate({
                      ...displayProps,
                      reprType: newReprType,
                    });
                  }
                  hasNodeDisplayProps && onRepresentationChanged(newReprType);
                }}
                options={representationGroup}
                size="small"
              />
            </Form.LabeledInput>
          )}
          {(activeEditParam?.typ === SURFACE_L_I_C) && (
            <>
              <Form.LabeledInput
                help="Dark contrast control for adjusting the intensity transition gradient.
                Set a value between 0 and 100."
                label="Dark Contrast">
                <NumberSliderCombo
                  maximumValue={100}
                  minimumValue={0}
                  onChange={(newValue) => {
                    const currCtrlVal = activeEditParam.textureContrastControl ?? [0, 0];
                    // Ensure newValue is bounded between 0 and 100
                    const boundedValue = clamp(newValue, [0, 100]);
                    const newCtrlVal: [number, number] = [Math.floor(boundedValue), currCtrlVal[1]];
                    setSliderValue(newCtrlVal);
                  }}
                  onCommit={(newValue) => {
                    const currCtrlVal = activeEditParam.textureContrastControl ?? [0, 0];
                    const boundedValue = clamp(newValue, [0, 100]);
                    const newCtrlVal: [number, number] = [Math.floor(boundedValue), currCtrlVal[1]];
                    setSliderValue(newCtrlVal);
                    props.updateFilterRepresentation?.(newCtrlVal);
                  }}
                  step={1}
                  value={sliderValue[0]}
                />
              </Form.LabeledInput>
              <Form.LabeledInput
                help="Light contrast control for adjusting the intensity transition gradient.
                Set a value between 0 and 100."
                label="Light Contrast">
                <NumberSliderCombo
                  maximumValue={100}
                  minimumValue={0}
                  onChange={(newValue) => {
                    const currCtrlVal = activeEditParam.textureContrastControl ?? [0, 0];
                    const boundedValue = clamp(newValue, [0, 100]);
                    const newCtrlVal: [number, number] = [currCtrlVal[0], Math.floor(boundedValue)];
                    setSliderValue(newCtrlVal);
                  }}
                  onCommit={(newValue) => {
                    const currCtrlVal = activeEditParam.textureContrastControl ?? [0, 0];
                    const boundedValue = clamp(newValue, [0, 100]);
                    const newCtrlVal: [number, number] = [currCtrlVal[0], Math.floor(boundedValue)];
                    setSliderValue(newCtrlVal);
                    props.updateFilterRepresentation?.(newCtrlVal);
                  }}
                  step={1}
                  value={sliderValue[1]}
                />
              </Form.LabeledInput>
            </>
          )}
          {(activeEditParam?.typ === STREAMLINES) && !lcvisEnabled && (
            <>
              <Form.LabeledInput label="Rendering">
                <RadioButtonGroup
                  kind="secondary"
                  name="streamlinesRendering"
                  onChange={(type) => {
                    props.updateFilterRepresentation?.(defaultStreamlineRenderParams(
                      type as ParaviewRpc.StreamlineRendering,
                    ));
                  }}
                  options={streamlineRenderOptions}
                  value={activeEditParam.streamlineRenderParams!.typ}
                />
              </Form.LabeledInput>
              {(activeEditParam.streamlineRenderParams?.typ === LINE_STREAMLINE) && (
                <Form.LabeledInput
                  help="The width of the line in pixels. This must be a whole number greater than
                  or equal to 1."
                  label="Width">
                  <ValidNumberInput
                    asBlock
                    endAdornment={<TextAdornment label="px" />}
                    onCommit={(value) => {
                      if (validateLineStreamline(value)) {
                        return;
                      }
                      const newRenderParams: ParaviewRpc.LineStreamline = {
                        typ: LINE_STREAMLINE,
                        width: value,
                      };
                      props.updateFilterRepresentation?.(newRenderParams);
                    }}
                    size="small"
                    validate={validateLineStreamline}
                    value={(activeEditParam.streamlineRenderParams as
                      ParaviewRpc.LineStreamline).width}
                  />
                </Form.LabeledInput>
              )}
              {(activeEditParam.streamlineRenderParams?.typ === TUBE_STREAMLINE) && (
                <Form.LabeledInput label="Radius">
                  <NumberInput
                    asBlock
                    onCommit={(value) => {
                      const newRenderParams: ParaviewRpc.TubeStreamline = {
                        typ: TUBE_STREAMLINE,
                        radius: value,
                      };
                      props.updateFilterRepresentation?.(newRenderParams);
                    }}
                    quantityType={QuantityType.LENGTH}
                    size="small"
                    value={(activeEditParam.streamlineRenderParams as
                      ParaviewRpc.TubeStreamline).radius}
                  />
                </Form.LabeledInput>
              )}
            </>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </>
  );
};
