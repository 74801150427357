// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { validateExpressions } from '../../../../lib/derivedOutput';
import { DERIVED_DEPENDENCY_SUFFIXES, UNIDENTIFIED_OUTPUT_ID, findOutputNodeById } from '../../../../lib/outputNodeUtils';
import * as feoutputpb from '../../../../proto/frontend/output/output_pb';
import { useOutputNodes } from '../../../../recoil/outputNodes';
import { BaseExpressionPanel } from '../common/BaseExpressionPanel';

const INCLUDE_TO_SUFFIX = new Map<feoutputpb.OutputIncludes, string>();
DERIVED_DEPENDENCY_SUFFIXES.forEach((include, suffix) => {
  INCLUDE_TO_SUFFIX.set(include, suffix);
});
INCLUDE_TO_SUFFIX.set(feoutputpb.OutputIncludes.OUTPUT_INCLUDE_BASE, '');

function displayExpression(
  expressionElements: feoutputpb.ExpressionElement[],
  outputNodes: feoutputpb.OutputNodes,
): string {
  let expression = '';

  expressionElements.forEach((element) => {
    switch (element.elementType.case) {
      case 'dependency': {
        const dep = element.elementType.value;
        const node = findOutputNodeById(outputNodes, dep.id);
        const dependencyName = node ? node.name : UNIDENTIFIED_OUTPUT_ID;
        expression += `"${dependencyName}${INCLUDE_TO_SUFFIX.get(dep.include)}"`;
      } break;
      case 'substring':
        expression += `${element.elementType.value}`;
        break;
      default:
        throw Error('Unrecognized expression element type case.');
    }
  });
  return expression;
}

// Custom hook for updating expressions
const useValidateExpressions = (projectId: string) => {
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, '', '');
  return useCallback((expressions: Map<string, string>) => {
    validateExpressions(outputNodes, setOutputNodes, projectId, expressions);
  }, [projectId, outputNodes, setOutputNodes]);
};

export interface ExpressionPanelProps {
  outputNode: feoutputpb.OutputNode;
  outputNodes: feoutputpb.OutputNodes;
  projectId: string;
  error?: boolean;
}

export function ExpressionPanel(props: ExpressionPanelProps) {
  const { error, outputNode, outputNodes, projectId } = props;

  const updateExpression = useValidateExpressions(projectId);

  const options = useMemo(
    () => outputNodes.nodes.reduce((acc, node) => {
      if (node.type !== feoutputpb.OutputNode_Type.GLOBAL_OUTPUT_TYPE) {
        acc.push(node.name);
        const timeAverage = !!node.include[feoutputpb.OutputIncludes.OUTPUT_INCLUDE_TIME_AVERAGE];
        if (timeAverage) {
          acc.push(`${node.name} - Average`);
        }
        if (node.nodeProps.case === 'force') {
          acc.push(`${node.name} - Coefficient`);
          if (timeAverage) {
            acc.push(`${node.name} - Coefficient Average`);
          }
        }
      }
      return acc;
    }, [] as string[]),
    [outputNodes],
  );

  const elements = outputNode.nodeProps.case === 'derived' ?
    outputNode.nodeProps.value.elements : [];
  const expression = displayExpression(elements, outputNodes);

  const handleUpdateExpression = (newExpression: string, id: string) => {
    updateExpression(new Map<string, string>().set(id, newExpression));
  };

  return (
    <BaseExpressionPanel
      error={error}
      expression={expression}
      expressionId={outputNode.id}
      onUpdateExpression={handleUpdateExpression}
      options={options}
      placeholderText={'Use existing outputs, math operators, and numeric values to create an' +
        ' expression.\nExample: max(0.0, "Friction Coefficient")'}
      tooltipText={'Define a custom output using an expression with other outputs in quotation ' +
        'marks.'}
    />
  );
}
