// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useEffect, useMemo } from 'react';

import { useRecoilCallback } from 'recoil';

import { assignDomainsToMaterial, assignDomainsToPhysics } from '../../lib/entityRelationships';
import { useWorkflowConfig } from '../../model/hooks/useWorkflowConfig';
import { addPhysicsResidualsCallback } from '../../recoil/addPhysicsResidualNode';
import { useHasImportedModel, useSetImportedModel } from '../../recoil/importedModelState';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useStoppingConditions } from '../../recoil/useStoppingConditions';
import { useStaticVolumes } from '../../recoil/volumes';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';

/**
 * This hook assigns volumes to the automatically created Standard Air
 * material and Fluid Flow Physics.
 *
 * A useEffect hook monitors when volumes are ready and checks if only
 * automatically created materials are present. If both conditions are met,
 * it proceeds with the assignment.
 */
export const useDefaultVolumesAssignment = (enabled: boolean) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, false);
  const isGeometryView = useIsGeometryView();
  const staticVolumes = useStaticVolumes(projectId);
  const hasImportedModel = useHasImportedModel();
  const setHasImportedModel = useSetImportedModel();
  const addResidualNodes = useRecoilCallback(addPhysicsResidualsCallback);
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const [stoppingConditions] = useStoppingConditions(projectId, workflowId, jobId);

  const createdMaterialId = useMemo(() => {
    const foundEntities = simParam.materialEntity.filter(
      ({ material }) => material.case === 'materialFluid',
    );

    if (foundEntities.length !== 1) {
      return '';
    }

    const [firstEntity] = foundEntities;

    return firstEntity.materialIdentifier?.id ?? '';
  }, [simParam.materialEntity]);

  const createdFluidPhysics = useMemo(() => {
    const foundPhysics = simParam.physics.filter(({ params }) => params.case === 'fluid');

    if (foundPhysics.length !== 1) {
      return '';
    }

    const [firstPhysics] = foundPhysics;

    return firstPhysics;
  }, [simParam.physics]);

  const assignVolumes = useCallback(() => {
    if (staticVolumes.length === 0 || !hasImportedModel || !enabled || isGeometryView) {
      return;
    }

    setHasImportedModel(false);

    // Avoid adding residuals and stopping conditions if they already exist. This happens when
    // loading to setup multiple times since the backend may preserve the state.
    const hasResidualsInOutputs = outputNodes.nodes.some((node) => (
      node.nodeProps.case === 'residual'
    ));
    const hasResidualsInStopConds = stoppingConditions.cond.some((cond) => (
      cond.node?.nodeProps.case === 'residual'
    ));
    const hasResiduals = hasResidualsInOutputs && hasResidualsInStopConds;
    const volumeDomains = new Set(staticVolumes.map(({ domain }) => domain));

    saveParamAsync((newParam) => {
      // The UI prevents the user from assigning physics until materials are assigned.
      // However, we handle both simultaneously here, and that's not an issue.

      const createdFluidPhysicsId = createdFluidPhysics ?
        createdFluidPhysics.physicsIdentifier?.id ?? '' :
        '';

      assignDomainsToMaterial(newParam, volumeDomains, createdMaterialId);
      assignDomainsToPhysics(newParam, volumeDomains, createdFluidPhysicsId);
    }).then(() => {
      if (!createdFluidPhysics || hasResiduals) {
        return;
      }

      addResidualNodes([createdFluidPhysics], projectId, workflowId, jobId).catch(() => { });
    }).catch(() => { });
  }, [
    createdFluidPhysics,
    createdMaterialId,
    enabled,
    hasImportedModel,
    saveParamAsync,
    setHasImportedModel,
    staticVolumes,
    addResidualNodes,
    workflowId,
    projectId,
    jobId,
    outputNodes.nodes,
    stoppingConditions.cond,
    isGeometryView,
  ]);

  useEffect(assignVolumes, [assignVolumes]);
};
