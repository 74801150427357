import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { isAssistantResponding } from '../../../lib/assistant/assistantCall';
import { colors } from '../../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../../lib/event';
import { useResetAssistantChat } from '../../../state/external/assistant/assistantMessages';
import { useAssistantSideRailCollapsed } from '../../../state/internal/assistant/assistantSideRailCollapsed';
import { pushConfirmation, useSetConfirmations } from '../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../Button/ActionButton';
import { createStyles, makeStyles } from '../../Theme';
import { FolderTriangle } from '../../controls/FolderTriangle';
import { FixedSizeWrapper } from '../../layout/FixedSizeWrapper';
import { ResetIcon } from '../../svg/ResetIcon';
import { SparkleDoubleIcon } from '../../svg/SparkleDoubleIcon';

import { AssistantChat } from './AssistantChat';
import { MacroSubscriber } from './MacroSubscriber';

export const COLLAPSED_WIDTH = 36;
export const EXPANDED_WIDTH = 350;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '100%',
      borderLeft: `1px solid ${colors.neutral50}`,
      width: `${EXPANDED_WIDTH}px`,
      transition: 'width 0.3s',
      flex: '0 0 auto',
      display: 'flex',
      flexDirection: 'column',

      '&.collapsed': {
        width: `${COLLAPSED_WIDTH}px`,
        '&:hover': {
          cursor: 'w-resize',
        },
      },
    },

    header: {
      height: '36px',
      display: 'flex',
      justifyContent: 'space-between',
      // 4 top and bottom, 18 left, 10 right to account for reset button padding
      padding: '4px 10px 4px 18px',
      alignItems: 'center',
      flex: '0 0 auto',
      borderBottom: `1px solid ${colors.neutral50}`,
      zIndex: 1,

      '&.collapsed': {
        justifyContent: 'center',
        padding: '4px 18px',
      },
    },
    headerGroup: {
      display: 'flex',
      gap: '6px',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: '13px',
      color: colors.highEmphasisText,

      '&:first-child': {
        flexGrow: 1,
        ':not(.collapsed) &:hover': {
          cursor: 'e-resize',
        },
      },
    },
    body: {
      overflow: 'auto',
      flex: '1 1 auto',
      // This is a hack to make the chat scrollable while also preventing the entire chat
      // from expanding beyond the viewport height. The 94px is a magic number to match the
      // PageHeader and chat header.
      maxHeight: 'calc(100vh - 94px)',

      '&.collapsed': {
        display: 'none',
      },
    },
  }),
  { name: 'SideRail' },
);

/**
 * A rail component on the right of the screen which holds the assistant chat. It's collapsible.
 */
export const SideRail = () => {
  // == Hooks
  const classes = useStyles();

  // == State
  const [collapsed, setCollapsed] = useAssistantSideRailCollapsed();
  const setConfirmStack = useSetConfirmations();
  const resetAssistantChat = useResetAssistantChat();

  // == Internal state
  const [isResponding, setIsResponding] = useState(false);

  useEffect(() => {
    const subscription = isAssistantResponding.subscribe((value) => {
      setIsResponding(value);
    });
    return () => subscription.unsubscribe();
  }, []);

  const toggleRail = () => {
    setCollapsed(!collapsed);
  };

  const onClickReset = () => {
    pushConfirmation(setConfirmStack, {
      title: 'Are you sure you want to reset your chat?',
      subtitle: `Resetting your chat will remove your chat history and
            Lumi AI will no longer have context to your existing conversation.
            Are you sure you want to proceed?`,
      onContinue: async () => {
        try {
          await resetAssistantChat();
          console.log('Chat reset successfully');
        } catch (error) {
          console.error('Failed to reset chat:', error);
        }
      },
      destructive: true,
    });
  };

  const icon = (
    <FixedSizeWrapper height={12} width={12}>
      <SparkleDoubleIcon color={colors.purple800} />
    </FixedSizeWrapper>
  );

  return (
    <>
      <div
        className={cx(classes.root, { collapsed })}
        onClick={() => {
          if (collapsed) {
            toggleRail();
          }
        }}
        onKeyUp={(event) => {
          if (collapsed && isUnmodifiedSpaceKey(event)) {
            toggleRail();
          }
        }}
        role="button"
        tabIndex={0}>
        <div className={cx(classes.header, { collapsed })}>
          {collapsed && icon}
          {!collapsed && (
            <>
              <div
                className={classes.headerGroup}
                onClick={() => toggleRail()}
                onKeyUp={(event) => {
                  if (isUnmodifiedSpaceKey(event)) {
                    toggleRail();
                  }
                }}
                role="button"
                tabIndex={0}>
                <FolderTriangle open={!collapsed} />
                {icon}
                <div>Assistant</div>
              </div>
              <div className={classes.headerGroup}>
                <ActionButton
                  disabled={isResponding}
                  kind="minimal"
                  onClick={onClickReset}
                  size="small">
                  <FixedSizeWrapper height={12} width={12}>
                    <ResetIcon />
                  </FixedSizeWrapper>
                  <div style={{ fontSize: '13px' }}>Reset</div>
                </ActionButton>
              </div>
            </>
          )}
        </div>
        <div className={cx(classes.body, { collapsed })}>
          <AssistantChat />
        </div>
      </div>
      <MacroSubscriber />
    </>
  );
};
