// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { colors } from '../../lib/designSystem';
import { getWorkorderStepMetadata } from '../../lib/meshingStatusUtils';
import { progressFraction } from '../../lib/progressInfoUtils';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { useMeshUrlState } from '../../recoil/meshState';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { ActionButton } from '../Button/ActionButton';
import { SummaryPanel } from '../Panel/SummaryPanel';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { useProjectContext } from '../context/ProjectContext';
import { DiskInfoIcon } from '../svg/DiskInfoIcon';
import { LoadingEllipsis } from '../visual/LoadingEllipsis';
import { ProgressMessage, ProgressMessageProps } from '../visual/ProgressMessage';

const useStyles = makeStyles(() => createStyles({
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  stepProgressMessages: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '12px',
  },
  tooltip: {
    display: 'flex',
    marginRight: '12px',
  },
}), { name: 'MeshingStatusSummary' });

export interface MeshingStatusSummaryProps {
  pending: frontendpb.PendingWorkOrder,
  type: frontendpb.WorkOrderType,
  // Cancels the current work order.
  cancel: () => void;
}

/**
 * This panel displays the status of a pending work order. Each work order consists of a set of
 * steps with one step active and the remaining steps queued.
 */
export const MeshingStatusSummary = (props: MeshingStatusSummaryProps) => {
  const { projectId } = useProjectContext();
  const { cancel, pending, type } = props;
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [meshUrlState] = useMeshUrlState(projectId);
  const isGeometryView = useIsGeometryView();

  const [selectedGeometry] = useSelectedGeometry(projectId);
  const isIgeoProject = !!selectedGeometry.geometryId;

  const isInteractiveGeometry = selectedGeometry.geometryId !== '';

  // Determine the heading, summary, tooltip, and list of steps.
  const {
    heading = '',
    summary = '',
    tooltip = '',
    stepMessages = [],
    currentStepIndex = 0,
  } = getWorkorderStepMetadata(pending, type, meshUrlState, isInteractiveGeometry, isGeometryView);

  const stepProps: ProgressMessageProps[] = stepMessages.map((name, index) => {
    const size = stepMessages.length;
    return {
      backgroundColor: colors.surfaceLight2,
      message: name,
      progress: 0,
      details: ['Queued'],
      stepCount: size > 1 ? `Step ${index + 1}/${size}` : '',
    };
  });
  stepProps.splice(0, currentStepIndex);

  // Copy the progress information in to the first step properties. The rest of the steps are
  // queued.
  const progressInfo = pending.progressInfo;
  if (progressInfo) {
    const details = progressInfo.details || '';
    stepProps[0].details = details.split('\n');
    stepProps[0].progress = progressFraction(progressInfo) ?? null;
  }
  const stepProgressMessages = stepProps.map((messageProps) => (
    <ProgressMessage key={messageProps.message} {...messageProps} />
  ));

  const cancelButton = pending.workOrderId && (
    <div className={classes.action}>
      <ActionButton
        kind="cancel"
        name="summaryCancelButton"
        onClick={cancel}
        size="small">
        {stepProps.length > 1 ? 'Cancel All' : 'Cancel'}
      </ActionButton>
    </div>
  );

  return (
    <SummaryPanel
      collapsed={collapsed}
      headerRight={tooltip ? (
        <Tooltip title={tooltip}>
          <div className={classes.tooltip}><DiskInfoIcon maxWidth={12} /></div>
        </Tooltip>
      ) : undefined}
      heading={collapsed ? <>{heading}<LoadingEllipsis /></> : heading}
      onToggle={() => setCollapsed(!collapsed)}
      summary={summary ? <>{summary}</> : undefined}>
      <div className={classes.stepProgressMessages}>
        {stepProgressMessages}
        {(!isIgeoProject || type === frontendpb.WorkOrderType.GET_MESH) && cancelButton}
      </div>
    </SummaryPanel>
  );
};
