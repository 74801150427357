import React, { useState } from 'react';

import cx from 'classnames';

import { IconName } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { SampleProjectCategory } from '../../lib/sampleProjects';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '336px',
      overflow: 'auto',
    },
    container: {
      display: 'grid',
      alignItems: 'stretch',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '24px 16px',
      height: 'max-content',
    },
    item: {
      '--details-opacity': '1',

      borderRadius: '4px',
      backgroundColor: colors.neutral100,
      boxShadow: '0px 4px 20px 0px #0C13204D',
      padding: '16px',
      border: '1.4px solid transparent',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      overflow: 'hidden',
      position: 'relative',

      '&[disabled]': {
        '&, &:hover': {
          '--details-opacity': '0.7',
          backgroundColor: colors.neutral250,
        },
      },

      '&:not(&[disabled])': {
        cursor: 'pointer',
      },

      '&:not(.selected):hover': {
        backgroundColor: colors.neutral50,
      },

      '&.light': {
        backgroundColor: colors.neutral250,

        '&:not(.selected):hover': {
          backgroundColor: colors.neutral200,
        },
      },

      '&.selected': {
        borderColor: '#9A89ED',
      },
    },
    details: {
      marginTop: '4px',
      padding: '0 4px',
      display: 'flex',
      gap: '2px',
      flexDirection: 'column',
      opacity: 'var(--details-opacity)',
    },
    title: {
      color: colors.neutral900,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
    },
    teaser: {
      color: colors.neutral800,
      fontSize: '12px',
      lineHeight: '16px',
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    comingSoon: {
      padding: '2px 10px',
      borderRadius: '16px',
      backgroundColor: colors.purple500,
      fontSize: '10px',
      letterSpacing: '0.3px',
      fontWeight: '600',
      color: colors.purple900,
      textTransform: 'uppercase',
      position: 'absolute',
      top: '16px',
      right: '16px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconWrapper: {
      margin: '-16px',
      marginBottom: '0',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
      padding: '16px',
      paddingBottom: '0',

      '&.image': {
        padding: '0',
        height: '128px',
        maxHeight: '128px',
        justifyContent: 'center',
        flex: '1 1 auto',
      },
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
  { name: 'SampleProjectsMenu' },
);

interface SampleProjectItemBase {
  projectId: string;
  projectDescription: string;
  projectName: string;
  documentationUrl: string;
  category?: SampleProjectCategory;
  teaser?: string;
  comingSoon?: boolean;
}

type SampleProjectItem = SampleProjectItemBase & ({ imageUrl: string } | { iconName?: string });

interface SampleProjectsMenuProps {
  sampleProjects: SampleProjectItem[];
  selectedId: string;
  onSelectedIdChange: (id: string) => void;
  disabled?: boolean;
}

export function SampleProjectsMenu({
  sampleProjects,
  selectedId,
  onSelectedIdChange,
  disabled = false,
}: SampleProjectsMenuProps) {
  const classes = useStyles();
  const [hoveredItemId, setHoveredItemId] = useState('');

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {sampleProjects.map((project) => {
          const image = 'imageUrl' in project;
          const selected = selectedId === project.projectId;
          const hovered = hoveredItemId === project.projectId;

          let iconColor = colors.neutral650;

          if (project.comingSoon) {
            iconColor = colors.neutral400;
          } else if (selected || hovered) {
            iconColor = colors.neutral800;
          }

          return (
            <button
              className={cx(classes.item, { selected, light: image })}
              disabled={disabled || project.comingSoon}
              key={project.projectId}
              onBlur={() => { }}
              onClick={() => onSelectedIdChange(project.projectId)}
              onFocus={() => { }}
              onMouseOut={() => {
                setHoveredItemId('');
              }}
              onMouseOver={() => {
                setHoveredItemId(project.projectId);
              }}
              type="button">
              {project.comingSoon && (
                <div className={cx(classes.comingSoon)}>
                  Coming Soon
                </div>
              )}
              <div className={cx(classes.iconWrapper, { image })}>
                {'imageUrl' in project ? (
                  <img
                    alt={project.projectName}
                    className={cx(classes.image)}
                    crossOrigin="anonymous"
                    src={project.imageUrl}
                  />
                ) : (
                  <SvgIcon
                    color={iconColor}
                    maxHeight={48}
                    maxWidth={48}
                    name={project.iconName as IconName}
                  />
                )}
              </div>
              <div className={classes.details}>
                <div className={classes.title}>{project.projectName}</div>
                {project.teaser && (
                  <div className={classes.teaser} title={project.teaser}>{project.teaser}</div>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}
