// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from 'react';

import { Descendant, createEditor } from 'slate';
import { ReactEditor, withReact } from 'slate-react';

import { colors } from '../../../../lib/designSystem';
import { AutocompleteEditor, MATH_FUNCTIONS } from '../../../../lib/expressionInput/AutocompleteEditor';
import { moveCursorToEnd, setEditorValue } from '../../../../lib/expressionInput/util';
import { ExpressionInput } from '../../../ExpressionInput/ExpressionInput';
import { makeStyles } from '../../../Theme';
import { useCommonMultiInputLines } from '../../../Theme/commonStyles';
import Tooltip from '../../../Tooltip';
import { EditButtons } from '../../../controls/EditButtons';

const useStyles = makeStyles(() => ({
  headingLayout: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  sectionHeading: {
    fontSize: '13px',
    fontWeight: 600,
    color: colors.neutral650,
  },
}), { name: 'ExpressionInput' });

export interface BaseExpressionPanelProps {
  error?: boolean;
  expression: string;
  options: string[];
  tooltipText: string;
  placeholderText: string;
  onUpdateExpression: (expression: string, id: string) => void;
  expressionId: string;
}

export function BaseExpressionPanel(props: BaseExpressionPanelProps) {
  const {
    error,
    expression,
    options,
    tooltipText,
    placeholderText,
    onUpdateExpression,
    expressionId,
  } = props;

  const multiInputClasses = useCommonMultiInputLines();
  const classes = useStyles();

  const [editor] = useState(() => AutocompleteEditor.withAutocomplete(withReact(createEditor())));
  const [isEditing, setIsEditing] = useState(false);
  const preEditState = useRef<Descendant[]>(editor.children);
  const [localExpression, setLocalExpression] = useState(expression);

  useEffect(() => {
    if (isEditing) {
      return;
    }
    const value = AutocompleteEditor.deserialize(localExpression, options, MATH_FUNCTIONS);
    setEditorValue(editor, value);
  }, [editor, options, localExpression, isEditing]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    if (isEditing) {
      ReactEditor.focus(editor);
    } else {
      ReactEditor.blur(editor);
    }
  }, [isEditing, editor]);

  const onStart = () => {
    setIsEditing(true);
    preEditState.current = editor.children;
    moveCursorToEnd(editor);
  };

  const onSave = () => {
    const newExpression = AutocompleteEditor.serialize(editor);
    setLocalExpression(newExpression);
    onUpdateExpression(newExpression, expressionId);
    setIsEditing(false);
  };

  const onCancel = () => {
    setIsEditing(false);
    editor.children = preEditState.current;
  };

  return (
    <div className={multiInputClasses.root}>
      <div className={classes.headingLayout}>
        <Tooltip title={tooltipText}>
          <div className={classes.sectionHeading}>Expression</div>
        </Tooltip>
        <EditButtons
          editMode={isEditing}
          onCancel={onCancel}
          onSave={onSave}
          onStartEdit={onStart}
        />
      </div>
      <ExpressionInput
        editor={editor}
        faultType={error ? 'error' : undefined}
        functions={MATH_FUNCTIONS}
        minHeight={100}
        options={options}
        placeholder={placeholderText}
        readOnly={!isEditing}
      />
    </div>
  );
}
