// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 17;
const baseHeight = 16;
export const CompassIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="compass"
      fill="none"
      height={finalHeight}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.16665 2.10002C4.90817 2.10002 2.26665 4.74154 2.26665
             8.00002C2.26665 11.2585 4.90817 13.9 8.16665 13.9C11.4251
             13.9 14.0667 11.2585 14.0667 8.00002C14.0667 4.74154 11.4251
             2.10002 8.16665 2.10002ZM1.06665 8.00002C1.06665 4.0788 4.24543
             0.900024 8.16665 0.900024C12.0879 0.900024 15.2667 4.0788 15.2667
             8.00002C15.2667 11.9212 12.0879 15.1 8.16665 15.1C4.24543 15.1
             1.06665 11.9212 1.06665 8.00002ZM11.5909 4.57576C11.7678 4.75261
             11.8164 5.02062 11.7129 5.24831L9.83787 9.37331C9.77784 9.50537
             9.672 9.61122 9.53993 9.67124L5.41493 11.5462C5.18724 11.6497
             4.91924 11.6011 4.74239 11.4243C4.56553 11.2474 4.51694 10.9794
             4.62043 10.7517L6.49543 6.62674C6.55546 6.49468 6.6613 6.38883
             6.79337 6.3288L10.9184 4.4538C11.1461 4.35031 11.4141 4.39891
             11.5909 4.57576ZM7.49476 7.32814L6.37495 9.79172L8.83854
             8.67191L9.95835 6.20833L7.49476 7.32814Z"
        style={{ fill: getColor(0), fillRule: 'evenodd', clipRule: 'evenodd' }}
      />
    </svg>
  );
});
