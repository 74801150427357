import React from 'react';

import { useNavigate } from 'react-router-dom';

import { SvgIcon } from '@/components/Icon/SvgIcon';
import { useProjectContext } from '@/components/context/ProjectContext';
import { Dialog } from '@/components/dialog/Base';
import { colors } from '@/lib/designSystem';
import { routes } from '@/lib/navigation';
import { useSetLcVisEnabledState } from '@/recoil/lcvis/lcvisEnabledState';
import { useSetLcvisMemoryExceeded } from '@/state/external/lcvis/lcvisMemoryExceeded';

export function MemoryExceededOverlay() {
  const { projectId } = useProjectContext();
  const navigate = useNavigate();
  const setLcvisEnabledState = useSetLcVisEnabledState(projectId);
  const setLcvisMemoryExceeded = useSetLcvisMemoryExceeded(projectId);

  const switchToSSR = () => {
    setLcvisEnabledState(false);

    // Reset the flag to allow the user to return to LCVis after tweaking or changing
    // some configuration with PV.
    setLcvisMemoryExceeded(false);
  };

  return (
    <Dialog
      cancelButton={{ label: 'Close project', kind: 'secondary' }}
      continueButton={{ label: 'Switch to server-side rendering' }}
      hasCloseButton={false}
      modal
      onClose={() => {
        navigate(routes.projectList);
      }}
      onContinue={switchToSSR}
      open
      subtitle={(
        <div style={{ paddingLeft: '25px' }}>
          The current processes have exceeded the client-side rendering limit.<br />
          Switch to server-side rendering to continue.
        </div>
      )}
      title={(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <SvgIcon color={colors.red600} maxWidth={18} name="diskExclamation" />
          Client-side rendering limit exceeded
        </div>
      )}
      width="560px"
    />
  );
}
