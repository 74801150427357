// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { AddCameraButton } from '../AddCameraButton';
import { TreeRow } from '../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const CameraContainerTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    addControl={<AddCameraButton />}
    primaryIcon={getNodeTypeIcon(props.node.type)}
  />
);
