// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect } from 'react';

import cx from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { CurrentView } from '../../lib/componentTypes/context';
import { getJobStatusIconProps, getJobStatusIconType } from '../../lib/jobUtils';
import { projectLink } from '../../lib/navigation';
import { useStoppingConditionStatus } from '../../model/hooks/useStoppingConditionStatus';
import { useEditStateValue } from '../../recoil/paraviewState';
import { useControlPanelMode } from '../../recoil/useProjectPage';
import { TreeViewType, useTreeViewType } from '../../recoil/useTreeViewState';
import { useWorkflowState } from '../../recoil/workflowState';
import { useIsRunStatusVisible } from '../../state/internal/component/isRunStatusVisible';
import { useCurrentView } from '../../state/internal/global/currentView';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { IconButton } from '../Button/IconButton';
import { DataSelect } from '../Form/DataSelect';
import { SvgIcon } from '../Icon/SvgIcon';
import RightPaneSwitcher from '../Pane/RightPaneSwitcher';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { useProjectContext } from '../context/ProjectContext';
import { useIsExplorationSetup } from '../hooks/exploration/useCreateExploration';
import { useCancelEdit } from '../hooks/useCancelEdit';
import { DuplicateIcon } from '../svg/DuplicateIcon';
import SimulationTreeDeleteButton from '../treePanel/SimulationTreeDeleteButton';
import SimulationTreeMoreMenu from '../treePanel/SimulationTreeMoreMenu';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 0,
      // If shrinking is not prevented, the container will change in height when in the simulation
      // tab due to the rightPane's fullWidth
      flexShrink: 0,
    },
    leftControls: {
      paddingLeft: '8px',

      // In the Setup page, element must grow so that the Tabs' bottom border contain the full width
      '&.fullWidth': {
        paddingLeft: 0,
        flex: 1,
        zIndex: 1,
      },
    },
    paneSwitcher: {
      '& > *': {
        paddingLeft: '1px',
      },
    },
    rightControls: {
      zIndex: 2,
      display: 'flex',
      gap: '4px',
      position: 'absolute',
      right: '8px',
    },
  }),
  { name: 'RightPaneTopControls' },
);

interface RightPaneTopControlsProps {
  height: number;
}

const RightPaneTopControls = (props: RightPaneTopControlsProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [controlPanelMode] = useControlPanelMode();
  const { projectId, workflowId, jobId, copyToSetup } = useProjectContext();
  const currentView = useCurrentView();
  const [treeViewType, setTreeViewType] = useTreeViewType();
  const editState = useEditStateValue();
  const workflow = useWorkflowState(projectId, workflowId);
  const stopStatus = useStoppingConditionStatus(projectId, workflowId, jobId);
  const job = workflow?.job[jobId]!;
  const cancelEdit = useCancelEdit();
  const isExplorationSetup = useIsExplorationSetup();
  const workflowFlag = useWorkflowFlagValue();

  const [
    isRunStatusVisible,
    setIsRunStatusVisible,
  ] = useIsRunStatusVisible({ projectId, workflowId, jobId });

  const setupView = (currentView === CurrentView.SETUP);
  const analysisView = (currentView === CurrentView.ANALYSIS);

  const jobStatusIconType = getJobStatusIconType(job, stopStatus);
  const showRunStatusButton = job && jobStatusIconType && !isExplorationSetup;
  const showCopyToSetupButton = (
    analysisView &&
    treeViewType === TreeViewType.SETUP &&
    controlPanelMode !== 'exploration'
  );

  useEffect(() => {
    setTreeViewType(TreeViewType.POST_PROCESSING);
  }, [location, setTreeViewType]);

  return (
    <div className={classes.root} style={{ height: props.height }}>
      <div className={cx(classes.leftControls, { fullWidth: setupView })}>
        {!workflowFlag && setupView && (
          <div className={classes.paneSwitcher}><RightPaneSwitcher /></div>
        )}
        {analysisView && !isExplorationSetup && (
          <DataSelect
            asBlock
            kind="minimal"
            locator="treeViewTypeDropdown"
            onChange={(mode) => {
              // Make sure we don't have an active edit state when switching to the Setup view
              // or the app will break because the Setup tree doesn't show the Visualization nodes
              if (mode === TreeViewType.SETUP && editState) {
                cancelEdit();
              }
              setTreeViewType(mode);
            }}
            options={[
              {
                value: TreeViewType.POST_PROCESSING,
                name: 'Post-processing',
                selected: treeViewType === TreeViewType.POST_PROCESSING,
              },
              {
                value: TreeViewType.SETUP,
                name: 'Setup Details',
                selected: treeViewType === TreeViewType.SETUP,
              },
            ]}
            size="small"
          />
        )}
      </div>
      <div className={classes.rightControls}>
        {showRunStatusButton && (
          <Tooltip title={isRunStatusVisible ? 'Close Run Status Panel' : 'Open Run Status Panel'}>
            <span>
              <IconButton
                name="openRunStatus"
                onClick={(ev) => {
                  ev.stopPropagation();
                  setIsRunStatusVisible(!isRunStatusVisible);
                }}>
                <SvgIcon
                  maxHeight={12}
                  {...getJobStatusIconProps(jobStatusIconType)}
                />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <SimulationTreeDeleteButton />
        {showCopyToSetupButton && (
          <Tooltip title="Copy to Setup">
            <span>
              <IconButton
                name="copyToSetup"
                onClick={async (event) => {
                  event.stopPropagation();
                  await copyToSetup();
                  navigate(projectLink(projectId));
                }}>
                <DuplicateIcon maxHeight={12} />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <SimulationTreeMoreMenu />
      </div>
    </div>
  );
};

export default RightPaneTopControls;
