import { LCVManipulationMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';

import { LcvWidget } from './LcvWidget';

const WIDGET_NAME = 'finite_plane';

export type LcvFinitePlaneWidgetState = {
  position: [number, number, number];
  rotation: [number, number, number];
  width: number;
  height: number;
  displaySeedGrid: number;
  nSeedRows: number;
  nSeedsPerRow: number;
  // Note: the x and y axis params are read-only from LCVis
  // the app controls plane orientation through the rotation parameter
  xAxis: [number, number, number];
  yAxis: [number, number, number];
};

const WIDGET_PARAMS = {
  position: {
    name: 'position',
    type: LCVType.kLCVDataTypeFloat3,
  },
  rotation: {
    name: 'rotation',
    type: LCVType.kLCVDataTypeFloat3,
  },
  width: {
    name: 'width',
    type: LCVType.kLCVDataTypeFloat,
  },
  height: {
    name: 'height',
    type: LCVType.kLCVDataTypeFloat,
  },
  displaySeedGrid: {
    name: 'display_seed_grid',
    type: LCVType.kLCVDataTypeUint,
  },
  nSeedRows: {
    name: 'n_seed_rows',
    type: LCVType.kLCVDataTypeUint,
  },
  nSeedsPerRow: {
    name: 'n_seeds_per_row',
    type: LCVType.kLCVDataTypeUint,
  },
};

export class LcvFinitePlaneWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, WIDGET_NAME);
  }

  setState(newState: Partial<LcvFinitePlaneWidgetState>) {
    (Object.keys(newState) as (keyof LcvFinitePlaneWidgetState)[]).forEach((key) => {
      // These are read only
      if (key === 'xAxis' || key === 'yAxis') {
        return;
      }
      this.setParam(WIDGET_PARAMS[key].name, WIDGET_PARAMS[key].type, newState[key]);
    });
  }

  getState(): LcvFinitePlaneWidgetState {
    return {
      position: this.getProperty('position', LCVType.kLCVDataTypeFloat3),
      rotation: this.getProperty('rotation', LCVType.kLCVDataTypeFloat3),
      width: this.getProperty('width', LCVType.kLCVDataTypeFloat),
      height: this.getProperty('height', LCVType.kLCVDataTypeFloat),
      displaySeedGrid: this.getProperty('display_seed_grid', LCVType.kLCVDataTypeUint),
      nSeedRows: this.getProperty('n_seed_rows', LCVType.kLCVDataTypeUint),
      nSeedsPerRow: this.getProperty('n_seeds_per_row', LCVType.kLCVDataTypeUint),
      xAxis: this.getProperty('x_axis', LCVType.kLCVDataTypeFloat3),
      yAxis: this.getProperty('y_axis', LCVType.kLCVDataTypeFloat3),
    };
  }

  showControls({ manipulationMode }: { manipulationMode?: LCVManipulationMode } = {}) {
    const DEFAULT_MANIPULATION_MODE = LCVManipulationMode.kLCVManipulationModeAll;

    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      manipulationMode ?? DEFAULT_MANIPULATION_MODE,
    );
  }

  hideControls(): void {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeNone,
    );
  }

  /**
   * Set the callback to be invoked whenever the drag line widget state changes.
   * @param callback a callback to be invoked whenever the drag line widget state changes.
   */
  setOnUpdateCallback(
    callback: ((newState: LcvFinitePlaneWidgetState, message?: string) => void) | null,
  ) {
    if (!callback) {
      this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, null);
      return;
    }

    const internalCallback = (
      _lcv: LcvModule,
      _session: LCVObject,
      _obj: LCVObject,
      message: string,
    ) => {
      const newState = this.getState();
      callback(newState, message);
    };

    this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, internalCallback);
  }
}
