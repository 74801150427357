// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { AddMotionDataFrameButton } from '../../SimulationNodeAddButtons';
import { TreeRow } from '../../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const MotionGlobalFrameTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    addControl={<AddMotionDataFrameButton />}
    earlyAccess
    primaryIcon={getNodeTypeIcon(props.node.type)}
  />
);
