// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue, useSetRecoilState, waitForAll } from 'recoil';

import { UrlType } from '../proto/projectstate/projectstate_pb';

import { meshUrlState } from './meshState';
import { isLMAState } from './useIsLMA';
import { projectMeshListAtom } from './useProjectMeshList';

export enum MeshPanelType {
  SELECT = 0,
  EDIT = 1,
  DETAILS = 2
}

const meshPanelStateDefaultSelector = selectorFamily<MeshPanelType, string>({
  key: 'meshPanelStateDefault',
  get: (projectId: string) => ({ get }) => {
    const [list, isLMA, meshUrl] = get(waitForAll([
      projectMeshListAtom(projectId),
      isLMAState({ projectId, workflowId: '', jobId: '' }),
      meshUrlState(projectId),
    ]));
    // We want to populate the edit mesh panel mode without requiring users to click on New Mesh.
    if (meshUrl.activeType === UrlType.GEOMETRY && meshUrl.meshId === '') {
      return MeshPanelType.EDIT;
    }
    return (list.length === 0 && !isLMA) ? MeshPanelType.SELECT : MeshPanelType.EDIT;
  },
});

/**
 * This atom tracks the state of the MultiMesh panel: Select Mesh, Edit Mesh, Mesh Details
 */
export const meshPanelStateAtom = atomFamily<MeshPanelType, string>({
  key: 'meshPanelType',
  default: meshPanelStateDefaultSelector,
});

export const useSetMeshPanelState = (projectId: string) => (
  useSetRecoilState(meshPanelStateAtom(projectId))
);

export const useMeshPanelState = (projectId: string) => (
  useRecoilValue(meshPanelStateAtom(projectId))
);

export const useIsMeshSelectPanel = (projectId: string) => (
  useRecoilValue(meshPanelStateAtom(projectId)) === MeshPanelType.SELECT
);

export const useIsMeshEditPanel = (projectId: string) => (
  useRecoilValue(meshPanelStateAtom(projectId)) === MeshPanelType.EDIT
);

export const useIsMeshDetailsPanel = (projectId: string) => (
  useRecoilValue(meshPanelStateAtom(projectId)) === MeshPanelType.DETAILS
);
