// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { SetterOrUpdater } from 'recoil';

import { EditState } from '../../../visUtils';
import { LcvModule } from '../../types';
import { LcvFrame } from '../LcvFrame';
import { LcvObject } from '../base/LcvObject';

type WidgetType = 'arcball_camera' | 'selection' | 'clip_plane' |
  'clip_box' | 'probe' | 'triad' | 'line_widget' | 'box' | 'sphere' |
  'cylinder' | 'half_sphere' | 'drag_line' | 'finite_plane';

export class LcvWidget extends LcvObject {
  frame: LcvFrame | null = null;
  type: WidgetType;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    type: WidgetType,
  ) {
    super(lcv, lcv.newWidget(sessionHandle, type, 0).widget, sessionHandle);
    this.type = type;
  }

  attachFrame(frame: LcvFrame) {
    if (this.frame) {
      this.detachFrame();
    }
    this.lcv.widgetAttach(this.sessionHandle, this.handle, frame.handle);
    this.frame = frame;
  }

  detachFrame() {
    if (!this.frame) {
      // do nothing if the widget isn't attached to a frame already.
      return;
    }
    this.lcv.widgetDetach(this.sessionHandle, this.handle);
    this.frame = null;
  }
}

/** All LcvWidgets that represent vis filters must implement this type. */
export interface LcvFilterWidgetType extends LcvWidget {
  /**
   * Attach a callback to update the editState with this widget's state whenever the user
   * manipulates it in the visualizer.
   *
   * If setEditState is null, setEditStateCallback removes the callback.
   */
  setEditStateCallback(setEditState: null | SetterOrUpdater<EditState | null>,): void

  showControls(): void;

  hideControls(): void;
}
