// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMeshUrlState } from '../../recoil/meshState';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';

export const useNeedToImportGeometry = (projectId: string) => {
  const [meshUrl] = useMeshUrlState(projectId);
  const [selectedGeometry] = useSelectedGeometry(projectId);
  const hasImport = useIsGeometryPending(projectId);

  const hasInitiatedLoadToSetup = !!selectedGeometry.geometryId;
  const hasMeshGeo = !!meshUrl.mesh || !!meshUrl.geometry;

  // If the user has not yet uploaded a geometry, or if the geometry has not been loaded to setup,
  // on any tab other than the geometry tab, we should show the unavailableStage component.
  return !hasInitiatedLoadToSetup && !hasMeshGeo && !hasImport;
};
