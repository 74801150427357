// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect } from 'react';

import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';

import { addClosableTab, removeTab } from '../../lib/TabManager';
import { getJobType } from '../../lib/jobNameMap';
import { geometryLink, projectLink, routes } from '../../lib/navigation';
import { ProjectParams } from '../../lib/routeParamTypes';
import useProjectWorkflowAndJobStatusMap from '../../model/hooks/useProjectWorkflowAndJobStatusMap';
import { JobStatusType } from '../../proto/base/base_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { useJobNameMapLoadable } from '../../recoil/jobNameMap';
import { useCurrentTab, useTabsState } from '../../recoil/useTabsState';
import { useWorkflowState } from '../../recoil/workflowState';
import { useSetLastOpenedResultsTab } from '../../state/external/project/lastOpenedResultsTab';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { DataTabs } from '../layout/Tabs';
import { TabConfig } from '../layout/Tabs/useDraggableTabs';

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    '& > *': {
      paddingLeft: '12px',
    },
  },
}), { name: 'TabPanel' });

// A panel showing a set of tabs and possibly a menu of workflows and the right
// pane switcher.
const TabPanel = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams<ProjectParams>();
  const { projectId, jobId } = useProjectContext();
  const jobNameMap = useJobNameMapLoadable(projectId);
  const [tabsState, setTabsState] = useTabsState(projectId);
  const statuses = useProjectWorkflowAndJobStatusMap(projectId);
  const setLastOpenedResultsTab = useSetLastOpenedResultsTab(projectId);

  // We show the "Setup" and "Geometry" links on the main navbar, and we don't want to show the same
  // link with the rest of the individual simulation tabs.
  const undesiredLinks = [projectLink(projectId), geometryLink(projectId)];
  const tabInfos = tabsState.tabs.filter((tab) => !undesiredLinks.includes(tab.link));
  const currentTab = useCurrentTab(projectId);

  // If the current simulation is not in the list of tabs, add it. To find
  // the text of the tab, get the update time from the workflow metadata.
  const workflowId = params.workflowId || '';
  const workflowState = useWorkflowState(projectId, workflowId);
  useEffect(() => {
    if (!currentTab && workflowState && workflowState.config && jobNameMap) {
      if (Object.keys(workflowState.job).length) {
        const name = jobNameMap.getDefault({ workflowId, jobId, type: getJobType(workflowState) });
        setTabsState(addClosableTab(name, pathname, tabsState));
      }
    }
  }, [currentTab, jobNameMap, jobNameMap?.hash, workflowState, pathname, workflowId,
    setTabsState, tabsState, jobId]);

  const tabProps: TabConfig[] = tabInfos.map((tab: projectstatepb.TabInfo) => {
    const link = tab.link;
    // TODO(bamo): Once we upgrade react-router-dom, a single call to matchPath can
    // check against multiple path patterns.  But for now, we're stuck with this.
    const match = (
      matchPath(routes.simulation, link) ??
      matchPath(routes.exploration, link) ??
      matchPath(routes.explorationJob, link)
    );

    const onRename = match ? (newName: string) => {
      jobNameMap?.set({
        workflowId: match.params.workflowId ?? '',
        jobId: match.params.jobId,
      }, newName);
    } : undefined;

    const isResultsTab = tab.text === 'Results';
    const label = isResultsTab ? 'All Results' : tab.text;
    const foreground = (link === pathname);
    const lastIdFraction = tab.link.split('/').pop();
    const isActive = !!(lastIdFraction && statuses[lastIdFraction] === JobStatusType.Active);

    return {
      dataTabClickableLocator: label,
      id: link,
      foreground,
      label,
      loading: isActive,
      help: label,
      draggable: !isResultsTab,
      onClick: () => {
        // Save the sub tab we are opening so we can automatically return to it later, if we go to
        // another page and then open the project's Results page
        setLastOpenedResultsTab(link);
        navigate(link);
      },
      ...tab.closable && {
        close: {
          onClose: () => {
            if (link === pathname) {
              navigate(tabInfos[0].link);
            }
            setTabsState(removeTab(tab, tabsState));
          },
        },
        onRename,
      },
    };
  });

  return (
    <div
      className={classes.root}
      data-locator="tabPanel">
      <DataTabs draggable orderPersistenceKey={`project:${projectId}`} tabs={tabProps} />
    </div>
  );
};

export default TabPanel;
