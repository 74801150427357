import React, { ReactNode } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { isUnmodifiedEnterKey } from '../../lib/event';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      '--main-color': colors.neutral900,
      cursor: 'pointer',
      color: 'var(--main-color)',
      display: 'flex',
      borderRadius: '8px',
      gap: '8px',
      width: '100%',
      alignItems: 'center',
      textDecoration: 'none',
      transition: 'color 250ms, box-shadow 350ms',
      background: colors.neutral250,
      justifyContent: 'center',

      '&:hover': {
        color: colors.neutral800,
      },

      '&:focus-visible': {
        outline: '1px solid var(--main-color)',
        borderRadius: '1px',
      },
    },
    children: {
      fontSize: '14px',
      fontWeight: 600,
      padding: '16px 0',
    },
  }),
  { name: 'RectangleButton' },
);

type RectangleButtonProps = {
  children: ReactNode;
  icon?: SvgIconSpec;
  allowPropagation?: boolean;
  onClick?: () => void;
  href?: string;
  reload?: boolean;
}

export const RectangleButton = ({
  children,
  icon,
  allowPropagation = false,
  onClick,
  href,
  reload,
}: RectangleButtonProps) => {
  const classes = useStyles();

  if (href) {
    if (href.startsWith('/') && !reload) {
      return (
        <Link
          className={cx(classes.root, { link: true })}
          onClick={() => onClick?.()}
          to={href}>
          {icon && (
            <SvgIcon
              {...icon}
              color="currentColor"
            />
          )}
          {children}
        </Link>
      );
    }

    const external = href?.startsWith('http') || href?.startsWith('mailto:');
    return (
      <a
        className={cx(
          classes.root,
          { link: true },
        )}
        // since we use a hashrouter, if there's an internal link we need to prepend /# to it.
        // Otherwise the a tag navigates to the link without the hash.
        href={href}
        onClick={(event) => {
          !allowPropagation && event.stopPropagation();
          onClick?.();
          // for internal links, force a refresh since setting the route to /# will just change
          // the url but not reload automatically.
          reload && !external && setTimeout(window.location.reload.bind(window.location), 1);
        }}
        onKeyUp={(event) => event.stopPropagation()}
        rel={external ? 'noopener noreferrer' : undefined}
        target={external ? '_blank' : undefined}>
        {icon && (
          <SvgIcon
            {...icon}
            color="currentColor"
          />
        )}
        {children}
      </a>
    );
  }

  const handleInternalButtonAction = (
    event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
  ) => {
    !allowPropagation && event.stopPropagation();
    onClick?.();
  };

  return (
    <div
      className={classes.root}
      onClick={handleInternalButtonAction}
      onKeyUp={(event) => {
        if (isUnmodifiedEnterKey(event)) {
          handleInternalButtonAction(event);
        }
      }}
      role="button"
      tabIndex={0}>
      {icon && (
        <SvgIcon
          {...icon}
          color="currentColor"
        />
      )}
      <div className={classes.children}>
        {children}
      </div>
    </div>
  );
};

RectangleButton.displayName = 'RectangleButton';

export default RectangleButton;
