// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { filteredMenuItems } from '../../../lib/menuUtil';
import { RecoilProjectKey } from '../../../lib/persist';
import { ungroupTreeNodeMenuItem } from '../../../lib/treeUtils';
import { CameraGroupMapAccessType, useCameraGroupData } from '../../../recoil/cameraState';
import { useProjectContext } from '../../context/ProjectContext';
import { useNodeGrouping } from '../../hooks/useNodeGrouping';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const CameraGroupTreeRow = (props: SimulationRowProps) => {
  // Props
  const { node } = props;

  // Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const cameraKey: RecoilProjectKey = { projectId, workflowId, jobId };

  // Hooks
  const renaming = useNodeRenaming(node);
  const cameraGroupData = useCameraGroupData(cameraKey, CameraGroupMapAccessType.ALL);
  const { canUngroup, ungroupCameras } = useNodeGrouping();

  // Handlers
  const getContextMenuItems = useCallback(() => filteredMenuItems([
    {
      itemConfig: ungroupTreeNodeMenuItem(() => ungroupCameras(node.id)),
      shouldShow: canUngroup(node, cameraGroupData),
    },
  ]), [canUngroup, cameraGroupData, node, ungroupCameras]);

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={getNodeTypeIcon(props.node.type)}
      renaming={renaming}
    />
  );
};
