// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

import newInt from './intUtils';
import { clamp } from './number';

// A practical limit on mesh adaptation. Increase with remeshing performance.
// Keep in sync with maxAdaptFinalCellsInMillions in
// go/core/protoutil/fvmparam.go
const MAX_ADAPT_FINAL_CELLS_IN_MILLIONS = 100;

export function clampMaxMeshCount(newValue: number) {
  return newInt(clamp(Math.round(newValue), [1, MAX_ADAPT_FINAL_CELLS_IN_MILLIONS]));
}

export interface WorkorderStepMetadata {
  heading?: string;
  tooltip?: string;
  summary?: string;
  stepMessages?: string[];
  // Which of the steps is active. The previous steps have finished.
  currentStepIndex?: number;
}

export function getWorkorderStepMetadata(
  pending: frontendpb.PendingWorkOrder,
  type: frontendpb.WorkOrderType,
  meshUrlState: projectstatepb.MeshUrl,
  isInteractiveGeo: boolean,
  isGeometryView: boolean,
): WorkorderStepMetadata {
  switch (type) {
    case frontendpb.WorkOrderType.GET_GEOMETRY: {
      const stepMessages: string[] = isInteractiveGeo ? ['Generating Tessellation'] :
        ['Geometry Clean-Up'];
      let currentStepIndex = 0;

      // When adding a far-field or igeo there is only one step.
      const hasUserGeoMod = pending.typ.case === 'getGeometry' && pending.typ.value.userGeoMod;
      if (!hasUserGeoMod && !isInteractiveGeo) {
        stepMessages.unshift('File Translation');
        // If we have a preview, the file translation step has finished.
        if (meshUrlState.preview) {
          currentStepIndex = 1;
        }
      }

      return {
        heading: 'Geometry Preparation',
        summary: `This may take a few minutes. You can modify settings once these steps complete.
          You can keep this project open or close it for later – we will continue to process your
          file in the background.`,
        currentStepIndex,
        stepMessages,
      };
    }
    case frontendpb.WorkOrderType.CHECK_GEOMETRY: {
      const summary = `We're validating that your geometry can be successfully meshed.`;
      // In the geometry view there are no settings to modify.
      const suffix = !isGeometryView ? ` You can modify geometry objects and certain setup
        parameters as this process runs in the background.` : '';
      return {
        heading: 'Geometry Health Check',
        summary: `${summary}${suffix}`,
        stepMessages: ['Volume Check'],
      };
    }
    case frontendpb.WorkOrderType.GET_MESH: {
      return {
        heading: 'Mesh Generation',
        tooltip: `This may take a few minutes. You can modify other settings as this runs.  You can
        keep this project open or close it for later – we will continue to generate the mesh in the
        background.`,
        stepMessages: ['Cell Estimate and Generation'],
      };
    }
    case frontendpb.WorkOrderType.COMPUTE_GEOMETRY_CONTACTS: {
      return {
        heading: 'Automatic Contact Detection',
        summary: `This may take a few minutes. You can modify other settings as this runs.`,
        stepMessages: ['Computing'],
      };
    }
    default: {
      return {};
    }
  }
}
