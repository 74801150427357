// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const assistantRespondingState = atom<boolean>({
  key: 'assistantRespondingState',
  default: false,
});

export function useAssistantResponding() {
  return useRecoilState(assistantRespondingState);
}

export function useAssistantRespondingValue() {
  return useRecoilValue(assistantRespondingState);
}

export function useSetAssistantResponding() {
  return useSetRecoilState(assistantRespondingState);
}
