// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo } from 'react';

import * as Vector from '../../../../lib/Vector';
import { FaultType, RadioButtonOption, SelectOption } from '../../../../lib/componentTypes/form';
import { expandGroupsExcludingTags } from '../../../../lib/entityGroupUtils';
import { Logger } from '../../../../lib/observability/logs';
import { hideSurfaces } from '../../../../lib/paraviewUtils';
import { SURFACE_NODE_TYPES } from '../../../../lib/simulationTree/node';
import { addRpcError } from '../../../../lib/transientNotification';
import { EditSource, filterWarnings, isSurfaceListOverset, visComputeFilterWrapper } from '../../../../lib/visUtils';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useEntityGroupData } from '../../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { useEditState } from '../../../../recoil/paraviewState';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import { RadioButtonGroup } from '../../../Form/RadioButtonGroup';
import { Vector3Input } from '../../../Form/Vector3Input';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { useVisualizationSurfacesSubselect } from '../../../hooks/subselect/useVisualizationSurfaces';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { SectionMessage } from '../../../notification/SectionMessage';
import { PlaneInput, PlaneParam } from '../../../visFilter/PlaneInput';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';
import { CommonFilterMessages } from '../shared/CommonFilterMessages';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

const logger = new Logger('filter/SurfaceLIC');

// Version 0 is surface LIC is the initial basic version
// Version 1 includes refinement and reduced precision
const VERSION = 1;

const WALL_SHEAR_STRESS = 'Wall Shear Stress (N/m\u00B2)';

const SUBSELECT_ID = 'surface-lic-surfaces';

function defaultPlaneLICParam(bounds: ParaviewRpc.Bounds): ParaviewRpc.PlaneLICParam {
  return {
    plane: {
      typ: 'Plane',
      // The initial center is the bounds center.
      origin: {
        x: 0.5 * (bounds[1] + bounds[0]),
        y: 0.5 * (bounds[3] + bounds[2]),
        z: 0.5 * (bounds[5] + bounds[4]),
      },
      // The default normal is the YZ plane.
      normal: { x: 1, y: 0, z: 0 },
    },
    useBounds: true,
    bounds,
  };
}

function defaultSeedLICParams(): ParaviewRpc.SeedLICParam {
  return {
    typ: ParaviewRpc.SeedPlacementType.L_I_C,
    surfaceType: ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE,
    surfaces: [],
    numberSteps: 50,
  };
}

function defaultMaxLength(bounds: ParaviewRpc.Bounds | null) {
  // Set maximum length to be based on volume bounds.
  // We set it to the sum of lengths along x, y, and z dimensions.
  // Although large lengths previously resulted in low contrast LICs, since we now support contrast
  // contro this is not a concern.
  let maxLen = 1e10; // This should be a sufficiently high default.
  if (bounds) {
    const [xmin, xmax, ymin, ymax, zmin, zmax] = bounds;
    maxLen = (xmax - xmin) + (ymax - ymin) + (zmax - zmin);
  }
  return maxLen;
}

/** Create the param filled with default values, to be used when creating a new
    filter off the given parent. */
export function newSurfaceLICParam(
  parent: ParaviewRpc.TreeNode,
):
  ParaviewRpc.SurfaceLICParam {
  const data = parent.pointData.filter((item: ParaviewRpc.ArrayInformation) => item.dim === 3);

  // We want Wall Shear Stress to be the default if the surface LIC plot
  // is being generated for geometry.
  // TODO (LC-9757): This will need to be set to velocity if the surface LIC plot
  // is being generated for slice planes in the volume.
  const hasWallShearStress = data.find((item) => item.name === WALL_SHEAR_STRESS);
  const defaultDataName = hasWallShearStress ? WALL_SHEAR_STRESS : data[0].name;

  return {
    typ: ParaviewRpc.TreeNodeType.SURFACE_L_I_C,
    dataName: defaultDataName,
    integrationDirection: ParaviewRpc.IntegrationDirection.BOTH,
    seedPlacementType: ParaviewRpc.SeedPlacementType.L_I_C,
    seedPlacementParams: defaultSeedLICParams(),
    maximumLength: defaultMaxLength(parent.bounds),
    textureContrastControl: [0, 0],
    version: VERSION,
    url: '',
  };
}

// Panel for displaying and modifying a surface LIC filter.
export const SurfaceLICPropPanel = (props: FilterPropertiesPanelProps) => {
  const { displayProps, filterNode, nodeId, parentFilterNode } = props;

  const {
    paraviewActiveUrl,
    paraviewClientState,
    paraviewProjectId,
    paraviewMeshMetadata,
    paraviewRenderer,
    viewState,
    visibilityMap,
    activeEdit,
    getDataVisibilityBounds,
    setVisibility,
  } = useParaviewContext();
  const geometryTags = useGeometryTags(paraviewProjectId);
  const { projectId, workflowId, jobId } = useProjectContext();

  const [editState] = useEditState();
  const editSource = editState ? editState.editSource : EditSource.FORM;
  const paraviewClient = paraviewClientState.client;
  const { updateEditState } = useSelectedFilterNode();
  const { isActive, nodeFilter } = useVisualizationSurfacesSubselect(SUBSELECT_ID, geometryTags);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  const onUpdate = useCallback(
    (source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => updateEditState({
      editSource: source,
      param: newParam,
    }),
    [updateEditState],
  );

  const param = props.param as ParaviewRpc.SurfaceLICParam;
  const licParam = param.seedPlacementParams as ParaviewRpc.SeedLICParam;
  const planeLicParam = licParam.surfaces as ParaviewRpc.PlaneLICParam;
  const warnings = filterWarnings(param);
  // If there are warnings, we just leave the URL blank, so it doesn't mean we're waiting.
  const calc = !editState && param.url === '' && warnings.length === 0;
  const creationError = param.url === 'ERROR';
  const readOnly = !editState;
  const surfaceList = useMemo(() => {
    if (Array.isArray(licParam.surfaces)) {
      return licParam.surfaces as string[];
    }
    return [];
  }, [licParam]);
  const { simParam } = useSimulationConfig();
  const showSurfaceOverlapWarning = isSurfaceListOverset(surfaceList, simParam);

  // Surface LIC filter works only for vector data.
  // Some data arrays are not compatiblr with some surface types.
  const [dataNameOptions, dataNameFault] = useMemo(() => {
    const data = parentFilterNode.pointData.filter(
      (item: ParaviewRpc.ArrayInformation) => item.dim === 3,
    );
    let faultType: FaultType | undefined;
    const selectOptions = data.map(({ name }): SelectOption<string> => {
      let disabled = false;
      let disabledReason = '';
      const selected = name === param.dataName;
      if (
        licParam.surfaceType === ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE &&
        name === 'Velocity (m/s)'
      ) {
        disabled = true;
        disabledReason = 'Velocity is not compatible with geometric surfaces';
        faultType = selected ? 'warning' : undefined;
      } else if (
        licParam.surfaceType === ParaviewRpc.LICSurfaceType.PLANE &&
        name === WALL_SHEAR_STRESS
      ) {
        disabled = true;
        disabledReason = 'Wall Shear Stress is not compatible with plane clip';
        faultType = selected ? 'warning' : undefined;
      }
      return ({
        name,
        value: name,
        selected,
        disabled,
        disabledReason,
      });
    });
    return [selectOptions, faultType];
  }, [licParam.surfaceType, param.dataName, parentFilterNode.pointData]);
  const empty = !dataNameOptions || dataNameOptions.length <= 0;

  const outOfBounds = useMemo(() => {
    if (Array.isArray(planeLicParam)) {
      // if the surface type is surface, then this param is an array of surfaces, not a plane object
      return false;
    }
    const { bounds: [xmin, xmax, ymin, ymax, zmin, zmax], plane } = planeLicParam;
    return (
      plane.origin.x < xmin || plane.origin.x > xmax ||
      plane.origin.y < ymin || plane.origin.y > ymax ||
      plane.origin.z < zmin || plane.origin.z > zmax
    );
  }, [planeLicParam]);

  useEffect(() => {
    if (!editState) {
      return () => { };
    }

    let mounted = true;
    let unsubscribeFn: (() => void) | null = null;

    // Called when the user moves the widget interactively.
    const onPlaneWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION) {
        throw Error('only plane widget supported now');
      }
      if (!mounted) {
        return;
      }
      const planeParams = planeLicParam.plane;

      if (
        !Vector.nearPv(newParam.plane.origin, planeParams.origin) ||
        !Vector.nearPv(newParam.plane.normal, planeParams.normal)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            seedPlacementParams: {
              ...licParam,
              surfaces: {
                ...planeLicParam,
                plane: {
                  typ: 'Plane',
                  origin: newParam.plane.origin,
                  normal: newParam.plane.normal,
                },
                bounds: newParam.bounds,
              },
            },
            url: '',
          },
        );
      }
    };

    const startWidget = async (): Promise<void> => {
      if (!paraviewClient || !paraviewMeshMetadata) {
        return;
      }
      if (licParam.surfaceType === ParaviewRpc.LICSurfaceType.PLANE) {
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onPlaneWidgetUpdate);
        // Activate the widget.
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
          {
            typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
            plane: planeLicParam.plane,
            bounds: planeLicParam.bounds,
          },
        );
      }
    };

    startWidget().then(() => { }).catch((err: Error) => {
      addRpcError('could not activate widget', err);
    });
    return () => {
      mounted = false;
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, nodeId, param.dataName, licParam.surfaceType]);

  useEffect(() => {
    if (editState && editSource !== EditSource.PARAVIEW && viewState && paraviewMeshMetadata) {
      // When the user manually updates the plane dialog, reflect the new plane
      // params to the widget.
      if (licParam.surfaceType === ParaviewRpc.LICSurfaceType.PLANE) {
        logger.debug('SurfaceLIC: set widget');
        const ws: ParaviewRpc.ImplicitPlaneWidgetState = {
          typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
          plane: planeLicParam.plane,
          bounds: planeLicParam.bounds,
        };
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(
            paraviewMeshMetadata.meshMetadata,
          ),
          ws,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState, editSource, licParam.surfaceType, nodeId, planeLicParam]);

  // Function to make asynchronous call to vis service to compute Streamlines
  const executeVisFilter = (newNodeId: string) => {
    const currentParam = editState!.param as ParaviewRpc.SurfaceLICParam;
    visComputeFilterWrapper(
      paraviewProjectId,
      paraviewActiveUrl,
      currentParam,
      newNodeId,
      activeEdit,
    );
  };

  const updateFilterRepresentation = (vals: [number, number]) => {
    // Ensure values are integers as input configuration implementation (slider) could change.
    const intVals: [number, number] = [Math.round(vals[0]), Math.round(vals[1])];

    if (!editState) {
      const newParam = {
        ...param,
        textureContrastControl: intVals,
      };
      activeEdit(nodeId, newParam);
    } else {
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          textureContrastControl: intVals,
        },
      );
    }
  };

  const setDataName = (newName: string) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        dataName: newName,
        url: '',
      },
    );
  };

  const surfaceLICMeshTypeOptions: RadioButtonOption<string>[] = [{
    label: 'Geometry Surface',
    value: ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE,
  }, {
    label: 'Plane',
    value: ParaviewRpc.LICSurfaceType.PLANE,
  }];

  const updateSurfaces = useCallback((surfaceIds: string[]) => {
    const unrolledSurfaceList = expandGroupsExcludingTags(entityGroupData, surfaceIds);
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        seedPlacementParams: {
          ...licParam,
          surfaces: unrolledSurfaceList,
        },
        url: '',
      },
    );
  }, [licParam, onUpdate, param, entityGroupData]);

  const setSurfaceType = (newType: ParaviewRpc.LICSurfaceType) => {
    const visibleBounds = getDataVisibilityBounds(paraviewMeshMetadata!.meshMetadata, 1.0);
    if (newType === ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE) {
      paraviewRenderer.deleteWidget();
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          seedPlacementParams: {
            ...licParam,
            surfaceType: newType,
            surfaces: [],
          },
          url: '',
        },
      );
    } else if (newType === ParaviewRpc.LICSurfaceType.PLANE) {
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          seedPlacementParams: {
            ...licParam,
            surfaceType: newType,
            surfaces: defaultPlaneLICParam(visibleBounds),
          },
          url: '',
        },
      );
    }
  };

  // A use effect that calls code when this component is mounted and unmounted.
  useEffect(() => {
    // Function called when the component is unMounted.
    const onUnmount = () => {
      // Ensure that when this panel closes, we reset the active node table.
      paraviewRenderer.deleteWidget();
    };
    return onUnmount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let disableApplyHelp = '';
  if (dataNameFault) {
    disableApplyHelp = 'Selected data array is not compatible with selected surface type';
  } else if (outOfBounds) {
    disableApplyHelp = 'Plane origin is out of bounds';
  }

  return (
    <div>
      <CommonFilterMessages
        calculating={calc}
        creationError={creationError}
        disabled={isActive}
        emptyFilter={empty}
        warnings={warnings}
      />
      <FilterDisplayPanel
        filterNode={filterNode}
        updateFilterRepresentation={updateFilterRepresentation}
      />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableApply={!!dataNameFault || outOfBounds}
              disableApplyHelp={disableApplyHelp}
              disableEdit={calc || empty}
              displayProps={displayProps}
              executeVisFilter={executeVisFilter}
              nodeId={nodeId}
              // hide lic surfaces from Geometry section
              onSave={() => setVisibility(hideSurfaces(surfaceList, visibilityMap))}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <Form.LabeledInput label="Surface LIC Field">
            <DataSelect
              asBlock
              disabled={readOnly}
              faultType={dataNameFault}
              onChange={setDataName}
              options={dataNameOptions}
              size="small"
            />
          </Form.LabeledInput>
          <Form.LabeledInput label="Generate LIC On">
            <RadioButtonGroup
              disabled={readOnly}
              kind="secondary"
              name="surfaceType"
              onChange={(type) => setSurfaceType(type as ParaviewRpc.LICSurfaceType)}
              options={surfaceLICMeshTypeOptions}
              value={licParam.surfaceType}
            />
          </Form.LabeledInput>
          {licParam.surfaceType === ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE && (
            <div style={{ paddingTop: '8px' }}>
              <NodeSubselect
                autoStart={!displayProps.displayVariable}
                id={SUBSELECT_ID}
                independentSelection
                labels={['mesh surfaces']}
                nodeFilter={nodeFilter}
                nodeIds={surfaceList}
                onChange={updateSurfaces}
                readOnly={readOnly}
                referenceNodeIds={[nodeId]}
                visibleTreeNodeTypes={SURFACE_NODE_TYPES}
              />
              {showSurfaceOverlapWarning && (
                <div style={{ paddingTop: '8px' }}>
                  <SectionMessage
                    level="warning"
                    message="Geometry surfaces from different volumes may overlap."
                  />
                </div>
              )}
            </div>
          )}
          {licParam.surfaceType === ParaviewRpc.LICSurfaceType.PLANE && (
            <>
              <PlaneInput
                onCommit={(newParam: PlaneParam) => onUpdate(
                  EditSource.FORM,
                  {
                    ...param,
                    seedPlacementParams: {
                      ...licParam,
                      surfaces: {
                        ...planeLicParam,
                        plane: {
                          typ: 'Plane',
                          origin: Vector.toPvProto(newParam.origin),
                          normal: Vector.toPvProto(newParam.normal),
                        },
                      },
                    },
                    url: '',
                  },
                )}
                param={{
                  origin: Vector.toProto(
                    planeLicParam.plane.origin,
                  ),
                  normal: Vector.toProto(
                    planeLicParam.plane.normal,
                  ),
                }}
                readOnly={readOnly}
              />
              <Form.LabeledInput
                help={
                  `The plane is clipped using an axis-aligned bounding box. Please specify the
                   minimum and maximum points of the box. The default values reflect the bounds of
                   all visible artifacts.`
                }
                label="Plane Bounds">
                <Form.LabeledInput help="Minimum values in x, y, and z." label="Minimum">
                  <Vector3Input
                    disabled={readOnly}
                    onCommit={(value) => {
                      const min = Vector.toPvProto(value);
                      const bounds = planeLicParam.bounds;
                      onUpdate(
                        EditSource.FORM,
                        {
                          ...param,
                          seedPlacementParams: {
                            ...licParam,
                            surfaces: {
                              ...planeLicParam,
                              bounds: [
                                min.x < bounds[1] ? min.x : bounds[1], bounds[1],
                                min.y < bounds[3] ? min.y : bounds[3], bounds[3],
                                min.z < bounds[5] ? min.z : bounds[5], bounds[5],
                              ],
                            },
                          },
                          url: '',
                        },
                      );
                    }}
                    value={Vector.newProto(
                      planeLicParam.bounds[0],
                      planeLicParam.bounds[2],
                      planeLicParam.bounds[4],
                    )}
                  />
                </Form.LabeledInput>
                <Form.LabeledInput help="Maximum values in x, y, and z." label="Maximum">
                  <Vector3Input
                    disabled={readOnly}
                    onCommit={(value) => {
                      const max = Vector.toPvProto(value);
                      const bounds = planeLicParam.bounds;
                      onUpdate(
                        EditSource.FORM,
                        {
                          ...param,
                          seedPlacementParams: {
                            ...licParam,
                            surfaces: {
                              ...planeLicParam,
                              bounds: [
                                bounds[0], max.x > bounds[0] ? max.x : bounds[0],
                                bounds[2], max.y > bounds[2] ? max.y : bounds[2],
                                bounds[4], max.z > bounds[4] ? max.z : bounds[4],
                              ],
                            },
                          },
                          url: '',
                        },
                      );
                    }}
                    value={Vector.newProto(
                      planeLicParam.bounds[1],
                      planeLicParam.bounds[3],
                      planeLicParam.bounds[5],
                    )}
                  />
                </Form.LabeledInput>
              </Form.LabeledInput>
            </>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </div>
  );
};
