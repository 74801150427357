import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { Vector3 } from '../ProtoDescriptor';
import { BoundingBox } from '../lib/geometry';
import { LCStatus } from '../proto/lcstatus/lcstatus_pb';

export type CoordinatesIssue = { issue: LCStatus, boundingBox: BoundingBox; };
export type VisualizerError = { coordinates: Vector3; issues: CoordinatesIssue[] };

const selectedVisualizerErrorState = atomFamily<null | VisualizerError, string>({
  key: 'selectedVisualizerError',
  default: null,
});

export const useSetSelectedVisualizerError = (projectId: string) => (
  useSetRecoilState(selectedVisualizerErrorState(projectId))
);
export const useSelectedVisualizerErrorValue = (projectId: string) => (
  useRecoilValue(selectedVisualizerErrorState(projectId))
);
