// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
// Updates the pvproto message to newer versions.
import deepMapKeys from 'deep-map-keys';

import * as ParaviewRpc from '../pvproto/ParaviewRpc';

// The default values of parameters - bins and discretize - for the colormap.
// TODO (Sudhanshu) : Remove when not required for backward compatibility.
const DEFAULT_DISCRETE_BINS = 8;
const DEFAULT_DISCRETIZE = false;

function upgradeDataName(name: string): any {
  // NOTE: we use unused names here to make sure that we can update them later with a map.
  // If not, we end up with an infinite loop.
  return {
    newDisplayDataName: name,
    newDisplayDataNameComponent: 0,
  };
}

function isNullDataName(name: string): boolean {
  return !name || name === 'None';
}

// We upgraded displayDataName -> displayVariable: DisplayPvVariable with version 0.
// The upgrade applies to the root viewAttrs and the displayProps.
// Also, the color maps had to be upgraded to reflect the new indexing approach.
function upgradeDisplayPropsOrViewAttrsV0(proto: any): void {
  // Pass displayDataName to displayVariable type.
  if (proto.displayDataName) {
    if (!isNullDataName(proto.displayDataName)) {
      proto.displayVariable = upgradeDataName(proto.displayDataName);
    } else {
      proto.displayVariable = { newDisplayDataName: 'None', newDisplayDataNameComponent: 0 };
    }
  }
  delete proto.displayDataName;

  // Change colorMaps from a dictionary with str keys to an array with entries
  // [ParaviewRpc.DisplayPvVariable, ParaviewRpc.ColorMap].
  if (proto.colorMaps) {
    const newColorMaps: [ParaviewRpc.DisplayPvVariable, ParaviewRpc.ColorMap][] = [];
    Object.entries(proto.colorMaps).forEach(([key, value]) => {
      const colorMap: any = value;
      // Checks for added parameters - bins and discretize - for compatibility with old projects.
      // TODO (Sudhanshu) : Remove these changes if not required anymore.
      if (colorMap.bins === undefined) {
        // Initial level of discretization for a discrete color map.
        colorMap.bins = DEFAULT_DISCRETE_BINS;
      }
      if (colorMap.discretize === undefined) {
        // Legacy, continuous color map.
        colorMap.discretize = DEFAULT_DISCRETIZE;
      }
      newColorMaps.push([upgradeDataName(key), colorMap as any]);
    });
    // The new key is used to avoid collisions. We use deepMapKeys later on to change key names.
    proto.newColorMaps = newColorMaps;
  }
  delete proto.colorMaps;
}

function renameKeyV0(key: string): string {
  if (key === 'newColorMaps') {
    return 'colorMaps';
  } if (key === 'newDisplayDataName') {
    return 'displayDataName';
  } if (key === 'newDisplayDataNameComponent') {
    return 'displayDataNameComponent';
  }
  return key;
}

// We replaced dataName -> contourVariable: DisplayPvVariable with version 1.
function upgradeDataNameContourV1(proto: any): void {
  if (proto.param?.typ === 'Contour') {
    // dataName should always be valid because of how Contour dataName is defined
    // in ContourPropsPanel.
    const varName = proto.param.dataName;
    if (varName) {
      // Default to component 0 (Magnitude).
      const varComponent = 0;
      const contourVariable: ParaviewRpc.DisplayPvVariable = {
        displayDataName: varName,
        displayDataNameComponent: varComponent,
      };
      proto.param.contourVariable = contourVariable;
      delete proto.param.dataName;
    }
  }
}

// We added smooth and invert to the ClipSliceParam when upgrading V1 -> V2.
function upgradeClipV2(proto: any): void {
  if (['Clip', 'Slice'].includes(proto.param?.typ)) {
    if (proto.param.smooth === undefined || proto.param.smooth === null) {
      proto.param.smooth = false;
      proto.param.invert = false;
    }
  }
}

// We added typ to the PlaneParam when upgrading V2 -> V3.
function upgradePlaneParamV3(proto: any): void {
  if (['Clip', 'Slice', 'ActuatorDisk'].includes(proto.param?.typ) && proto.param?.plane) {
    if (proto.param.plane.typ === undefined || proto.param.plane.typ === null) {
      proto.param.plane.typ = 'Plane';
    }
  }
}

// We added paramType and filterParam to the ClipSliceParam when upgrading V2 -> V3.
// The paramType can be one of Clip, Slice, and BoxClip (new) as a string
// The filterParam can be one of PlaneParam and BoxClipParam
function upgradeClipTypesV3(proto: any): void {
  if (['Clip', 'Slice'].includes(proto.param?.typ)) {
    if (proto.param?.plane) {
      proto.param.paramType = String(proto.param.typ);
      proto.param.filterParam = proto.param.plane;
      delete proto.param.plane;
    }
  }
}

// We added a project vectors boolean to the multislice param.
// Add it with a default of false if not present.
function upgradeMultiSliceV4(proto: any): void {
  if (proto.param?.typ === 'MultiSlice') {
    if (proto.param.projectvectors === undefined || proto.param.projectvectors === null) {
      proto.param.projectvectors = false;
    }
  }
}

// We changed the interpretation of the box clip's position.
// It used to be a corner, now it is the center of the box.
function upgradeBoxClipV5(proto: any): void {
  if (proto.typ === 'BoxClip') {
    if (proto.position) {
      // convert box corner to box center
      proto.position.x += proto.length.x / 2;
      proto.position.y += proto.length.y / 2;
      proto.position.z += proto.length.z / 2;
    }
  }
}

// We added a contrast enhancement boolean to the surfaceLIC param.
// Add it with a default of false if not present.
function upgradeSurfaceLICV6(proto: any): void {
  if (proto.param?.typ === 'SurfaceLIC') {
    if (proto.param.textureContrastControl === undefined ||
      proto.param.textureContrastControl === null) {
      proto.param.textureContrastControl = [0, 0];
    }
  }
}

// We added the ability to trace LIC on planes and thus introduced a new param to indicate
// the type of surfaces - geometry surfaces or plane.
// By default, we can set it to geometry surface.
function upgradeSurfaceLICV7(proto: any): void {
  if (proto.param?.typ === 'SurfaceLIC') {
    if (proto.param.seedPlacementParams.surfaceType === undefined ||
      proto.param.seedPlacementParams.surfaceType === null) {
      proto.param.seedPlacementParams.surfaceType = ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE;
    }
  }
}

function upgradePvprotoImplV0(proto: any): void {
  if (!proto) {
    return;
  }
  if (typeof proto !== 'object') {
    throw Error(`pvprotoUpgrader: non object ${JSON.stringify(proto)}`);
  }

  upgradeDisplayPropsOrViewAttrsV0(proto);
  Object.values(proto).forEach((value) => {
    if (typeof value === 'object') {
      upgradePvprotoImplV0(value);
    }
  });
}

function upgradePvprotoImplV1(proto: any): void {
  if (!proto) {
    return;
  }
  if (typeof proto !== 'object') {
    throw Error(`pvprotoUpgrader: non object ${JSON.stringify(proto)}`);
  }

  upgradeDataNameContourV1(proto);
  Object.values(proto).forEach((value) => {
    if (typeof value === 'object') {
      upgradePvprotoImplV1(value);
    }
  });
}

function upgradePvprotoImplV2(proto: any): void {
  if (!proto) {
    return;
  }
  if (typeof proto !== 'object') {
    throw Error(`pvprotoUpgrader: non object ${JSON.stringify(proto)}`);
  }

  upgradeClipV2(proto);
  Object.values(proto).forEach((value) => {
    if (typeof value === 'object') {
      upgradePvprotoImplV2(value);
    }
  });
}

function upgradePvprotoImplV3(proto: any): void {
  if (!proto) {
    return;
  }
  if (typeof proto !== 'object') {
    throw Error(`pvprotoUpgrader: non object ${JSON.stringify(proto)}`);
  }
  upgradePlaneParamV3(proto);
  upgradeClipTypesV3(proto);
  Object.values(proto).forEach((value) => {
    if (typeof value === 'object') {
      upgradePvprotoImplV3(value);
    }
  });
}

function upgradePvprotoImplV4(proto: any): void {
  if (!proto) {
    return;
  }
  if (typeof proto !== 'object') {
    throw Error(`pvprotoUpgrader: non object ${JSON.stringify(proto)}`);
  }
  upgradeMultiSliceV4(proto);
  Object.values(proto).forEach((value) => {
    if (typeof value === 'object') {
      upgradePvprotoImplV4(value);
    }
  });
}

function upgradePvprotoImplV5(proto: any): void {
  if (!proto) {
    return;
  }
  if (typeof proto !== 'object') {
    throw Error(`pvprotoUpgrader: non object ${JSON.stringify(proto)}`);
  }
  upgradeBoxClipV5(proto);
  Object.values(proto).forEach((value) => {
    if (typeof value === 'object') {
      upgradePvprotoImplV5(value);
    }
  });
}

function upgradePvprotoImplV6(proto: any): void {
  if (!proto) {
    return;
  }
  if (typeof proto !== 'object') {
    throw Error(`pvprotoUpgrader: non object ${JSON.stringify(proto)}`);
  }
  upgradeSurfaceLICV6(proto);
  Object.values(proto).forEach((value) => {
    if (typeof value === 'object') {
      upgradePvprotoImplV6(value);
    }
  });
}

function upgradePvprotoImplV7(proto: any): void {
  if (!proto) {
    return;
  }
  if (typeof proto !== 'object') {
    throw Error(`pvprotoUpgrader: non object ${JSON.stringify(proto)}`);
  }
  upgradeSurfaceLICV7(proto);
  Object.values(proto).forEach((value) => {
    if (typeof value === 'object') {
      upgradePvprotoImplV7(value);
    }
  });
}

export function upgradePvprotoVersion(proto: any, version: number): any {
  switch (version) {
    case null:
    case undefined:
    case -1:
      upgradePvprotoImplV0(proto);
      // Replace newly introduced keys used to avoid collisions.
      proto = deepMapKeys(proto, renameKeyV0);
      // Make sure that {} gets converted to []
      if (Object.keys(proto.attrs.colorMaps).length === 0) {
        proto.attrs.colorMaps = [];
      }
      break;
    case 0:
      upgradePvprotoImplV1(proto);
      break;
    case 1:
      upgradePvprotoImplV2(proto);
      break;
    case 2:
      upgradePvprotoImplV3(proto);
      break;
    case 3:
      upgradePvprotoImplV4(proto);
      break;
    case 4:
      upgradePvprotoImplV5(proto);
      break;
    case 5:
      upgradePvprotoImplV6(proto);
      break;
    case 6:
      upgradePvprotoImplV7(proto);
      break;
    default:
      throw Error('Unsupported version');
  }
  proto.attrs.viewAttrsVersion = version;
  return proto;
}

export default function upgradePvproto(proto: any): any {
  let version: number = proto.attrs?.viewAttrsVersion;
  if (version === null || version === undefined) {
    version = -1;
  }
  for (version; version < ParaviewRpc.PVPROTO_VIEW_ATTRS_VERSION; version += 1) {
    proto = upgradePvprotoVersion(proto, version);
  }
  proto.attrs.viewAttrsVersion = ParaviewRpc.PVPROTO_VIEW_ATTRS_VERSION;
  return proto;
}
