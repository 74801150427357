// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useMemo } from 'react';

import * as flags from '../../flags';
import { CurrentView } from '../../lib/componentTypes/context';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import { useCurrentView } from '../../state/internal/global/currentView';

export const useAssistantEnabled = () => {
  const currentView = useCurrentView();
  const aiAssistantEnabled = useIsEnabled(flags.aiAssistant);

  return useMemo(() => aiAssistantEnabled && [
    CurrentView.ADVANCED_ANALYSIS,
    CurrentView.GEOMETRY,
    CurrentView.SETUP,
  ].includes(currentView), [aiAssistantEnabled, currentView]);
};
