// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { matchPath } from 'react-router-dom';

import * as simulationpb from '../../proto/client/simulation_pb';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import { ViewName } from '../ParaviewClientEnums';
import { geometryLink, resultsLink, routes, setupLink } from '../navigation';

export enum CurrentView {
  SETUP = 0,
  ANALYSIS = 1,
  RESULTS = 2,
  GEOMETRY = 3,
  ADVANCED_ANALYSIS = 8, // in this page we setup DoE in the new workflow UI
}

export const currentViewMap = {
  [CurrentView.SETUP]: 'setup',
  [CurrentView.ANALYSIS]: 'analysis',
  [CurrentView.RESULTS]: 'results',
  [CurrentView.GEOMETRY]: 'geometry',
  [CurrentView.ADVANCED_ANALYSIS]: 'advanced analysis',
} as const;

export type CurrentViewPartial = (typeof currentViewMap)[keyof typeof currentViewMap];

export const getViewFromPartial = (partial: CurrentViewPartial): CurrentView => {
  switch (partial) {
    case 'geometry':
      return CurrentView.GEOMETRY;
    case 'setup':
      return CurrentView.SETUP;
    case 'analysis':
      return CurrentView.ANALYSIS;
    case 'results':
      return CurrentView.RESULTS;
    case 'advanced analysis':
      return CurrentView.ADVANCED_ANALYSIS;
    default:
      throw Error('Invalid partial type');
  }
};

export const getPathFromView = (view: CurrentView, projectId: string): string => {
  switch (view) {
    case CurrentView.GEOMETRY:
      return geometryLink(projectId);
    case CurrentView.SETUP:
      return setupLink(projectId);
    case CurrentView.RESULTS:
      return resultsLink(projectId);
    default:
      return '';
  }
};

export const getViewFromPath = (path: string): CurrentView => {
  if (matchPath(routes.advancedAnalysisV1, path) || matchPath(routes.advancedAnalysisV2, path)) {
    return CurrentView.ADVANCED_ANALYSIS;
  }
  return CurrentView.SETUP;
};

export type ProjectContextType = {
  projectId: string;
  workflowId: string;
  jobId: string;
  readOnly: boolean;
  geometryId: string;
  // Called to update the simulation parameters.
  onParamUpdate: (newParam: simulationpb.SimulationParam) => void;
  asyncOnParamUpdate: (newParam: simulationpb.SimulationParam) => Promise<void>;
  // Called to update the workflow config.
  onNewWorkflowConfig: (newConfig: workflowpb.Config) => void;
  copyToSetup: () => Promise<void>;
};

export const getParaviewViewName = (currentView: CurrentView) => (
  (currentView === CurrentView.SETUP) ? ViewName.SETUP : ViewName.SOLUTION
);
