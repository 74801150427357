import React from 'react';

import cx from 'classnames';

import { IconName } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { expandGroups } from '../../lib/entityGroupUtils';
import { getIconSpecDims } from '../../lib/svgIcon/utils';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeoClipValue, useToggleGeometryClip } from '../../recoil/geometry/geometryClipState';
import { useLcvisExplodeFactorState } from '../../recoil/lcvis/explodeFactor';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcvisMeasureValue } from '../../recoil/lcvis/lcvisMeasureState';
import { useLcvisProbeValue } from '../../recoil/lcvis/lcvisProbeState';
import { TRANSPARENCY_MODE_SUBTITLE, useTransparencySettings } from '../../recoil/lcvis/transparencySettings';
import { useEntitySelectionValue } from '../../recoil/selectionOptions';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { SvgIcon } from '../Icon/SvgIcon';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { createStyles, makeStyles } from '../Theme';
import { DEFAULT_TOOLBAR_ICON_SIZE } from '../Toolbar/ToolbarButton';
import Tooltip from '../Tooltip';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { FixedSizeWrapper } from '../layout/FixedSizeWrapper';
import { useHandleLcVisFilterClick } from '../visFilter/useHandleLcVisFilterClick';
import { useHandleProbeClick } from '../visFilter/useHandlePvFilterClick';

import environmentState from '@/state/environment';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      width: 'fit-content',
      gap: '2px',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      cursor: 'pointer',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: colors.neutral150,
      },
      '&.engaged': {
        backgroundColor: colors.neutral150,
      },
      '&.disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
    },
    icon: {
      backgroundColor: colors.neutral150,
      borderRadius: '4px',
      width: '36px',
      height: '36px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    text: {
      paddingRight: '8px',
      fontSize: '13px',
      'body.lcvis-light-background &': {
        color: colors.neutral150,
      },
      'body.lcvis-light-background .VisToolsPanel-item:hover &': {
        color: `inherit`,
      },
    },
  }),
  { name: 'VisToolsPanel' },
);

type VisToolItem = {
    icon: IconName;
    label: string;
    onClick: (ev: React.MouseEvent | React.KeyboardEvent) => void;
    key: string;
    engaged: boolean;
    disabled: boolean;
    tooltip: string;
}

type VisToolMenuProps = {
    items: VisToolItem[];
}

const VisToolMenu = ({ items }: VisToolMenuProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {items.map((item) => (
        <Tooltip key={item.key} placement="right" title={item.tooltip}>
          <div
            className={cx(
              classes.item,
              { engaged: item.engaged },
              { disabled: item.disabled },
            )}
            key={item.key}
            onClick={(ev) => {
              if (!item.disabled) {
                item.onClick(ev);
              }
            }}
            onKeyDown={(ev) => {
              if (!item.disabled && (ev.key === 'Enter' || ev.key === ' ')) {
                item.onClick(ev);
              }
            }}
            role="button"
            tabIndex={0}>
            <div className={classes.icon}>
              <FixedSizeWrapper
                height={DEFAULT_TOOLBAR_ICON_SIZE}
                width={DEFAULT_TOOLBAR_ICON_SIZE}>
                <SvgIcon
                  {...getIconSpecDims(
                    { name: item.icon },
                    DEFAULT_TOOLBAR_ICON_SIZE,
                    DEFAULT_TOOLBAR_ICON_SIZE,
                  )}
                  name={item.icon}
                />
              </FixedSizeWrapper>
            </div>
            <div className={classes.text}>{item.label}</div>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export const useVisToolsItems = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { viewState } = useParaviewContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcVisReady = environmentState.use.lcvisReady;
  const lcvisProbe = useLcvisProbeValue();
  const lcvisMeasure = useLcvisMeasureValue();
  const handleLcvButtonClick = useHandleLcVisFilterClick();
  const [transparencySettings, setTransparencySettings] = useTransparencySettings();
  const entitySelectionState = useEntitySelectionValue(projectId);
  const { selectedNodeIds } = useSelectionContext();
  const { groupMap: entityGroupMap, leafMap } = useEntityGroupData(projectId, workflowId, jobId);
  const [explodeFactor, setExplodeFactor] = useLcvisExplodeFactorState(projectId);
  const isGeometryView = useIsGeometryView();
  const geoClipState = useGeoClipValue(projectId);
  const startGeoClip = useToggleGeometryClip(projectId);
  const handleParaviewProbe = useHandleProbeClick();

  const canMakeSelectionTransparent = (
    entitySelectionState === 'surface' &&
    selectedNodeIds.length &&
    entityGroupMap.has(selectedNodeIds[0])
  );

  const menuItems: VisToolItem[] = [];

  if (lcvisEnabled && isGeometryView) {
    menuItems.push({
      icon: 'scissors',
      label: 'Clip Tool',
      onClick: startGeoClip,
      key: 'clipBtn',
      engaged: geoClipState.active,
      disabled: !lcVisReady,
      tooltip: 'Use a clip plane to view a section of the geometry.',
    });
  }

  if (lcvisEnabled) {
    menuItems.push({
      icon: 'transparency',
      label: 'Transparency Mode',
      onClick: () => setTransparencySettings((prev) => ({
        ...prev,
        active: !prev.active,
        surfaces: (
          (prev.active || !canMakeSelectionTransparent) ?
            new Set() :
            new Set(expandGroups(leafMap)(selectedNodeIds))
        ),
      })),
      key: 'transparencyBtn',
      engaged: transparencySettings.active,
      disabled: !lcVisReady,
      tooltip: TRANSPARENCY_MODE_SUBTITLE,
    });

    menuItems.push({
      icon: 'cubeFacesOutline',
      label: 'Exploded View',
      onClick: () => {
        setExplodeFactor((prev) => {
          if (prev === null) {
            return 0;
          }
          return null;
        });
      },
      key: 'explodeBtn',
      engaged: explodeFactor !== null,
      disabled: !lcVisReady,
      tooltip: 'Expand objects out from the center of the scene\'s visible bounds.',
    });
  }

  // If LCVis is not enabled, only show the probe button
  menuItems.push({
    icon: 'probe',
    label: 'Probe',
    onClick: async (ev) => {
      if (lcvisEnabled) {
        await handleLcvButtonClick(ev, 'probe');
      } else {
        handleParaviewProbe();
      }
    },
    key: 'probeBtn',
    engaged: lcvisProbe.active,
    disabled: lcvisEnabled ? !lcVisReady : !viewState,
    tooltip: 'Probe',
  });

  if (lcvisEnabled) {
    menuItems.push({
      icon: 'ruler',
      label: 'Measure',
      onClick: (ev) => handleLcvButtonClick(ev, 'measure'),
      key: 'measureBtn',
      engaged: lcvisMeasure.active,
      disabled: !lcVisReady,
      tooltip: 'Measure',
    });
  }

  return menuItems;
};

/**
 * A panel containing vis tools,
 * e.g. probe/measure/transparency.
 */
export const VisToolsPanel = () => {
  const classes = useStyles();
  const menuItems = useVisToolsItems();

  return (
    <div className={classes.root}>
      <VisToolMenu items={menuItems} />
    </div>
  );
};
