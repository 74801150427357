// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useRef, useState } from 'react';

import { useRecoilCallback } from 'recoil';

import { ParamType } from '../../ProtoDescriptor';
import { paramDesc, paramGroupDesc } from '../../SimulationParamDescriptor';
import WorkflowConfigValidator, { formatValidatorMessage } from '../../lib/WorkflowConfigValidator';
import assert from '../../lib/assert';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { upgradeMultiPhysicsPresets } from '../../lib/configUpgradeUtils';
import { FARFIELD_ID, FARFIELD_NAME } from '../../lib/farfieldUtils';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { paramCallback } from '../../lib/paramCallback';
import { DEFAULT_FRONTEND_MENU_STATE } from '../../lib/paramDefaults/frontendMenuState';
import { defaultConfig } from '../../lib/paramDefaults/workflowConfig';
import { upgradeUploadedParam } from '../../lib/paramUpgradeUtils';
import { createValidScope, updateSimulationParam } from '../../lib/paramUtils';
import { lazyValidViewState, lazyViewStatesSimilarEnough } from '../../lib/paraviewUtils';
import { getFluid, getHeat, getPhysicsId } from '../../lib/physicsUtils';
import { useUserCanEdit } from '../../lib/projectRoles';
import { jsonToProto, updateExploration } from '../../lib/proto';
import { saveAsJson, saveObjectAsJson, saveParamAsJsonSafe } from '../../lib/saveAsJson';
import { getSimulationParam } from '../../lib/simulationParamUtils';
import sleep from '../../lib/sleep';
import * as status from '../../lib/status';
import { updateStoppingConds } from '../../lib/stoppingCondsUtils';
import {
  PARAM_VALIDATION_ERROR_NOTIFICATION_ID,
  addInfo,
  addPvRpcError,
  addRpcError,
  addWarning,
  setNotification,
} from '../../lib/transientNotification';
import upgradePvproto from '../../lib/upgradePvproto';
import * as basepb from '../../proto/base/base_pb';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as entitypb from '../../proto/entitygroup/entitygroup_pb';
import * as explorationpb from '../../proto/exploration/exploration_pb';
import { OutputNodes } from '../../proto/frontend/output/output_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { addPhysicsResidualsCallback } from '../../recoil/addPhysicsResidualNode';
import {
  createDefaultGroupMap,
  entityGroupDataSelector,
  entityGroupState,
  pruneGroups,
  updateGroups,
  useEntityGroupMap,
} from '../../recoil/entityGroupState';
import { frontendMenuState } from '../../recoil/frontendMenuState';
import { geometryTagsState } from '../../recoil/geometry/geometryTagsState';
import { useGeometryUsesTags } from '../../recoil/geometry/geometryUsesTags';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { meshMetadataSelector, useMeshMetadata, useMeshUrlState } from '../../recoil/meshState';
import { outputNodesState, useOutputNodes } from '../../recoil/outputNodes';
import { useCameraPosition } from '../../recoil/paraviewState';
import { useCadMetadata } from '../../recoil/useCadMetadata';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useControlPanelMode } from '../../recoil/useProjectPage';
import {
  defaultStopConds,
  stoppingConditionsSelectorUpdate,
  stoppingConditionsState,
} from '../../recoil/useStoppingConditions';
import { useCurrentTab } from '../../recoil/useTabsState';
import { useResetFilterState } from '../../recoil/vis/filterState';
import { defaultVolumeState, staticVolumesState, useStaticVolumes } from '../../recoil/volumes';
import { projectConfigState, useCurrentConfig, useSetProjectConfig } from '../../recoil/workflowConfig';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { IconButton } from '../Button/IconButton';
import { CommonMenu } from '../Menu/CommonMenu';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import Tooltip from '../Tooltip';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { FileUpload } from '../controls/FileUpload';
import { ImportLibraryItemDialog } from '../dialog/ImportLibraryItem';
import { SaveEditLibraryItemDialog } from '../dialog/SaveEditLibraryItem';
import { HorizontalCirclesTripleIcon } from '../svg/HorizontalCirclesTripleIcon';

import environmentState from '@/state/environment';

const { INVALID_INITIALIZATION_TYPE } = simulationpb.InitializationType;

const onExplorationDownload = (exp: explorationpb.Exploration) => saveAsJson(
  exp,
  'exploration.json',
);

const onParamDownload = (param: simulationpb.SimulationParam) => saveAsJson(param, 'param.json');

type FileUploadKey = 'camera' | 'view' | 'settings';
type FileUploadState = Record<FileUploadKey, boolean>;

export const useOnParamUpload = () => {
  const {
    projectId,
    workflowId,
    jobId,
  } = useProjectContext();
  const { impostersUpdate, paraviewActiveUrl } = useParaviewContext();

  const staticVolumes = useStaticVolumes(projectId);
  const experimentConfig = useEnabledExperiments();
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const meshMetadata = useMeshMetadata(projectId, paraviewActiveUrl);
  const addResidualNodes = useRecoilCallback(addPhysicsResidualsCallback);

  const syncOutputs = async (nodes: OutputNodes, newParam: simulationpb.SimulationParam) => {
    // Maintain a set of physics IDs that need a residual output node
    const physicsIds = new Set(newParam.physics.map((physics) => getPhysicsId(physics)));

    nodes.nodes = nodes.nodes.filter((output) => {
      if (output.nodeProps.case === 'residual') {
        const { physicsId } = output.nodeProps.value;
        if (physicsIds.has(physicsId)) {
          physicsIds.delete(physicsId);
          return true;
        }
        // The physics ID referenced in the output is no longer valid, so remove this output
        return false;
      }
      return true;
    });

    // Any physics IDs left need a residual output node
    const newPhysics = newParam.physics.filter((physics) => physicsIds.has(getPhysicsId(physics)));
    await addResidualNodes(newPhysics, projectId, workflowId, jobId);
  };

  // onParamUpload is called when a new JSON file is uploaded to define simulation parameters.
  const onParamUpload = useRecoilCallback(
    ({ set, snapshot: { getPromise } }) => async (file: File, showToast = true) => {
      const json = await file.text();
      const proto = await jsonToProto(json, simulationpb.SimulationParam);

      const newStaticVolumes = await getPromise(staticVolumesState(projectId));
      const geometryTags = await getPromise(geometryTagsState({ projectId }));
      upgradeUploadedParam(proto, geometryTags, newStaticVolumes);

      // Make sure that the configuration is valid before applying it.
      let valid = true;
      const validator = new WorkflowConfigValidator(async (err: basepb.Status) => {
        if (err.code !== 0) {
          // Invalid configuration.
          setNotification(
            PARAM_VALIDATION_ERROR_NOTIFICATION_ID,
            'error',
            formatValidatorMessage(err),
            err,
          );
          valid = false;
        }
      });

      const { paramScope, validParam } =
        createValidScope(proto, experimentConfig, geometryTags, newStaticVolumes);
      validParam.input = simParam.input!.clone();
      const newConfig = updateSimulationParam(
        config,
        validParam,
        paramScope,
        geometryTags,
        newStaticVolumes,
      );
      upgradeMultiPhysicsPresets(newConfig, new projectstatepb.FrontendMenuState());

      if (!meshMetadata?.meshMetadata) {
        return;
      }
      await validator.checkAsync(newConfig, meshMetadata?.meshMetadata);
      if (!valid) {
        // The uploaded configuration is not valid, do not set it in the state.
        return;
      }

      // Set the new states. Note: the order of the "set" calls below is important.
      set(projectConfigState(projectId), newConfig);

      set(entityGroupState({ projectId, workflowId, jobId }), (oldGroups) => {
        const newParam = getSimulationParam(newConfig);
        const [newGroups] = pruneGroups(oldGroups, newParam);
        // Update surface names
        Object.entries(proto.surfaceName).forEach(([key, entry]) => {
          if (newGroups.has(key)) {
            newGroups.get(key).name = entry.surfaceName;
          }
        });
        updateGroups({
          groupMap: newGroups,
          param: newParam,
          meshMetadata: meshMetadata?.meshMetadata,
          staticVolumes,
        });
        return newGroups;
      });
      const oldNodes = await getPromise(outputNodesState({ projectId, workflowId, jobId }));
      await syncOutputs(oldNodes, validParam);

      impostersUpdate(proto);
      if (showToast) {
        addInfo(`Uploaded ${file.name}`);
      }
    },
  );
  return onParamUpload;
};

export const usePrepareSimulationSettings = () => {
  const {
    projectId,
    workflowId,
    jobId,
  } = useProjectContext();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);

  const prepareSettings = () => {
    const newParam = simParam.clone();
    // Store the surfaces names in the param so that they can be restored when uploading the
    // settings
    newParam.surfaceName = {};
    entityGroupMap.getGroups(
      (group) => group.entityType === entitypb.EntityType.SURFACE,
    ).forEach(
      (group) => {
        newParam.surfaceName[group.id] = new simulationpb.SurfaceName({
          surfaceName: group.name,
        });
      },
    );

    // Remove any references to internally stored data
    newParam.tableReferences = {};
    // Clear table references and urls
    paramCallback(
      newParam,
      paramGroupDesc.simulation_param,
      ({ param, set }) => {
        // Exclude particle positions table (monitor points) because they are stored directly in
        // the param rather than in gcs.
        const tableUrl = (
          paramDesc[param.name].type === ParamType.TABLE &&
          param !== paramDesc.particle_positions_table
        );
        if (
          tableUrl ||
          param === paramDesc.url
        ) {
          set('');
        }
      },
      () => { },
      null,
    );
    return newParam;
  };
  return prepareSettings;
};

// This is the more options menu above the simulation tree that allows users to edit it. Users can
// add save/import to library and download/upload/reset simulation settings.
const SimulationTreeMoreMenu = () => {
  // == Contexts
  const {
    paraviewProjectId,
    paraviewActiveUrl,
    paraviewMeshMetadata,
    paraviewRenderer,
    paraviewClientState,
    setSyncing,
    onRpcSuccess,
    viewState,
    resetVisState,
  } = useParaviewContext();
  const {
    projectId,
    workflowId,
    jobId,
    readOnly,
    onNewWorkflowConfig,
  } = useProjectContext();
  const { isTreeModal, setSelection } = useSelectionContext();

  // == Recoil
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const setProjectConfig = useSetProjectConfig(projectId);
  const meshMetadata = useMeshMetadata(projectId, paraviewActiveUrl);
  const currentTab = useCurrentTab(projectId);
  const [controlPanelMode] = useControlPanelMode();
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const [outputNodes] = useOutputNodes(projectId, '', '');

  const isStaff = useIsStaff();
  const projectMetadata = useProjectMetadata(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  const [meshUrlState] = useMeshUrlState(projectId);
  const setConfirmStack = useSetConfirmations();
  const [cameraPosition] = useCameraPosition(
    paraviewProjectId,
    paraviewActiveUrl,
    paraviewMeshMetadata,
  );
  const geoUsesTags = useGeometryUsesTags(projectId);

  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const resetFilterState = useResetFilterState({ projectId, workflowId, jobId });

  const onParamUpload = useOnParamUpload();

  // == State
  const [saveLibraryItemDialogOpen, setSaveLibraryItemDialogOpen] = useState<boolean>(false);
  const [importLibraryItemDialogOpen, setImportLibraryItemDialogOpen] = useState<boolean>(false);
  const [ellipsisOpened, setEllipsisOpened] = useState(false);
  // Manage state for various file uploaders
  const [fileUploadState, setFileUploadState] = useState<FileUploadState>({
    camera: false,
    view: false,
    settings: false,
  });
  const ellipsisRef = useRef<HTMLButtonElement | null>(null);

  // == Data
  const projectName = projectMetadata ? projectMetadata.summary!.name : '';

  const updateUploadState = (key: FileUploadKey, open: boolean) => {
    setFileUploadState({ ...fileUploadState, [key]: open });
  };

  const hasInvalidInitialization = simParam.physics.some((physics) => {
    const fluid = getFluid(physics);
    const heat = getHeat(physics);
    return (
      (fluid?.initializationFluid?.initializationType === INVALID_INITIALIZATION_TYPE) ||
      (heat?.initializationHeat?.initializationType === INVALID_INITIALIZATION_TYPE)
    );
  });

  const onExplorationUpload = (file: File) => {
    const uploadAsync = async () => {
      const json = await file.text();
      const newExperiment = await jsonToProto(json, explorationpb.Exploration);
      if (!newExperiment) {
        throw Error('Invalid experiment file');
      }
      setProjectConfig(
        updateExploration(config, newExperiment),
      );
      addInfo(`Uploaded ${file.name}`);
    };
    uploadAsync().then(() => { }).catch((err: Error) => {
      addRpcError('Error uploading experiment', err, { projectId, projectName });
    });
  };

  // onViewStateUpload is called when a new view state JSON file is uploaded.
  // to define simulation parameters.
  const onViewStateUpload = (file: File) => {
    const uploadAsync = async () => {
      if (!viewState) {
        return;
      }
      const js = file.text();
      const inViewState = JSON.parse(await js);

      // Try to upgrade and cast the input view state.
      const newViewState = upgradePvproto(inViewState) as ParaviewRpc.ViewState;

      // Check if the view state is sufficiently valid given that we allow upgrades.
      // This is needed because ParaviewRpc.ViewState is not a proto message so we
      // need to implement manual checks.
      if (!lazyValidViewState(inViewState)) {
        addWarning('Invalid view state');
        return;
      }

      // Check if the input view state refers to the same mesh.
      // NOTE: this check is very lazy, please don't use it in production.
      if (!lazyViewStatesSimilarEnough(newViewState, viewState)) {
        addWarning('The uploaded view state could not be applied');
        return;
      }

      // Replace the solution/mesh urls to match what's being shown in the UI.
      const rootParam = viewState!.root.param as ParaviewRpc.ReaderParam;
      (newViewState.root.param as ParaviewRpc.ReaderParam).url = rootParam.url;
      newViewState.path = viewState.path;

      // Sync nodes.
      setSyncing(true);
      ParaviewRpc.syncnodes(
        paraviewClientState.client!,
        newViewState.root,
        newViewState.attrs!,
        cameraPosition,
      )
        .then((result: ParaviewRpc.RpcResult) => {
          onRpcSuccess('syncnodes', result);
        })
        .catch((err: status.ParaviewError) => {
          addPvRpcError('Could not open file in ParaView', err);
        }).finally(() => {
          setSyncing(false);
        });
      addInfo(`Uploaded ${file.name}`);
    };
    uploadAsync().then(() => { }).catch((err: Error) => {
      addRpcError('Error uploading view state', err, { projectId, projectName });
    });
  };

  // onCameraStateUpload is called when a new camera state JSON file is uploaded.
  const onCameraStateUpload = (file: File) => {
    const uploadAsync = async () => {
      if (!viewState) {
        return;
      }
      const js = file.text();
      const cameraState = JSON.parse(await js) as ParaviewRpc.CameraState;

      // Update the camera state on the renderer side.
      paraviewRenderer.updateCameraState(cameraState);

      // Sync nodes.
      // TODO(gonzalo): make a setCameraState RPC, calling syncnodes here
      // is an overkill.
      setSyncing(true);
      ParaviewRpc.syncnodes(
        paraviewClientState.client!,
        viewState!.root!,
        viewState!.attrs!,
        cameraState,
      )
        .then((result: ParaviewRpc.RpcResult) => {
          onRpcSuccess('syncnodes', result);
        })
        .catch((err: status.ParaviewError) => {
          addPvRpcError('Could not open file in ParaView', err);
        }).finally(() => {
          setSyncing(false);
        });
      addInfo(`Uploaded ${file.name}`);
    };
    uploadAsync().then(() => { }).catch((err: Error) => {
      addRpcError('Error uploading camera state', err, { projectId, projectName });
    });
  };

  const [cadMetadata] = useCadMetadata(projectId);
  const handleResetSettings = useRecoilCallback(
    ({ set, snapshot: { getPromise } }) => async () => {
      const recoilKey = { projectId, workflowId, jobId };
      if (controlPanelMode === 'simulation') {
        const currMeshMetadata = await getPromise(
          meshMetadataSelector({ projectId, meshUrl: meshUrlState.geometry }),
        );
        const defaultConf = defaultConfig(
          meshUrlState.mesh,
          null,
          meshUrlState.meshId,
        );
        const newStaticVolumes =
          defaultVolumeState(currMeshMetadata?.meshMetadata, cadMetadata, geoUsesTags);

        set(projectConfigState(projectId), defaultConf);
        set(staticVolumesState(projectId), newStaticVolumes);
        // Make sure to restart the frontend menu state to the default values, since the user asked
        // to fully wipe any custom state (i.e. presets, materials, ...).
        set(frontendMenuState(recoilKey), DEFAULT_FRONTEND_MENU_STATE);
        if (!geoUsesTags) {
          // We cannot disable the update callback of the entity group because in this if condition
          // the project had modifiable entity groups and hence the entity groups could be of mixed
          // types.
          const newGroupMap = createDefaultGroupMap({
            param: getSimulationParam(defaultConf),
            meshMetadata: currMeshMetadata?.meshMetadata,
            staticVolumes: newStaticVolumes,
          }, false);
          // NOTE: in the pre-tags world, farfields were not generated in the geometry tab, so we
          // keep this behavior for backwards compatibility. When the geometry has tags, we don't
          // reset the entity groups since they are immutable.
          // Changing the farfield and regenerating the mesh is an expensive operation. Reset
          // settings should not change the farfield. Copy the existing farfield group.
          const farfieldGroup = entityGroupMap.has(FARFIELD_ID) && entityGroupMap.get(FARFIELD_ID);
          if (farfieldGroup) {
            newGroupMap.group(FARFIELD_NAME, [...farfieldGroup.children], FARFIELD_ID);
          }
          set(entityGroupState({ projectId, workflowId, jobId }), newGroupMap);
        }
        set(
          stoppingConditionsState(recoilKey),
          defaultStopConds(defaultConf),
        );
      } else {
        const newExploration = config.exploration!.clone();
        newExploration.var = [];
        onNewWorkflowConfig(
          updateExploration(config, newExploration),
        );
      }
      setSelection([]);
      setEllipsisOpened(false);
    },
  );
  const prepareSettings = usePrepareSimulationSettings();

  const handleDownloadSettings = () => {
    if (controlPanelMode === 'simulation') {
      const newParam = prepareSettings();
      onParamDownload(newParam);
    } else {
      const newExploration = config.exploration!.clone();
      // Remove any references to internally stored data
      newExploration.tableReferences = {};
      if (newExploration.policy.case === 'custom') {
        newExploration.policy.value.table = '';
        newExploration.var.forEach((variable) => {
          variable.valueTyp = { case: 'column', value: new explorationpb.TableColumn() };
        });
      }
      onExplorationDownload(newExploration);
    }
    setEllipsisOpened(false);
  };

  const handleDownloadCameraState = () => {
    saveObjectAsJson(
      cameraPosition,
      `cameraState Project ${projectName} ${currentTab!.text}.json`,
    );
    setEllipsisOpened(false);
  };

  const handleDownloadViewState = () => {
    saveObjectAsJson(viewState, `viewState Project ${projectName} ${currentTab!.text}.json`);
    setEllipsisOpened(false);
  };

  const handleDownloadRawParams = useRecoilCallback(
    ({ snapshot: { getPromise } }) => async () => {
      assert(isStaff, 'Non-staff user');
      // If we are in the setup tab, set the stopping conditions in the param. Else, reuse what's
      // already inside the param.
      let paramToDownload = simParam.clone();
      if (jobId === '') {
        const recoilKey = { projectId, workflowId, jobId };
        const stopConds = await getPromise(stoppingConditionsSelectorUpdate(recoilKey));
        const entityGroupData = await getPromise(entityGroupDataSelector(recoilKey));
        paramToDownload = updateStoppingConds(
          stopConds,
          paramToDownload,
          outputNodes,
          entityGroupData,
        );
      }

      // Save as json, bypassing the stable API conversion.
      saveAsJson(paramToDownload, 'param_raw.json', false);
      setEllipsisOpened(false);
    },
  );

  const handleDownloadSimulationParamJSON = useRecoilCallback(
    ({ snapshot: { getPromise } }) => async () => {
      // If we are in the setup tab, set the stopping conditions in the param. Else, reuse what's
      // already inside the param.
      let paramToDownload = simParam.clone();
      if (jobId === '') {
        const recoilKey = { projectId, workflowId, jobId };
        const stopConds = await getPromise(stoppingConditionsSelectorUpdate(recoilKey));
        const entityGroupData = await getPromise(entityGroupDataSelector(recoilKey));
        paramToDownload = updateStoppingConds(
          stopConds,
          paramToDownload,
          outputNodes,
          entityGroupData,
        );
      }

      // Save as json. This will fail if the param object has internal URLs.
      saveParamAsJsonSafe(paramToDownload, 'api_simulation_params.json');
      setEllipsisOpened(false);
    },
  );

  const handleUploadCameraState = async () => {
    updateUploadState('camera', true);
  };

  const handleUploadViewState = async () => {
    updateUploadState('view', true);
  };

  const handleUploadSettingsState = async () => {
    updateUploadState('settings', true);
  };

  const queueResetSettings = () => {
    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: async () => {
        // handleResetSettings() sets new recoil state which seems to stall the current execution
        // context.  Adding a short sleep here lets the spinner show up on the dialog's continue
        // button,
        await sleep(100);
        await handleResetSettings();
        if (lcvisEnabled) {
          if (lcvisReady) {
            lcvHandler.display!.filterHandler!.deleteAllNodes();
          }
          resetFilterState();
          addInfo('Reset visualization filters');
        } else {
          await resetVisState();
        }
      },
      title: 'Reset Simulation Settings',
      children: (
        <div>
          This will completely reset the simulation and visualization settings. Are you sure you
          want to proceed?
        </div>
      ),
    });
  };
  const queueSaveLibraryItem = () => {
    setSaveLibraryItemDialogOpen(true);
  };
  const queueImportLibraryItem = () => {
    setImportLibraryItemDialogOpen(true);
  };

  const menuItems: CommonMenuItem[] = [
    {
      title: 'LIBRARY',
    },
    {
      label: 'Save settings to Library',
      nameAttribute: 'SAVE_TO_LIBRARY',
      onClick: queueSaveLibraryItem,
    },
    {
      disabled: readOnly,
      disabledReason: 'Settings import is only available in the Setup tab',
      label: 'Import settings from Library',
      nameAttribute: 'IMPORT_FROM_LIBRARY',
      onClick: queueImportLibraryItem,
    },
    {
      separator: true,
    },
    {
      title: 'JSON',
    },
    {
      disabled: hasInvalidInitialization,
      disabledReason: 'Cannot download settings with Existing Solution initialization type',
      label: controlPanelMode === 'simulation' ?
        'Download Settings' : 'Download Experiment Settings',
      help: controlPanelMode === 'simulation' ?
        'Download solver settings as JSON' : 'Download experiment settings as JSON',
      onClick: handleDownloadSettings,
    },
    {
      disabled: !userCanEdit || readOnly || !meshMetadata?.meshMetadata,
      label: 'Upload Settings',
      help: controlPanelMode === 'simulation' ?
        'Upload solver settings as JSON' : 'Upload experiment settings as JSON',
      onClick: handleUploadSettingsState,
    },
  ];

  menuItems.push({
    label: 'Download simulation parameters (API use only)',
    help: 'For API use only, cannot be re-uploaded in the UI. Schema is currently unstable and ' +
      'may not be compatible with different versions of the API.',
    onClick: handleDownloadSimulationParamJSON,
  });

  if (viewState && isStaff) {
    menuItems.push(
      {
        separator: true,
      },
      {
        title: 'STAFF ONLY',
      },
      {
        label: 'Download Camera State',
        onClick: handleDownloadCameraState,
      },
      {
        label: 'Download View State',
        onClick: handleDownloadViewState,
      },
    );
    if (userCanEdit) {
      menuItems.push(
        {
          label: 'Upload Camera State',
          help: 'Upload camera state as JSON',
          onClick: handleUploadCameraState,
        },
        {
          label: 'Upload View State',
          help: 'Upload view state as JSON',
          onClick: handleUploadViewState,
        },
      );
    }
  }
  if (isStaff) {
    menuItems.push(
      {
        label: 'Download Params',
        help: 'Download the Raw Params (API input only)',
        onClick: handleDownloadRawParams,
      },
    );
  }
  if (!readOnly) {
    menuItems.push(
      {
        separator: true,
      },
      {
        label: 'Reset All Settings',
        nameAttribute: 'RESET_SETTINGS',
        onClick: () => {
          setEllipsisOpened(false);
          queueResetSettings();
        },
      },
    );
  }
  return (
    <div>
      {userCanEdit && (
        <>
          <FileUpload
            onOpened={() => updateUploadState('settings', false)}
            onUploadFiles={async (files: File[]) => {
              if (controlPanelMode === 'simulation') {
                await onParamUpload(files[0]);
              } else {
                onExplorationUpload(files[0]);
              }
            }}
            opening={fileUploadState.settings}
          />
          <FileUpload
            accept=".json,application/json"
            onOpened={() => updateUploadState('camera', false)}
            onUploadFiles={async (files: File[]) => {
              onCameraStateUpload(files[0]);
              setEllipsisOpened(false);
            }}
            opening={fileUploadState.camera}
          />
          <FileUpload
            accept=".json,application/json"
            onOpened={() => updateUploadState('view', false)}
            onUploadFiles={async (files: File[]) => {
              onViewStateUpload(files[0]);
              setEllipsisOpened(false);
            }}
            opening={fileUploadState.view}
          />
        </>
      )}
      <Tooltip placement="bottom" title="More options">
        <IconButton
          disabled={isTreeModal}
          name="moreOptionsMenuButton"
          onClick={() => setEllipsisOpened(true)}
          ref={ellipsisRef}>
          <HorizontalCirclesTripleIcon maxWidth={12} />
        </IconButton>
      </Tooltip>
      <CommonMenu
        anchorEl={ellipsisRef.current}
        closeOnSelect
        menuItems={menuItems}
        onClose={() => setEllipsisOpened(false)}
        open={ellipsisOpened}
      />
      <SaveEditLibraryItemDialog
        onClose={() => setSaveLibraryItemDialogOpen(false)}
        open={saveLibraryItemDialogOpen}
      />
      <ImportLibraryItemDialog
        onClose={() => setImportLibraryItemDialogOpen(false)}
        open={importLibraryItemDialogOpen}
        projectId={projectId}
      />
    </div>
  );
};

export default SimulationTreeMoreMenu;
