// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from 'react';

import { callAfterAnimation } from '../../../lib/animationUtils';
import { colors, hexToRgb } from '../../../lib/designSystem';
import { DEFAULT_FILTER_ROOT } from '../../../lib/visUtils';
import { useLcvisFilterStatusValue } from '../../../recoil/lcvis/lcvisFilterStatus';
import { useIsAnalysisView } from '../../../state/internal/global/currentView';
import { createStyles, makeStyles } from '../../Theme';
import { ProgressBar } from '../../visual/ProgressBar';

const useStyles = makeStyles(() => createStyles({
  overlay: {
    backgroundColor: hexToRgb(colors.surfaceBackground, 0.5),
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    overflow: 'hidden',
  },
  dialog: {
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    position: 'absolute',
  },
  title: {
    fontSize: '14',
    fontColor: colors.highEmphasisText,
    fontWeight: 600,
    paddingBottom: '24px',
    textShadow: '0px 1px 1px #000000',
  },
}), { name: 'LcVisLoading' });

/** A Black overlay to show when lcvis is still loading the dataset. */
const LoadingOverlay = ({ suspense = false }: {suspense?: boolean}) => {
  const classes = useStyles();
  const filterStatus = useLcvisFilterStatusValue();
  const analysisView = useIsAnalysisView();
  const [delayedFilterProgress, setDelayedFilterProgress] = useState(0);
  const delayProgress = useRef(filterStatus.has(DEFAULT_FILTER_ROOT));

  // The progress bar is based on the status of the import dataset filter,
  // which is given the id of DEFAULT_FILTER_ROOT in LCVis.
  const rootStatus = filterStatus.get(DEFAULT_FILTER_ROOT)?.status;
  const filterProgressInPercent = (() => {
    if (rootStatus === 'queued') {
      return 0;
    }
    if (rootStatus === 'completed' || rootStatus === 'error') {
      return 100;
    }
    return rootStatus ?? 0;
  })();

  // `filterProgressInPercent` resets when switching tabs (e.g., Setup to Result).
  // The issue: progress resets after the loader shows, making it seem like progress drops.
  // Fixed it with an effect that enforces progress to start below 100. It looks smoother.
  // Worst case: the progress bar shows "fully loaded" before lcvis is 100% ready, which seems fine.
  useEffect(() => {
    if (delayProgress.current && filterProgressInPercent === 100) {
      setDelayedFilterProgress(0);
    } else if (filterProgressInPercent !== 100) {
      delayProgress.current = false;
      setDelayedFilterProgress(filterProgressInPercent);
    }
  }, [filterProgressInPercent]);

  const progressItems = [
    ...(analysisView ? [] : [suspense ? 0 : 100]),
    suspense ? 0 : delayedFilterProgress,
  ];

  const totalProgressInPercent = (
    progressItems.reduce((result, item) => result + item) / progressItems.length
  );

  // (LC-17037) This is a band-aid fix to hide the loading overlay when generating a far-field.
  // Currently generating a farfield filter in LCVis happens very quickly, so we get a 'flicker'
  // where the overlay is visible. We remove it by waiting for 100ms (+ 1 frame) before showing
  // the overlay.
  const [showOverlay, setShowOverlay] = useState(suspense);
  useEffect(() => {
    const cancel = callAfterAnimation(() => setShowOverlay(true), 100);
    return cancel;
  }, []);
  if (!showOverlay) {
    return <></>;
  }
  let loadingPhrase: string;

  if (analysisView) {
    loadingPhrase = 'Computing Visualization Fields...';
  } else if (suspense) {
    loadingPhrase = 'Downloading project...';
  } else {
    loadingPhrase = 'Setting up environment...';
  }

  return (
    <>
      <div className={classes.overlay} data-locator={suspense ? 'loading-widget' : undefined}>
        <div className={classes.dialog}>
          <div className={classes.title}>
            {loadingPhrase}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '50%' }}>
              <ProgressBar
                backgroundColor="rgb(255, 255, 255, 0.5)"
                height={5}
                progress={totalProgressInPercent / 100}
                transitionDuration={100}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingOverlay;
