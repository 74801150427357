// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';

import { RgbColor } from '../../../designSystem';
import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { SliceState } from './filterUtils';

/**
 * A filter for making a slice on a mesh.
 */
export class LcvSlice extends LcvFilter {
  state: SliceState;
  parent: LcvFilter;
  color: RgbColor | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: SliceState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'slice',
        `slice_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'slice',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  /**
   * Update the mesh_extra field command to be compatible with the new filter state.
   */
  updateParams() {
    const { state } = this;
    const { origin, normal } = state.plane;

    this.setParam('origin', LCVType.kLCVDataTypeFloat3, origin);
    this.setParam('normal', LCVType.kLCVDataTypeFloat3, normal);
    this.setParam('project_vectors', LCVType.kLCVDataTypeUint, state.projectVectors ? 1 : 0);
  }

  setState(newState: SliceState) {
    if (deepEqual(this.state, newState)) {
      return;
    }
    this.state = newState;
    return this.updateParams();
  }
}
