// Copyright 2021-2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { Empty } from '@bufbuild/protobuf';

import { ActionButton } from '../../components/Button/ActionButton';
import { IconButton } from '../../components/Button/IconButton';
import { createStyles, makeStyles } from '../../components/Theme';
import { Table } from '../../components/data/Table';
import { Dialog } from '../../components/dialog/Base';
import { CopyIcon } from '../../components/svg/CopyIcon';
import { CircularLoader } from '../../components/visual/CircularLoader';
import { colors } from '../../lib/designSystem';
import * as rpc from '../../lib/rpc';
import { addRpcError } from '../../lib/transientNotification';
import * as frontendpb from '../../proto/frontend/frontend_pb';

const useStyles = makeStyles(
  () => createStyles({
    flexContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
    },
  }),
  { name: 'ApiKeySection' },
);

export const ApiKeySection = () => {
  const classes = useStyles();
  const [apiKeys, setApiKeys] = useState<frontendpb.ApiKeyInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [keyToRevoke, setKeyToRevoke] = useState<string | null>(null);
  const [keyToDelete, setKeyToDelete] = useState<string | null>(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newKeyName, setNewKeyName] = useState('');
  const [newKeyValue, setNewKeyValue] = useState<string | null>(null);
  const defaultExpirationMonths = 3;
  const [expirationMonths, setExpirationMonths] = useState<number>(defaultExpirationMonths);

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const req = new Empty();
        const response = await rpc.callRetry(
          'ListApiKeys',
          rpc.client.listApiKeys,
          req,
        );
        setApiKeys(response.apiKeyInfo);
      } catch (err) {
        addRpcError('Error fetching API keys', err);
      } finally {
        setLoading(false);
      }
    };

    // Explicitly mark the promise as handled
    fetchApiKeys().catch((err) => {
      addRpcError('Error in fetchApiKeys', err);
    });
  }, []);

  const handleCreateKey = () => {
    setIsCreateDialogOpen(true);
  };

  const handleCreateKeySubmit = async () => {
    if (isCreating) {
      return;
    }
    setIsCreating(true);
    try {
      const req = new frontendpb.CreateApiKeyRequest();
      req.name = newKeyName;
      req.expirationMonths = expirationMonths;
      const response = await rpc.callRetry(
        'CreateApiKey',
        rpc.client.createApiKey,
        req,
      );
      setNewKeyValue(response.apiKeyInfo?.value || null);
      // Refresh the table
      const listReq = new Empty();
      const listResponse = await rpc.callRetry(
        'ListApiKeys',
        rpc.client.listApiKeys,
        listReq,
      );
      setApiKeys(listResponse.apiKeyInfo);
    } catch (err) {
      addRpcError('Error creating API key', err);
    } finally {
      setIsCreating(false);
    }
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    setNewKeyName('');
    setNewKeyValue(null);
    setExpirationMonths(defaultExpirationMonths);
  };

  const handleRevokeKey = async (keyId: string) => {
    setKeyToRevoke(keyId);
  };
  const handleDeleteKey = async (keyId: string) => {
    setKeyToDelete(keyId);
  };

  const confirmRevoke = async () => {
    if (!keyToRevoke) {
      return;
    }
    try {
      const req = new frontendpb.RevokeApiKeyRequest();
      req.id = keyToRevoke;
      await rpc.callRetry(
        'RevokeApiKey',
        rpc.client.revokeApiKey,
        req,
      );
      // Refresh the API keys list after revoking
      const listReq = new Empty();
      const response = await rpc.callRetry(
        'ListApiKeys',
        rpc.client.listApiKeys,
        listReq,
      );
      setApiKeys(response.apiKeyInfo);
    } catch (err) {
      addRpcError('Error revoking API key', err);
    } finally {
      setKeyToRevoke(null);
    }
  };

  const confirmDelete = async () => {
    if (!keyToDelete) {
      return;
    }
    try {
      const req = new frontendpb.DeleteApiKeyRequest();
      req.id = keyToDelete;
      await rpc.callRetry(
        'DeleteApiKey',
        rpc.client.deleteApiKey,
        req,
      );
      // Refresh the API keys list after deleting
      const listReq = new Empty();
      const response = await rpc.callRetry(
        'ListApiKeys',
        rpc.client.listApiKeys,
        listReq,
      );
      setApiKeys(response.apiKeyInfo);
    } catch (err) {
      addRpcError('Error deleting API key', err);
    } finally {
      setKeyToDelete(null);
    }
  };

  const apiKeyColumns = [
    { id: 'name', label: 'Name', type: 'string' as const },
    { id: 'maskedKey', label: 'Key', type: 'string' as const },
    { id: 'createdTime', label: 'Created', type: 'string' as const, defaultSort: 'desc' },
    { id: 'expirationTime', label: 'Expiration', type: 'string' as const },
    { id: 'lastUsedTime', label: 'Last Used', type: 'string' as const },
    { id: 'active', label: 'Active', type: 'string' as const },
  ];

  const formatDateTime = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const apiKeyRows = apiKeys
    .filter((key) => !key.deletedTime)
    .map((key) => ({
      id: key.id.toString(),
      values: {
        name: key.name,
        maskedKey: key.maskedKey,
        createdTime: key.createdTime?.toDate() ?
          formatDateTime(key.createdTime.toDate()) :
          '-',
        expirationTime: key.expirationTime?.toDate() ?
          formatDateTime(key.expirationTime.toDate()) :
          'Never',
        lastUsedTime: key.lastUsedTime?.toDate() ?
          formatDateTime(key.lastUsedTime.toDate()) :
          'Never',
        active: (!key.expirationTime?.toDate() || key.expirationTime.toDate() > new Date()) ?
          '✓' :
          '✗',
      },
      menuItems: [
        // Show Revoke for non-expired keys
        ...(key.expirationTime?.toDate() && key.expirationTime.toDate() > new Date() ? [{
          label: 'Revoke',
          onClick: () => handleRevokeKey(key.id.toString()),
          kind: 'destructive',
        }] : []),
        // Show Delete for expired keys
        ...(key.expirationTime?.toDate() && key.expirationTime.toDate() <= new Date() ? [{
          label: 'Delete',
          onClick: () => handleDeleteKey(key.id.toString()),
          kind: 'destructive',
        }] : []),
      ],
    }));

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '16px' }}>
          <ActionButton
            disabled={isCreating}
            kind="primary"
            onClick={handleCreateKey}
            showSpinner={isCreating}>
            Create API Key
          </ActionButton>
        </div>
        {(() => {
          if (loading) {
            return <CircularLoader />;
          }
          if (apiKeyRows.length === 0) {
            return (
              <div style={{ textAlign: 'center', padding: '24px' }}>
                Click &apos;Create API Key&apos; to get started
              </div>
            );
          }
          return (
            <Table
              columnConfigs={apiKeyColumns}
              name="api-keys-table"
              rowConfigs={apiKeyRows}
            />
          );
        })()}
      </div>

      <Dialog
        cancelButton={newKeyValue ? undefined : {
          kind: 'cancel',
          label: 'Cancel',
        }}
        continueButton={{
          kind: 'primary',
          label: newKeyValue ? 'Close' : 'Create',
        }}
        modal
        onClose={handleCloseCreateDialog}
        onContinue={newKeyValue ? handleCloseCreateDialog : handleCreateKeySubmit}
        open={isCreateDialogOpen}
        title="Create API Key">
        {newKeyValue ? (
          <div className={classes.flexContent}>
            <p>
              The API key value is below. Copy this API key to a secure place
              since it will not be shown again.
            </p>
            <div style={{
              background: colors.surfaceBackground,
              borderRadius: '4px',
              padding: '12px',
              wordBreak: 'break-all',
            }}>
              {newKeyValue}
              <IconButton
                onClick={() => navigator.clipboard.writeText(newKeyValue)}
                title="Copy to clipboard">
                <CopyIcon maxHeight={16} />
              </IconButton>
            </div>
          </div>
        ) : (
          <div className={classes.flexContent}>
            <label htmlFor="apiKeyNameInput">
              Name
              <input
                id="apiKeyNameInput"
                name="apiKeyNameInput"
                onChange={(event) => setNewKeyName(event.target.value)}
                style={{ width: '100%' }}
                type="text"
                value={newKeyName}
              />
            </label>
            <label htmlFor="expirationSelect">
              Expiration
              <select
                id="expirationSelect"
                name="expirationSelect"
                onChange={(event) => setExpirationMonths(Number(event.target.value))}
                style={{ width: '100%' }}
                value={expirationMonths}>
                <option value={0}>No expiration</option>
                <option value={3}>3 months</option>
                <option value={6}>6 months</option>
                <option value={12}>12 months</option>
              </select>
            </label>
          </div>
        )}
      </Dialog>

      <Dialog
        cancelButton={{
          kind: 'cancel',
          label: 'Cancel',
        }}
        continueButton={{
          kind: 'destructive',
          label: 'Revoke',
        }}
        destructive
        modal
        onClose={() => setKeyToRevoke(null)}
        onContinue={confirmRevoke}
        open={keyToRevoke !== null}
        title="Revoke API Key">
        Are you sure you want to revoke this API key? This action cannot be undone.
      </Dialog>

      <Dialog
        cancelButton={{
          kind: 'cancel',
          label: 'Cancel',
        }}
        continueButton={{
          kind: 'destructive',
          label: 'Delete',
        }}
        destructive
        modal
        onClose={() => setKeyToDelete(null)}
        onContinue={confirmDelete}
        open={keyToDelete !== null}
        title="Delete API Key">
        Are you sure you want to delete this API key? This action cannot be undone.
      </Dialog>
    </>
  );
};
