// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { useGeometryContacts } from '../../../recoil/geometryContactsState';
import { useProjectContext } from '../../context/ProjectContext';
import { TreeRow } from '../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const GeometryContactTreeRow = (props: SimulationRowProps) => {
  const { projectId } = useProjectContext();

  const { contacts } = useGeometryContacts(projectId);

  return (
    <TreeRow
      {...props}
      primaryIcon={getNodeTypeIcon(props.node.type, { nodeId: props.node.id, contacts })}
      propertiesControl
    />
  );
};
