import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * If LCVis throws an error due to exceeded memory, we set a project-based flag to true
 * to display a dialog, enforcing the user to switch to PV.
 */
const lcvisMemoryExceeded = atomFamily<boolean, string>({
  key: 'lcvisMemoryExceeded',
  default: false,
});

export const useLcvisMemoryExceededValue = (
  (projectId: string) => useRecoilValue(lcvisMemoryExceeded(projectId))
);

export const useSetLcvisMemoryExceeded = (
  (projectId: string) => useSetRecoilState(lcvisMemoryExceeded(projectId))
);
