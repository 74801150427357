// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { CommonMenuItem } from '../../../../../lib/componentTypes/menu';
import { colors } from '../../../../../lib/designSystem';
import { getMeshStatsAndCreateSubtitle } from '../../../../../lib/mesh';
import { Logger } from '../../../../../lib/observability/logs';
import * as rpc from '../../../../../lib/rpc';
import * as simulationpb from '../../../../../proto/frontend/simulation/simulation_pb';
import { useIsGeometryPending, useIsMeshPending } from '../../../../../recoil/pendingWorkOrders';
import { DEFAULT_MESH_NAME, useMeshName, useSetMeshName } from '../../../../../recoil/useMeshName';
import { useIsMeshEditPanel } from '../../../../../recoil/useMeshPanelState';
import { MESH_GEN_WARNING_MESSAGE, MeshSettingsWarning, useMeshValidator } from '../../../../../recoil/useMeshValidator';
import { projectActiveMeshSelector, useHasActiveMesh } from '../../../../../recoil/useProjectActiveMesh';
import { useProjectMeshList } from '../../../../../recoil/useProjectMeshList';
import { useIsSetupOrAdvancedView } from '../../../../../state/internal/global/currentView';
import { CardButton } from '../../../../Button/CardButton';
import { createStyles, makeStyles } from '../../../../Theme';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useDeleteMesh, useIsLMAActive, useRenameMesh } from '../../../../hooks/useMesh';
import { SectionMessage } from '../../../../notification/SectionMessage';
import { LoadingEllipsis } from '../../../../visual/LoadingEllipsis';

import { GenerateMeshButton } from './GenerateMeshButton';
import SwapMeshButton from './SwapMeshButton';

const logger = new Logger('propPanel/MeshHeaderSection');

const useStyles = makeStyles(
  () => createStyles({
    meshSelectionHeader: {
      fontWeight: 600,
      fontSize: '13px',
      color: colors.highEmphasisText,
      margin: '0',
    },
    meshSelectionButtonContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignContent: 'center',
      marginBottom: '4px',
    },
    link: {
      flex: '1 0 60%',
      color: colors.lowEmphasisText,
      fontSize: '13px',
      fontWeight: 600,
      textDecoration: 'underline',
      cursor: 'pointer',
      outline: 'none',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '4px',
      gap: '8px',
    },

  }),
  { name: 'MeshSelectionPropPanel' },
);

interface NewMeshNameSectionProps {
  warning: MeshSettingsWarning
}

const NewMeshNameSection = (props: NewMeshNameSectionProps) => {
  const { warning } = props;
  const { projectId } = useProjectContext();

  const meshName = useMeshName(projectId);
  const setMeshName = useSetMeshName(projectId);
  const [meshList] = useProjectMeshList(projectId);
  const [editNameMode, setEditNameMode] = useState(false);

  const { disabledReason } = warning;
  const isMeshGenerating = disabledReason === MESH_GEN_WARNING_MESSAGE;

  const menuItems: CommonMenuItem[] = [
    {
      label: 'Rename',
      onClick: () => {
        setEditNameMode(true);
      },
      disabled: isMeshGenerating,
      disabledReason: isMeshGenerating ? disabledReason : '',
    },
  ];
  const handleChangeMeshName = (newName: string) => {
    setMeshName(newName);
  };

  useEffect(() => {
    if (meshName === DEFAULT_MESH_NAME) {
      const meshNum = meshList.length + 1;
      setMeshName(`Mesh ${meshNum}`);
    }
  }, [meshName, setMeshName, meshList.length]);

  return (
    <CardButton
      compact
      editableDisabled={!editNameMode || isMeshGenerating}
      handleEditTitle={(val) => {
        handleChangeMeshName(val);
        setEditNameMode(false);
      }}
      handleTextDoubleClick={() => {
        if (!isMeshGenerating) {
          setEditNameMode(true);
        }
      }}
      icon={{ maxHeight: 12, name: 'cubeOutline', color: colors.neutral650 }}
      menuItems={menuItems}
      showIconDot
      subtitle="Mesh has not been generated yet"
      title={meshName}
    />
  );
};

const ExistingMeshNameSection = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const mesh = useRecoilValue(projectActiveMeshSelector({ projectId, workflowId, jobId }));
  const deleteMesh = useDeleteMesh();

  const [editNameMode, setEditNameMode] = useState(false);
  const [meshSubtitle, setMeshSubtitle] = useState<string | ReactNode>(<LoadingEllipsis />);
  const [simulationsForMesh, setSimulationsForMesh] = useState<simulationpb.Simulation[]>([]);
  const { renameMesh } = useRenameMesh();
  const meshId = mesh?.id || '';
  const meshName = mesh?.name || '';

  useEffect(() => {
    async function getSubtitle() {
      const subtitle = await getMeshStatsAndCreateSubtitle(meshId);
      setMeshSubtitle(subtitle);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getSubtitle();
  }, [meshId]);

  useEffect(() => {
    if (!meshId) {
      setSimulationsForMesh([]);
      return;
    }
    rpc.callRetry(
      'listSimulationsWithMesh',
      rpc.client.listSimulationsWithMesh,
      new simulationpb.ListSimulationsWithMeshRequest({ meshId }),
    ).then((reply: simulationpb.ListSimulationsWithMeshResponse) => {
      setSimulationsForMesh(reply.simulations);
    }).catch(
      (err: Error) => {
        // non-fatal, so just log and continue
        logger.warn(`ListSimulationsWithMesh error: ${JSON.stringify(err)}`);
        setSimulationsForMesh([]);
      },
    );
  }, [meshId]);

  const disableMeshDeletion = simulationsForMesh.length > 0;
  const disabledReasonMsg = disableMeshDeletion ?
    `Mesh cannot be deleted because it is used by: ${simulationsForMesh
      .map((sim) => sim.name).join(', ')}` : '';
  const menuItems: CommonMenuItem[] = [
    {
      label: 'Rename',
      onClick: () => {
        setEditNameMode(true);
      },
    },
    {
      label: 'Delete',
      destructive: true,
      disabled: disableMeshDeletion, // disable if the mesh has existing simulations
      disabledReason: disabledReasonMsg,
      onClick: () => deleteMesh(meshId),
    },
  ];

  return (
    <CardButton
      compact
      editableDisabled={!editNameMode}
      handleEditTitle={async (val) => {
        if (val !== meshName) {
          await renameMesh(meshId, val);
        }
        setEditNameMode(false);
      }}
      handleTextDoubleClick={() => {
        setEditNameMode(true);
      }}
      icon={{ maxHeight: 12, name: 'cubeOutline', color: colors.neutral900 }}
      menuItems={menuItems}
      subtitle={meshSubtitle}
      title={meshName}
    />
  );
};

export const MeshHeaderSection = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const isSetupOrAdvancedView = useIsSetupOrAdvancedView();
  const isLMA = useIsLMAActive();
  const hasActiveMesh = useHasActiveMesh(projectId);
  const meshGenerating = useIsMeshPending(projectId);
  const geometryPending = useIsGeometryPending(projectId);
  const isNewMesh = useIsMeshEditPanel(projectId);
  const classes = useStyles();

  const workOrderPending = geometryPending || meshGenerating;
  const warning = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const { disabledReason, disabledLevel } = warning;

  const selectionHeaderText = isLMA ? 'Base Mesh Selection' : 'Mesh Selection';

  const meshNameSection = isNewMesh ?
    <NewMeshNameSection warning={warning} /> :
    <ExistingMeshNameSection />;

  return (
    <>
      <div className={classes.meshSelectionButtonContainer}>
        <p className={classes.meshSelectionHeader}>{selectionHeaderText}</p>
        {/* Show mesh swap on setup page only */}
        {isSetupOrAdvancedView && (
          <SwapMeshButton disabled={workOrderPending} />
        )}
      </div>
      {(!hasActiveMesh && isLMA) ? (
        <div className={classes.buttonContainer}>
          {disabledReason && (
            <SectionMessage level={disabledLevel}>
              {disabledReason}
            </SectionMessage>
          )}
          <GenerateMeshButton disabled={!!disabledReason || workOrderPending} lmaVariant />
        </div>
      ) : meshNameSection}
    </>
  );
};
