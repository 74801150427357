import { LCVMapMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';
import { LcvFrame } from '../LcvFrame';
import { LcvObject } from '../base/LcvObject';

type PositionCallback = (value: [number, number] | null) => void;

export class LcvProjectPointsAnnotation extends LcvObject {
  frame: LcvFrame | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, lcv.newAnnotation(sessionHandle, 'project_points', 0).annotation, sessionHandle);
  }

  /**
   * Updates the parameter with the coordinates of the point to be tracked.
   * The point is represented as a three-element array containing the x, y, and z coordinates.
   */
  private updatePointParam(point: [number, number, number]) {
    const data = this.lcv.newData1D(this.sessionHandle, LCVType.kLCVDataTypeFloat3, 1, 0).data;
    const { mapping, size } = this.lcv.mapData(
      this.sessionHandle,
      data,
      LCVMapMode.kLCVMapModeWrite,
      0,
      0,
    );

    new Float32Array(this.lcv.memory(), mapping, size * 3).set(point);
    this.lcv.unmapData(this.sessionHandle, data);

    this.setParam('points', LCVType.kLCVDataTypeData1D, data);

    this.lcv.release(this.sessionHandle, data, 0);
  }

  /**
   * Retrieves the current position of the tracked item.
   * If the item is not visible on the screen, the result is `null`.
   * Otherwise, it returns a two-element array representing the fractional
   * x and y coordinates of the item's position on the screen.
   */
  private getCurrentPosition(obj: LCVObject): [number, number] | null {
    const projection_data = this.lcv.getProperty(
      this.sessionHandle,
      obj,
      'projected_points',
      LCVType.kLCVDataTypeData1D,
      0,
    ).property;

    if (projection_data === 0) {
      return null;
    }

    const { mapping, size } = this.lcv.mapData(
      this.sessionHandle,
      projection_data,
      LCVMapMode.kLCVMapModeRead,
      0,
      0,
    );

    const projections = new Float32Array(this.lcv.memory(), mapping, size * 3);
    this.lcv.unmapData(this.sessionHandle, projection_data);
    this.lcv.release(this.sessionHandle, projection_data, 0);

    // If any coord is nan the point is not visible on screen/behind the camera and
    // we should execute callback with null value
    if (projections.some((item) => Number.isNaN(item)) || !this.frame?.displaySize) {
      return null;
    }

    const frameSize = this.frame.displaySize;
    // Transform from frame coords to canvas coords to place the text

    // 1. We need to flip the y coord to transfrom from OpenGL coords to
    // canvas/HTML coordinate system
    projections[1] = frameSize[1] - projections[1];

    // 2. Scale from the LCVFrame coordinates to a fraction
    projections[0] /= frameSize[0];
    projections[1] /= frameSize[1];

    return [projections[0], projections[1]];
  }

  private updateCallback(callback: PositionCallback) {
    this.setParam(
      'updated_callback',
      LCVType.kLCVDataTypeFunction,
      (_lcv: any, _session: LCVObject, obj: LCVObject, _msg: string) => {
        callback(this.getCurrentPosition(obj));
      },
    );
  }

  setPoint(point: [number, number, number], callback: PositionCallback) {
    this.updatePointParam(point);
    this.updateCallback(callback);
  }

  clearPoint() {
    this.setParam('points', LCVType.kLCVDataTypeData1D, 0);
    this.updateCallback(() => {});
  }

  setFrame(frame: LcvFrame | null) {
    this.frame = frame;
  }
}
