// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useMeshReadyState } from '../../recoil/useMeshReadyState';
import { useProjectContext } from '../context/ProjectContext';

import { useIsInitialLoadOrImportFeature } from './geometry/useIsInitialLoadOrImportFeature';

export const useGeometryStatus = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const geometryPending = useIsGeometryPending(projectId);
  const meshReady = useMeshReadyState(projectId, workflowId, jobId);
  const isInitialLoadOrImportFeature = useIsInitialLoadOrImportFeature();

  const working = !meshReady || geometryPending || isInitialLoadOrImportFeature;

  return {
    working,
  };
};
