// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { AdvancedAnalysisReferrerType, AdvancedAnalysisSetupType } from './routeParamTypes';

/*
  Utility functions for getting the navigation path to different pages.
*/

const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Macintosh/i);
const webkit = !!ua.match(/WebKit/i);
const iOSSafari = iOS && webkit && !ua.match(/CriOS/i) && !ua.match(/Chrome/i);
// check if the user is on Safari (on computer, or ipad/iphone)
export const isSafari = (
  iOSSafari ||
  (window as (typeof window & { safari: any })).safari !== undefined
);

export const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
export const METAKEY_SYMBOL = isMac ? '⌘' : 'Ctrl';

// Keeping track of all the new login flow pages. We might expand this to include the rest of the
// top level pages. Obviously, pages with params would still use a function (i.e. /project/:id ).
export const routes = {
  activateAccount: '/activate-account',
  setupSelect2fa: '/mfa-enroll-two-factor',
  setupApp2FA: '/two-factor-app-setup',
  setupSms2FA: '/two-factor-sms-setup',
  setup2FABackup: '/two-factor-backup',
  login: '/login',
  loginBackup: '/login-backup',
  loginApp2FA: '/login-two-factor-app',
  loginSms2FA: '/login-two-factor-sms',
  forgotPassword: '/forgot-password',
  authCode: 'authcode',

  acceptTerms: '/accept-terms',
  acceptTermsUpdated: '/accept-updated-terms',
  googleAuthCallback: '/callback',
  setPassword: '/set-password',

  landingPage: '/',
  getStarted: '/start',
  account: '/account/profile',
  accountUsage: '/account/usage',
  library: '/library',
  adminUsers: '/account/admin/users',
  adminUsage: '/account/admin/usage',
  adminSecurity: '/account/admin/security',
  adminBilling: '/account/admin/billing',
  adminSettings: '/account/admin/settings',

  platformAdminTeam: '/platform/admin',
  platformAdminSupport: '/platform/support',
  platformAdminSample: '/platform/sample',
  platformAdminBizops: '/platform/bizops',

  exploration: '/project/:projectId/exploration/:workflowId',
  explorationJob: '/project/:projectId/exploration/:workflowId/:jobId',
  simulation: '/project/:projectId/simulation/:workflowId',

  geometryV1: 'project/:projectId/geometry',
  geometryV2: 'project/:projectId/geometry/:geometryId',

  projectList: '/projectlist',
  projectPage: 'project/:projectId', // project setup page

  resultsPage: 'results/:projectId',
  resultPageAlt: 'project/:projectId/results',
  analysisPage: 'workflow/:projectId/:workflowId',
  analysisPageAlt: 'job/:projectId/:workflowId/:jobId',

  // The initial DoE setup in the new workflow was only accessible from an existing simulation run
  // so we need this temporarily for backwards compatibility.
  advancedAnalysisV1:
    'project/:projectId/advanced/:advancedType/:referrerWorkflowId/:referrerJobId/:referrerType',
  // If the DoE page was accessed from the setup pages, we'll just keep the referrer page in the
  // url (solver, physics, etc.). But if the page was accessed from an existing run,
  // the url will contain the sim type and the workflow/job.
  advancedAnalysisV2:
    'project/:projectId/advanced/:advancedType' +
    '/ref/:referrerType/workflow?/:referrerWorkflowId?/job?/:referrerJobId?',

  signup: '/signup',

  notFound: '*',
};

export const mfaInProgressRoutes = [
  routes.setupSelect2fa,
  routes.setupApp2FA,
  routes.setupSms2FA,
  routes.loginApp2FA,
  routes.loginSms2FA,
];

// Returns a link to the landing page
export function landingPageLink(): string {
  return '/';
}

// Returns a link to the project list page
export function projectsLink(): string {
  return '/projectlist';
}

export function advancedAnalysisLink(
  projectId: string,
  workflowId: string,
  jobId: string,
  setupType: AdvancedAnalysisSetupType,
  referrerType: AdvancedAnalysisReferrerType,
): string {
  // We must keep extra params in the url so that we can return to the same page when we close
  // the Advanced mode. If we have opened the Advanced mode from one of the setup pages, we'll just
  // keep the referrer page (solver, physics, etc.), but if we are coming from an existing run, we
  // should also keep the workflowId and the jobId.
  const workflowPart = workflowId ? `/workflow/${workflowId}` : '';
  const jobPart = jobId ? `/job/${jobId}` : '';
  return `/project/${projectId}/advanced/${setupType}/ref/${referrerType}${workflowPart}${jobPart}`;
}

// Returns a link to the registration page
export function registrationLink(): string {
  return '/register';
}

// Returns a link to the project setup page.
export function projectLink(projectId: string): string {
  return `/project/${projectId}`;
}

// Returns a link to fetch a file by ID.
export function fetchLink(fileId: string): string {
  return `/fetch/${fileId}`;
}

export function isInProject(pathname: string, projectId?: string) {
  if (projectId) {
    const reg = `^/project/${projectId}`;
    return !!pathname.match(new RegExp(reg));
  }
  return !!pathname.match(/^\/project\/p-(\w+)/);
}

// Returns true if the pathname matches a simulation or exploration path.
export function isInProjectResult(pathname: string, projectId?: string) {
  if (projectId) {
    const reg = `^/project/${projectId}/(exploration|simulation)`;
    return !!pathname.match(new RegExp(reg));
  }
  return !!pathname.match(/^\/project\/p-(\w+)\/(exploration|simulation)/);
}

// Checks if a pathname is an exploration url
export function isInExploration(pathname: string) {
  return pathname.split('/')[3] === 'exploration';
}

// Returns an error message hash string
export function unauthorizedErrorMessageHash(message: string): string {
  return `#error=unauthorized&error_description=${message}`;
}

// Returns an href to the home page with the error message for auth0 pop up
export function homePageLinkWithError(
  origin: string,
  link: string,
  errorMessage: string,
): string {
  const errorHash = unauthorizedErrorMessageHash(errorMessage);

  // NOTE (Cedric): now that this is refactored from reloadPageToHomePageWithError, it's not
  // entirely clear to me how/why these code paths differ.
  if (origin.includes('localhost')) {
    return `http://localhost:8080/${errorHash}`;
  }
  return `${origin + link}${errorHash}`;
}

// Redirects the page to the home page with the error message for auth0 pop up
export function reloadPageToHomePageWithError(errorMessage: string): void {
  window.location.replace(homePageLinkWithError(
    window.location.origin,
    landingPageLink(),
    errorMessage,
  ));
  window.location.reload();
}

// Returns an absolute URL by combining the current window's origin with an optional route, if the
// route is provided. If no route is given, it returns the window's origin alone. This is helpful
// for external libraries like Auth0, where we need to provide a redirectUrl as an absolute path in
// order for the redirect to work. We cannot hardcode the domain and we must use location.origin,
// because different environments have different domains (localhost, main, preview envs, prod).
export function locationOriginRoute(route?: string): string {
  if (route) {
    return `${window.location.origin}${route}`;
  }
  return window.location.origin;
}

// The following URLs must be kept in-sync with the logic in email_notification_handler.go,
// which generates the links used in notification emails.

// Returns a link to the results page.
export function resultsLink(projectId: string): string {
  return `/project/${projectId}/results`;
}

// Returns a link to the geometry page.
export function geometryLink(projectId: string): string {
  return `/project/${projectId}/geometry`;
}

// Returns a link to the geometry page for an existing geometry.
export function geometryIdLink(projectId: string, geometryId: string): string {
  return `/project/${projectId}/geometry/${geometryId}`;
}

/**
* @returns a link to the setup tab/page of a given project.
*/
export function setupLink(projectId: string): string {
  return `/project/${projectId}`;
}

// Returns a link to a particular workflow.
export function workflowLink(
  projectId: string,
  workflowId: string,
  isExploration: boolean,
): string {
  if (isExploration) {
    return `/project/${projectId}/exploration/${workflowId}`;
  }
  return `/project/${projectId}/simulation/${workflowId}`;
}

// Returns a link to a particular job.
export function jobLink(projectId: string, workflowId: string, jobId: string): string {
  return `/project/${projectId}/exploration/${workflowId}/${jobId}`;
}
