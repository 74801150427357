import React from 'react';

import cx from 'classnames';

import { colors } from '../../../../lib/designSystem';
import { createStyles, makeStyles } from '../../../Theme';
import { useProjectContext } from '../../../context/ProjectContext';
import { useAddNewAssistantMessage } from '../../../hooks/assistant/useAddNewAssistantMessage';
import { useAssistantSend } from '../../../hooks/assistant/useAssistantSend';

const useSuggestedListStyles = makeStyles(
  () => createStyles({
    root: {
      marginBottom: '8px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    label: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'bold',
      marginBottom: '8px',
    },
    item: {
      background: 'none',
      border: 'none',
      fontSize: '13px',
      color: colors.disabledType,
      padding: '0',
      textAlign: 'left',
      transition: 'animate 250ms',
      display: 'block',

      '& + &': {
        marginTop: '2px',
      },

      '&:not(.disabled)': {
        cursor: 'pointer',
        color: colors.primaryInteractive,

        '&:hover': {
          color: colors.primaryCta,
        },
      },
    },
    dot: {
      display: 'inline-block',
      marginRight: '6px',
      position: 'relative',
      top: '-1px',
    },
  }),
  { name: 'SuggestedList' },
);

type SuggestedGroup = {
  label?: string,
  items: string[],
}

type SuggestedListProps = {
  list: SuggestedGroup[];
}

export const SuggestedList = (props: SuggestedListProps) => {
  // == Props
  const { list } = props;

  // == Contexts
  const { projectId } = useProjectContext();

  // == Hooks
  const classes = useSuggestedListStyles();

  // == State
  const { disabled } = useAssistantSend();
  const addNewAssistantMessage = useAddNewAssistantMessage(projectId);

  if (list.length) {
    return (
      <div className={classes.root}>
        {list.map((group) => (
          <div key={`group-${group.label || group.items[0]}`}>
            {group.label && <div className={classes.label}>{group.label}</div>}
            {group.items.map((action) => (
              <button
                className={cx(classes.item, { disabled })}
                key={group.items[0]}
                onClick={() => {
                  if (!disabled) {
                    addNewAssistantMessage(action);
                  }
                }}
                type="button">
                <span className={classes.dot}>•</span>
                {action}
              </button>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return null;
};
