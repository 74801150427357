import React, { useEffect, useRef } from 'react';

import {
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import { DELETABLE_NODE_TYPES, createDropAreaData, isDraggableData } from '@/lib/draggableTreeNodeUtils';

export function TagDropZone({ className }: { className?: string }) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    return dropTargetForElements({
      element,
      canDrop({ source }) {
        if (source.element === element || !isDraggableData(source.data)) {
          return false;
        }

        return DELETABLE_NODE_TYPES.includes(source.data.node.type);
      },
      getData: createDropAreaData,
    });
  }, []);

  return <div className={className} ref={ref} />;
}
