// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useCustomFieldNodes } from '../../recoil/customFieldNodes';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useViewStateOverflow } from '../../recoil/lcvis/viewStateOverflow';
import { useProjectContext } from '../context/ProjectContext';

import DataComponentSelect, { LcvisDataComponent } from './DataComponentSelect';
import DataNameSelect from './DataNameSelect';
import { useParaviewContext } from './ParaviewManager';

const filterOptionItem = ({ type }: ParaviewRpc.ArrayInformation) => (
  type === ParaviewRpc.FieldAssociation.POINT
);

const ResultsVariableSelect = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const {
    setViewStateDisplayVariable,
    viewState,
  } = useParaviewContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [lcvisData, setLcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const fieldList = lcvisData.data.filter(filterOptionItem).map(({ name }) => name);
  const [customFields] = useCustomFieldNodes(projectId);

  if ((
    !viewState?.data?.length || !viewState.attrs) &&
    (!lcvisEnabled)
  ) {
    return null;
  }

  if (!lcvisEnabled && viewState) {
    return (
      <>
        <DataNameSelect
          customFields={customFields}
          kind="minimal"
          onChange={(variableName: string) => {
            const newDisplayVariable: ParaviewRpc.DisplayPvVariable = {
              ...viewState!.attrs!.displayVariable!,
              displayDataName: variableName,
            };
            // displayDataNameComponent may be null when we are in the Mesh tab.
            if (!newDisplayVariable.displayDataNameComponent) {
              newDisplayVariable.displayDataNameComponent = 0;
            }
            setViewStateDisplayVariable(newDisplayVariable, false, null);
          }}
          options={viewState.data.filter(filterOptionItem).map(({ name }) => name)}
          size="medium"
          value={viewState.attrs.displayVariable?.displayDataName || 'None'}
        />
        {/* This dropdown is only rendered for display variables with multiple components
        (e.g. velocity vector) */}
        <DataComponentSelect
          kind="minimal"
          onChange={(component: number) => {
            const newDisplayVariable: ParaviewRpc.DisplayPvVariable = {
              ...viewState!.attrs!.displayVariable!,
              displayDataNameComponent: component,
            };
            setViewStateDisplayVariable(newDisplayVariable, true, null);
          }}
          size="medium"
          viewState={viewState}
        />
      </>
    );
  }
  if (lcvisEnabled) {
    return (
      <>
        <DataNameSelect
          customFields={customFields}
          kind="minimal"
          onChange={(variableName: string) => {
            setLcvisData({
              ...lcvisData,
              attrs: {
                ...lcvisData.attrs,
                displayVariable: {
                  displayDataName: variableName,
                  displayDataNameComponent: (
                    lcvisData.attrs.displayVariable?.displayDataNameComponent ?? 0
                  ),
                },
              },
            });
          }}
          options={fieldList}
          size="medium"
          value={lcvisData.attrs.displayVariable?.displayDataName ?? 'None'}
        />
        {/* This dropdown is only rendered for display variables with multiple components
        (e.g. velocity vector) */}
        <LcvisDataComponent
          displayVariable={lcvisData.attrs.displayVariable ?
            lcvisData.attrs.displayVariable : undefined}
          filterNodeId={null}
          kind="minimal"
          onChange={(component: number) => {
            // TODO will: This uses the global attrs here? I don't see how different
            // filters can have different colormapping even right now with how this
            // is set up.
            setLcvisData({
              ...lcvisData,
              attrs: {
                ...lcvisData.attrs,
                displayVariable: {
                  displayDataName: lcvisData.attrs.displayVariable?.displayDataName ?? 'None',
                  displayDataNameComponent: component,
                },
              },
            });
          }}
          size="medium"
        />
      </>
    );
  }
  return null;
};

export default ResultsVariableSelect;
