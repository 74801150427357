import { useCallback, useMemo } from 'react';

import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { addRpcError } from '../../lib/transientNotification';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useProjectContext } from '../context/ProjectContext';

import { useTagsInteractiveGeometry } from './useInteractiveGeometry';

export const useNodeDrop = (parent: SimulationTreeNode) => {
  const { projectId } = useProjectContext();
  const {
    addSurfacesToExistingTag,
    addVolumesToExistingTag,
    removeItemsFromTag,
  } = useTagsInteractiveGeometry();
  const geometryTags = useGeometryTags(projectId);

  // if the item is dropped on surface group (tag) - use node.id,
  const destinationTag = useMemo(() => (
    parent.type === NodeType.SURFACE_GROUP ? parent : parent.parent!
  ), [parent]);

  const onNodeDrop = useCallback(async (droppedNode: SimulationTreeNode) => {
    const isDroppedNodeSurface = (
      [NodeType.TAGS_FACE, NodeType.SURFACE].includes(droppedNode.type)
    );
    const isDroppedNodeTagChild = (
      [NodeType.TAGS_FACE, NodeType.TAGS_BODY].includes(droppedNode.type)
    );

    // We don't need to perform operations sequentially; we can trigger them simultaneously.
    // TODO(LC-23335): Once we have a function to move an item from one tag to another, we can
    // use it here instead of manually removing it from the old tag and adding it to the new one.
    const asyncOperations: Promise<void>[] = [];

    // if it's been moved from other tag – remove it there first
    if (isDroppedNodeTagChild) {
      asyncOperations.push(removeItemsFromTag(
          droppedNode.parent!.id,
          isDroppedNodeSurface ? [geometryTags.surfaceFromTagEntityGroupId(droppedNode.id)] : [],
          !isDroppedNodeSurface ? [geometryTags.domainFromTagEntityGroupId(droppedNode.id)!] : [],
      ));
    }

    // then extract core identifier and attach to the destination
    const coreNodeId = geometryTags.getCoreNodeIdentifier(droppedNode.id);

    if (isDroppedNodeSurface) {
      asyncOperations.push(addSurfacesToExistingTag(destinationTag.id, [coreNodeId]));
    } else {
      asyncOperations.push(addVolumesToExistingTag(destinationTag.id, [coreNodeId]));
    }

    try {
      await Promise.all(asyncOperations);
    } catch (error) {
      addRpcError('Failed to change tag assignment', error);
    }
  }, [
    addSurfacesToExistingTag,
    addVolumesToExistingTag,
    destinationTag,
    geometryTags,
    removeItemsFromTag,
  ]);

  return { onNodeDrop, destinationTag };
};
