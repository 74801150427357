// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
/** A Button component styled according to designs at
 * https://www.figma.com/file/8rNGeZ9HiJ4Zl6pVaEk5Lq/Luminary-Component-Library?node-id=408%3A45177
 */
import React, { ReactNode, forwardRef, memo } from 'react';

import cx from 'classnames';

import { FormControlSize } from '../../lib/componentTypes/form';
import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { colors, hexToRgb } from '../../lib/designSystem';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { Propeller } from '../visual/Propeller';

export type ActionKind = 'primary' | 'secondary' | 'alternative' | 'cancel' |
  'destructive' | 'minimal';

interface StatefulColors {
  main: string;
  hover?: string;
  active?: string;
  focused?: string;
  disabled?: string;
}

interface ActionTheme {
  background: StatefulColors;
  text: StatefulColors;
  dropShadow: StatefulColors;
  insetShadow: StatefulColors;
  focusColor: string;
  stroke: {
    colors: StatefulColors;
    width: number;
  };
}

function getStatefulColor(
  statefulColors: StatefulColors,
  state: keyof StatefulColors,
): string {
  return statefulColors?.[state] || statefulColors.main;
}

// Default theme options are insufficient to cover variations needed in the
// Figma design doc.  Instead of extending the Theme type, we can define a
// button-specific theme here.
const themes: Record<ActionKind, ActionTheme> = {
  primary: {
    background: {
      main: colors.purple600,
      hover: colors.purple500,
      active: colors.purple400,
      focused: colors.purple600,
      disabled: colors.neutral350,
    },
    text: {
      main: colors.highEmphasisText,
      disabled: colors.neutral700,
    },
    dropShadow: {
      main: 'rgba(5, 12, 25, 0.1)',
      disabled: hexToRgb(colors.neutral50, 0.15),
    },
    insetShadow: {
      main: 'rgba(159, 142, 251, 0.32)',
      disabled: colors.neutral450,
    },
    focusColor: colors.purple400,
    stroke: {
      width: 0,
      colors: { main: colors.transparent },
    },
  },
  secondary: {
    background: {
      main: colors.neutral450,
      hover: colors.neutral550,
      active: colors.neutral550,
      focused: colors.neutral450,
      disabled: colors.neutral350,
    },
    text: {
      main: colors.highEmphasisText,
      disabled: colors.neutral700,
    },
    dropShadow: {
      main: hexToRgb(colors.neutral50, 0.16),
      disabled: hexToRgb(colors.neutral50, 0.16),
    },
    insetShadow: {
      main: hexToRgb(colors.neutral900, 0.16),
      hover: hexToRgb(colors.neutral900, 0.12),
      active: hexToRgb(colors.neutral900, 0.16),
      focused: hexToRgb(colors.neutral900, 0.16),
      disabled: hexToRgb(colors.neutral900, 0.16),
    },
    focusColor: colors.primaryCta,
    stroke: {
      width: 0,
      colors: { main: colors.transparent },
    },
  },
  alternative: {
    background: {
      main: colors.neutral900,
      hover: colors.neutral850,
      active: colors.neutral800,
      disabled: colors.neutral750,
    },
    text: {
      main: colors.neutral0,
      disabled: colors.neutral300,
    },
    dropShadow: {
      main: 'rgb(24, 27, 30, 0.15)',
      focused: colors.transparent,
      disabled: hexToRgb(colors.neutral50, 0.15),
    },
    insetShadow: {
      main: '#95959a',
      hover: '#7e7f83',
      active: '#75767a',
      focused: colors.transparent,
      disabled: colors.neutral450,
    },
    focusColor: colors.neutral500,
    stroke: {
      width: 0,
      colors: { main: colors.transparent },
    },
  },
  cancel: {
    background: {
      main: colors.neutral200,
      hover: colors.neutral300,
      active: colors.neutral350,
      focused: colors.neutral200,
      disabled: colors.neutral200,
    },
    text: {
      main: colors.highEmphasisText,
      disabled: colors.neutral700,
    },
    dropShadow: {
      main: colors.transparent,
    },
    insetShadow: {
      main: colors.transparent,
    },
    focusColor: colors.purple400,
    stroke: {
      width: 2,
      colors: {
        main: colors.neutral550,
        active: colors.neutral500,
        disabled: colors.neutral550,
      },
    },
  },
  destructive: {
    background: {
      main: colors.red300,
      hover: colors.red200,
      active: colors.red100,
      disabled: colors.neutral350,
    },
    text: {
      main: colors.highEmphasisText,
      disabled: colors.neutral700,
    },
    dropShadow: {
      main: 'rgb(5, 12, 25, 0.1)',
      disabled: hexToRgb(colors.neutral50, 0.15),
    },
    insetShadow: {
      main: '#ce5537',
      hover: '#a9442b',
      active: '#8f3924',
      disabled: colors.neutral450,
    },
    focusColor: colors.red100,
    stroke: {
      width: 0,
      colors: { main: colors.transparent },
    },
  },
  minimal: {
    background: {
      main: colors.transparent,
      hover: colors.neutral300,
      active: colors.neutral350,
      focused: colors.neutral300,
    },
    text: {
      main: colors.highEmphasisText,
      disabled: colors.neutral550,
    },
    dropShadow: {
      main: colors.transparent,
    },
    insetShadow: {
      main: colors.transparent,
    },
    focusColor: colors.transparent,
    stroke: {
      width: 0,
      colors: { main: colors.transparent },
    },
  },
};

// Generate custom CSS variables for a given theme and button state
const buildColorVars = (
  theme: ActionTheme,
  state: keyof StatefulColors,
) => {
  const { stroke: { colors: strokeColors } } = theme;
  return {
    [`--${state}-bg-color`]: getStatefulColor(theme.background, state),
    [`--${state}-text-color`]: getStatefulColor(theme.text, state),
    [`--${state}-drop-shadow-color`]: getStatefulColor(theme.dropShadow, state),
    [`--${state}-inset-shadow-color`]: getStatefulColor(theme.insetShadow, state),
    [`--${state}-border-color`]: getStatefulColor(strokeColors, state),
  };
};

// Generate custom CSS variables for a given theme for all button states
// (hover, active, etc.)
const buildVars = (theme: ActionTheme) => {
  const { stroke: { width: strokeWidth } } = theme;
  return {
    ...buildColorVars(theme, 'main'),
    ...buildColorVars(theme, 'hover'),
    ...buildColorVars(theme, 'active'),
    ...buildColorVars(theme, 'focused'),
    ...buildColorVars(theme, 'disabled'),
    '--stroke-width': `${strokeWidth}px`,
    '--focus-color': theme.focusColor,
  };
};
const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'inline-flex',
      '&.asBlock': {
        display: 'flex',
        boxSizing: 'border-box',
        width: '100%',
      },
    },
    content: {
      ...buildVars(themes.primary),
      '&.secondaryKind': {
        ...buildVars(themes.secondary),
      },
      '&.alternativeKind': {
        ...buildVars(themes.alternative),
      },
      '&.cancelKind': {
        ...buildVars(themes.cancel),
      },
      '&.destructiveKind': {
        ...buildVars(themes.destructive),
      },
      '&.minimalKind': {
        ...buildVars(themes.minimal),
      },
      '--action-border-color': 'var(--main-border-color)',
      '--action-border-radius': '4px',
      '--padding-vertical': '8px',
      '--padding-horizontal': '12px',
      '--font-size': '14px',
      '--line-height': '20px',
      '--button-dim': '36px',
      '&.rightInset': {
        '--action-border-radius': '0px 4px 4px 0px',
      },
      '&.leftInset': {
        '--action-border-radius': '4px 0px 0px 4px',
      },
      '&.isoPadding': {
        '--padding-horizontal': '4px',
      },
      '&.small': {
        '--padding-vertical': '8px',
        '--padding-horizontal': '8px',
        '--font-size': '13px',
        '--line-height': '12px',
        '--button-dim': '28px',
        '&.isoPadding': {
          '--padding-horizontal': '6px',
        },
      },
      '&.compact': {
        '--padding-vertical': '5px',
        '--padding-horizontal': '5px',
        '--button-dim': '20px',
      },
      margin: 0,
      outline: 0,
      padding: 'var(--padding-vertical) var(--padding-horizontal)',
      backgroundColor: 'var(--main-bg-color)',
      color: 'var(--main-text-color)',
      borderRadius: 'var(--action-border-radius)',
      border: 0,
      fontSize: 'var(--font-size)',
      fontWeight: 600,
      lineHeight: 'var(--line-height)',
      whiteSpace: 'nowrap',
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      textDecoration: 'none',
      boxShadow: `0 1px 0 var(--main-drop-shadow-color),
                  inset 0 1px 0 var(--main-inset-shadow-color),
                  inset 0 0 0 var(--stroke-width) var(--action-border-color)`,
      transition: 'border-color 250ms, color 250ms, box-shadow 250ms, background-color 250ms',
      position: 'relative',
      userSelect: 'none',
      minHeight: 'var(--button-dim)',
      minWidth: 'var(--button-dim)',

      '&:enabled': {
        cursor: 'pointer',
      },

      '&:not(:disabled):hover': {
        '--action-border-color': 'var(--hover-border-color)',
        backgroundColor: 'var(--hover-bg-color)',
        color: 'var(--hover-text-color)',
        boxShadow: `0 1px 0 var(--hover-drop-shadow-color),
                    inset 0 1px 0 var(--hover-inset-shadow-color),
                    inset 0 0 0 var(--stroke-width) var(--action-border-color)`,
      },
      '&:not(:disabled):active': {
        '--action-border-color': 'var(--active-border-color)',
        backgroundColor: 'var(--active-bg-color)',
        color: 'var(--active-text-color)',
        boxShadow: `0 1px 0 var(--active-drop-shadow-color),
                    inset 0 1px 0 var(--active-inset-shadow-color),
                    inset 0 0 0 var(--stroke-width) var(--action-border-color)`,
      },
      '&:not(:disabled):not(:hover):not(:active):focus': {
        '--action-border-color': 'var(--focused-border-color)',
        backgroundColor: 'var(--focused-bg-color)',
        color: 'var(--focused-text-color)',
        boxShadow: `0 1px 0 var(--focused-drop-shadow-color),
                    inset 0 1px 0 var(--focused-inset-shadow-color),
                    0 0 0 2px var(--focus-color),
                    inset 0 0 0 var(--stroke-width) var(--action-border-color)`,
      },
      '&:disabled': {
        '--action-border-color': 'var(--disabled-border-color)',
        backgroundColor: 'var(--disabled-bg-color)',
        color: 'var(--disabled-text-color)',
        boxShadow: `0 1px 0 var(--disabled-drop-shadow-color),
                    inset 0 1px 0 var(--disabled-inset-shadow-color),
                    inset 0 0 0 var(--stroke-width) var(--action-border-color),`,
        // Preserve the "border" for a disabled `cancel` kind
        '&.cancelKind': {
          boxShadow: `0 1px 0 var(--main-drop-shadow-color),
                    inset 0 1px 0 var(--main-inset-shadow-color),
                    inset 0 0 0 var(--stroke-width) var(--action-border-color)`,
        },
      },
    },
    label: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      transition: 'opacity 250ms',
      '&.spinning': {
        opacity: 0,
      },
    },
    spinner: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0,
      zIndex: -1,
      transition: 'opacity 250ms, z-index 0ms 250ms',
      '&.spinning': {
        opacity: 1,
        zIndex: 1,
        transition: 'opacity 250ms, z-index 0ms 0ms',
      },
    },
  }),
  { name: 'ActionButton' },
);

/* eslint-disable */
// Addressing false positives - https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md
export interface ActionButtonProps {
  kind?: ActionKind;
  size?: FormControlSize;
  // Used to indicate the button is one of several buttons in an element with
  // its own border radius defined.  For example, if inset='right', the left
  // border-radius values are set to 0.
  inset?: 'left' | 'right';
  // When true, inner padding is substantially reduced
  compact?: boolean;

  startIcon?: SvgIconSpec;
  endIcon?: SvgIconSpec;

  // Render root element as a block element instead of the default inline
  asBlock?: boolean;
  // Customize the 'justify-content' CSS property (this only matters when
  // asBlock is true)
  justifyContent?: 'space-between' | 'center';
  // Add a tooltip to the button
  title?: string | JSX.Element;
  // Add hover text to the button
  hoverText?: string;
  // Set the type on the native <button> element ('button' by default)
  type?: 'button' | 'submit';
  // Add a 'name' attribute to the root element with this value
  name?: string;
  // Instead of a button, render a hyperlink pointing to the href value
  href?: string;
  // Handle button click events
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  // Optionally disable button
  disabled?: boolean;
  // Show a spinner instead of the regular label/icon content
  showSpinner?: boolean;
  // By default, horizontal and vertical padding values differ.  Set isoPadding to true to ensure
  // uniform padding on all four sides.
  isoPadding?: boolean;
  // Optionally set a different tabIndex. This is useful if we want to prevent the button from being
  // accessible with the keyboard's tab key.
  tabIndex?: number;
  // optional locator, which can be used for UI tests. Defaults to `actionButton`.
  dataLocator?: string;
  children: ReactNode;
  // should we open links in new tab
  openInNewTab?: boolean;
}
/* eslint-enable */
const getSpinnerSize = (small: boolean, compact: boolean) => {
  if (compact) {
    return small ? 14 : 16;
  }
  return small ? 18 : 20;
};

const getSpinnerColor = (kind: ActionKind, disabled: boolean) => {
  if (kind === 'destructive' && disabled) {
    return colors.red500;
  } if (kind === 'alternative') {
    return colors.neutral300;
  }
  return undefined;
};

export const ActionButton = memo(forwardRef<HTMLButtonElement, ActionButtonProps>((props, ref) => {
  const {
    compact,
    dataLocator = 'actionButton',
    disabled,
    endIcon,
    asBlock,
    href,
    inset,
    isoPadding,
    justifyContent = 'center',
    kind = 'primary',
    name,
    onClick,
    showSpinner,
    size = 'medium',
    startIcon,
    tabIndex,
    title,
    type = 'button',
    hoverText,
    openInNewTab,
  } = props;
  const classes = useStyles();
  const small = size === 'small';

  const className = cx([
    classes.content,
    `${kind}Kind`,
    { inset },
    inset ? `${inset}Inset` : '',
    { small, compact, isoPadding },
  ]);

  // Matches font sizes at 1.0 line height
  const iconHeight = small ? 12 : 14;

  const baseProps = {
    name,
    className,
    'data-locator': dataLocator,
    tabIndex,
  };

  const content = (
    <>
      <div className={cx(classes.label, { spinning: showSpinner })}>
        {startIcon && (
          <SvgIcon
            {...startIcon}
            color="currentColor"
            maxHeight={startIcon.maxHeight ?? iconHeight}
          />
        )}
        {props.children}
        {endIcon && (
          <SvgIcon
            {...endIcon}
            color="currentColor"
            maxHeight={endIcon.maxHeight ?? iconHeight}
          />
        )}
      </div>
      {showSpinner && (
        <div className={cx(classes.spinner, { spinning: showSpinner })}>
          <Propeller
            color={getSpinnerColor(kind, !!disabled)}
            size={getSpinnerSize(small, !!compact)}
          />
        </div>
      )}
    </>
  );

  return (
    <Tooltip title={title || ''}>
      <div className={cx(classes.root, { asBlock })}>
        {href && !disabled ? (
          <a
            {...baseProps}
            href={href}
            rel={openInNewTab ? 'noopener noreferrer' : undefined}
            target={openInNewTab ? '_blank' : undefined}>
            {content}
          </a>
        ) : (
          <button
            {...baseProps}
            disabled={disabled}
            onClick={onClick}
            ref={ref}
            style={{ justifyContent }}
            title={hoverText}
            /* eslint-disable-next-line react/button-has-type */
            type={type}>
            {content}
          </button>
        )}
      </div>
    </Tooltip>
  );
}));

ActionButton.displayName = 'ActionButton';
