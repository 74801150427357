// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { RefObject, useEffect, useRef } from 'react';

import { toPvProto } from '../../../lib/Vector';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { OVERLAY_CARD_WIDTH, newBoxParam, newPlaneParam } from '../../../lib/visUtils';
import { useGeoClipState } from '../../../recoil/geometry/geometryClipState';
import { useFloatingPanelTopRight } from '../../../recoil/lcvis/infoCardDims';
import { useLcVisFilterClipHideEnabled } from '../../../recoil/lcvis/lcvisClipHide';
import Form from '../../Form';
import { RadioButtonGroup } from '../../Form/RadioButtonGroup';
import { useDraggable } from '../../Paraview/draggable';
import Divider from '../../Theme/Divider';
import { PROP_PANEL_GAP } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { LuminaryToggleSwitch } from '../../controls/LuminaryToggleSwitch';
import { Dialog } from '../../dialog/Base';
import { ClipSliceInput, ClipSliceInputType } from '../../visFilter/ClipSliceInput';

const CLIP_PANEL_ID = 'geometry-mod-panel';

interface GeometryModificationClipPanelProps {
  overlayRef: RefObject<HTMLDivElement>;
}

export const GeometryModificationClipPanel = (props: GeometryModificationClipPanelProps) => {
  const { overlayRef } = props;

  const { projectId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();
  const [geoClipState, setGeoClipState] = useGeoClipState(projectId);
  const [clipHide, setClipHide] = useLcVisFilterClipHideEnabled();

  const { initDrag, draggedPositions, dragPositionToStyle, currentDragId } = useDraggable();
  const dialogNode = useRef<HTMLDivElement>(null);
  const isDragging = currentDragId === CLIP_PANEL_ID;

  const onMouseDown = (event: any) => {
    if (overlayRef.current && dialogNode.current) {
      initDrag(
        CLIP_PANEL_ID,
        event,
        dialogNode.current,
        overlayRef.current,
      );
    }
  };

  // Design requirements:
  // 1. If some node is selected, hide the widget controls. Otherwise, show the
  //    widget controls.
  // 2. Closing the dialog should deactivate the widget.
  useEffect(() => {
    if (selectedNodeIds.length) {
      setGeoClipState((prev) => ({
        ...prev,
        editSource: 'ui',
        showControls: false,
      }));
    } else {
      setGeoClipState((prev) => ({
        ...prev,
        editSource: 'ui',
        showControls: true,
      }));
    }
  }, [selectedNodeIds.length, setGeoClipState]);

  useEffect(() => {
    setGeoClipState((prev) => ({
      active: false,
      editSource: 'ui',
      showControls: true,
      param: null,
    }));
  }, [setGeoClipState]);

  const topRight = useFloatingPanelTopRight(dialogNode);

  return (
    <div
      ref={dialogNode}
      style={{
        position: 'absolute',
        zIndex: 1000,
        transition: 'top 0.2s',
        ...topRight,
        ...dragPositionToStyle(draggedPositions[CLIP_PANEL_ID]),
      }}>
      <Dialog
        compact
        draggable={!!onMouseDown}
        dragging={isDragging}
        onClose={() => {
          setGeoClipState((prev) => ({
            ...prev,
            active: false,
            showControls: true,
            editSource: 'ui',
          }));
        }}
        onContinue={() => {}}
        onFrameMousedown={onMouseDown}
        open={geoClipState.active}
        title="Clip Tool"
        width={OVERLAY_CARD_WIDTH}>
        <Form.LabeledInput label="Clip Type">
          <RadioButtonGroup
            kind="secondary"
            name="clipSliceTypes"
            onChange={(value: 'Plane' | 'BoxClip') => {
              const bounds = lcvHandler.display?.getCurrentDatasetBounds();
              if (!bounds) {
                return;
              }
              const newParam = value === 'Plane' ? newPlaneParam(bounds) : newBoxParam(bounds);
              setGeoClipState((prev) => ({
                ...prev,
                editSource: 'ui',
                param: newParam,
              }));
            }}
            options={[
              { label: 'Plane', value: 'Plane' },
              { label: 'Box', value: 'BoxClip' },
            ]}
            value={geoClipState.param?.typ}
          />
        </Form.LabeledInput>
        <ClipSliceInput
          normalWarning=""
          onBoxCommit={(newParam) => {
            setGeoClipState((prev) => ({
              ...prev,
              editSource: 'ui',
              param: newParam,
            }));
          }}
          onPlaneChange={(newParam) => {}}
          onPlaneCommit={(newParam) => {
            setGeoClipState((prev) => ({
              ...prev,
              editSource: 'ui',
              param: {
                typ: 'Plane',
                origin: toPvProto(newParam.origin),
                normal: toPvProto(newParam.normal),
              },
            }));
          }}
          param={geoClipState.param!}
          readonly={false}
          type={(geoClipState.param?.typ === 'Plane' ? 'Clip' : 'BoxClip') as ClipSliceInputType}
        />
        <div style={{ marginTop: PROP_PANEL_GAP }}>
          <Divider />
          <Form.LabeledInput label="Hide Clipped Surfaces" layout="favorLabel">
            <LuminaryToggleSwitch
              onChange={setClipHide}
              small
              value={clipHide}
            />
          </Form.LabeledInput>
        </div>
      </Dialog>
    </div>
  );
};
