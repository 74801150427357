// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as geometryservicepb from '../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometry from '../../../proto/geometry/geometry_pb';
import { useGeometryBusyMessageSelector, useGeometryBusyState, useIsGeoServerCreatingFeature } from '../../../recoil/geometry/geometryServerStatus';
import { useGeometryState } from '../../../recoil/geometry/geometryState';
import { useProjectContext } from '../../context/ProjectContext';

export function useIsInitialLoadOrImportFeature() {
  // == Context
  const { projectId, geometryId } = useProjectContext();

  // == State
  const geometryState = useGeometryState(projectId, geometryId);
  const [geometryBusyState] = useGeometryBusyState(geometryId);
  const geometryBusyMessage = useGeometryBusyMessageSelector(geometryId);
  const isGeoServerCreatingFeature = useIsGeoServerCreatingFeature(geometryId);

  // Progress reporting is only valid when the status is busy and when the progress messages
  // is not empty. This is because the server may report a busy state without a progress
  // message.
  if (!isGeoServerCreatingFeature || !geometryBusyMessage) {
    return false;
  }

  const isFeatureProgress = geometryBusyState?.BusyStateType?.case === 'featureProgress';
  const { featureId = '' } = geometryBusyState?.BusyStateType?.value as
    geometryservicepb.SubscribeGeometryResponse_BusyState_FeatureProgress;

  const featureInProgress = geometryState?.geometryFeatures.find(
    (feature) => feature.id === featureId,
  );

  // Only show the progress panel if the feature is an import operation or during the initial load
  // step.
  if (!isFeatureProgress) {
    return false;
  }
  if (!(featureInProgress?.operation?.value instanceof geometry.Import) && geometryState) {
    return false;
  }

  return true;
}
