// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { Logger } from '../lib/observability/logs';
import * as rpc from '../lib/rpc';
import { isTestingEnv } from '../lib/testing/utils';
import * as frontendpb from '../proto/frontend/frontend_pb';

const logger = new Logger('recoil/meshState');

export async function getAllMeshes(projectId: string): Promise<frontendpb.Mesh[]> {
  const req = new frontendpb.ListMeshesRequest({ projectId });
  try {
    const reply = await rpc.callRetry('ListMeshes', rpc.client.listMeshes, req);
    return reply.meshes;
  } catch (err) {
    logger.error('Unable to retrieve mesh list for projectId=%s: %s', projectId, err);
    return [];
  }
}

// returns true if the given mesh should be included in the mesh list
function includeInMeshList(mesh: frontendpb.Mesh) {
  return (
    mesh.status === frontendpb.Mesh_MeshStatus.COMPLETED &&
    (mesh.meshOrigin === frontendpb.Mesh_MeshOrigin.USER_GENERATED ||
      mesh.meshOrigin === frontendpb.Mesh_MeshOrigin.ADAPTED_MESH)
  );
}

export const projectMeshListAtom = atomFamily<frontendpb.Mesh[], string>({
  key: 'projectMeshListAtom',
  default: async (projectId: string) => {
    if (isTestingEnv()) {
      return [];
    }
    const meshList = await getAllMeshes(projectId);

    // If mesh status is not 2 (completed) mesh is not done and metadata req will fail
    return meshList.filter(includeInMeshList);
  },
  dangerouslyAllowMutability: true,
});

export const useProjectMeshList = (projectId: string) => useRecoilState(
  projectMeshListAtom(projectId),
);

export const useSetProjectMeshList = (projectId: string) => useSetRecoilState(
  projectMeshListAtom(projectId),
);

export const useRefetchProjectMeshList = (projectId: string) => {
  const setProjectMeshList = useSetRecoilState(projectMeshListAtom(projectId));

  async function refetchMeshList() {
    const meshList = await getAllMeshes(projectId);
    setProjectMeshList(meshList.filter(includeInMeshList));
  }

  return { refetchMeshList };
};
