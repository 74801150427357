// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as flags from '../../../flags';
import { SvgIconSpec } from '../../../lib/componentTypes/svgIcon';
import { useLcVisAllowedValue, useLcVisEnabledState } from '../../../recoil/lcvis/lcvisEnabledState';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import { useProjectContext } from '../../context/ProjectContext';

export const useLcVisButtonData = () => {
  const { projectId } = useProjectContext();
  const hasLcVisFlag = useIsEnabled(flags.lcvis);
  const hasLcVisMeshFlag = useIsEnabled(flags.lcvisMesh);
  const [lcVisEnabled, setLcVisEnabled] = useLcVisEnabledState(projectId);
  const allowLCVisToggle = useLcVisAllowedValue(projectId);

  const toggleLcVis = () => {
    if (!hasLcVisFlag) {
      console.error("this function shouldn't be callable");
      return;
    }
    setLcVisEnabled((prev: boolean) => !prev);
  };

  const switchToLCVisText = hasLcVisMeshFlag ?
    'Switch to client-side rendering' :
    'Switch to client-side rendering (only available when viewing geometry)';

  // Enable users with access to lcvis to switch between lcvis and Paraview
  // Don't even show the toggle if in simulation results view.
  if (hasLcVisFlag) {
    return {
      disabled: !allowLCVisToggle,
      icon: { name: lcVisEnabled ? 'sparkle' : 'sparkleDouble' } as SvgIconSpec,
      key: 'lcvisToggle',
      locator: 'toolbar-lcvis',
      onClick: toggleLcVis,
      label: lcVisEnabled ? 'Client-Side Rendering' : 'Server Rendering',
      title: lcVisEnabled ? 'Switch to server-side rendering' : switchToLCVisText,
    };
  }

  return null;
};
