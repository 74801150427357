// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo } from 'react';

import { unwrapSurfaceIds } from '../../lib/entityGroupUtils';
import { subtractSet } from '../../lib/lang';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { getPorousInterfaces, getPorousSurfaces } from '../../lib/porousModelUtils';
import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import { EntityGroupData } from '../../recoil/entityGroupState';
import { GeometryTags } from '../../recoil/geometry/geometryTagsObject';
import { useActiveNodeTableValue } from '../../recoil/useActiveNodeTable';
import { StaticVolume } from '../../recoil/volumes';

// Nodes that should also be disabled during NodeTable selection mode
const nodeTypesToDisable = [NodeType.GEOMETRY, NodeType.POINT_CONTAINER];

export function inSurfacesError(
  outputNode: feoutputpb.OutputNode | undefined,
  entityGroupData: EntityGroupData,
  nodeType: NodeType,
  nodeId: string,
  simParam: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const expand = (input: string[]) => unwrapSurfaceIds(input, geometryTags, entityGroupData);

  const outputSurfaces = outputNode?.inSurfaces && expand(outputNode?.inSurfaces);
  if (outputNode?.nodeProps.case === 'force') {
    const porousSurfaces = new Set(getPorousSurfaces(simParam, staticVolumes, geometryTags));
    // LC-17106, LC-18838
    // Porous force calculations have a special case where if a porous interface is
    // selected, then only other porous surfaces can be selected for its output
    const porousInterfaces = new Set(getPorousInterfaces(simParam, staticVolumes, geometryTags));
    // once a porous interface is selected, only porous surfaces can be selected
    const selectedPorousInterface = !!outputSurfaces?.length && outputSurfaces.some(
      (surface) => porousInterfaces.has(surface),
    );
    // if a non-porous surface is selected, only other non-porous surfaces can be selected
    const selectedNonPorous = !!outputSurfaces?.length && outputSurfaces.some(
      (surface) => !porousSurfaces.has(surface),
    );
    if (nodeType === NodeType.SURFACE) {
      if (selectedPorousInterface && !porousSurfaces.has(nodeId)) {
        return {
          disabled: true,
          disabledReason: 'You can only select bounding surfaces of Porous Volumes.',
        };
      }
      if (selectedNonPorous && porousInterfaces.has(nodeId)) {
        return {
          disabled: true,
          disabledReason: 'You can only select surfaces that do not bound Porous Volumes.',
        };
      }
    } else if (nodeType === NodeType.SURFACE_GROUP) {
      const surfaces = new Set(expand([nodeId]));
      if (selectedPorousInterface && subtractSet(surfaces, porousSurfaces).size) {
        return {
          disabled: true,
          disabledReason: 'You can only select groups containing bounding surfaces ' +
            'of Porous Volumes.',
        };
      }
      if (selectedNonPorous && !subtractSet(surfaces, porousInterfaces).size) {
        return {
          disabled: true,
          disabledReason: 'You can only select groups containing surfaces that do not bound ' +
            'Porous Volumes.',
        };
      }
    }
  }
  return {
    disabled: false,
  };
}

/**
 * When the NodeTable is in edit mode, the hook can be used to disable a particular TreeRow node
 * type and optionally, to provide a reason why it's disabled (the reason will appear as a Tooltip).
 */
export const useNodeTableTreeRowError = (node: SimulationTreeNode): {
  disabled: boolean,
  disabledReason?: string,
} => {
  // == Recoil
  const activeNodeTable = useActiveNodeTableValue();

  return useMemo(() => {
    if (activeNodeTable.type !== NodeTableType.NONE && nodeTypesToDisable.includes(node.type)) {
      return {
        disabled: true,
      };
    }

    return {
      disabled: false,
    };
  }, [activeNodeTable.type, node]);
};
