// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useRef } from 'react';

import { colors } from '../../lib/designSystem';
import { useGeometryServerStatus } from '../../recoil/geometry/geometryServerStatus';
import { useSetRpcGeometryRestartTrigger } from '../../recoil/geometry/geometryState';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { SectionMessage } from '../notification/SectionMessage';

interface PageBodyOverlayProps {
  message: ReactNode;
}

const useStyles = makeStyles(
  () => createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.0)',
      zIndex: 1000,
      padding: '12px',
      // Make sure that the overlay does not disallow interaction with the page. This was requested
      // by our customers.
      pointerEvents: 'none',
    },
    messageContainer: {
      // Enable pointer events here so that we can click on the reconnect text.
      pointerEvents: 'auto',
    },
    reconnectText: {
      color: colors.purple800,
      fontWeight: '600',
      cursor: 'pointer',
    },
  }),
  { name: 'PageBodyOverlay' },
);

/**
 * An overlay that covers the entire PageBody.
 */
export const PageBodyOverlay = (props: PageBodyOverlayProps) => {
  const { message } = props;
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classes.root}
      ref={ref}
      role="dialog">
      <div className={classes.messageContainer}>
        {message}
      </div>
    </div>
  );
};

/**
 * An overlay that covers the entire PageBody when the geometry tab is disconnected.
 */
export const GeoDisconnectedOverlay = () => {
  const { geometryId } = useProjectContext();
  const isGeometryView = useIsGeometryView();
  const setGeoRestart = useSetRpcGeometryRestartTrigger(geometryId);
  const [geometryServerStatus] = useGeometryServerStatus(geometryId);

  if (!isGeometryView || !geometryId || geometryServerStatus !== 'disconnected') {
    return <></>;
  }

  return (
    <PageBodyOverlay message={(
      <SectionMessage
        level="info"
        message={(
          <span>Your session has been paused. &nbsp;
            <span
              onClick={() => setGeoRestart(true)}
              onKeyDown={() => { }}
              role="button"
              style={{ color: colors.purple800, cursor: 'pointer', fontWeight: '600' }}
              tabIndex={0}>
              Click here to reconnect.
            </span>
          </span>
        )}
      />
    )}
    />

  );
};
