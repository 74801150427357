// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useState } from 'react';

import * as frontendpb from '../../../../proto/frontend/frontend_pb';

export enum OpenAtEnum {
  Tutorials = 'tutorials',
  Vnv = 'vnv',
}

export type OpenAt = OpenAtEnum | null;

export const useProjectDialogState = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [projectId, setProjectId] = useState<string | undefined>();
  const [values, setValues] = useState({ name: '', description: '' });
  const [openAt, setOpenAt] = useState<OpenAt>();

  const newProject = () => {
    setIsOpen(true);
    setIsEdit(false);
    setProjectId(undefined);
    setValues({ name: '', description: '' });
    setOpenAt(null);
  };

  const editProject = (project: frontendpb.ProjectSummary) => {
    setIsOpen(true);
    setIsEdit(true);
    setProjectId(project.projectId);
    setValues({ name: project.name, description: project.description });
    setOpenAt(null);
  };

  const openTutorials = () => {
    setIsOpen(true);
    setIsEdit(false);
    setProjectId(undefined);
    setValues({ name: '', description: '' });
    setOpenAt(OpenAtEnum.Tutorials);
  };

  const openVnV = () => {
    setIsOpen(true);
    setIsEdit(false);
    setProjectId(undefined);
    setValues({ name: '', description: '' });
    setOpenAt(OpenAtEnum.Vnv);
  };

  const close = () => {
    setIsOpen(false);
  };

  return {
    newProject,
    editProject,
    openTutorials,
    openVnV,
    close,
    props: {
      isOpen,
      isEdit,
      openAt,
      onCancel: close,
      projectId,
      ...values,
    },
  };
};
