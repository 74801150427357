// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVError, LCVObject, LCVStatusSeverity, LCVType, initLCVis } from '@luminarycloudinternal/lcvis';
import * as Sentry from '@sentry/react';

import { CurrentView } from '../../componentTypes/context';
import { getLcUserId } from '../../jwt';
import { Logger } from '../../observability/logs';
import { createRecursiveMock, isStorybookEnv, isTestingEnv } from '../../testing/utils';
import { addError } from '../../transientNotification';
import { getPipelineDataUrlsStreaming } from '../../visUtils';
import { LcvDisplay } from '../classes/LcvDisplay';
import { LcvSession } from '../classes/LcvSession';
import { recordWasmCompileStreaming, recordWasmInstantiateStreaming, registerWasmSentryEventHandler } from '../sentry/LcvSentryIntegration';
import { FilterProgressCallback, ImportFilterSetupOptions, LcvModule } from '../types';

import environmentState from '@/state/environment';

const logger = new Logger('LcvHandler');
const perfLogger = new Logger('LcvHandler', 'perf');
const fatalErrorLogger = new Logger('LcvHandler', 'fatal_error');

function getEnumKeyByValue<T>(enumObj: T, value: number): T[keyof T] | undefined {
  return enumObj[value as keyof T];
}

/** A debug function to print errors from the lcvis wasm.
 * When we're called from the abort handler we'll already have a stack trace
 * because we want to capture the trace as close to the failing stack frames
 * as we can. If we do it here in the callback we'll have half the trace we get
 * filled with error handling callbacks.
 */
function printStatus(
  lcv: LcvModule,
  session: LCVObject,
  source: LCVObject,
  error: LCVError,
  severity: LCVStatusSeverity,
  message: string,
  projectId: string | null,
  stackTrace?: string,
  currentView?: CurrentView,
) {
  let objectType = LCVType.kLCVDataTypeUnknown;
  if (lcv !== undefined && session !== 0 && source !== 0) {
    objectType = lcv.getObjectType(session, source, 0).type;
  }
  const lcvMessage = {
    userId: getLcUserId(),
    error: LCVError[error],
    severity: LCVStatusSeverity[severity],
    from: {
      handle: source,
      objectType: LCVType[objectType],
    },
    message,
    projectId,
    stackTrace: '',
    currentView,
  };
  // We only want stack traces on warnings and errors, we don't need it for general
  // logs and perf stats
  if (stackTrace || (severity !== LCVStatusSeverity.kLCVStatusSeverityPerformance &&
    severity !== LCVStatusSeverity.kLCVStatusSeverityInfo)) {
    lcvMessage.stackTrace = !stackTrace ? lcv.stackTrace() : stackTrace;
  }

  const jsonDump = JSON.stringify(lcvMessage);
  switch (severity) {
    case LCVStatusSeverity.kLCVStatusSeverityInfo:
      logger.info(jsonDump);
      break;
    case LCVStatusSeverity.kLCVStatusSeverityPerformance:
      perfLogger.info(jsonDump);
      break;
    case LCVStatusSeverity.kLCVStatusSeverityWarning:
      logger.warn(jsonDump);
      break;
    case LCVStatusSeverity.kLCVStatusSeverityError:
      logger.error(jsonDump);
      break;
    case LCVStatusSeverity.kLCVStatusSeverityFatalError:
      fatalErrorLogger.error(jsonDump);
      break;
    default:
      break;
  }

  // Send errors to Sentry as well
  if (severity === LCVStatusSeverity.kLCVStatusSeverityError ||
      severity === LCVStatusSeverity.kLCVStatusSeverityFatalError) {
    Sentry.captureException(lcvMessage);
  }

  // Don't display error messages that contain the internal debug message
  // to the user. These unsupported client messages will come through twice,
  // once when LCVis calls the status callback with the nice message,
  // and a second time when the LCVis typescript wrapper sees the call
  // returned an error with the internal error info. We don't want to
  // show the message on the second call. The second messages will always
  // start with 'Error calling'
  if (message.startsWith('Error calling')) {
    return;
  }
  // On fatal error we should reset LCVis and reload everything, something
  // very bad has happened. Similarly, on unsupported client errors we should
  // show some information to the user
  if (error === LCVError.kLCVErrorUnsupportedClient) {
    addError(message, 'Unsupported client for client-side visualization');
  } else if (error === LCVError.kLCVErrorWebGLContextCreationFailed) {
    addError(message, 'Unable to create WebGL2 context, does the browser support WebGL2?');
  } else if (error === LCVError.kLCVErrorWorkspaceGRPCFailed) {
    addError('', 'An error occurred computing visualization filters');
  }
}

/** Load the wasm module and initialize lcv. */
const init = async (handler: LcvHandler) => {
  const start = Date.now();

  // Register Sentry event to add debug mapping info for events with Wasm frames
  registerWasmSentryEventHandler();

  // Intercept WebAssembly instantiateStreaming/compileStreaming to get build
  // info we need for Sentry
  WebAssembly.instantiateStreaming = recordWasmInstantiateStreaming;
  WebAssembly.compileStreaming = recordWasmCompileStreaming;

  let lcv: LcvModule;
  lcv = await initLCVis({ // eslint-disable-line prefer-const
    getPipelineDataUrlsStreaming,
    onAbort: (message: string) => {
      // We get the stack trace here to try and get the deepest possible
      // stack trace, if we got it in printStatus we would include more
      // stack frames we don't care about in the trace and lose frames we
      // want to see.
      const stackTrace = new Error().stack?.toString();
      printStatus(
        lcv,
        0,
        0,
        LCVError.kLCVErrorAbort,
        LCVStatusSeverity.kLCVStatusSeverityFatalError,
        message,
        handler.projectId,
        stackTrace,
        getEnumKeyByValue(CurrentView, handler.currentView),
      );
    },
    print: (message: string) => {
      printStatus(
        lcv,
        0,
        0,
        LCVError.kLCVErrorNone,
        LCVStatusSeverity.kLCVStatusSeverityInfo,
        message,
        handler.projectId,
        '',
        getEnumKeyByValue(CurrentView, handler.currentView),
      );
    },
    printErr: (message: string) => {
      const stackTrace = new Error().stack?.toString();
      printStatus(
        lcv,
        0,
        0,
        LCVError.kLCVErrorUnknown,
        LCVStatusSeverity.kLCVStatusSeverityError,
        message,
        handler.projectId,
        stackTrace,
        getEnumKeyByValue(CurrentView, handler.currentView),
      );
    },
  });

  logger.info(JSON.stringify({
    userId: getLcUserId(),
    wasmDownloadAndCompile_ms: Date.now() - start,
  }));

  // Status callback is global, we should set it before creating the session in case that fails
  // We wrap printStatus here so that we can add the project ID to the logs as well
  lcv.setStatusCallback((
    lcvm: LcvModule,
    session: LCVObject,
    source: LCVObject,
    error: LCVError,
    severity: LCVStatusSeverity,
    message: string,
    stackTrace?: string,
  ) => {
    printStatus(
      lcvm,
      session,
      source,
      error,
      severity,
      message,
      handler.projectId,
      stackTrace,
      getEnumKeyByValue(CurrentView, handler.currentView),
    );
  });

  return lcv;
};

class LcvHandler {
  /**
   * Because loading the wasm is asynchronous, we use a param, ready, that will not resolve
   * until lcvis is ready to use. Then all member functions must await this.ready.
   */
  ready: Promise<LcvModule>;
  lcv: LcvModule = null;
  display: LcvDisplay | null = null;
  session: LcvSession | null = null;
  removeVisibilityListener = new AbortController();
  projectId: string | null = null;
  tearingDown: boolean = false;
  // A temporary bucket to store callbacks that are added while the display is being torn down.
  teardownCallbacks: Map<string, ((display: LcvDisplay) => void)> = new Map();
  currentView: CurrentView;

  /**
   * An object with callbacks to be called once the display has been initialized. When teardown is
   * called, this list is reset.
   * This is a map to guarantee queued functions are called in the order they were inserted.
   */
  callbacks: Map<string, ((display: LcvDisplay) => void)> = new Map();

  constructor() {
    this.ready = new Promise((resolve, reject) => {
      if (isTestingEnv() || isStorybookEnv()) {
        this.lcv = createRecursiveMock();
        resolve(true);
        return;
      }

      init(this).then((result) => {
        this.lcv = result;
        resolve(true);
      }).catch((error) => {
        if (isStorybookEnv()) {
          // the wasm doesn't load properly in a jsdom or node environment.
          return;
        }
        logger.error('initialization error for lcvis', error);
        throw new Error('there was an error initializing lcvis');
      });
    });

    this.currentView = CurrentView.SETUP;
  }

  async startSession(jwt: string, projectId: string) {
    await this.ready;
    if (this.session) {
      return;
    }
    this.session = new LcvSession(this.lcv, jwt);
    this.projectId = projectId;
  }

  async startDisplay(
    canvasId: string,
    filterOptions: ImportFilterSetupOptions,
    progressCallback: FilterProgressCallback,
    onMemoryExceeded: () => void,
  ) {
    await this.ready;
    if (this.session) {
      if (this.display) {
        this.display.release();
        this.display = null;
      }
      this.display = new LcvDisplay(this.lcv, canvasId, this.session.handle, onMemoryExceeded);
      await this.display.initFrame(filterOptions, progressCallback, () => {
        this.callbacks.forEach((callback, id) => {
          callback(this.display!);
          this.callbacks.delete(id);
        });
      });
      environmentState.lcvisReady = true;

      // We tell the LCVis Display when the document is hidden so that
      // it can record the right frame times, instead of including the
      // time the document was hidden (and thus renderAnimationFrame loop paused)
      // as part of the render time
      document.addEventListener(
        'visibilitychange',
        () => {
          if (document.hidden) {
            this.display?.displayHidden();
          }
        },
        { signal: this.removeVisibilityListener.signal },
      );
    }
  }

  async setCurrentView(currentView: CurrentView) {
    await this.ready;
    this.currentView = currentView;
  }

  async teardown() {
    this.tearingDown = true;
    await this.ready;
    this.display?.release();
    this.display = null;
    environmentState.lcvisReady = false;

    // Remove the visibility listener
    this.removeVisibilityListener.abort();

    this.tearingDown = false;
    // Reset the callbacks but add any that were added while the display was being torn down
    this.callbacks = new Map(this.teardownCallbacks);
    this.teardownCallbacks = new Map();
  }

  async getDisplay() {
    await this.ready;
    return this.display;
  }

  /**
   * Sometimes (as in atom effects), we want to attach a callback to a widget or other LCVObject.
   * But since they are added in recoil or elsewhere, the addCallback logic might run before
   * the display and its children are ready to accept it.
   * To work around this we can queue callbacks that will be called once the display is ready.
   * If 2 callbacks are added with the same id, the most recent callback wins. This way we can avoid
   * attaching old callbacks (e.g. if the user switches between simulation tabs before the display
   * loads).
    */
  queueDisplayFunction(id: string, callback: (display: LcvDisplay) => void) {
    if (this.tearingDown) {
      this.teardownCallbacks.set(id, callback);
      return;
    }
    if (!this.display?.complete) {
      this.callbacks.set(id, callback);
      return;
    }
    this.callbacks.delete(id);
    callback(this.display!);
  }
}

export const lcvHandler = new LcvHandler();
