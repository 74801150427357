// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo, useRef, useState } from 'react';

import assert from '../../../lib/assert';
import { SelectOption } from '../../../lib/componentTypes/form';
import { fromBigInt } from '../../../lib/number';
import { Logger } from '../../../lib/observability/logs';
import { traverseTreeNodes } from '../../../lib/paraviewUtils';
import { defaultPlotRange, getPositionRangeBounds, getQuantityRangeBounds } from '../../../lib/plot';
import { useNodePanel } from '../../../lib/useNodePanel';
import * as plotpb from '../../../proto/plots/plots_pb';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { useCustomFieldNodes } from '../../../recoil/customFieldNodes';
import { useOutputNodes } from '../../../recoil/outputNodes';
import { useCurrentlySelectedPlot, usePlotNodes } from '../../../recoil/plotNodes';
import { useViewState } from '../../../recoil/useViewState';
import { DataSelect } from '../../Form/DataSelect';
import LabeledInput from '../../Form/LabeledInput';
import { NumberInput } from '../../Form/NumberInput';
import { CollapsiblePanel } from '../../Panel/CollapsiblePanel';
import { DataComponentSelect } from '../../Paraview/DataComponentSelect';
import DataNameSelect from '../../Paraview/DataNameSelect';
import { createStyles, makeStyles } from '../../Theme';
import Divider from '../../Theme/Divider';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { RangeSlider, RangeValue } from '../../controls/slider/RangeSlider';
import { AttributesDisplay } from '../AttributesDisplay';
import PropertiesSection from '../PropertiesSection';

import { ForceDistributionPropPanel } from './ForceDistribution';

const logger = new Logger('propPanel/Plot');

const useStyles = makeStyles(
  () => createStyles({
    rangeSlider: {
      padding: '10px',
    },
    rangeNumberInput: {
      display: 'flex',
      flexDirection: 'row',
      gap: '5px',
    },
  }),
  { name: 'PlotPropPanel' },
);

export const PlotPropPanel = () => {
  // == Contexts
  const { projectId } = useProjectContext();
  const { selectedNode: node, outputGraphId, setOutputGraphId } = useSelectionContext();
  assert(!!node, 'No selected plot row');

  // == Recoil
  const [viewState] = useViewState(projectId);
  const [combinedOutputs] = useOutputNodes(projectId, '', '');
  const [thisPlotNode, setThisPlotNode] = useCurrentlySelectedPlot();
  const dataSeriesPanel = useNodePanel(node.id, 'dataSeries');
  const plotPanel = useNodePanel(node.id, 'plot');
  const formatPanel = useNodePanel(node.id, 'formatPlot');
  const [plotState, setPlotState] = usePlotNodes(projectId);

  // == Hooks
  const classes = useStyles();

  // == Data
  const graphNodes = combinedOutputs.nodes;
  const originalXYPlot = thisPlotNode.plot.case === 'xyPlot' ? thisPlotNode.plot.value : undefined;
  const thisXYPlot = originalXYPlot?.clone();
  const [lastSelectedPlotId, setLastSelectedPlotId] = useState('');
  const [customFields] = useCustomFieldNodes(projectId);

  // == State
  // A ref to set timeouts so that we don't update the chart (and send an RPC) every single change
  // of the range
  const updateFromRange = useRef<ReturnType<typeof setTimeout> | null>(null);
  // The minimum and maximum possible quantity and position ranges, derived from the viewstate
  const [maxPosRange, setMaxPosRange] = useState<number[]>(defaultPlotRange());
  const [maxQuantRange, setMaxQuantRange] = useState<number[]>(defaultPlotRange());
  // The currently set position and quantity range, kept in a separate state to enable debounced
  // updates of the protobuf fields.
  const [currentPosRange, setCurrentPosRange] = useState<number[]>(defaultPlotRange());
  const [currentQuantRange, setCurrentQuantRange] = useState<number[]>(defaultPlotRange());

  useEffect(() => {
    const selectedPlotNode = plotState.plots.find((plot) => plot.id === node?.id);
    if (selectedPlotNode && selectedPlotNode !== thisPlotNode) {
      setThisPlotNode(selectedPlotNode);
    }
  }, [plotState, node, thisPlotNode, setThisPlotNode]);

  // The output node is identified by the outputGraphId. We use the output
  // node to calculate which graphNode we have selected, similar to how it's done
  // in OutputChartPanel
  const outputNode = useMemo(() => {
    const nodeGraphOutputs = graphNodes.filter((output) => output.id === outputGraphId.node);
    return nodeGraphOutputs[outputGraphId.graphIndex] || null;
  }, [graphNodes, outputGraphId]);

  const outputIndex = outputNode ? graphNodes.indexOf(outputNode) : -1;

  const yScaleOptions = [{
    name: 'Linear',
    value: plotpb.AxisScale.LINEAR,
    selected: thisXYPlot?.yAxisScale === plotpb.AxisScale.LINEAR,
  },
  {
    name: 'Log Scale',
    value: plotpb.AxisScale.LOG,
    selected: thisXYPlot?.yAxisScale === plotpb.AxisScale.LOG,
  }];

  const updatePlot = () => {
    updateFromRange.current = null;
    const newPlotNode = thisPlotNode.clone();
    if (thisXYPlot) {
      newPlotNode.plot = { case: 'xyPlot', value: thisXYPlot };
    }
    setThisPlotNode(newPlotNode);

    // We want to insert the updated plot in the list at the same index it was at before
    const newPlotState = plotState.clone();
    newPlotState.plots = newPlotState.plots.map((plot) => {
      if (plot.id === newPlotNode.id) {
        return newPlotNode;
      }
      return plot;
    });
    setPlotState(newPlotState);
  };

  // Calculate the range bounds from the viewstate and set them as the min and max possible range
  // values, as well as the initial starting values
  const setPositionRange = () => {
    const bounds = thisXYPlot && viewState ?
      getPositionRangeBounds(viewState, thisXYPlot.xAxis, thisXYPlot.dataIds) :
      defaultPlotRange();
    setMaxPosRange(bounds);
    setCurrentPosRange(bounds);
    if (thisXYPlot?.xAxisRange) {
      thisXYPlot.xAxisRange.rangeStart = bounds[0];
      thisXYPlot.xAxisRange.rangeEnd = bounds[1];
    }
  };

  // Calculate the range bounds from the viewstate and set them as the min and max possible range
  // values, as well as the initial starting values
  const setQuantityRange = () => {
    const bounds = getQuantityRangeBounds(viewState, thisXYPlot?.yAxis?.displayDataName);
    setMaxQuantRange(bounds);
    setCurrentQuantRange(bounds);
    if (thisXYPlot?.yAxisRange) {
      thisXYPlot.yAxisRange.rangeStart = bounds[0];
      thisXYPlot.yAxisRange.rangeEnd = bounds[1];
    }
  };

  // If thisPlotNode has a different id than lastSelectedPlotId, we've selected a different plot,
  // as opposed to having updated the currently selected plot. In that case, we reset the quantity
  // and range defaults.
  if (thisPlotNode.id !== lastSelectedPlotId) {
    setLastSelectedPlotId(thisPlotNode.id);
    setPositionRange();
    setQuantityRange();
    updatePlot();
  }

  const lineOptions = viewState?.root.child.filter((visNode) => visNode.param.typ === 'Line');

  // Find all intersection curves in the tree, even if they are nested below other filters
  const intersectionCurveOptions: ParaviewRpc.TreeNode[] = [];
  const findIntersectionCurves = (visNode: ParaviewRpc.TreeNode) => {
    if (visNode.param.typ === 'IntersectionCurve') {
      intersectionCurveOptions.push(visNode);
    }
  };
  viewState && traverseTreeNodes(viewState.root, findIntersectionCurves);
  const DataTypeOptions = [
    {
      name: 'Line',
      value: plotpb.DataTypeOptions.LINE,
      selected: thisXYPlot?.dataType === plotpb.DataTypeOptions.LINE,
    },
    {
      name: 'Intersection Curve',
      value: plotpb.DataTypeOptions.INTERSECTION_CURVE,
      selected: thisXYPlot?.dataType === plotpb.DataTypeOptions.INTERSECTION_CURVE,
    },
  ];

  const XAxisOptions = [
    {
      name: 'Arc Length',
      value: plotpb.XAxisOptions.ARC_LENGTH,
      selected: thisXYPlot?.xAxis === plotpb.XAxisOptions.ARC_LENGTH,
    },
    {
      name: 'X Coordinate',
      value: plotpb.XAxisOptions.X_COORD,
      selected: thisXYPlot?.xAxis === plotpb.XAxisOptions.X_COORD,
    },
    {
      name: 'Y Coordinate',
      value: plotpb.XAxisOptions.Y_COORD,
      selected: thisXYPlot?.xAxis === plotpb.XAxisOptions.Y_COORD,
    },
    {
      name: 'Z Coordinate',
      value: plotpb.XAxisOptions.Z_COORD,
      selected: thisXYPlot?.xAxis === plotpb.XAxisOptions.Z_COORD,
    },
  ];

  let dataOptions: SelectOption<string>[] = [];
  let hasData = false;
  if (thisXYPlot?.dataType === plotpb.DataTypeOptions.LINE && lineOptions) {
    if (lineOptions.length === 0) {
      dataOptions = [{
        description: 'Create at least one Line from the visualization toolbar.',
        disabled: true,
        name: 'None',
        value: 'none',
      }];
    } else {
      hasData = true;
      dataOptions = (lineOptions.map((output) => ({
        name: output.name,
        value: output.id,
        selected: thisXYPlot?.dataIds.includes(output.id),
      })));
    }
  } else if (
    thisXYPlot?.dataType === plotpb.DataTypeOptions.INTERSECTION_CURVE &&
    intersectionCurveOptions
  ) {
    if (intersectionCurveOptions.length === 0) {
      dataOptions = [{
        description: 'Create at least one Intersection Curve from the visualization toolbar.',
        disabled: true,
        name: 'None',
        value: 'none',
      }];
    } else {
      hasData = true;
      dataOptions = (intersectionCurveOptions.map(((output) => ({
        name: output.name,
        value: output.id,
        selected: thisXYPlot?.dataIds.includes(output.id),
      }))));
    }
  }

  const xyPlotQuantityVar: ParaviewRpc.DisplayPvVariable = {
    displayDataName: thisXYPlot?.yAxis?.displayDataName ?? '',
    displayDataNameComponent: fromBigInt(thisXYPlot?.yAxis?.displayDataNameComponent ?? 0),
  };

  // The selector for the chart type. This will be an AttributesDisplay for Monitor Plots
  // or a dropdown for any other type of plot. For now, I am keeping it simply as an
  // AttributesDisplay until there is more than one option for the chart type, so we will
  // simply display "XY Plot" when the selected node is not a Monitor Plot.
  const chartType = (plotCase: plotpb.PlotSettings['plot']['case']) => {
    switch (plotCase) {
      case 'monitorPlot':
        return 'Monitor Plot';
      case 'xyPlot':
        return 'XY Plot';
      case 'forceDistribution':
        return 'Force Distribution';
      default:
        // This should never happen
        logger.warn(`Unrecognized chart panel type: ${plotCase}`);
        return (<>Plot type is unknown</>);
    }
  };

  const chartTypeSection = (
    <div>
      <AttributesDisplay attributes={[{
        label: 'Chart Type',
        value: chartType(thisPlotNode.plot.case),
      }]}
      />
    </div>
  );

  // The properties panel for Monitor Plots
  const monitorPlot = (
    <PropertiesSection>
      <CollapsiblePanel
        collapsed={plotPanel.collapsed}
        heading="Plot"
        onToggle={plotPanel.toggle}>
        <LabeledInput
          label="Quantity">
          <DataSelect
            asBlock
            disabled={graphNodes.length === 0}
            onChange={(value) => {
              const graphNode: string = graphNodes[value].id;
              // Find the new graphIndex by counting the number of earlier nodes
              // that have the same ID.
              let graphIndex = 0;
              for (let i = 0; i < value; i += 1) {
                if (graphNodes[i].id === graphNode) {
                  graphIndex += 1;
                }
              }
              if (thisPlotNode.plot.case === 'monitorPlot') {
                thisPlotNode.plot.value.outputGraphId = graphNodes[value].id;
              }
              setOutputGraphId({ node: graphNode, graphIndex });
            }}
            options={graphNodes.map((output, i) => ({
              name: output.name,
              value: i,
              selected: i === outputIndex,
            }))}
            size="small"
          />
        </LabeledInput>
      </CollapsiblePanel>
    </PropertiesSection>
  );

  // The properties panel for XY plots
  const xyPlot = (
    <div>
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={dataSeriesPanel.collapsed}
          heading="Data Series"
          onToggle={dataSeriesPanel.toggle}>
          <LabeledInput
            label="Type">
            <DataSelect
              asBlock
              onChange={(value) => {
                if (thisXYPlot) {
                  thisXYPlot.dataType = value;
                }
                updatePlot();
              }}
              options={DataTypeOptions}
              size="small"
            />
          </LabeledInput>
          <LabeledInput
            label="Data">
            <DataSelect
              asBlock
              multiple={hasData}
              onChangeMultiple={(value) => {
                if (thisXYPlot) {
                  thisXYPlot.dataIds = [...value];
                }
                setPositionRange();
                updatePlot();
              }}
              options={dataOptions}
              placeholderText={hasData ? 'Select...' : 'None'}
              size="small"
            />
          </LabeledInput>
        </CollapsiblePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={plotPanel.collapsed}
          heading="Plot"
          onToggle={plotPanel.toggle}>
          <LabeledInput
            label="Quantity">
            <DataNameSelect
              asBlock
              customFields={customFields}
              disabled={!viewState || viewState.surfaceData.length === 0}
              displayNoneOption={false}
              innerMenuPosition="left-down"
              innerMenuPositionTransform={{ left: -4 }}
              onChange={(value) => {
                if (thisXYPlot?.yAxis) {
                  thisXYPlot.yAxis.displayDataName = value;
                  thisXYPlot.yAxis.displayDataNameComponent = 0n;
                }
                setQuantityRange();
                updatePlot();
              }}
              options={viewState?.surfaceData.map(({ name }) => name) || []}
              position="left-down"
              positionTransform={{ left: -3 }}
              value={viewState?.surfaceData.find(
                ({ name }) => name === thisXYPlot?.yAxis?.displayDataName,
              )?.name || 'None'}
            />
          </LabeledInput>
          <DataComponentSelect
            disabled={!viewState || viewState.surfaceData.length === 0}
            displayVariable={xyPlotQuantityVar}
            fullRow
            onChange={(component: number) => {
              if (thisXYPlot?.yAxis) {
                thisXYPlot.yAxis.displayDataNameComponent = BigInt(component);
              }
              updatePlot();
            }}
            viewState={viewState}
          />
          <LabeledInput
            label="Position">
            <DataSelect
              asBlock
              onChange={(value) => {
                if (thisXYPlot) {
                  thisXYPlot.xAxis = value;
                }
                updatePlot();
                setPositionRange();
              }}
              options={XAxisOptions}
              size="small"
            />
          </LabeledInput>
        </CollapsiblePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={formatPanel.collapsed}
          heading="Format"
          onToggle={formatPanel.toggle}>
          <LabeledInput
            label="Quantity Range">
            <div className={classes.rangeNumberInput}>
              <NumberInput
                asBlock
                onCommit={(value: number) => {
                  if (thisXYPlot?.yAxisRange) {
                    thisXYPlot.yAxisRange.rangeStart = value;
                  }
                  updatePlot();
                  setCurrentQuantRange([value, currentQuantRange[1]]);
                }}
                placeholder="Min"
                size="small"
                value={currentQuantRange[0]}
              />
              <NumberInput
                asBlock
                onCommit={(value: number) => {
                  if (thisXYPlot?.yAxisRange) {
                    thisXYPlot.yAxisRange.rangeEnd = value;
                  }
                  setCurrentQuantRange([currentQuantRange[0], value]);
                  updatePlot();
                }}
                placeholder="Max"
                size="small"
                value={currentQuantRange[1]}
              />
            </div>
            <div className={classes.rangeSlider}>
              <RangeSlider
                max={maxQuantRange[1]}
                min={maxQuantRange[0]}
                onChange={([start, end]: RangeValue) => {
                  if (thisXYPlot?.yAxisRange) {
                    thisXYPlot.yAxisRange.rangeStart = start;
                    thisXYPlot.yAxisRange.rangeEnd = end;
                  }
                  setCurrentQuantRange([start, end]);
                  // Don't flood updates (and therefore RPCs) for every small change
                  if (updateFromRange.current) {
                    clearTimeout(updateFromRange.current);
                  }
                  updateFromRange.current = setTimeout(updatePlot, 50);
                }}
                readoutConfig={{ disabled: true }}
                value={[currentQuantRange[0], currentQuantRange[1]]}
              />
            </div>
          </LabeledInput>
          <LabeledInput
            label="Position Range">
            <div className={classes.rangeNumberInput}>
              <NumberInput
                asBlock
                onCommit={(value: number) => {
                  if (thisXYPlot?.xAxisRange) {
                    thisXYPlot.xAxisRange.rangeStart = value;
                  }
                  updatePlot();
                  setCurrentPosRange([value, currentPosRange[1]]);
                }}
                placeholder="Min"
                size="small"
                value={currentPosRange[0]}
              />
              <NumberInput
                asBlock
                onCommit={(value: number) => {
                  if (thisXYPlot?.xAxisRange) {
                    thisXYPlot.xAxisRange.rangeEnd = value;
                  }
                  updatePlot();
                  setCurrentPosRange([currentPosRange[0], value]);
                }}
                placeholder="Max"
                size="small"
                value={currentPosRange[1]}
              />
            </div>
            <div className={classes.rangeSlider}>
              <RangeSlider
                max={maxPosRange[1]}
                min={maxPosRange[0]}
                onChange={([start, end]: RangeValue) => {
                  if (thisXYPlot?.xAxisRange) {
                    thisXYPlot.xAxisRange.rangeStart = start;
                    thisXYPlot.xAxisRange.rangeEnd = end;
                  }
                  // Don't flood updates (and therefore RPCs) for every small change
                  setCurrentPosRange([start, end]);
                  if (updateFromRange.current) {
                    clearTimeout(updateFromRange.current);
                  }
                  updateFromRange.current = setTimeout(updatePlot, 50);
                }}
                readoutConfig={{ disabled: true }}
                simpleStyling
                value={[currentPosRange[0], currentPosRange[1]]}
              />
            </div>
          </LabeledInput>
          <LabeledInput
            label="Quantity Scale">
            <DataSelect
              asBlock
              disabled={false}
              onChange={(value) => {
                if (thisXYPlot) {
                  thisXYPlot.yAxisScale = value;
                }
                updatePlot();
              }}
              options={yScaleOptions}
              size="small"
            />
          </LabeledInput>
        </CollapsiblePanel>
      </PropertiesSection>
    </div>
  );

  const distributionPlot = ForceDistributionPropPanel();

  // A switch that returns the properties to display given the type of
  // the selected node.
  const chartPanel = (plotCase: plotpb.PlotSettings['plot']['case']) => {
    switch (plotCase) {
      case 'monitorPlot':
        return monitorPlot;
      case 'xyPlot':
        return xyPlot;
      case 'forceDistribution':
        return distributionPlot;
      default:
        // This should never happen
        logger.warn(`Unrecognized chart panel type: ${plotCase}`);
        return (<>Plot type is unknown</>);
    }
  };

  return (
    <div>
      {chartTypeSection}
      <Divider />
      {chartPanel(thisPlotNode.plot.case)}
    </div>
  );
};
