// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/plots/plots.proto (package luminary.proto.plots, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum luminary.proto.plots.XAxisOptions
 */
export const XAxisOptions = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.plots.XAxisOptions",
  [
    {no: 0, name: "ARC_LENGTH"},
    {no: 1, name: "X_COORD"},
    {no: 2, name: "Y_COORD"},
    {no: 3, name: "Z_COORD"},
  ],
);

/**
 * @generated from enum luminary.proto.plots.DataTypeOptions
 */
export const DataTypeOptions = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.plots.DataTypeOptions",
  [
    {no: 0, name: "LINE"},
    {no: 1, name: "INTERSECTION_CURVE"},
  ],
);

/**
 * @generated from enum luminary.proto.plots.AxisScale
 */
export const AxisScale = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.plots.AxisScale",
  [
    {no: 0, name: "LINEAR"},
    {no: 1, name: "LOG"},
  ],
);

/**
 * @generated from enum luminary.proto.plots.ForceDistributionOptions
 */
export const ForceDistributionOptions = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.plots.ForceDistributionOptions",
  [
    {no: 0, name: "LOCAL"},
    {no: 1, name: "CUMULATIVE"},
  ],
);

/**
 * @generated from enum luminary.proto.plots.ForceDirectionOptions
 */
export const ForceDirectionOptions = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.plots.ForceDirectionOptions",
  [
    {no: 0, name: "VECTOR"},
  ],
);

/**
 * @generated from message luminary.proto.plots.AxisRange
 */
export const AxisRange = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.plots.AxisRange",
  () => [
    { no: 1, name: "range_start", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "range_end", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message luminary.proto.plots.YAxisTuple
 */
export const YAxisTuple = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.plots.YAxisTuple",
  () => [
    { no: 1, name: "display_data_name_component", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "display_data_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.plots.PlotSettings
 */
export const PlotSettings = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.plots.PlotSettings",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "monitor_plot", kind: "message", T: PlotSettings_MonitorPlot, oneof: "plot" },
    { no: 4, name: "xy_plot", kind: "message", T: PlotSettings_XYPlot, oneof: "plot" },
    { no: 5, name: "force_distribution", kind: "message", T: PlotSettings_ForceDistribution, oneof: "plot" },
  ],
);

/**
 * @generated from message luminary.proto.plots.PlotSettings.MonitorPlot
 */
export const PlotSettings_MonitorPlot = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.plots.PlotSettings.MonitorPlot",
  () => [
    { no: 1, name: "output_graph_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "PlotSettings_MonitorPlot"},
);

/**
 * @generated from message luminary.proto.plots.PlotSettings.XYPlot
 */
export const PlotSettings_XYPlot = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.plots.PlotSettings.XYPlot",
  () => [
    { no: 1, name: "dataIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "data_type", kind: "enum", T: proto3.getEnumType(DataTypeOptions) },
    { no: 3, name: "y_axis", kind: "message", T: YAxisTuple },
    { no: 4, name: "x_axis", kind: "enum", T: proto3.getEnumType(XAxisOptions) },
    { no: 5, name: "show_legend", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "include_profile_shape", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "x_axis_range", kind: "message", T: AxisRange },
    { no: 8, name: "y_axis_range", kind: "message", T: AxisRange },
    { no: 9, name: "x_axis_scale", kind: "enum", T: proto3.getEnumType(AxisScale) },
    { no: 10, name: "y_axis_scale", kind: "enum", T: proto3.getEnumType(AxisScale) },
  ],
  {localName: "PlotSettings_XYPlot"},
);

/**
 * @generated from message luminary.proto.plots.PlotSettings.ForceDistribution
 */
export const PlotSettings_ForceDistribution = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.plots.PlotSettings.ForceDistribution",
  () => [
    { no: 1, name: "axis", kind: "enum", T: proto3.getEnumType(XAxisOptions) },
    { no: 2, name: "distribution_type", kind: "enum", T: proto3.getEnumType(ForceDistributionOptions) },
    { no: 3, name: "force_dir_type", kind: "enum", T: proto3.getEnumType(ForceDirectionOptions) },
    { no: 4, name: "force_dir_x", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "force_dir_y", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "force_dir_z", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "bounds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "n_bins", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
  {localName: "PlotSettings_ForceDistribution"},
);

/**
 * @generated from message luminary.proto.plots.Plots
 */
export const Plots = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.plots.Plots",
  () => [
    { no: 1, name: "plots", kind: "message", T: PlotSettings, repeated: true },
  ],
);

