// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { singlePhysicsCustomFieldNodesFixture, singlePhysicsWorkflowConfigFixture }
  from '../lib/fixtures';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { isTestingEnv } from '../lib/testing/utils';
import * as feoutputpb from '../proto/frontend/output/output_pb';

export const serialize = (val: feoutputpb.CustomFields) => val.toBinary();

export const customFieldNodesPrefix = 'customFieldNodes';

// customFieldNodesSelector gets the custom field nodes state from the kvstore.
export const customFieldNodesSelectorRpc = selectorFamily<
  feoutputpb.CustomFields | undefined,
  persist.RecoilProjectKey
>({
  key: 'customFieldNodesSelector/rpc',
  get: (key: persist.RecoilProjectKey) => () => persist.getProjectState(
    key.projectId,
    [
      persist.getProjectStateKey(customFieldNodesPrefix, key),
      customFieldNodesPrefix,
    ],
    (val: Uint8Array) => (val.length ?
      feoutputpb.CustomFields.fromBinary(val) :
      undefined),
  ),
  dangerouslyAllowMutability: true,

});

// Selector used for testing
const customFieldNodesSelectorTesting = selectorFamily<
  feoutputpb.CustomFields,
  persist.RecoilProjectKey
>({
  key: 'customFieldNodesSelector/testing',
  get: () => () => {
    const fixture = singlePhysicsWorkflowConfigFixture();
    return singlePhysicsCustomFieldNodesFixture(fixture);
  },
  dangerouslyAllowMutability: true,
});

const customFieldNodesSelector = isTestingEnv() ? customFieldNodesSelectorTesting :
  customFieldNodesSelectorRpc;

const customFieldNodesUpdateSelector =
selectorFamily<feoutputpb.CustomFields, persist.RecoilProjectKey>({
  key: 'customFieldNodesUpdateSelector',
  get: (key: persist.RecoilProjectKey) => ({ get }) => {
    const maybeCustomFieldNodes = get(customFieldNodesSelector(key));

    let customFieldNodes: feoutputpb.CustomFields;
    // If custom fields are unset, use the defaults.
    if (!maybeCustomFieldNodes) {
      customFieldNodes = new feoutputpb.CustomFields();
    } else {
      customFieldNodes = maybeCustomFieldNodes;
    }
    return customFieldNodes;
  },
  dangerouslyAllowMutability: true,
});

export const customFieldNodesState =
atomFamily<feoutputpb.CustomFields, persist.RecoilProjectKey>({
  key: 'customFieldNodes',
  default: customFieldNodesUpdateSelector,
  effects: (key: persist.RecoilProjectKey) => [
    syncProjectStateEffect(
      key.projectId,
      persist.getProjectStateKey(customFieldNodesPrefix, key),
      (val) => feoutputpb.CustomFields.fromBinary(val),
      serialize,
    ),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const useCustomFieldNodes = (projectId: string) => (
  useRecoilState(customFieldNodesState({ projectId, workflowId: '', jobId: '' }))
);

export const useSetCustomFieldNodes = (projectId: string) => (
  useSetRecoilState(customFieldNodesState({ projectId, workflowId: '', jobId: '' }))
);
