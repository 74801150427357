// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { useVisDropdownData } from '../hooks/ribbonToolbar/useVisDropdownData';

import { AddNodeMenuButton } from './AddNodeButton';

// Renders action button to add visualizations.
export const VisualizationsActionButton = ({ parentNodeId }: { parentNodeId: string }) => {
  const visualizationsButtonData = useVisDropdownData({ parentNodeId });

  return (
    <AddNodeMenuButton
      disabled={visualizationsButtonData.disabled}
      menuItems={visualizationsButtonData.items || []}
    />
  );
};
