// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const ShrinkwrapIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="shrinkwrap"
      fill="none"
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.66499 3.665
           C9.66499 3.47998 9.81498 3.33 9.99999 3.33
           H13.995
           V2
           H9.99999
           C9.53397 2 9.11267 2.19145 8.81045 2.5
           C8.78231 2.52874 8.75517 2.55849 8.72915 2.5892
           C8.48327 2.87937 8.33499 3.25488 8.33499 3.665
           L8.33499 7.99999
           C8.33499 8.18501 8.18501 8.33499 7.99999 8.33499
           H3.665
           C3.25487 8.33499 2.87937 8.48328 2.5892 8.72917
           C2.55849 8.75519 2.52872 8.7823 2.49998 8.81045
           C2.19145 9.11267 2 9.53398 2 9.99999
           V13.995
           H3.33
           V9.99999
           C3.33 9.81498 3.47998 9.66499 3.665 9.66499
           H7.99999
           C8.91955 9.66499 9.66499 8.91955 9.66499 7.99999
           L9.66499 3.665Z"
        fill={getColor(0)}
      />
      <path
        d="M11.33 4.99499
           H14
           V14
           H4.99499
           V11.33
           H7.99998
           C9.83909 11.33 11.33 9.83909 11.33 7.99998
           L11.33 4.99499Z"
        fill={getColor(0)}
      />
    </svg>
  );
});
