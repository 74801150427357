// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { NodeType } from '../../../../lib/simulationTree/node';
import { useMaterialEntityNode } from '../../../hooks/useMaterialEntityNode';
import { useNodeRenaming } from '../../../hooks/useNodeRenaming';
import { TreeRow } from '../../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const MaterialFluidTreeRow = (props: SimulationRowProps) => {
  const { node } = props;
  const { getContextMenuItems } = useMaterialEntityNode(node.id, NodeType.MATERIAL_SOLID);
  const renaming = useNodeRenaming(node);

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={getNodeTypeIcon(props.node.type)}
      renaming={renaming}
    />
  );
};
