import { useCallback } from 'react';

import { ChatMessage } from '../../../lib/assistant/assistant';
import { useSetAssistantMessages } from '../../../state/external/assistant/assistantMessages';
import { useSetAssistantThinking } from '../../../state/external/assistant/assistantThinking';

export const useAddNewAssistantMessage = (projectId: string) => {
  const setMessages = useSetAssistantMessages(projectId);
  const setAssistantThinking = useSetAssistantThinking();

  return useCallback((text: string) => {
    const newMessage: ChatMessage = {
      id: `${Date.now()}`,
      text,
      isUser: true,
      timestamp: new Date(),
    };

    setMessages((currMessages) => {
      const updatedMessages = new Map(currMessages);
      updatedMessages.set(newMessage.id, newMessage);
      return updatedMessages;
    });

    setAssistantThinking(true);
  }, [setMessages, setAssistantThinking]);
};
