// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { findCustomFieldNodeById } from '../../../lib/customFieldNodeUtils';
import { copiedName } from '../../../lib/name';
import { newNodeId } from '../../../lib/projectDataUtils';
import { useCustomFieldNodes } from '../../../recoil/customFieldNodes';
import { initializeNewNode, useSetNewNodes } from '../../../recoil/nodeSession';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

/** @returns a callback to duplicate the custom field node with the given id. */
export const useCopyCustomField = () => {
  const { projectId } = useProjectContext();
  const [customFieldNodes, setCustomFieldNodes] = useCustomFieldNodes(projectId);
  const { setSelection } = useSelectionContext();
  const setNewNodes = useSetNewNodes();

  const duplicateRow = useCallback((id: string) => {
    const newCustomFieldNodes = customFieldNodes.clone();
    const oldCustomField = findCustomFieldNodeById(newCustomFieldNodes, id);
    if (oldCustomField) {
      const newCustomField = oldCustomField?.clone();
      newCustomField.id = newNodeId();
      newCustomField.name = copiedName(oldCustomField.name);
      newCustomFieldNodes.customFields.push(newCustomField);
      setCustomFieldNodes(newCustomFieldNodes);
      const nodeId = newCustomField.id;
      setSelection([nodeId]);
      setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
    }
  }, [customFieldNodes, setNewNodes, setCustomFieldNodes, setSelection]);

  return duplicateRow;
};
