// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CurrentView } from '../../../../../lib/componentTypes/context';
import { getComplexityLabel, isDefaultMeshMode, meshAggregateStats, meshParamsUrl } from '../../../../../lib/mesh';
import { getCopyName } from '../../../../../lib/name';
import { fromBigInt } from '../../../../../lib/number';
import { isSimulationSteady } from '../../../../../lib/simulationUtils';
import { isGeometryFile } from '../../../../../lib/upload/uploadUtils';
import * as frontendpb from '../../../../../proto/frontend/frontend_pb';
import { MeshFileMetadata } from '../../../../../proto/lcn/lcmesh_pb';
import { useMeshMetadata, useMeshUrlState } from '../../../../../recoil/meshState';
import { useNoCredits } from '../../../../../recoil/useAccountInfo';
import { useInputFilename } from '../../../../../recoil/useInputFilename';
import { useSetMeshName } from '../../../../../recoil/useMeshName';
import { MeshPanelType, useSetMeshPanelState } from '../../../../../recoil/useMeshPanelState';
import { useMeshValidator } from '../../../../../recoil/useMeshValidator';
import useMeshMultiPart from '../../../../../recoil/useMeshingMultiPart';
import { useSetPreviouslySelectedMesh } from '../../../../../recoil/usePreviouslyActiveMesh';
import { useProjectActiveMesh } from '../../../../../recoil/useProjectActiveMesh';
import { useProjectMeshList } from '../../../../../recoil/useProjectMeshList';
import { useSimulationParam } from '../../../../../state/external/project/simulation/param';
import { useIsStaff } from '../../../../../state/external/user/frontendRole';
import { useCurrentView, useIsSetupOrAdvancedView } from '../../../../../state/internal/global/currentView';
import { ActionButton } from '../../../../Button/ActionButton';
import { CollapsibleNodePanel } from '../../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../../Paraview/ParaviewManager';
import { createStyles, makeStyles } from '../../../../Theme';
import Divider from '../../../../Theme/Divider';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useSelectionContext } from '../../../../context/SelectionManager';
import { useIsAllTetActive, useIsLMAActive, useSetAppToMeshEditMode, useSetLMA } from '../../../../hooks/useMesh';
import { SectionMessage } from '../../../../notification/SectionMessage';
import { Flex } from '../../../../visual/Flex';
import PropertiesSection from '../../../PropertiesSection';
import { MeshOrSurfacePanel } from '../../shared/MeshOrSurfacePanel';
import { MeshAdaptationBoundaryParams } from '../AdaptationBoundary';
import { LumiMeshAdaptationSection, MeshAutomationScaling } from '../Automation';

import { OptionalParamsPanel } from './OptionalParamsPanel';

const useStyles = makeStyles(
  () => createStyles({
    meshingProps: {
      flex: '1 1 auto',
      overflow: 'auto',
    },
    meshingContainer: {
      flex: '0 0 auto',
      borderTop: '1px solid black',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
  }),
  { name: 'MeshPropPanel' },
);

// A panel for displaying the mesh statistics.
const MeshInfoPanel = () => {
  const { projectId, workflowId, jobId } = useProjectContext();

  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const currentView = useCurrentView();
  const isSetupOrAdvancedView = useIsSetupOrAdvancedView();
  const [meshUrl] = useMeshUrlState(projectId);
  const projectActiveMesh = useProjectActiveMesh(projectId);
  const meshMetadataUrlState = useMeshMetadata(projectId, meshUrl.mesh)?.meshMetadata;
  const { paraviewMeshMetadata } = useParaviewContext();
  const inputFileName = useInputFilename(projectId);
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const meshingEnabled = isGeometryFile(meshUrl.url);
  const complexity = meshMultiPart?.complexityParams;
  let meshingStrategy = meshingEnabled ? getComplexityLabel(complexity?.type) : '';
  if (meshingEnabled && projectActiveMesh?.meshOrigin === frontendpb.Mesh_MeshOrigin.ADAPTED_MESH) {
    meshingStrategy = 'Adaptive';
  }
  // Don't show the cell count if the complexity type is minimal
  const meshingStrategyCellCount = complexity?.type !== 2 ? Math.max(
    fromBigInt(complexity?.targetCells || BigInt(0)),
    fromBigInt(complexity?.limitMaxCells || BigInt(0)),
  ) : undefined;
  // While on the setup tab, the metadata can be grabbed from the URL state. However, from the
  // solution tab, the mesh metadata must be grabbed from the mesh URL attached to the solution
  // file.
  let meshMetadata: MeshFileMetadata | undefined;
  if (isSetupOrAdvancedView) {
    meshMetadata = meshMetadataUrlState;
  } else if (currentView === CurrentView.ANALYSIS) {
    meshMetadata = paraviewMeshMetadata?.meshMetadata;
  }
  const isStaff = useIsStaff();
  const classes = useStyles();

  const meshStats = meshAggregateStats(meshMetadata);

  // NOTE: We don't want to expose the mesh URL for non-staff members. For staff members, we
  // expose the mesh URL to ease bug reporting and debugging.
  return (
    <div className={classes.meshingProps}>
      <MeshOrSurfacePanel
        // When we are generating the meshes ourselves, only the Geometry panel will show the input
        // file name. If the mesh is input by the user, then we display here the file name of the
        // uploaded input mesh file.
        fileName={!meshingEnabled ? inputFileName.name : ''}
        maxBoundingBox={meshStats.boundingBoxes.mesh?.max}
        meshUrlOnlyStaff={isStaff ? simParam.input?.url : ''}
        minBoundingBox={meshStats.boundingBoxes.mesh?.min}
        numCvs={meshStats.counters.controlVolume}
        numFaces={meshStats.counters.face}
        numPoints={meshStats.counters.point}
        sizingStrategy={meshingStrategy}
        strategyCellCount={meshingStrategyCellCount}
      />
    </div>
  );
};

// The button for converting to LMA
function LumiMeshButton(disabled: boolean, disabledReason: string) {
  const { setLMA } = useSetLMA();
  const noCredits = useNoCredits();

  const convertToLMA = () => {
    setLMA(true);
  };

  return (
    <ActionButton
      asBlock
      disabled={disabled || noCredits}
      onClick={convertToLMA}
      size="small"
      title={noCredits ? 'Credits are required to perform this action.' : disabledReason}>
      Use in Lumi Mesh Adaptation
    </ActionButton>
  );
}

// A panel for displaying the mesh details once a mesh has been generated.
export const MeshDetailsPanel = () => {
  const { selectedNode } = useSelectionContext();
  const classes = useStyles();
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const [meshUrlState] = useMeshUrlState(projectId);
  const projectActiveMesh = useProjectActiveMesh(projectId)!;
  const setPreviousMesh = useSetPreviouslySelectedMesh(projectId);
  const setMeshPanelState = useSetMeshPanelState(projectId);
  const { setAppToEditMode } = useSetAppToMeshEditMode(projectId);
  const setMeshName = useSetMeshName(projectId);
  const { setLMA } = useSetLMA();
  const isLMA = useIsLMAActive();
  const isAllTet = useIsAllTetActive();
  const [meshList] = useProjectMeshList(projectId);

  const isGeometry = isGeometryFile(meshUrlState.url);

  // The logic to derive the warning now resides in the MeshValidator
  const warning = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const { disabledReason, disabledLevel } = warning;

  const handleEditButtonClick = () => {
    // Update the previouslySelectedMesh. This saves the current mesh id. If the user decides not to
    // edit a new mesh, when they click 'Cancel' we want the previously selected mesh to return.
    setPreviousMesh(projectActiveMesh?.id);
    // Get the new mesh name based on the number of times the mesh is copied (only if copied).
    const newMeshName = getCopyName(
      projectActiveMesh?.name,
      meshList.map((mesh) => mesh.name),
    );
    // Set the mesh name to be "Mesh (Copy)" so the user remembers the base mesh
    setMeshName(newMeshName);
    // Toggle app to edit mode, this will also clear the active mesh
    setAppToEditMode();
  };

  const handleCancelLMA = () => {
    setLMA(false);
    // If there is no mesh selected go back to the select panel, else we must be on the details
    // panel and can stay there.
    if (!projectActiveMesh) {
      setMeshPanelState(MeshPanelType.SELECT);
    }
  };

  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);

  const meshUrl = meshParamsUrl(simParam, true);

  // Cannot start LMA from final LMA meshes due to missing links to the meshb files and mesh
  // generation parameters. The latter also prevent using these meshes as starting point for manual
  // meshes.
  const fromLMA = projectActiveMesh?.meshOrigin === frontendpb.Mesh_MeshOrigin.ADAPTED_MESH;
  let lmaDisabledReason = '';

  if (fromLMA) {
    lmaDisabledReason = 'Final adapted meshes cannot be used in Lumi Mesh Adaptation';
  } else if (!isSimulationSteady(simParam)) {
    lmaDisabledReason = 'Lumi Mesh Adaptation is only available for steady simulations.';
  }

  const noManualFromLMA =
    fromLMA ? 'Adapted mesh parameters cannot be copied to a New Mesh' : 'Copy to New Mesh';

  const lumiMeshButton = LumiMeshButton(!!lmaDisabledReason, lmaDisabledReason);

  return (
    <>
      <div className={classes.meshingProps}>
        {isLMA && <LumiMeshAdaptationSection />}
        {!isLMA && isGeometry && <><MeshAutomationScaling /><Divider /></>}
        <PropertiesSection>
          <CollapsibleNodePanel
            heading="Info"
            nodeId={selectedNode!.id}
            panelName="info">
            <MeshInfoPanel />
          </CollapsibleNodePanel>
        </PropertiesSection>

        {(isDefaultMeshMode(meshMultiPart) && isGeometry) && (
          <>
            <Divider />
            <OptionalParamsPanel meshUrl={meshUrl} readOnly />
          </>
        )}

        {isLMA && !isAllTet && <MeshAdaptationBoundaryParams boundaryIndex={0} isInput={false} />}
      </div>
      <div className={classes.meshingContainer}>
        {disabledReason && isGeometry && (
          <SectionMessage level={disabledLevel}>
            {disabledReason}
          </SectionMessage>
        )}
        {isGeometry && !isLMA && !readOnly && (
          <Flex gap={12}>
            <ActionButton
              disabled={fromLMA}
              kind="secondary"
              onClick={handleEditButtonClick}
              size="small"
              title={noManualFromLMA}>
              New
            </ActionButton>
            {isGeometry && lumiMeshButton}
          </Flex>
        )}
        {isLMA && !readOnly && (
          <ActionButton
            disabled={readOnly}
            kind="cancel"
            onClick={handleCancelLMA}
            size="small">
            Exit Lumi Mesh Adaptation
          </ActionButton>
        )}
      </div>
    </>
  );
};
