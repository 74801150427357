// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { cadMetadataFixture } from '../lib/fixtures';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as cadmetadatapb from '../proto/cadmetadata/cadmetadata_pb';

import { getGeoState, onGeometryTabSelector } from './geometry/geometryState';

const cadMetadataKey = 'cadMetadata';

const DEFAULT_MODIFIER = new cadmetadatapb.CadMetadata();

function serialize(val: cadmetadatapb.CadMetadata): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): cadmetadatapb.CadMetadata {
  return (val.length ?
    cadmetadatapb.CadMetadata.fromBinary(val) :
    DEFAULT_MODIFIER);
}

// Selects the CAD metadata from the session state kv store.
const cadMetadataSelectorRpc = selectorFamily<cadmetadatapb.CadMetadata, string>({
  key: `${cadMetadataKey}/rpc`,
  get: (projectId: string) => ({ get }) => {
    if (get(onGeometryTabSelector)) {
      const geoState = getGeoState(get, projectId);
      return geoState ? geoState.cadMetadata : DEFAULT_MODIFIER.clone();
    }
    return persist.getProjectState(projectId, [cadMetadataKey], deserialize);
  },
  dangerouslyAllowMutability: true,
});

export const cadMetadataSelectorSetupTabRpc = selectorFamily<cadmetadatapb.CadMetadata, string>({
  key: `${cadMetadataKey}SetupTab/rpc`,
  get: (projectId: string) => () => persist.getProjectState(
    projectId,
    [cadMetadataKey],
    deserialize,
  ),
  dangerouslyAllowMutability: true,
});

const cadMetadataSelectorTesting = selectorFamily<cadmetadatapb.CadMetadata, string>({
  key: `${cadMetadataKey}/testing`,
  get: () => cadMetadataFixture,
  dangerouslyAllowMutability: true,
});

export const cadMetadataSelector = isTestingEnv() ?
  cadMetadataSelectorTesting : cadMetadataSelectorRpc;

export const cadMetadataState = atomFamily<cadmetadatapb.CadMetadata, string>({
  key: cadMetadataKey,
  default: cadMetadataSelector,
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, cadMetadataKey, deserialize, serialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

const cadMetadataSetupTabSelector = isTestingEnv() ?
  cadMetadataSelectorTesting : cadMetadataSelectorSetupTabRpc;

export const cadMetadataSetupTabState = atomFamily<cadmetadatapb.CadMetadata, string>({
  key: `${cadMetadataKey}SetupTab`,
  default: cadMetadataSetupTabSelector,
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, cadMetadataKey, deserialize, serialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const useCadMetadata = (projectId: string) => (
  useRecoilState(cadMetadataState(projectId))
);

export const useSetCadMetadata = (projectId: string) => (
  useSetRecoilState(cadMetadataState(projectId))
);
