// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as codespb from '../proto/lcstatus/codes_pb';
import * as geometrypb from '../proto/lcstatus/details/geometry/geometry_pb';
import * as lcstatuspb from '../proto/lcstatus/lcstatus_pb';

import { EntityGroupMap } from './entityGroupMap';
import { formatVector } from './motionDataFormat';
import { formatNumber } from './number';
import { unpackProto } from './protoUtils';
import { truncatedWordList, wordsToList } from './text';
import { volumeNodeId } from './volumeUtils';

// Provides a list of supported error codes. Errors not in this list will be filtered out.
// This also provides the desired order of the error codes. The exact order here is not so
// important, but it is important to group the errors and be consistent.
export const SUPPORTED_ERROR_CODES = [
  // Errors
  codespb.Code.GEO_VERTEX_DUPLICATE,
  codespb.Code.GEO_FACE_FACE_INTERSECTION,
  codespb.Code.GEO_FACE_SELF_INTERSECTION,
  codespb.Code.GEO_VOLUME_OPEN,
  codespb.Code.GEO_VOLUME_NON_MANIFOLD,
  codespb.Code.GEO_VOLUME_UNMESHABLE,
  codespb.Code.GEO_EDGE_UNMESHABLE,
  codespb.Code.GEO_FACE_UNMESHABLE,

  // Warnings
  codespb.Code.GEO_FACE_EDGES_TOO_CLOSE,
  codespb.Code.GEO_EDGE_NOT_SMOOTH,
  codespb.Code.GEO_EDGE_LARGE_TOLERANCE,
  codespb.Code.GEO_FACE_NOT_SMOOTH,
  codespb.Code.GEO_FACE_LARGE_TOLERANCE,
  codespb.Code.GEO_FACE_EDGE_TOO_SMALL,
  codespb.Code.GEO_FACE_EDGE_CROSS,
  codespb.Code.GEO_FACE_POOR_APPROX,
];

// Get the title of an issue.
export function getTitle(issue: lcstatuspb.LCStatus): string {
  switch (issue.code) {
    case codespb.Code.GEO_VERTEX_DUPLICATE:
      return 'Duplicate vertices';
    case codespb.Code.GEO_EDGE_UNMESHABLE:
      return 'Unmeshable edges';
    case codespb.Code.GEO_FACE_UNMESHABLE:
      return 'Unmeshable surfaces';
    case codespb.Code.GEO_FACE_EDGES_TOO_CLOSE:
      return 'Sliver surfaces';
    case codespb.Code.GEO_FACE_EDGE_TOO_SMALL:
      return 'Tiny edges';
    case codespb.Code.GEO_FACE_FACE_INTERSECTION:
      return 'Surface-surface intersections';
    case codespb.Code.GEO_FACE_SELF_INTERSECTION:
      return 'Surface self-intersections';
    case codespb.Code.GEO_VOLUME_OPEN:
      return 'Open volumes';
    case codespb.Code.GEO_VOLUME_NON_MANIFOLD:
      return 'Non-manifold volumes';
    case codespb.Code.GEO_VOLUME_UNMESHABLE:
      return 'Unmeshable volumes';
    case codespb.Code.GEO_EDGE_NOT_SMOOTH:
      return 'Edges not smooth';
    case codespb.Code.GEO_EDGE_LARGE_TOLERANCE:
      return 'Edges with large tolerance';
    case codespb.Code.GEO_FACE_NOT_SMOOTH:
      return 'Surfaces not smooth';
    case codespb.Code.GEO_FACE_LARGE_TOLERANCE:
      return 'Surfaces with large tolerance';
    case codespb.Code.GEO_FACE_EDGE_CROSS:
      return 'Surfaces with crossing edges';
    case codespb.Code.GEO_FACE_POOR_APPROX:
      return 'Surfaces with poor approximation';
    default:
      return 'Unknown';
  }
}

// Returns the surface name, given the ID and entityGroupMap. This also guards against a crash when
// the ID is not in the entityGroupMap. This can happen temporarily if entityGroupMap is updated
// while the geometryHealth card is being removed.
function getSurfaceName(id: string, entityGroupMap: EntityGroupMap) {
  return entityGroupMap.has(id) ? entityGroupMap.get(id).name : '?';
}

function getEdgeSurfaceNames(
  edge: geometrypb.Edge,
  entityGroupMap: EntityGroupMap,
  maxSize?: number,
) {
  const items = edge.adjacentSurfaces.map((id) => getSurfaceName(id, entityGroupMap));

  if (maxSize !== undefined) {
    return truncatedWordList(items, maxSize);
  }

  return wordsToList(items);
}

export function getIssueSubject(
  issue: lcstatuspb.LCStatus,
  entityGroupMap: EntityGroupMap,
): string {
  switch (issue.code) {
    case codespb.Code.GEO_VERTEX_DUPLICATE: {
      const details = unpackProto(issue.details, geometrypb.GeoVertexDuplicateDetails);
      const coords1 = details?.vertex1?.coords;
      const coords2 = details?.vertex2?.coords;
      formatVector(coords1, undefined);

      return (
        `Vertex ${formatVector(coords1, undefined)} and Vertex ${formatVector(coords2, undefined)}`
      );
    }
    case codespb.Code.GEO_EDGE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeUnmeshableDetails);
      const surfaceIds = details?.edge?.adjacentSurfaces;
      const names = surfaceIds?.map((surfaceId) => getSurfaceName(surfaceId, entityGroupMap)) || [];

      return truncatedWordList(names, 2);
    }
    case codespb.Code.GEO_FACE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceUnmeshableDetails);
      const surfaceId = details?.surfaceId || '';
      return getSurfaceName(surfaceId, entityGroupMap);
    }
    case codespb.Code.GEO_FACE_EDGES_TOO_CLOSE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgesTooCloseDetails);
      const surfaceId = details?.surfaceId || '';

      return getSurfaceName(surfaceId, entityGroupMap);
    }
    case codespb.Code.GEO_FACE_EDGE_TOO_SMALL: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeTooSmallDetails);
      const surfaceId = details?.surfaceId || '';

      return getSurfaceName(surfaceId, entityGroupMap);
    }
    case codespb.Code.GEO_FACE_FACE_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceFaceIntersectionDetails);
      const name1 = getSurfaceName(details!.surface1Id, entityGroupMap);
      const name2 = getSurfaceName(details!.surface2Id, entityGroupMap);

      return `${name1}, ${name2}`;
    }
    case codespb.Code.GEO_FACE_SELF_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceSelfIntersectionDetails);
      return getSurfaceName(details!.surfaceId, entityGroupMap);
    }
    case codespb.Code.GEO_FACE_POOR_APPROX: {
      const details = unpackProto(issue.details, geometrypb.GeoFacePoorApproxDetails);
      const surfaceId = details?.surfaceId || '';

      return getSurfaceName(surfaceId, entityGroupMap);
    }
    case codespb.Code.GEO_VOLUME_OPEN: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeOpenDetails);

      return entityGroupMap.get(volumeNodeId(details!.volumesId)).name;
    }
    case codespb.Code.GEO_VOLUME_NON_MANIFOLD: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeNonManifoldDetails);

      return entityGroupMap.get(volumeNodeId(details!.volumeId)).name;
    }
    case codespb.Code.GEO_VOLUME_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeUnmeshableDetails);

      return entityGroupMap.get(volumeNodeId(details!.volumeId)).name;
    }
    case codespb.Code.GEO_EDGE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeNotSmoothDetails);

      return getEdgeSurfaceNames(details!.edge!, entityGroupMap, 2);
    }
    case codespb.Code.GEO_EDGE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeLargeToleranceDetails);

      return getEdgeSurfaceNames(details!.edge!, entityGroupMap, 2);
    }
    case codespb.Code.GEO_FACE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceNotSmoothDetails);

      return getSurfaceName(details!.surfaceId, entityGroupMap);
    }
    case codespb.Code.GEO_FACE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceLargeToleranceDetails);

      return getSurfaceName(details!.surfaceId, entityGroupMap);
    }
    case codespb.Code.GEO_FACE_EDGE_CROSS: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeCrossDetails);

      return getSurfaceName(details!.surfaceId, entityGroupMap);
    }
    default:
      return 'Unknown';
  }
}

export function getIssueDetails(
  issue: lcstatuspb.LCStatus,
  entityGroupMap: EntityGroupMap,
): { name: string; value: string; }[] {
  switch (issue.code) {
    case codespb.Code.GEO_VERTEX_DUPLICATE: {
      const details = unpackProto(issue.details, geometrypb.GeoVertexDuplicateDetails);
      const coords1 = details?.vertex1?.coords;
      const coords2 = details?.vertex2?.coords;

      return [
        { name: 'Vertex 1', value: formatVector(coords1) },
        { name: 'Vertex 2', value: formatVector(coords2) },
      ];
    }
    case codespb.Code.GEO_EDGE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeUnmeshableDetails);
      const surfaceIds = details?.edge?.adjacentSurfaces;
      const names = surfaceIds?.map((surfaceId) => getSurfaceName(surfaceId, entityGroupMap)) || [];

      return [{ name: 'Surfaces', value: names.join(', ') }];
    }
    case codespb.Code.GEO_FACE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceUnmeshableDetails);
      const surfaceId = details?.surfaceId || '';
      const name = getSurfaceName(surfaceId, entityGroupMap);

      return [{ name: 'Surface', value: name }];
    }
    case codespb.Code.GEO_FACE_EDGES_TOO_CLOSE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgesTooCloseDetails);
      const surfaceId = details?.surfaceId || '';
      const name = getSurfaceName(surfaceId, entityGroupMap);

      const result = [{ name: 'Surface', value: name }];

      if (details && details.aspectRatio > 0) {
        result.push({ name: 'Aspect Ratio', value: formatNumber(details.aspectRatio) });
      }

      return result;
    }
    case codespb.Code.GEO_FACE_EDGE_TOO_SMALL: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeTooSmallDetails);
      const surfaceId = details?.surfaceId || '';
      const name = getSurfaceName(surfaceId, entityGroupMap);

      return [{ name: 'Surface', value: name }];
    }
    case codespb.Code.GEO_FACE_FACE_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceFaceIntersectionDetails);
      const name1 = getSurfaceName(details!.surface1Id, entityGroupMap);
      const name2 = getSurfaceName(details!.surface2Id, entityGroupMap);

      return [
        { name: 'Surface 1', value: name1 },
        { name: 'Surface 2', value: name2 },
      ];
    }
    case codespb.Code.GEO_FACE_SELF_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceSelfIntersectionDetails);
      const name = getSurfaceName(details!.surfaceId, entityGroupMap);

      return [{ name: 'Surface', value: name }];
    }
    case codespb.Code.GEO_FACE_POOR_APPROX: {
      const details = unpackProto(issue.details, geometrypb.GeoFacePoorApproxDetails);
      const surfaceId = details?.surfaceId || '';
      const name = getSurfaceName(surfaceId, entityGroupMap);

      const result = [{ name: 'Surface', value: name }];

      if (details && details.approx >= -1 && details.approx <= 1) {
        result.push({ name: 'Approximation', value: formatNumber(details.approx) });
      }

      return result;
    }
    case codespb.Code.GEO_VOLUME_OPEN: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeOpenDetails);
      const name = entityGroupMap.get(volumeNodeId(details!.volumesId)).name;

      return [{ name: 'Volume', value: name }];
    }
    case codespb.Code.GEO_VOLUME_NON_MANIFOLD: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeNonManifoldDetails);
      const name = entityGroupMap.get(volumeNodeId(details!.volumeId)).name;

      return [{ name: 'Volume', value: name }];
    }
    case codespb.Code.GEO_VOLUME_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeUnmeshableDetails);
      const name = entityGroupMap.get(volumeNodeId(details!.volumeId)).name;

      return [{ name: 'Volume', value: name }];
    }
    case codespb.Code.GEO_EDGE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeNotSmoothDetails);
      const surfaces = getEdgeSurfaceNames(details!.edge!, entityGroupMap);

      return [{ name: 'Surfaces', value: surfaces }];
    }
    case codespb.Code.GEO_EDGE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeLargeToleranceDetails);
      const tolerance = formatNumber(details?.tolerance ?? 0);
      const surfaces = getEdgeSurfaceNames(details!.edge!, entityGroupMap);

      return [
        { name: 'Tolerance', value: tolerance },
        { name: 'Surfaces', value: surfaces },
      ];
    }
    case codespb.Code.GEO_FACE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceNotSmoothDetails);
      const name = getSurfaceName(details!.surfaceId, entityGroupMap);

      return [{ name: 'Surface', value: name }];
    }
    case codespb.Code.GEO_FACE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceLargeToleranceDetails);
      const tolerance = formatNumber(details?.tolerance ?? 0);
      const name = getSurfaceName(details!.surfaceId, entityGroupMap);

      return [
        { name: 'Tolerance', value: tolerance },
        { name: 'Surface', value: name },
      ];
    }
    case codespb.Code.GEO_FACE_EDGE_CROSS: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeCrossDetails);
      const name = getSurfaceName(details!.surfaceId, entityGroupMap);

      return [{ name: 'Surface', value: name }];
    }
    default:
      return [];
  }
}

// Get the description of an issue.
export function getDescription(issue: lcstatuspb.LCStatus): string {
  switch (issue.code) {
    case codespb.Code.GEO_VERTEX_DUPLICATE: {
      return (
        'The space between the specified vertices is too small.' +
        ' Please merge them in CAD.'
      );
    }
    case codespb.Code.GEO_EDGE_UNMESHABLE: {
      return (
        'The underlying geometry of the specified edges could not be robustly interpreted.' +
        ' Please replace their definition in CAD.'
      );
    }
    case codespb.Code.GEO_FACE_UNMESHABLE: {
      return (
        'The underlying geometry of the specified faces could not be robustly interpreted.' +
        ' Please replace their definition in CAD.'
      );
    }
    case codespb.Code.GEO_FACE_EDGES_TOO_CLOSE: {
      return (
        'The specified surfaces are narrow.' +
        ' If tiny cells are generated or the boundary layer mesh collapses,' +
        ' please merge these sliver surfaces in CAD.'
      );
    }
    case codespb.Code.GEO_FACE_EDGE_TOO_SMALL: {
      return (
        'The specified surfaces have a tiny edge.' +
        ' If tiny cells are generated or the boundary layer mesh collapses,' +
        ' please merge these edges in CAD.'
      );
    }
    case codespb.Code.GEO_FACE_FACE_INTERSECTION: {
      return (
        'The specified surfaces intersect each other to form a non-manifold volume.' +
        ' Please replace their definition in CAD to avoid intersection.'
      );
    }
    case codespb.Code.GEO_FACE_SELF_INTERSECTION: {
      return (
        'The specified surfaces self-intersect to form a non-manifold volume.' +
        ' Please replace their definition in CAD to avoid self-intersection.'
      );
    }
    case codespb.Code.GEO_FACE_POOR_APPROX: {
      return (
        'The specified surfaces could not be robustly approximated.' +
        ' If the mesh quality is poor, please replace their definition in CAD.'
      );
    }
    case codespb.Code.GEO_VOLUME_OPEN: {
      return (
        'The specified volumes are not watertight.' +
        ' Please properly close them in CAD.'
      );
    }
    case codespb.Code.GEO_VOLUME_NON_MANIFOLD: {
      return (
        'The specified volumes are non-manifold.' +
        ' Please remove any interior surfaces in CAD.'
      );
    }
    case codespb.Code.GEO_VOLUME_UNMESHABLE: {
      return (
        'An unknown error was encountered for the specified volumes.' +
        ' Please reach out to Support.'
      );
    }
    case codespb.Code.GEO_EDGE_NOT_SMOOTH: {
      return (
        'A discontinuity was detected in the specified edges.' +
        ' If the mesh is too fine, please replace their definition in CAD.'
      );
    }
    case codespb.Code.GEO_EDGE_LARGE_TOLERANCE: {
      return (
        'A large geometric tolerance was detected in the specified edges.' +
        ' If the mesh quality is poor, please replace their definition in CAD.'
      );
    }
    case codespb.Code.GEO_FACE_NOT_SMOOTH: {
      return (
        'A discontinuity was detected in the specified surfaces,' +
        ' If the mesh is too fine, please replace their definition in CAD.'
      );
    }
    case codespb.Code.GEO_FACE_LARGE_TOLERANCE: {
      return (
        'A large geometric tolerance was detected in the specified surfaces,' +
        ' If the mesh quality is poor, please replace their definition in CAD.'
      );
    }
    case codespb.Code.GEO_FACE_EDGE_CROSS: {
      return (
        'The specified surfaces have bounding edges crossing each other.' +
        ' If the mesh quality is poor, please replace their definition in CAD.'
      );
    }
    default:
      return 'Unknown Issue';
  }
}

export function verticesToSurfacesIds(vertices: (geometrypb.Vertex | undefined)[]): string[] {
  return vertices.map((vertex) => vertex?.adjacentSurfaces ?? []).flat();
}

// Get a list of surface IDs from the status proto.
export function getNodeIds(issue: lcstatuspb.LCStatus): string[] {
  switch (issue.code) {
    case codespb.Code.GEO_VERTEX_DUPLICATE: {
      const details = unpackProto(issue.details, geometrypb.GeoVertexDuplicateDetails);
      return verticesToSurfacesIds([details?.vertex1, details?.vertex2]);
    }
    case codespb.Code.GEO_EDGE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeUnmeshableDetails);
      const surfaceIds = details?.edge?.adjacentSurfaces || [];
      return surfaceIds;
    }
    case codespb.Code.GEO_FACE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceUnmeshableDetails);
      const surfaceId = details?.surfaceId || '';
      return [surfaceId];
    }
    case codespb.Code.GEO_FACE_EDGES_TOO_CLOSE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgesTooCloseDetails);
      const surfaceId = details?.surfaceId || '';
      return [surfaceId];
    }
    case codespb.Code.GEO_FACE_EDGE_TOO_SMALL: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeTooSmallDetails);
      const surfaceId = details?.surfaceId || '';
      return [surfaceId];
    }
    case codespb.Code.GEO_FACE_FACE_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceFaceIntersectionDetails);
      return [details!.surface1Id, details!.surface2Id];
    }
    case codespb.Code.GEO_FACE_SELF_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceSelfIntersectionDetails);
      return [details!.surfaceId];
    }
    case codespb.Code.GEO_FACE_POOR_APPROX: {
      const details = unpackProto(issue.details, geometrypb.GeoFacePoorApproxDetails);
      const surfaceId = details?.surfaceId || '';
      return [surfaceId];
    }
    case codespb.Code.GEO_VOLUME_OPEN: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeOpenDetails);
      return [volumeNodeId(details!.volumesId)];
    }
    case codespb.Code.GEO_VOLUME_NON_MANIFOLD: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeNonManifoldDetails);
      return [volumeNodeId(details!.volumeId)];
    }
    case codespb.Code.GEO_VOLUME_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeUnmeshableDetails);
      return [volumeNodeId(details!.volumeId)];
    }
    case codespb.Code.GEO_EDGE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeNotSmoothDetails);
      return details!.edge!.adjacentSurfaces;
    }
    case codespb.Code.GEO_EDGE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeLargeToleranceDetails);
      return details!.edge!.adjacentSurfaces;
    }
    case codespb.Code.GEO_FACE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceNotSmoothDetails);
      return [details!.surfaceId];
    }
    case codespb.Code.GEO_FACE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceLargeToleranceDetails);
      return [details!.surfaceId];
    }
    case codespb.Code.GEO_FACE_EDGE_CROSS: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeCrossDetails);
      return [details!.surfaceId];
    }
    default:
      return [];
  }
}

const GEOM_HEALTH_ID_PREFIX = 'geom-health-card-';

export function geomHealthIndexToNodeId(cardIndex: number) {
  return `${GEOM_HEALTH_ID_PREFIX}${cardIndex}`;
}

export function geomHealthNodeIdtoIndex(id: string) {
  const regexPattern = new RegExp(`^${GEOM_HEALTH_ID_PREFIX}(\\d+)+$`);
  if (regexPattern.test(id)) {
    return parseInt(RegExp.$1, 10);
  }
  return -1;
}

export function isGeomHealthId(id: string) {
  return id.startsWith(GEOM_HEALTH_ID_PREFIX);
}

export const GEOM_HEALTH_SUBTITLES = {
  errors: `The following errors were identified in your CAD file. Please fix the errors and
      re-upload the file to proceed.`,
  warnings: `The following warnings were found in your CAD file, which may introduce undesirably
      small or poor quality cells in your computational mesh, potentially affecting the solver's
      robustness and accuracy. Please check the quality of your mesh before running a simulation.`,
  // This happens when there are no errors to report, but check geometry failed. Ideally, we would
  // report all errors, but currently this can happen.
  notOk: `Errors were found in your CAD file. Please fix the errors and re-upload the file
      to proceed.`,
  ok: 'Geometry is clean.',

  // iGeo project
  igeoErrors: `The following errors were identified in your geometry. Please fix the errors in the
    Geometry tab to proceed.`,
  igeoWarnings: `The following warnings were found in your geometry, which may introduce undesirably
      small or poor quality cells in your computational mesh, potentially affecting the solver's
      robustness and accuracy. Please check the quality of your mesh before running a simulation.`,
  igeoNotOk: `Errors were found in your geometry. Please fix the errors in the Geometry tab to
    proceed.`,
} as const;

/**
 * Gets the subtitle for the geometry health card based on whether there are warnings, errors, or
 * if the geometry is ok. If the project is an iGeo project, the subtitle will be different.
 */
export const getGeomHealthSubtitle = (
  hasWarnings: boolean,
  hasErrors: boolean,
  ok: boolean,
  isIgeoProject: boolean,
) => {
  let subtitle = '';

  if (isIgeoProject) {
    if (hasErrors) {
      subtitle = GEOM_HEALTH_SUBTITLES.igeoErrors;
    } else if (!ok) {
      subtitle = GEOM_HEALTH_SUBTITLES.igeoNotOk;
    } else if (hasWarnings) {
      subtitle = GEOM_HEALTH_SUBTITLES.igeoWarnings;
    } else {
      subtitle = GEOM_HEALTH_SUBTITLES.ok;
    }
    return subtitle;
  }

  if (hasErrors) {
    subtitle = GEOM_HEALTH_SUBTITLES.errors;
  } else if (!ok) {
    // This happens when there are no errors to report, but check geometry failed. Ideally, we would
    // report all errors, but currently this can happen.
    subtitle = GEOM_HEALTH_SUBTITLES.notOk;
  } else if (hasWarnings) {
    subtitle = GEOM_HEALTH_SUBTITLES.warnings;
  } else {
    subtitle = GEOM_HEALTH_SUBTITLES.ok;
  }
  return subtitle;
};
