// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { LCVError } from '@luminarycloudinternal/lcvis';

import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { lcVisEnabledSelector } from '../../recoil/lcvis/lcvisEnabledState';
import {
  Vector,
  addVectors,
  applyMatrixToVector,
  computeViewMatrix,
  crossProduct,
  magnitude,
  scalarMultiplyVector,
  subtractVectors,
  unitVector,
} from '../vectorAlgebra';

import { lcvHandler } from './handler/LcvHandler';
import { LcVisVisibilityMap, LcvisCameraStateType } from './types';

import environmentState from '@/state/environment';

/** Given 2 maps, return only the keys that have changed or have been added to the new map. */
export const shallowVisMapDiff = (oldMap: LcVisVisibilityMap, newMap: LcVisVisibilityMap) => {
  const [toShow, toHide]: string[][] = [[], []];
  [...newMap.keys()].forEach((key) => {
    // check if the key was added to the new map, or if the old value and new value don't match.
    if (!(oldMap.has(key)) || oldMap.get(key) !== newMap.get(key)) {
      const mapToExtend = newMap.get(key) === true ? toShow : toHide;
      mapToExtend.push(key);
    }
  });
  return { toShow, toHide };
};

/* Converting proto values to an array of numbers can result in unexpected behavior for unset params
* For example, the resulting array might contain null or undefined values that cause errors in LCVis
* To better handle this, default the values to zero - this matches the users expectation since
* the UI shows 0 in cases where the parameter is unset.
*/
export const safeLcvVec3 = (values: (number | null | undefined)[]) => {
  const result: [number, number, number] = [0.0, 0.0, 0.0];
  values.forEach((value, index) => {
    result[index] = value !== null && value !== undefined ? value : 0.0;
  });
  return result;
};

export function toVector3(vec: { x: number, y: number, z: number }): Vector<3> {
  return [vec.x, vec.y, vec.z] as Vector<3>;
}

/*
* Translate the paraview camera state into the equivelent LCVis camera state
*/
export function paraviewCameraToLcvis(
  cameraJson: ParaviewRpc.CameraState,
  aspectRatio: number,
): LcvisCameraStateType {
  const center = toVector3(cameraJson.center);
  // center of the window in world space
  const focal = toVector3(cameraJson.focal);
  const up = unitVector(toVector3(cameraJson.up));
  let pos = toVector3(cameraJson.position);
  const look = subtractVectors(focal, pos);
  const uLook = unitVector(look);

  // Panning is implicit in paraview, meaning that the focal and camera
  // position already have pan applied. Saying that another way, when the
  // focal and center are not the same, pan exists. In lcvis, we
  // explicitly set the pan, so we need to compute the panning.
  const viewMat = computeViewMatrix(pos, focal, up);
  const panVec = subtractVectors(focal, center);
  const panVec4 = [panVec[0], panVec[1], panVec[2], 0] as Vector<4>;
  const projectedPan = applyMatrixToVector(viewMat, panVec4);
  // Reset the z and w components. Pan in view space should only be
  // on the xy plane.
  projectedPan[2] = 0;
  projectedPan[3] = 0;
  // Invert in view space
  projectedPan[0] = -projectedPan[0];
  projectedPan[1] = -projectedPan[1];

  const isOrtho = cameraJson.parallelProjection;

  if (isOrtho) {
    // LCVis uses view distance to keep "parallelScale" in sync with the
    // perspective camera. To match the parallel scale of paraview, we
    // need to make sure that the resulting ortho transform is the same.
    // This equation describes the relationship that solves for view
    // distance.
    const fovRad = (cameraJson.angle * Math.PI) / 180;
    const viewDistance = (cameraJson.scale * aspectRatio) / Math.tan(fovRad * 0.5);
    pos = subtractVectors(focal, scalarMultiplyVector(uLook, viewDistance));
  }

  // Undo the panning applied to the camera position in world space.
  const lcvisCam: LcvisCameraStateType = {
    position: pos,
    target: center,
    up,
    look: uLook, // the is read-only and set by lcvis
    pan: [projectedPan[0], projectedPan[1], 0],
    fov: cameraJson.angle,
    // TODO: these constants will be published by lcvis if a future release
    // to help keepn them in sync.
    near_clip: 0.001,
    far_clip: 10000,
    center_of_rotation_modifier: 0,
    zoom_to_box_modifier: 0,
    orthographic: !!isOrtho,
    editSource: 'UI',
  };

  return lcvisCam;
}

export function lcvisCameraToParaview(
  lcvisCam: LcvisCameraStateType,
  aspectRatio: number,
): ParaviewRpc.CameraState {
  // lcvis explicitly specifies the pan in camera space, but paraview expects
  // the pan is applied in world coordinates along with the window center (also
  // in world space). Thus we need to apply the pan to calculate three values:
  // 1. Cam position with pan applied.
  // 2. Center of rotation with pan applied.
  // 3. Center of the screen.
  const target = lcvisCam.target as Vector<3>;
  const pos = lcvisCam.position as Vector<3>;
  const up = lcvisCam.up as Vector<3>;
  const pan = lcvisCam.pan as Vector<3>;
  // This is a unit vector.
  const uLook = lcvisCam.look as Vector<3>;

  const right = unitVector(crossProduct(uLook, up));
  const uUp = unitVector(crossProduct(right, uLook));

  // get the pan directions in world space.
  const worldPanX = scalarMultiplyVector(right, pan[0]);
  const worldPanY = scalarMultiplyVector(uUp, pan[1]);
  const worldPan = addVectors(worldPanX, worldPanY);

  const pvPos = pos;
  const pvCenter = target;
  const pvFocal = subtractVectors(target, worldPan);
  const viewDistance = magnitude(subtractVectors(pvPos, pvFocal));

  // Always do both perspective and orthographic settings. This
  // has the added benifit that when users in paraview switch between
  // the ortho and perspective cameras, they will at least be in sync
  // way better until the camera moves.

  // Orthographic settings
  // We keep the view distance in sync for both ortho and perspective
  // cameras. This solves for parallel scale.
  const fovRad = (lcvisCam.fov * Math.PI) / 180;
  let parallelScale = 1;
  if (aspectRatio !== 0) {
    parallelScale = viewDistance * Math.tan(fovRad * 0.5) / aspectRatio;
  }
  const pvCam: ParaviewRpc.CameraState = {
    focal: {
      x: pvFocal[0],
      y: pvFocal[1],
      z: pvFocal[2],
    },
    position: {
      x: pvPos[0],
      y: pvPos[1],
      z: pvPos[2],
    },
    center: {
      x: pvCenter[0],
      y: pvCenter[1],
      z: pvCenter[2],
    },
    up: {
      x: up[0],
      y: up[1],
      z: up[2],
    },
    angle: lcvisCam.fov,
    parallelProjection: lcvisCam.orthographic,
    scale: parallelScale,
  };

  return pvCam;
}

// Returns the aspect ratio of the webgl canvas.
export async function getAspectRatio(projectId: string) {
  const lcvisEnabled = await lcVisEnabledSelector(projectId);
  const lcvisReady = environmentState.lcvisReady;
  let aspectRatio = 0;
  if (lcvisEnabled && lcvisReady) {
    const display = await lcvHandler.getDisplay();
    if (display) {
      const canvasId = display?.canvasId;
      const canvas: HTMLCanvasElement | null = (
        document.getElementById(canvasId) as HTMLCanvasElement
      );
      if (canvas) {
        aspectRatio = canvas.width / canvas.height;
      }
    } else {
      return 0;
    }
  }
  return aspectRatio;
}

/**
 * Given an error that may be a standard error or an LCVError, returns whether the error is
 * an LCVError.
 */
export const isLCVError = (
  error: Error | LCVError,
): error is LCVError => (error as Error).message === undefined;
