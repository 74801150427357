// Generated by quantities.py. DO NOT EDIT
import * as ProtoDescriptor from './ProtoDescriptor';
import * as QuantityOptionsPb from './proto/quantity/quantity_options_pb';
import * as QuantityPb from './proto/quantity/quantity_pb';

/* eslint-disable max-len */
export const mass: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Mass',
  name: 'mass',
  help: '',
  unit: 'kg',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.MASS,
};
export const length: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Length',
  name: 'length',
  help: '',
  unit: 'm',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Mesh',
  quantityType: QuantityPb.QuantityType.LENGTH,
};
export const area: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Area',
  name: 'area',
  help: '',
  unit: 'm²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Mesh',
  quantityType: QuantityPb.QuantityType.AREA,
};
export const volume: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Volume',
  name: 'volume',
  help: '',
  unit: 'm³',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Mesh',
  quantityType: QuantityPb.QuantityType.VOLUME,
};
export const time: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Time',
  name: 'time',
  help: '',
  unit: 's',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.TIME,
};
export const energy: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Energy',
  name: 'energy',
  help: '',
  unit: 'J',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Temperature',
  quantityType: QuantityPb.QuantityType.ENERGY,
};
export const radians: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Radians',
  name: 'radians',
  help: '',
  unit: 'rad',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.RADIANS,
};
export const degree: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Degree',
  name: 'degree',
  help: '',
  unit: '°',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.DEGREE,
};
export const power: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Power',
  name: 'power',
  help: '',
  unit: 'W',
  tags: [],
  children: [],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.POWER,
};
export const powerPerUnitVolume: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Power Per Unit Volume',
  name: 'power_per_unit_volume',
  help: '',
  unit: 'W/m³',
  tags: [],
  children: [],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.POWER_PER_UNIT_VOLUME,
};
export const thermalExpansionCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Thermal Expansion Coefficient',
  name: 'thermal_expansion_coefficient',
  help: '',
  unit: '1/K',
  tags: [],
  children: [],
  parents: [],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.THERMAL_EXPANSION_COEFFICIENT,
};
export const temperature: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Temperature',
  name: 'temperature',
  help: '',
  unit: 'K',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_POINT_PROBE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    29867,
    28,
    29,
    16866,
    45988,
  ],
  parents: [],
  category: 'Temperature',
  cond: {
    list: [
      {
        choice: 37484,
        param: 'density_relationship',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        choice: 41777,
        param: 'density_relationship',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ANY,
  },
  quantityType: QuantityPb.QuantityType.TEMPERATURE,
};
export const density: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Density',
  name: 'density',
  help: '',
  unit: 'kg/m³',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_POINT_PROBE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    17651,
  ],
  parents: [],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.DENSITY,
};
export const pressure: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure',
  name: 'pressure',
  help: '',
  unit: 'Pa',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_POINT_PROBE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    53930,
    28,
    21230,
    55273,
  ],
  parents: [],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.PRESSURE,
};
export const velocity: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Velocity',
  name: 'velocity',
  help: '',
  unit: 'm/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_POINT_PROBE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    65107,
    28,
    29,
    65042,
  ],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.VELOCITY,
};
export const velocityMagnitude: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Velocity Magnitude',
  name: 'velocity_magnitude',
  help: '',
  unit: 'm/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_POINT_PROBE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    30360,
  ],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.VELOCITY_MAGNITUDE,
};
export const eddyViscosity: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Eddy Viscosity',
  name: 'eddy_viscosity',
  help: '',
  unit: 'N·s/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    390,
  ],
  parents: [],
  category: 'Turbulence',
  cond: {
    list: [
      {
        choice: 35474,
        param: 'viscous_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        choice: 55751,
        param: 'viscous_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ANY,
  },
  quantityType: QuantityPb.QuantityType.EDDY_VISCOSITY,
};
export const viscosity: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Viscosity',
  name: 'viscosity',
  help: '',
  unit: 'N·s/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    56241,
  ],
  parents: [],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.VISCOSITY,
};
export const mach: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Mach',
  name: 'mach',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_POINT_PROBE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    47480,
  ],
  parents: [],
  category: 'Velocity',
  cond: {
    choice: 37484,
    param: 'density_relationship',
    type: ProtoDescriptor.CondType.CHOICE,
  },
  quantityType: QuantityPb.QuantityType.MACH,
};
export const saVariable: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Spalart-Allmaras Variable',
  name: 'sa_variable',
  help: '',
  unit: 'N·s/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    11897,
    18415,
  ],
  parents: [],
  category: 'Turbulence',
  cond: {
    list: [
      {
        choice: 35474,
        param: 'viscous_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        choice: 55751,
        param: 'viscous_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ANY,
  },
  quantityType: QuantityPb.QuantityType.SA_VARIABLE,
};
export const tke: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Turbulent Kinetic Energy',
  name: 'tke',
  help: '',
  unit: 'm²/s²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    26333,
  ],
  parents: [],
  category: 'Turbulence',
  cond: {
    list: [
      {
        choice: 14358,
        param: 'turbulence_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        list: [
          {
            choice: 35474,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.TKE,
};
export const omega: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Specific Dissipation Rate',
  name: 'omega',
  help: '',
  unit: '1/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    65253,
  ],
  parents: [],
  category: 'Turbulence',
  cond: {
    list: [
      {
        choice: 14358,
        param: 'turbulence_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        list: [
          {
            choice: 35474,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.OMEGA,
};
export const gamma: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Turbulence Intermittency',
  name: 'gamma',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    12087,
  ],
  parents: [],
  category: 'Transition',
  cond: {
    list: [
      {
        choice: 35474,
        param: 'viscous_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        list: [
          {
            choice: 2762,
            param: 'turbulence_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 14358,
            param: 'turbulence_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      {
        list: [
          {
            choice: 20304,
            param: 'transition_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 37856,
            param: 'transition_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 52077,
            param: 'transition_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.GAMMA,
};
export const reTheta: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Momentum-Thickness Reynolds Number',
  name: 're_theta',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    15544,
  ],
  parents: [],
  category: 'Transition',
  cond: {
    list: [
      {
        choice: 35474,
        param: 'viscous_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        list: [
          {
            choice: 2762,
            param: 'turbulence_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 14358,
            param: 'turbulence_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      {
        choice: 37856,
        param: 'transition_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.RE_THETA,
};
export const nTilde: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Amplification Factor',
  name: 'n_tilde',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    51864,
  ],
  parents: [],
  category: 'Transition',
  cond: {
    choice: 52077,
    param: 'transition_model',
    type: ProtoDescriptor.CondType.CHOICE,
  },
  quantityType: QuantityPb.QuantityType.N_TILDE,
};
export const yPlus: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Y Plus',
  name: 'y_plus',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_SURFACE_FIELD,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    43246,
  ],
  parents: [],
  category: 'Turbulence',
  cond: {
    cond: {
      choice: 8775,
      param: 'viscous_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    type: ProtoDescriptor.CondType.NOT,
  },
  quantityType: QuantityPb.QuantityType.Y_PLUS,
};
export const reRoughness: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Roughness Reynolds Number',
  name: 're_roughness',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_SURFACE_FIELD,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Turbulence',
  cond: {
    cond: {
      choice: 8775,
      param: 'viscous_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    type: ProtoDescriptor.CondType.NOT,
  },
  quantityType: QuantityPb.QuantityType.RE_ROUGHNESS,
};
export const wallShearStress: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Wall Shear Stress',
  name: 'wall_shear_stress',
  help: '',
  unit: 'N/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    10666,
    60077,
  ],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.WALL_SHEAR_STRESS,
};
export const massFlux: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Mass Flux',
  name: 'mass_flux',
  help: '',
  unit: 'kg/m²/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    45362,
  ],
  parents: [],
  category: 'Flow Rate',
  quantityType: QuantityPb.QuantityType.MASS_FLUX,
};
export const qCriterion: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Q-Criterion',
  name: 'q_criterion',
  help: '',
  unit: '1/s²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    30355,
  ],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.Q_CRITERION,
};
export const heatFlux: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Heat Flux',
  name: 'heat_flux',
  help: '',
  unit: 'W/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_SURFACE_FIELD,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    4408,
  ],
  parents: [],
  category: 'Temperature',
  cond: {
    list: [
      {
        list: [
          {
            choice: 9152,
            param: 'physics_type',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 37484,
            param: 'density_relationship',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 41777,
            param: 'density_relationship',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      {
        cond: {
          choice: 8775,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        type: ProtoDescriptor.CondType.NOT,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.HEAT_FLUX,
};
export const energyFlux: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Energy Flux',
  name: 'energy_flux',
  help: 'Flux of energy through a surface. Equivalent to heat flux for no-slip walls.',
  unit: 'W/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Temperature',
  cond: {
    list: [
      {
        list: [
          {
            choice: 37484,
            param: 'density_relationship',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 41777,
            param: 'density_relationship',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      {
        cond: {
          choice: 8775,
          param: 'viscous_model',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        type: ProtoDescriptor.CondType.NOT,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.ENERGY_FLUX,
};
export const heatTransferCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Heat Transfer Coefficient',
  name: 'heat_transfer_coefficient',
  help: '',
  unit: 'W/K/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_SURFACE_FIELD,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Temperature',
  cond: {
    list: [
      {
        list: [
          {
            choice: 9152,
            param: 'physics_type',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            type: ProtoDescriptor.CondType.FALSE,
          },
        ],
        type: ProtoDescriptor.CondType.ALL,
      },
      {
        list: [
          {
            choice: 52396,
            param: 'physics_type',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            list: [
              {
                choice: 37484,
                param: 'density_relationship',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              {
                choice: 41777,
                param: 'density_relationship',
                type: ProtoDescriptor.CondType.CHOICE,
              },
            ],
            type: ProtoDescriptor.CondType.ANY,
          },
          {
            cond: {
              choice: 8775,
              param: 'viscous_model',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            type: ProtoDescriptor.CondType.NOT,
          },
          {
            type: ProtoDescriptor.CondType.FALSE,
          },
        ],
        type: ProtoDescriptor.CondType.ALL,
      },
    ],
    type: ProtoDescriptor.CondType.ANY,
  },
  quantityType: QuantityPb.QuantityType.HEAT_TRANSFER_COEFFICIENT,
};
export const absolutePressure: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Absolute Pressure',
  name: 'absolute_pressure',
  help: '',
  unit: 'Pa',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.ABSOLUTE_PRESSURE,
};
export const densityN: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Density N',
  name: 'density_n',
  help: 'Previous time step density',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_HIDDEN_SOLVER_STATE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Properties',
  cond: {
    type: ProtoDescriptor.CondType.FALSE,
  },
  quantityType: QuantityPb.QuantityType.DENSITY_N,
};
export const momentumN: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Momentum N',
  name: 'momentum_n',
  help: 'Previous time step momentum',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_HIDDEN_SOLVER_STATE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Velocity',
  cond: {
    type: ProtoDescriptor.CondType.FALSE,
  },
  quantityType: QuantityPb.QuantityType.MOMENTUM_N,
};
export const energyN: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Energy N',
  name: 'energy_n',
  help: 'Previous time step energy (times density)',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_HIDDEN_SOLVER_STATE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Temperature',
  cond: {
    type: ProtoDescriptor.CondType.FALSE,
  },
  quantityType: QuantityPb.QuantityType.ENERGY_N,
};
export const saVariableN: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'SA Variable N',
  name: 'sa_variable_n',
  help: 'Previous time step nu tilde',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_HIDDEN_SOLVER_STATE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Adjoint Quantities',
  cond: {
    type: ProtoDescriptor.CondType.FALSE,
  },
  quantityType: QuantityPb.QuantityType.SA_VARIABLE_N,
};
export const tkeN: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'TKE N',
  name: 'tke_n',
  help: 'Previous time step tke (times density)',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_HIDDEN_SOLVER_STATE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Turbulence',
  cond: {
    type: ProtoDescriptor.CondType.FALSE,
  },
  quantityType: QuantityPb.QuantityType.TKE_N,
};
export const omegaN: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Omega N',
  name: 'omega_n',
  help: 'Previous time step omega (times density)',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_HIDDEN_SOLVER_STATE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Turbulence',
  cond: {
    type: ProtoDescriptor.CondType.FALSE,
  },
  quantityType: QuantityPb.QuantityType.OMEGA_N,
};
export const gammaN: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Gamma N',
  name: 'gamma_n',
  help: 'Previous time step gamma (times density)',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_HIDDEN_SOLVER_STATE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Transition',
  cond: {
    type: ProtoDescriptor.CondType.FALSE,
  },
  quantityType: QuantityPb.QuantityType.GAMMA_N,
};
export const reThetaN: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Re_Theta N',
  name: 're_theta_n',
  help: 'Previous time step re-theta (times density)',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_HIDDEN_SOLVER_STATE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Transition',
  cond: {
    type: ProtoDescriptor.CondType.FALSE,
  },
  quantityType: QuantityPb.QuantityType.RE_THETA_N,
};
export const nTildeN: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'N_Tilde N',
  name: 'n_tilde_n',
  help: 'Previous time step n-tilde (times density)',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_HIDDEN_SOLVER_STATE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Adjoint Quantities',
  cond: {
    type: ProtoDescriptor.CondType.FALSE,
  },
  quantityType: QuantityPb.QuantityType.N_TILDE_N,
};
export const gridVelocity: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Grid Velocity',
  name: 'grid_velocity',
  help: 'Velocity of the computational grid due to motion',
  unit: 'm/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    65107,
    10989,
  ],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.GRID_VELOCITY,
};
export const iblank: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Iblank',
  name: 'iblank',
  help: 'Iblanking for overset',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Mesh',
  quantityType: QuantityPb.QuantityType.IBLANK,
};
export const molecularWeight: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Molecular Weight',
  name: 'molecular_weight',
  help: '',
  unit: 'g/mol',
  tags: [],
  children: [
    29867,
    28,
    29,
    10666,
  ],
  parents: [],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.MOLECULAR_WEIGHT,
};
export const specificHeat: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Specific Heat',
  name: 'specific_heat',
  help: '',
  unit: 'J/K/kg',
  tags: [],
  children: [
    29867,
    28,
    29,
  ],
  parents: [],
  category: 'Temperature',
  quantityType: QuantityPb.QuantityType.SPECIFIC_HEAT,
};
export const thermalConductivity: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Thermal Conductivity',
  name: 'thermal_conductivity',
  help: '',
  unit: 'W/K/m',
  tags: [],
  children: [],
  parents: [],
  category: 'Temperature',
  quantityType: QuantityPb.QuantityType.THERMAL_CONDUCTIVITY,
};
export const darcyCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Darcy Coefficient',
  name: 'darcy_coefficient',
  help: '',
  unit: '1/m²',
  tags: [],
  children: [],
  parents: [],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.DARCY_COEFFICIENT,
};
export const forchheimerCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Forchheimer Coefficient',
  name: 'forchheimer_coefficient',
  help: '',
  unit: '1/m',
  tags: [],
  children: [],
  parents: [],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.FORCHHEIMER_COEFFICIENT,
};
export const relativeVelocity: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Relative Velocity',
  name: 'relative_velocity',
  help: 'Velocity in the local, moving reference frame',
  unit: 'm/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    29867,
  ],
  parents: [
    5,
    50745,
  ],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.RELATIVE_VELOCITY,
};
export const relativeMach: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Relative Mach',
  name: 'relative_mach',
  help: 'Mach number in the local, moving reference frame',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    65107,
    3,
    31438,
    1654,
  ],
  category: 'Velocity',
  cond: {
    choice: 37484,
    param: 'density_relationship',
    type: ProtoDescriptor.CondType.CHOICE,
  },
  quantityType: QuantityPb.QuantityType.RELATIVE_MACH,
};
export const pressureCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure Coefficient',
  name: 'pressure_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    37511,
  ],
  parents: [
    2,
  ],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.PRESSURE_COEFFICIENT,
};
export const totalPressure: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Total Pressure',
  name: 'total_pressure',
  help: '',
  unit: 'Pa',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    48695,
    15628,
  ],
  parents: [
    2,
    5,
    31438,
    1654,
  ],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.TOTAL_PRESSURE,
};
export const totalPressureCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Total Pressure Coefficient',
  name: 'total_pressure_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    28,
  ],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.TOTAL_PRESSURE_COEFFICIENT,
};
export const totalTemperature: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Total Temperature',
  name: 'total_temperature',
  help: '',
  unit: 'K',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_VOLUME,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    50819,
  ],
  parents: [
    3,
    5,
    31438,
    1654,
  ],
  category: 'Temperature',
  cond: {
    list: [
      {
        choice: 37484,
        param: 'density_relationship',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ANY,
  },
  quantityType: QuantityPb.QuantityType.TOTAL_TEMPERATURE,
};
export const skinFrictionCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Skin Friction Coefficient',
  name: 'skin_friction_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    52097,
    1654,
  ],
  category: 'Velocity',
  cond: {
    cond: {
      choice: 8775,
      param: 'viscous_model',
      type: ProtoDescriptor.CondType.CHOICE,
    },
    type: ProtoDescriptor.CondType.NOT,
  },
  quantityType: QuantityPb.QuantityType.SKIN_FRICTION_COEFFICIENT,
};
export const temperatureTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Temperature Average',
  name: 'temperature_time_average',
  help: '',
  unit: 'K',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    3,
  ],
  category: 'Temperature',
  quantityType: QuantityPb.QuantityType.TEMPERATURE_TIME_AVERAGE,
};
export const densityTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Density Average',
  name: 'density_time_average',
  help: '',
  unit: 'kg/m³',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    1,
  ],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.DENSITY_TIME_AVERAGE,
};
export const pressureTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure Average',
  name: 'pressure_time_average',
  help: '',
  unit: 'Pa',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    2,
  ],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.PRESSURE_TIME_AVERAGE,
};
export const velocityTimeAverage: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Velocity Average',
  name: 'velocity_time_average',
  help: '',
  unit: 'm/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [
    10989,
  ],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.VELOCITY_TIME_AVERAGE,
};
export const relativeVelocityTimeAverage: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Relative Velocity Average',
  name: 'relative_velocity_time_average',
  help: '',
  unit: 'm/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    10985,
    50745,
  ],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.RELATIVE_VELOCITY_TIME_AVERAGE,
};
export const velocityMagnitudeTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Velocity Magnitude Average',
  name: 'velocity_magnitude_time_average',
  help: '',
  unit: 'm/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    30,
  ],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.VELOCITY_MAGNITUDE_TIME_AVERAGE,
};
export const eddyViscosityTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Eddy Viscosity Average',
  name: 'eddy_viscosity_time_average',
  help: '',
  unit: 'N·s/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    4,
  ],
  category: 'Turbulence',
  quantityType: QuantityPb.QuantityType.EDDY_VISCOSITY_TIME_AVERAGE,
};
export const viscosityTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Viscosity Average',
  name: 'viscosity_time_average',
  help: '',
  unit: 'N·s/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    1424,
  ],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.VISCOSITY_TIME_AVERAGE,
};
export const machTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Mach Average',
  name: 'mach_time_average',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    6,
  ],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.MACH_TIME_AVERAGE,
};
export const saVariableTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Spalart-Allmaras Variable Average',
  name: 'sa_variable_time_average',
  help: '',
  unit: 'N·s/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    7,
  ],
  category: 'Turbulence',
  quantityType: QuantityPb.QuantityType.SA_VARIABLE_TIME_AVERAGE,
};
export const yPlusTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Y Plus',
  name: 'y_plus_time_average',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    616,
  ],
  category: 'Turbulence',
  quantityType: QuantityPb.QuantityType.Y_PLUS_TIME_AVERAGE,
};
export const pressureCoefficientTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure Coefficient Average',
  name: 'pressure_coefficient_time_average',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    53930,
  ],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.PRESSURE_COEFFICIENT_TIME_AVERAGE,
};
export const wallShearStressTimeAverage: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Wall Shear Stress Average',
  name: 'wall_shear_stress_time_average',
  help: '',
  unit: 'N/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_SURFACE_FIELD,
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    52097,
  ],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.WALL_SHEAR_STRESS_TIME_AVERAGE,
};
export const massFluxTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Mass Flux Average',
  name: 'mass_flux_time_average',
  help: '',
  unit: 'kg/m²/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    14067,
  ],
  category: 'Flow Rate',
  quantityType: QuantityPb.QuantityType.MASS_FLUX_TIME_AVERAGE,
};
export const totalPressureTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Total Pressure Average',
  name: 'total_pressure_time_average',
  help: '',
  unit: 'Pa',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    28,
  ],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.TOTAL_PRESSURE_TIME_AVERAGE,
};
export const totalTemperatureTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Total Temperature Average',
  name: 'total_temperature_time_average',
  help: '',
  unit: 'K',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    29,
  ],
  category: 'Temperature',
  quantityType: QuantityPb.QuantityType.TOTAL_TEMPERATURE_TIME_AVERAGE,
};
export const qCriterionTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Q-Criterion Average',
  name: 'q_criterion_time_average',
  help: '',
  unit: '1/s²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    804,
  ],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.Q_CRITERION_TIME_AVERAGE,
};
export const heatFluxTimeAverage: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Heat Flux Average',
  name: 'heat_flux_time_average',
  help: '',
  unit: 'W/m²',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    12327,
  ],
  category: 'Temperature',
  quantityType: QuantityPb.QuantityType.HEAT_FLUX_TIME_AVERAGE,
};
export const adjointContinuity: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Adjoint Continuity',
  name: 'adjoint_continuity',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    2,
  ],
  category: 'Adjoint Quantities',
  quantityType: QuantityPb.QuantityType.ADJOINT_CONTINUITY,
};
export const adjointMomentum: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Adjoint Momentum',
  name: 'adjoint_momentum',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    5,
  ],
  category: 'Adjoint Quantities',
  quantityType: QuantityPb.QuantityType.ADJOINT_MOMENTUM,
};
export const adjointEnergy: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Adjoint Energy',
  name: 'adjoint_energy',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    3,
  ],
  category: 'Adjoint Quantities',
  quantityType: QuantityPb.QuantityType.ADJOINT_ENERGY,
};
export const adjointSaVariable: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Adjoint Spalart-Allmaras Variable',
  name: 'adjoint_sa_variable',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    7,
  ],
  category: 'Adjoint Quantities',
  quantityType: QuantityPb.QuantityType.ADJOINT_SA_VARIABLE,
};
export const adjointTke: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Adjoint Turbulent Kinetic Energy',
  name: 'adjoint_tke',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    38457,
  ],
  category: 'Adjoint Quantities',
  quantityType: QuantityPb.QuantityType.ADJOINT_TKE,
};
export const adjointOmega: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Adjoint Specific Dissipation Rate',
  name: 'adjoint_omega',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    15375,
  ],
  category: 'Adjoint Quantities',
  quantityType: QuantityPb.QuantityType.ADJOINT_OMEGA,
};
export const adjointGamma: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Adjoint Turbulence Intermittency',
  name: 'adjoint_gamma',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_TIME_AVERAGE,
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    44979,
  ],
  category: 'Adjoint Quantities',
  quantityType: QuantityPb.QuantityType.ADJOINT_GAMMA,
};
export const adjointReTheta: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Adjoint Momentum-Thickness Reynolds Number',
  name: 'adjoint_re_theta',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    29711,
  ],
  category: 'Adjoint Quantities',
  quantityType: QuantityPb.QuantityType.ADJOINT_RE_THETA,
};
export const adjointNTilde: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Adjoint Amplification Factor',
  name: 'adjoint_n_tilde',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [
    23584,
  ],
  category: 'Adjoint Quantities',
  quantityType: QuantityPb.QuantityType.ADJOINT_N_TILDE,
};
export const sensitivity: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Sensitivity',
  name: 'sensitivity',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.SENSITIVITY,
};
export const normalSensitivity: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Normal Sensitivity',
  name: 'normal_sensitivity',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.NORMAL_SENSITIVITY,
};
export const smoothedNormalSensitivity: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Smoothed Normal Sensitivity',
  name: 'smoothed_normal_sensitivity',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.SMOOTHED_NORMAL_SENSITIVITY,
};
export const massFlow: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Mass Flow Rate',
  name: 'mass_flow',
  help: '',
  unit: 'kg/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_INTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
  ],
  children: [],
  parents: [],
  category: 'Flow Rate',
  quantityType: QuantityPb.QuantityType.MASS_FLOW,
};
export const absMassFlow: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Absolute Mass Flow Rate',
  name: 'abs_mass_flow',
  help: '',
  unit: 'kg/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_INTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
  ],
  children: [],
  parents: [],
  category: 'Flow Rate',
  quantityType: QuantityPb.QuantityType.ABS_MASS_FLOW,
};
export const pressureDrop: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure Drop',
  name: 'pressure_drop',
  help: '',
  unit: 'Pa',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_INTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_DROP,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
  ],
  children: [],
  parents: [],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.PRESSURE_DROP,
};
export const totalPressureDrop: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Total Pressure Drop',
  name: 'total_pressure_drop',
  help: '',
  unit: 'Pa',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_INTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_DROP,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
  ],
  children: [],
  parents: [],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.TOTAL_PRESSURE_DROP,
};
export const pressureRise: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure Rise',
  name: 'pressure_rise',
  help: '',
  unit: 'Pa',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_INTERNAL,
  ],
  children: [],
  parents: [],
  category: 'Pressure',
  quantityType: QuantityPb.QuantityType.PRESSURE_RISE,
};
export const volumeFlowRate: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Volume Flow Rate',
  name: 'volume_flow_rate',
  help: '',
  unit: 'm³/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_INTERNAL,
  ],
  children: [],
  parents: [],
  category: 'Flow Rate',
  quantityType: QuantityPb.QuantityType.VOLUME_FLOW_RATE,
};
export const genericForce: ProtoDescriptor.Quantity = {
  size: 3,
  text: 'Generic Force',
  name: 'generic_force',
  help: '',
  unit: 'N',
  tags: [],
  children: [],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.GENERIC_FORCE,
};
export const lift: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Lift',
  name: 'lift',
  help: 'Lift is the component of the total aerodynamic force in the direction of the cross product of the flow direction and [0,1,0] in the selected reference frame.',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    18,
  ],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.LIFT,
};
export const liftCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Lift Coefficient',
  name: 'lift_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    12,
  ],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.LIFT_COEFFICIENT,
};
export const drag: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Drag',
  name: 'drag',
  help: 'Drag is the component of the total aerodynamic force in the direction of the flow.',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    19,
  ],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.DRAG,
};
export const dragCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Drag Coefficient',
  name: 'drag_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    13,
  ],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.DRAG_COEFFICIENT,
};
export const viscousDrag: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Viscous Drag',
  name: 'viscous_drag',
  help: 'Viscous Drag is the component of the viscous force in the direction of the flow.',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    56,
  ],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.VISCOUS_DRAG,
};
export const viscousDragCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Viscous Drag Coefficient',
  name: 'viscous_drag_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    55,
  ],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.VISCOUS_DRAG_COEFFICIENT,
};
export const pressureDrag: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure Drag',
  name: 'pressure_drag',
  help: 'Pressure Drag is the component of the pressure force in the direction of the flow.',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    58,
  ],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.PRESSURE_DRAG,
};
export const pressureDragCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure Drag Coefficient',
  name: 'pressure_drag_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    57,
  ],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.PRESSURE_DRAG_COEFFICIENT,
};
export const sideforce: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Sideforce',
  name: 'sideforce',
  help: 'Sideforce is the component of the total aerodynamic force in the direction of the cross product of the Lift direction and the flow direction.',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    20,
  ],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.SIDEFORCE,
};
export const sideforceCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Sideforce Coefficient',
  name: 'sideforce_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    14,
  ],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.SIDEFORCE_COEFFICIENT,
};
export const pressureForce: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure Force',
  name: 'pressure_force',
  help: '',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    21,
  ],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.PRESSURE_FORCE,
};
export const pressureForceCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pressure Force Coefficient',
  name: 'pressure_force_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    15,
  ],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.PRESSURE_FORCE_COEFFICIENT,
};
export const frictionForce: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Friction Force',
  name: 'friction_force',
  help: '',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    22,
  ],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.FRICTION_FORCE,
};
export const frictionForceCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Friction Force Coefficient',
  name: 'friction_force_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    16,
  ],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.FRICTION_FORCE_COEFFICIENT,
};
export const totalForce: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Force',
  name: 'total_force',
  help: '',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    23,
  ],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.TOTAL_FORCE,
};
export const totalForceCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Force Coefficient',
  name: 'total_force_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    17,
  ],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.TOTAL_FORCE_COEFFICIENT,
};
export const downforce: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Downforce',
  name: 'downforce',
  help: 'Downforce is the component of the total aerodynamic force in the opposite direction to Lift.',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    26,
  ],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.DOWNFORCE,
};
export const downforceCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Downforce Coefficient',
  name: 'downforce_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    27,
  ],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.DOWNFORCE_COEFFICIENT,
};
export const totalMoment: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Moment',
  name: 'total_moment',
  help: '',
  unit: 'N·m',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_MOMENT,
    QuantityOptionsPb.QuantityTag.TAG_TORQUE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    25,
  ],
  parents: [],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.TOTAL_MOMENT,
};
export const totalMomentCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Moment Coefficient',
  name: 'total_moment_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_MOMENT,
    QuantityOptionsPb.QuantityTag.TAG_TORQUE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    24,
  ],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.TOTAL_MOMENT_COEFFICIENT,
};
export const pitchingMoment: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pitching Moment',
  name: 'pitching_moment',
  help: 'Pitching Moment is the component of the moment produced by the total aerodynamic force about the y-axis in the local reference frame defined using the Body Orientation.',
  unit: 'N·m',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_MOMENT,
    QuantityOptionsPb.QuantityTag.TAG_TORQUE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    35,
  ],
  parents: [],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.PITCHING_MOMENT,
};
export const pitchingMomentCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Pitching Moment Coefficient',
  name: 'pitching_moment_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_MOMENT,
    QuantityOptionsPb.QuantityTag.TAG_TORQUE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    32,
  ],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.PITCHING_MOMENT_COEFFICIENT,
};
export const rollingMoment: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Rolling Moment',
  name: 'rolling_moment',
  help: 'Rolling Moment is the component of the moment produced by the total aerodynamic force about the x-axis in the local reference frame defined using the Body Orientation.',
  unit: 'N·m',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_MOMENT,
    QuantityOptionsPb.QuantityTag.TAG_TORQUE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    36,
  ],
  parents: [],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.ROLLING_MOMENT,
};
export const rollingMomentCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Rolling Moment Coefficient',
  name: 'rolling_moment_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_MOMENT,
    QuantityOptionsPb.QuantityTag.TAG_TORQUE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    33,
  ],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.ROLLING_MOMENT_COEFFICIENT,
};
export const yawingMoment: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Yawing Moment',
  name: 'yawing_moment',
  help: 'Yawing Moment is the component of the moment produced by the total aerodynamic force about the z-axis in the local reference frame defined using the Body Orientation.',
  unit: 'N·m',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_MOMENT,
    QuantityOptionsPb.QuantityTag.TAG_TORQUE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [
    37,
  ],
  parents: [],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.YAWING_MOMENT,
};
export const yawingMomentCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Yawing Moment Coefficient',
  name: 'yawing_moment_coefficient',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_MOMENT,
    QuantityOptionsPb.QuantityTag.TAG_TORQUE,
    QuantityOptionsPb.QuantityTag.TAG_COEFFICIENT,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [
    34,
  ],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.YAWING_MOMENT_COEFFICIENT,
};
export const diskTorque: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Disk Torque',
  name: 'disk_torque',
  help: 'Disk Torque is the component of the moment produced by the total aerodynamic force about the normal vector to the actuator disk surface and centered at the center of the actuator disk.',
  unit: 'N·m',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ACTUATOR_DISK,
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_MOMENT,
    QuantityOptionsPb.QuantityTag.TAG_TORQUE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.DISK_TORQUE,
};
export const diskThrust: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Disk Thrust',
  name: 'disk_thrust',
  help: 'Disk Thrust is the component of the total aerodynamic force in the direction of the normal vector to the actuator disk surface.',
  unit: 'N',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ACTUATOR_DISK,
    QuantityOptionsPb.QuantityTag.TAG_AUTO_DIRECTION,
    QuantityOptionsPb.QuantityTag.TAG_AERODYNAMICS,
    QuantityOptionsPb.QuantityTag.TAG_EXTERNAL,
    QuantityOptionsPb.QuantityTag.TAG_FORCE,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_FORCES,
  ],
  children: [],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.DISK_THRUST,
};
export const diskRotationRate: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Disk Rotation Rate',
  name: 'disk_rotation_rate',
  help: 'The rate of rotation of the fictitious blades on an actuator disk.',
  unit: 'rad/s',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ACTUATOR_DISK,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_AVERAGE,
  ],
  children: [],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.DISK_ROTATION_RATE,
};
export const errorMetric: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Error Metric',
  name: 'error_metric',
  help: '',
  unit: '',
  tags: [],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.ERROR_METRIC,
};
export const residualDensity: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Mass',
  name: 'residual_density',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
  ],
  children: [],
  parents: [],
  category: 'Properties',
  quantityType: QuantityPb.QuantityType.RESIDUAL_DENSITY,
};
export const residualXMomentum: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'X-Momentum',
  name: 'residual_x_momentum',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
  ],
  children: [],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.RESIDUAL_X_MOMENTUM,
};
export const residualYMomentum: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Y-Momentum',
  name: 'residual_y_momentum',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
  ],
  children: [],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.RESIDUAL_Y_MOMENTUM,
};
export const residualZMomentum: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Z-Momentum',
  name: 'residual_z_momentum',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
  ],
  children: [],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.RESIDUAL_Z_MOMENTUM,
};
export const residualEnergy: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Energy',
  name: 'residual_energy',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
  ],
  children: [],
  parents: [],
  category: 'Temperature',
  cond: {
    list: [
      {
        choice: 37484,
        param: 'density_relationship',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        choice: 41777,
        param: 'density_relationship',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ANY,
  },
  quantityType: QuantityPb.QuantityType.RESIDUAL_ENERGY,
};
export const residualSaVariable: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Spalart-Allmaras Variable',
  name: 'residual_sa_variable',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
  ],
  children: [],
  parents: [],
  category: 'Turbulence',
  cond: {
    list: [
      {
        list: [
          {
            choice: 35474,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 55751,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      {
        choice: 2762,
        param: 'turbulence_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.RESIDUAL_SA_VARIABLE,
};
export const residualTke: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Turbulent Kinetic Energy',
  name: 'residual_tke',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
  ],
  children: [],
  parents: [],
  category: 'Turbulence',
  cond: {
    list: [
      {
        list: [
          {
            choice: 35474,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 55751,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      {
        choice: 14358,
        param: 'turbulence_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.RESIDUAL_TKE,
};
export const residualOmega: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Specific Dissipation Rate',
  name: 'residual_omega',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
  ],
  children: [],
  parents: [],
  category: 'Turbulence',
  cond: {
    list: [
      {
        list: [
          {
            choice: 35474,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 55751,
            param: 'viscous_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      {
        choice: 14358,
        param: 'turbulence_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.RESIDUAL_OMEGA,
};
export const residualGamma: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Turbulence Intermittency',
  name: 'residual_gamma',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
  ],
  children: [],
  parents: [],
  category: 'Transition',
  cond: {
    list: [
      {
        choice: 35474,
        param: 'viscous_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        list: [
          {
            choice: 2762,
            param: 'turbulence_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 14358,
            param: 'turbulence_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      {
        list: [
          {
            choice: 20304,
            param: 'transition_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 37856,
            param: 'transition_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 52077,
            param: 'transition_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.RESIDUAL_GAMMA,
};
export const residualReTheta: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Momentum-Thickness Reynolds Number',
  name: 'residual_re_theta',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
  ],
  children: [],
  parents: [],
  category: 'Transition',
  cond: {
    list: [
      {
        choice: 35474,
        param: 'viscous_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        list: [
          {
            choice: 2762,
            param: 'turbulence_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
          {
            choice: 14358,
            param: 'turbulence_model',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      {
        choice: 37856,
        param: 'transition_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.RESIDUAL_RE_THETA,
};
export const residualNTilde: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Amplification Factor',
  name: 'residual_n_tilde',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_RESIDUAL,
    QuantityOptionsPb.QuantityTag.TAG_TURBULENCE,
  ],
  children: [],
  parents: [],
  category: 'Transition',
  cond: {
    list: [
      {
        choice: 35474,
        param: 'viscous_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        choice: 2762,
        param: 'turbulence_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
      {
        choice: 52077,
        param: 'transition_model',
        type: ProtoDescriptor.CondType.CHOICE,
      },
    ],
    type: ProtoDescriptor.CondType.ALL,
  },
  quantityType: QuantityPb.QuantityType.RESIDUAL_N_TILDE,
};
export const angularVelocity: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Angular Velocity',
  name: 'angular_velocity',
  help: '',
  unit: 'rad/s',
  tags: [],
  children: [],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.ANGULAR_VELOCITY,
};
export const physicalTime: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Physical Time',
  name: 'physical_time',
  help: '',
  unit: 's',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.PHYSICAL_TIME,
};
export const iterationIndex: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Iteration Index',
  name: 'iteration_index',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.ITERATION_INDEX,
};
export const innerIterationCount: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Inner Iteration Count',
  name: 'inner_iteration_count',
  help: '',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_ANALYZER_BASIC,
  ],
  children: [],
  parents: [],
  category: 'Global',
  quantityType: QuantityPb.QuantityType.INNER_ITERATION_COUNT,
};
export const acceleration: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Acceleration',
  name: 'acceleration',
  help: '',
  unit: 'm/s²',
  tags: [],
  children: [],
  parents: [],
  category: 'Velocity',
  quantityType: QuantityPb.QuantityType.ACCELERATION,
};
export const thrustProfile: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Thrust Profile',
  name: 'thrust_profile',
  help: 'Thrust distribution over actuator disk',
  unit: '',
  tags: [],
  children: [],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.THRUST_PROFILE,
};
export const torqueProfile: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Torque Profile',
  name: 'torque_profile',
  help: 'Torque distribution over actuator disk',
  unit: '',
  tags: [],
  children: [],
  parents: [],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.TORQUE_PROFILE,
};
export const radialForceProfile: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Radial Force Profile',
  name: 'radial_force_profile',
  help: 'Radial force distribution over actuator disk',
  unit: '',
  tags: [],
  children: [],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.RADIAL_FORCE_PROFILE,
};
export const relativeRadius: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Relative Radius',
  name: 'relative_radius',
  help: 'Radius divided by a characteristic length',
  unit: '',
  tags: [],
  children: [],
  parents: [],
  category: 'Aerodynamic Properties',
  quantityType: QuantityPb.QuantityType.RELATIVE_RADIUS,
};
export const twistAngle: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Twist',
  name: 'twist_angle',
  help: '',
  unit: '°',
  tags: [],
  children: [],
  parents: [],
  category: 'Aerodynamic Properties',
  quantityType: QuantityPb.QuantityType.TWIST_ANGLE,
};
export const sweepAngle: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Sweep',
  name: 'sweep_angle',
  help: '',
  unit: '°',
  tags: [],
  children: [],
  parents: [],
  category: 'Aerodynamic Properties',
  quantityType: QuantityPb.QuantityType.SWEEP_ANGLE,
};
export const anhedralAngle: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Anhedral',
  name: 'anhedral_angle',
  help: '',
  unit: '°',
  tags: [],
  children: [],
  parents: [],
  category: 'Aerodynamic Properties',
  quantityType: QuantityPb.QuantityType.ANHEDRAL_ANGLE,
};
export const relativeChord: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Relative Chord',
  name: 'relative_chord',
  help: 'Airfoil chord length divided by a characteristic length',
  unit: '',
  tags: [],
  children: [],
  parents: [],
  category: 'Aerodynamic Properties',
  quantityType: QuantityPb.QuantityType.RELATIVE_CHORD,
};
export const thrustPerUnitArea: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Thrust-per-unit-area',
  name: 'thrust_per_unit_area',
  help: 'Thrust per unit area on an actuator disk',
  unit: 'N/m²',
  tags: [],
  children: [],
  parents: [],
  category: 'Forces',
  quantityType: QuantityPb.QuantityType.THRUST_PER_UNIT_AREA,
};
export const torquePerUnitArea: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Torque-per-unit-area',
  name: 'torque_per_unit_area',
  help: 'Torque per unit area on an actuator disk',
  unit: 'N·m/m²',
  tags: [],
  children: [],
  parents: [],
  category: 'Moments',
  quantityType: QuantityPb.QuantityType.TORQUE_PER_UNIT_AREA,
};
export const bladeLocalAngleOfAttack: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Local Angle of Attack',
  name: 'blade_local_angle_of_attack',
  help: 'Local angle of attack for a blade element on an actuator disk',
  unit: '°',
  tags: [],
  children: [],
  parents: [],
  category: 'Aerodynamic Properties',
  quantityType: QuantityPb.QuantityType.BLADE_LOCAL_ANGLE_OF_ATTACK,
};
export const bladeSectionalDragCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Sectional Drag Coefficient',
  name: 'blade_sectional_drag_coefficient',
  help: 'Local drag coefficient for a blade element on an actuator disk',
  unit: '',
  tags: [],
  children: [],
  parents: [],
  category: 'Aerodynamic Properties',
  quantityType: QuantityPb.QuantityType.BLADE_SECTIONAL_DRAG_COEFFICIENT,
};
export const bladeSectionalLiftCoefficient: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Sectional Lift Coefficient',
  name: 'blade_sectional_lift_coefficient',
  help: 'Local lift coefficient for a blade element on an actuator disk',
  unit: '',
  tags: [],
  children: [],
  parents: [],
  category: 'Aerodynamic Properties',
  quantityType: QuantityPb.QuantityType.BLADE_SECTIONAL_LIFT_COEFFICIENT,
};
export const debugQuantity: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Debug Quantity',
  name: 'debug_quantity',
  help: 'Debug Quantity',
  unit: '',
  tags: [],
  children: [],
  parents: [],
  category: 'Debugging & Issues',
  quantityType: QuantityPb.QuantityType.DEBUG_QUANTITY,
};
export const interfaceMatchingIssues: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Interface Issues',
  name: 'interface_matching_issues',
  help: 'Tag for mesh elements where interface matching issues are found',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Debugging & Issues',
  quantityType: QuantityPb.QuantityType.INTERFACE_MATCHING_ISSUES,
};
export const solverStabilityIssues: ProtoDescriptor.Quantity = {
  size: 1,
  text: 'Stability Issues',
  name: 'solver_stability_issues',
  help: 'Tag for poor quality mesh elements where solution stability issues are being mitigated',
  unit: '',
  tags: [
    QuantityOptionsPb.QuantityTag.TAG_FIELD,
  ],
  children: [],
  parents: [],
  category: 'Debugging & Issues',
  quantityType: QuantityPb.QuantityType.SOLVER_STABILITY_ISSUES,
};
export const quantities: ProtoDescriptor.Quantity[] = [
  mass,
  length,
  area,
  volume,
  time,
  energy,
  radians,
  degree,
  power,
  powerPerUnitVolume,
  thermalExpansionCoefficient,
  temperature,
  density,
  pressure,
  velocity,
  velocityMagnitude,
  eddyViscosity,
  viscosity,
  mach,
  saVariable,
  tke,
  omega,
  gamma,
  reTheta,
  nTilde,
  yPlus,
  reRoughness,
  wallShearStress,
  massFlux,
  qCriterion,
  heatFlux,
  energyFlux,
  heatTransferCoefficient,
  absolutePressure,
  densityN,
  momentumN,
  energyN,
  saVariableN,
  tkeN,
  omegaN,
  gammaN,
  reThetaN,
  nTildeN,
  gridVelocity,
  iblank,
  molecularWeight,
  specificHeat,
  thermalConductivity,
  darcyCoefficient,
  forchheimerCoefficient,
  relativeVelocity,
  relativeMach,
  pressureCoefficient,
  totalPressure,
  totalPressureCoefficient,
  totalTemperature,
  skinFrictionCoefficient,
  temperatureTimeAverage,
  densityTimeAverage,
  pressureTimeAverage,
  velocityTimeAverage,
  relativeVelocityTimeAverage,
  velocityMagnitudeTimeAverage,
  eddyViscosityTimeAverage,
  viscosityTimeAverage,
  machTimeAverage,
  saVariableTimeAverage,
  yPlusTimeAverage,
  pressureCoefficientTimeAverage,
  wallShearStressTimeAverage,
  massFluxTimeAverage,
  totalPressureTimeAverage,
  totalTemperatureTimeAverage,
  qCriterionTimeAverage,
  heatFluxTimeAverage,
  adjointContinuity,
  adjointMomentum,
  adjointEnergy,
  adjointSaVariable,
  adjointTke,
  adjointOmega,
  adjointGamma,
  adjointReTheta,
  adjointNTilde,
  sensitivity,
  normalSensitivity,
  smoothedNormalSensitivity,
  massFlow,
  absMassFlow,
  pressureDrop,
  totalPressureDrop,
  pressureRise,
  volumeFlowRate,
  genericForce,
  lift,
  liftCoefficient,
  drag,
  dragCoefficient,
  viscousDrag,
  viscousDragCoefficient,
  pressureDrag,
  pressureDragCoefficient,
  sideforce,
  sideforceCoefficient,
  pressureForce,
  pressureForceCoefficient,
  frictionForce,
  frictionForceCoefficient,
  totalForce,
  totalForceCoefficient,
  downforce,
  downforceCoefficient,
  totalMoment,
  totalMomentCoefficient,
  pitchingMoment,
  pitchingMomentCoefficient,
  rollingMoment,
  rollingMomentCoefficient,
  yawingMoment,
  yawingMomentCoefficient,
  diskTorque,
  diskThrust,
  diskRotationRate,
  errorMetric,
  residualDensity,
  residualXMomentum,
  residualYMomentum,
  residualZMomentum,
  residualEnergy,
  residualSaVariable,
  residualTke,
  residualOmega,
  residualGamma,
  residualReTheta,
  residualNTilde,
  angularVelocity,
  physicalTime,
  iterationIndex,
  innerIterationCount,
  acceleration,
  thrustProfile,
  torqueProfile,
  radialForceProfile,
  relativeRadius,
  twistAngle,
  sweepAngle,
  anhedralAngle,
  relativeChord,
  thrustPerUnitArea,
  torquePerUnitArea,
  bladeLocalAngleOfAttack,
  bladeSectionalDragCoefficient,
  bladeSectionalLiftCoefficient,
  debugQuantity,
  interfaceMatchingIssues,
  solverStabilityIssues,
];

export function getQuantitySize(quantityType: QuantityPb.QuantityType): number {
  return quantities.find((item) => item.quantityType === quantityType)!.size;
}

export function getQuantityText(quantityType: QuantityPb.QuantityType): string {
  return quantities.find((item) => item.quantityType === quantityType)!.text;
}

export function getQuantityName(quantityType: QuantityPb.QuantityType): string {
  return quantities.find((item) => item.quantityType === quantityType)!.name;
}

export function getQuantityHelp(quantityType: QuantityPb.QuantityType): string {
  return quantities.find((item) => item.quantityType === quantityType)!.help;
}

export function getQuantityUnit(quantityType: QuantityPb.QuantityType): string {
  return quantities.find((item) => item.quantityType === quantityType)!.unit;
}

export function getQuantityTags(quantityType: QuantityPb.QuantityType): number[] {
  return quantities.find((item) => item.quantityType === quantityType)!.tags;
}

export function getQuantityCategory(quantityType: QuantityPb.QuantityType): string {
  return quantities.find((item) => item.quantityType === quantityType)!.category;
}

export function getQuantityChildren(quantityType: QuantityPb.QuantityType): QuantityPb.QuantityType[] {
  return quantities.find((item) => item.quantityType === quantityType)!.children;
}

export function getQuantityParents(quantityType: QuantityPb.QuantityType): QuantityPb.QuantityType[] {
  return quantities.find((item) => item.quantityType === quantityType)!.parents;
}

export function getQuantityCond(quantityType: QuantityPb.QuantityType): ProtoDescriptor.Cond | undefined {
  return quantities.find((item) => item.quantityType === quantityType)!.cond;
}
