import * as Sentry from '@sentry/react';

/**
 * Tracks unexpected promise rejections without throwing.
 *
 * Use this helper when a promise is not expected to reject. If it does reject,
 * the error is logged via Sentry for debugging purposes.
 */
export const shouldNeverFail = (error: unknown) => (
  Sentry.captureException(new Error('a promise that should never reject rejected', {
    cause: { rejectedValue: error },
  }))
);
