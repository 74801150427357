// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.

import { Code } from '@connectrpc/connect';
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';

import { Logger } from '../../../lib/observability/logs';
import * as rpc from '../../../lib/rpc';
import * as status from '../../../lib/status';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import { accountInfoState } from '../../../recoil/useAccountInfo';

const logger = new Logger('resourcesQuota');

type ResourceQuota = {
  quota: number;
  used: number;
}

type ResourcesQuota = Map<frontendpb.GetUserQuotaReply_ResourceQuotaType, ResourceQuota>;

const DEFAULT_RESOURCES_QUOTA: ResourcesQuota = new Map();

// getUserQuota fetches the user's resources quota from the backend using the RPC endpoint. The
// result will remain immutable unless it is refreshed or the user refreshes the page.
const getUserQuota = selector<ResourcesQuota>({
  key: 'getUserQuota',
  get: async () => {
    try {
      const reply = await rpc.callRetry(
        'getUserQuota',
        rpc.client.getUserQuota,
        new frontendpb.GetUserQuotaRequest(),
        false,
      );
      const resourceQuotas = new Map<
        frontendpb.GetUserQuotaReply_ResourceQuotaType,
        ResourceQuota
      >();
      reply.quotaInfo.forEach((quota) => {
        resourceQuotas.set(quota.type, {
          quota: Number(quota.quota),
          used: Number(quota.used),
        });
      });
      return resourceQuotas;
    } catch (err) {
      const grpcError = status.getGrpcError(err);
      if (!grpcError || grpcError.code !== Code.NotFound) {
        logger.error(err);
      }
      return DEFAULT_RESOURCES_QUOTA;
    }
  },
});

// Stores the user's resources quota. Only users that belong to accounts that have the unlimited
// plan billing type have resources quota. If there are any errors fetching the data or the user
// does not belong to one of the aforementioned accounts, an empty quota will be returned.
export const userQuota = selector<ResourcesQuota>({
  key: 'userQuota',
  get: ({ get }) => {
    const accountInfo = get(accountInfoState);
    // Only the unlimited billing type has resources quota.
    if (!accountInfo || accountInfo.billingType !== frontendpb.BillingType.UNLIMITED_BILLING_TYPE) {
      return DEFAULT_RESOURCES_QUOTA;
    }
    return get(getUserQuota);
  },
});

export const useUserQuota = () => (
  useRecoilValue(userQuota)
);

export const useRefreshUserQuotaRpc = () => (
  useRecoilRefresher_UNSTABLE(getUserQuota)
);
