// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const SphereWithLines = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="expression"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        clipRule="evenodd"
        d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12
           0ZM22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.201 22.5 1.5 17.799 1.5 12C1.5 6.201 6.201
           1.5 12 1.5C17.799 1.5 22.5 6.201 22.5 12ZM16.0951 7.50082H19.7551C18.6451 5.61082 16.8751
           4.15582 14.7301 3.46582C15.3301 4.45582 16.0151 5.83582 16.0951 7.50082ZM15 12C15 13.095
           14.925 14.085 14.82 15H9.18C9.075 14.085 9 13.095 9 12C9 10.905 9.075 9.915 9.18
           9H14.82C14.925 9.915 15 10.905 15 12ZM14.595 7.50001C13.64001 5.725 12.505 4.5 12
           4.5C11.495 4.5 10.36001 5.725 9.59501 7.50001H14.595ZM9.27012 3.46582C8.67012 4.45582
           8.20512 5.83582 8.04762 7.50082H4.24562C5.35562 5.61082 7.12562 4.15582 9.27012
           3.46582ZM3.525 9C3.195 10.945 3 11.95 3 12C3 12.05 3.195 13.055 3.525 15H7.665C7.56
           13.07 7.5 12.075 7.5 12C7.5 11.925 7.56 10.93 7.665 9H3.525ZM4.24562 16.5H8.04762C8.22012
           17.165 8.43512 17.545 8.67012 18.2675C7.12562 17.9225 5.35562 16.845 4.24562
           16.5ZM9.40528 16.5C9.96028 18.225 10.4953 19.5 12 19.5C13.5053 19.5 14.0403 18.225
           14.5953 16.5H9.40528ZM14.7301 18.2675C15.3301 17.545 15.6151 17.165 15.7551
           16.5H19.7551C18.6451 16.845 16.8751 17.9225 14.7301 18.2675ZM16.3348 15H20.4758C20.8058
           13.055 20.9998 12.05 20.9998 12C20.9998 11.95 20.8058 10.945 20.4758 9H16.3348C16.4098
           9.93 16.4958 10.925 16.4958 12C16.4958 13.075 16.4098 14.07 16.3348 15Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
});
