import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { SimulationTreeNode } from '../../lib/simulationTree/node';

const dragSourceNode = atom<SimulationTreeNode | null>({
  key: 'dragSourceNode',
  default: null,
});

export const useSetDragSourceNode = () => useSetRecoilState(dragSourceNode);
export const useDragSourceNode = () => useRecoilValue(dragSourceNode);

const dropDestinationNode = atom<SimulationTreeNode | null>({
  key: 'dropDestinationNode',
  default: null,
});

export const useSetDropDestinationNode = () => useSetRecoilState(dropDestinationNode);
export const useDropDestinationNode = () => useRecoilValue(dropDestinationNode);

const activeActionNodeId = atom<string | null>({
  key: 'activeActionNodeId',
  default: null,
});

export const useSetActiveActionNodeId = () => useSetRecoilState(activeActionNodeId);
export const useActiveActionNodeId = () => useRecoilValue(activeActionNodeId);
