// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { isGroupVisible } from '../../../lib/entityGroupUtils';
import { assembleMenuSections } from '../../../lib/menuUtil';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem, visibilityToggleTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useCopySimAnnotation } from '../../hooks/nodeDuplication/useCopySimAnnotation';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

// A row in the simulation tree representing a monitor plane
export const MonitorPlaneTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { viewState, visibilityMap } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteMonitorPlaneNode, postDeleteNodeIds } = useNodeDeletion();
  const duplicateRow = useCopySimAnnotation();

  // == Data
  const visibilityId = node.id;
  const isVisible = isGroupVisible(
    lcvisEnabled ? visibilityV2 : visibilityMap,
    entityGroupMap,
    visibilityId,
  );
  const isSelected = selectedNodeIds.includes(visibilityId);

  const toggleIds = useMemo(
    () => new Set(isSelected ? selectedNodeIds : [visibilityId]),
    [isSelected, selectedNodeIds, visibilityId],
  );

  const toggleVis = useToggleVisibility(toggleIds, isVisible);

  const deleteRow = useCallback(async () => {
    if (await deleteMonitorPlaneNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteMonitorPlaneNode, node.id, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => {
    const visItems = [
      visibilityToggleTreeNodeMenuItem(isVisible, toggleVis, !viewState && !lcvisEnabled),
    ];

    const crudDisabled = !canDelete(node.type, node.id);
    const crudItems = [
      duplicateTreeNodeMenuItem(() => duplicateRow(node.id, 'plane'), crudDisabled),
      deleteTreeNodeMenuItem(deleteRow, crudDisabled),
    ];

    return assembleMenuSections(visItems, crudItems);
  }, [
    isVisible,
    toggleVis,
    viewState,
    lcvisEnabled,
    canDelete,
    node.type,
    node.id,
    deleteRow,
    duplicateRow,
  ]);

  const visButton = (
    <VisibilityButton
      disabled={!viewState && !lcvisEnabled}
      isVisible={isVisible}
      onClick={toggleVis}
    />
  );

  return (
    <TreeRow
      {...props}
      canMultiSelect
      getContextMenuItems={getContextMenuItems}
      primaryIcon={getNodeTypeIcon(props.node.type)}
      propertiesControl
      renaming={renaming}
      visibilityButton={visButton}
    />
  );
};
