// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { analytics } from '../../../services/analytics';
import { useSetSetupSummaryOpened } from '../../../state/external/project/simulation/setupSummaryOpened';
import { ActionButton } from '../../Button/ActionButton';
import { useRunSimulationButtonProps } from '../../hooks/useRunSimulation';

export const ViewSetupSummaryButton = () => {
  // == State
  const setSummaryOpened = useSetSetupSummaryOpened();
  const { disabled: runSimulationButtonDisabled } = useRunSimulationButtonProps();

  return (
    <ActionButton
      asBlock
      disabled={runSimulationButtonDisabled}
      kind="cancel"
      onClick={() => {
        setSummaryOpened(true);
        analytics.track('Setup Summary Opened');
      }}
      size="medium">
      View Setup Summary
    </ActionButton>
  );
};
