// Copyright 2024-2025 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ActionButton } from '@/components/Button/ActionButton';
import { useHandleLoadToSetup, useLoadToSetupDisabled } from '@/components/hooks/useLoadToSetup';
import { FormControlSize } from '@/lib/componentTypes/form';

interface LoadToSetupButtonProps {
  size?: FormControlSize;
}

export const LoadToSetupButton = (props: LoadToSetupButtonProps) => {
  // == Props
  const { size = 'medium' } = props;

  // == State
  const handleLoadToSetup = useHandleLoadToSetup();
  const { disabled, disabledReason } = useLoadToSetupDisabled();

  return (
    <ActionButton
      asBlock
      disabled={disabled}
      onClick={handleLoadToSetup}
      size={size}
      title={disabledReason}>
      Load to Setup
    </ActionButton>
  );
};
