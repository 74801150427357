import { LCVManipulationMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';

import { LcvWidget } from './LcvWidget';

const WIDGET_NAME = 'drag_line';

export type LcvDragLineWidgetState = {
  start: [number, number, number];
  end: [number, number, number];
};

export class LcvDragLineWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, WIDGET_NAME);
  }

  setState(newState: Partial<LcvDragLineWidgetState>) {
    (Object.keys(newState) as (keyof LcvDragLineWidgetState)[]).forEach((key) => {
      this.setParam(key, LCVType.kLCVDataTypeFloat3, newState[key]);
    });
  }

  getState(): LcvDragLineWidgetState {
    return {
      start: this.getProperty('start', LCVType.kLCVDataTypeFloat3),
      end: this.getProperty('end', LCVType.kLCVDataTypeFloat3),
    };
  }

  showControls({ manipulationMode }: { manipulationMode?: LCVManipulationMode } = {}) {
    const DEFAULT_MANIPULATION_MODE = LCVManipulationMode.kLCVManipulationModeAll;

    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      manipulationMode ?? DEFAULT_MANIPULATION_MODE,
    );
  }

  hideControls(): void {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeNone,
    );
  }

  /**
   * Set the callback to be invoked whenever the drag line widget state changes.
   * @param callback a callback to be invoked whenever the drag line widget state changes.
   */
  setOnUpdateCallback(
    callback: ((newState: LcvDragLineWidgetState, message?: string) => void) | null,
  ) {
    if (!callback) {
      this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, null);
      return;
    }

    const internalCallback = (
      _lcv: LcvModule,
      _session: LCVObject,
      _obj: LCVObject,
      message: string,
    ) => {
      const newState = this.getState();
      callback(newState, message);
    };

    this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, internalCallback);
  }
}
