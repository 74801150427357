import { LcvModule } from '../../types';
import { LcvObject } from '../base/LcvObject';

export class LcvOriginAnnotation extends LcvObject {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, lcv.newAnnotation(sessionHandle, 'origin', 0).annotation, sessionHandle);
  }
}
