// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect } from 'react';

import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useQuantitySelectionState } from '../../../recoil/selectionOptions';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { RibbonToolbarTool } from '../../RibbonToolbar/RibbonToolbarButton';
import { DEFAULT_TOOLBAR_ICON_SIZE } from '../../Toolbar/ToolbarButton';
import { TipWithBindings } from '../../Tooltip/TipWithBinding';
import { useProjectContext } from '../../context/ProjectContext';
import { LeftClickMouseIcon } from '../../svg/LeftClickMouseIcon';

import environmentState from '@/state/environment';

// A button to show in the Toolbar for setting selection options.
// There's a similar component for the old toolbar. The old BoxOrSingleSelectSwitcher should be
// removed when LC-22515 is done.
export const useBoxOrSingleSelectSwitcherData = (): RibbonToolbarTool => {
  // == Contexts
  const { projectId } = useProjectContext();
  const { viewState } = useParaviewContext();

  // == Recoil
  const [quantitySelectionState, setQuantitySelectionState] = useQuantitySelectionState(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  const pvReady = !!viewState;
  const visReady = lcvisEnabled ? lcvisReady : pvReady;

  // if LCVis is disabled, switch the quantity selection type back to single-select.
  useEffect(() => {
    setQuantitySelectionState('singleSelect');
  }, [lcvisEnabled, setQuantitySelectionState]);

  return {
    disabled: !visReady,
    engaged: quantitySelectionState === 'boxSelect',
    icon: { name: 'boxSelectCursorIcon', maxHeight: 14 },
    key: 'boxOrSingleSelectButton',
    locator: 'toolbar-box-or-single-select',
    onClick: () => {
      setQuantitySelectionState(
        (currentValue) => (currentValue === 'singleSelect' ? 'boxSelect' : 'singleSelect'),
      );
    },
    label: 'Box Select',
    title: quantitySelectionState === 'singleSelect' ? (
      <TipWithBindings
        binding={(
          <span style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
              &nbsp;⇧&nbsp;
            <LeftClickMouseIcon
              maxHeight={DEFAULT_TOOLBAR_ICON_SIZE}
              maxWidth={DEFAULT_TOOLBAR_ICON_SIZE}
            />
          </span>
          )}
        tip="Activate Box Select"
      />
    ) : 'Deativate Box Select',
  };
};
