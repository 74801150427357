// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useEffect } from 'react';

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useProjectContext } from '../../components/context/ProjectContext';
import GroupMap from '../../lib/GroupMap';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { useEntityGroupMap } from '../entityGroupState';
import { useEntitySelectionValue } from '../selectionOptions';
import { useControlPanelMode } from '../useProjectPage';
import { useRowsOpened } from '../useSimulationTreeState';
import { useStaticVolumes } from '../volumes';

export const lcvisHoveredId = atom<string | null>({
  key: 'lcvisHoveredId',
  default: null,
});

export const useLcvisHoveredId = () => useRecoilState(lcvisHoveredId);
export const useSetLcvisHoveredId = () => useSetRecoilState(lcvisHoveredId);
export const useLcvisHoveredIdValue = () => useRecoilValue(lcvisHoveredId);

/**
 * Attaches a callback to the selection widget such that when a surface is hovered in LCVis,
 * the corresponding surface or volume is outlined in the geometry panel.
 * */
export const useHoverInVisCallback = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const [controlPanelMode] = useControlPanelMode();
  const entitySelectionState = useEntitySelectionValue(projectId);
  const [nodesOpened] = useRowsOpened(projectId, controlPanelMode);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId);
  const setLcvisHoveredId = useSetLcvisHoveredId();

  /**
   * When a surface is hovered in LCVis, we want to highlight the corresponding
   * surface or volume in the geometry panel. However, if the surface is part of a group and that
   * group is not expanded, we should highlight its parent group instead since the surface itself
   * is not visible.
   */
  const getOutlinedEntity = useCallback((id: string): string | null => {
    if (entitySelectionState === 'surface_no_highlight') {
      return null;
    }
    const entity = entityGroupMap.has(id) ? entityGroupMap.get(id) : null;
    if (!entity) {
      return id;
    }
    if (entitySelectionState === 'volume') {
      // we're in a volume selection state,
      // so we should highlight the volume that contains the hovered surface
      return staticVolumes.find((vol) => vol.bounds.has(id))?.id ?? null;
    }

    let topEntityVisible = entity;
    entityGroupMap.walkAncestors(entity.id, (ancestor) => {
      if (!nodesOpened[ancestor.id] && (ancestor.id !== GroupMap.rootId)) {
        topEntityVisible = ancestor;
      }
      return true;
    });
    return topEntityVisible.id;
  }, [entityGroupMap, nodesOpened, entitySelectionState, staticVolumes]);

  const getSurfacesToHighlight = useCallback((hoveredId: string) => {
    if (entitySelectionState === 'surface_no_highlight') {
      return [];
    }
    if (entitySelectionState === 'volume') {
      const volume = staticVolumes.find((vol) => vol.bounds.has(hoveredId));
      return volume ? [...volume.bounds] : [hoveredId];
    }
    return [hoveredId];
  }, [entitySelectionState, staticVolumes]);

  useEffect(() => {
    lcvHandler.queueDisplayFunction('set mouseover callback', (display) => {
      const { simAnnotationHandler, workspace } = display;
      const { selectionWidget } = display.widgets;
      if (!selectionWidget || !workspace || !simAnnotationHandler) {
        return;
      }

      // When switching to no highlight, we clear out the hover state to reflect that we don't
      // want to highlight anything.
      if (entitySelectionState === 'surface_no_highlight') {
        setLcvisHoveredId(null);
        workspace.hoverSurfacesById([], entitySelectionState);
      }

      selectionWidget.setMouseOverCallback((selection) => {
        // Change the surface color in LCVis
        const [objectId, primitiveIndex] = selection;
        const surfaceOrVolumeId = workspace.getIdFromIndex(objectId, primitiveIndex);
        const annotationId = simAnnotationHandler.getIdFromSelection(objectId, primitiveIndex);
        const surfacesToHighlight = getSurfacesToHighlight(surfaceOrVolumeId);

        workspace.hoverSurfacesById.call(workspace, surfacesToHighlight, entitySelectionState);
        simAnnotationHandler.hoverAnnotationsById.call(simAnnotationHandler, [annotationId]);

        const hoveredErrorPoint = (
          display.errorList?.updateHoveredIdentifiers([objectId, primitiveIndex])
        );

        if (hoveredErrorPoint) {
          return;
        }

        // Tell recoil to outline the corresponding entity in the geometry panel
        const idInUI = annotationId || surfaceOrVolumeId;
        const idToOutline = getOutlinedEntity(idInUI);
        // TODO will question for PR review: Should I still be using Memo somewhere here?
        setLcvisHoveredId(idToOutline);
      });
    });
  }, [getOutlinedEntity, setLcvisHoveredId, getSurfacesToHighlight, entitySelectionState]);
};
