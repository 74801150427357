// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import {
  getQuantitySize,
  getQuantityTags,
} from '../../../QuantityDescriptor';
import { ParamScope } from '../../../lib/ParamScope';
import assert from '../../../lib/assert';
import { appendFluidBoundaryCondition, findFarfield } from '../../../lib/boundaryConditionUtils';
import { RadioButtonOption, SelectOption } from '../../../lib/componentTypes/form';
import { HelpfulIconSpec } from '../../../lib/componentTypes/menu';
import { BaseSettingsProps } from '../../../lib/componentTypes/output';
import { colors } from '../../../lib/designSystem';
import { parseString } from '../../../lib/html';
import { NodeTableType } from '../../../lib/nodeTableUtil';
import { createIncludeOption, getIncludeOptions } from '../../../lib/output/formUtil';
import {
  DIM3D,
  STOP_COND_OUTPUT_NODES,
  calcForceOptions,
  changeOutputType,
  choicesForType,
  componentSuffix,
  defaultChoice,
  findOutputNodeById,
  getOutputNodeWarnings,
  getOutputQuantity,
  isIncluded,
  needsFarfield,
  nodeSelectorChoices,
  protoCategoriesToSelectOptions,
  setDefaultFrameId,
  setDefaultName,
} from '../../../lib/outputNodeUtils';
import { getFluidPhysics, getPhysicsId, getPhysicsName } from '../../../lib/physicsUtils';
import { NodeType, STOPPING_CONDITIONS_NODE_ID } from '../../../lib/simulationTree/node';
import { isSimulationTransient } from '../../../lib/simulationUtils';
import { newStopCond } from '../../../lib/stoppingCondsUtils';
import { defaultNodeFilter, mapVisualizerEntitiesToVolumes } from '../../../lib/subselectUtils';
import { useOutput } from '../../../model/hooks/useOutput';
import * as basepb from '../../../proto/base/base_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { OutputIncludes } from '../../../proto/frontend/output/output_pb';
import * as feoutputpb from '../../../proto/frontend/output/output_pb';
import * as outputpb from '../../../proto/output/output_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { initializeNewNode, useSetNewNodes } from '../../../recoil/nodeSession';
import { useOutputNodes } from '../../../recoil/outputNodes';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useEnabledExperiments } from '../../../recoil/useExperimentConfig';
import { useMeshReadyState } from '../../../recoil/useMeshReadyState';
import { useStoppingConditions } from '../../../recoil/useStoppingConditions';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useWorkflowState } from '../../../recoil/workflowState';
import { useSimulationParamScope } from '../../../state/external/project/simulation/paramScope';
import { useIsSetupOrAdvancedView } from '../../../state/internal/global/currentView';
import { ActionButton } from '../../Button/ActionButton';
import Form from '../../Form';
import { DataSelect } from '../../Form/DataSelect';
import LabeledInput from '../../Form/LabeledInput';
import { RadioButtonGroup } from '../../Form/RadioButtonGroup';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import DataNameSelect from '../../Paraview/DataNameSelect';
import { createStyles, makeStyles } from '../../Theme';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useIsExplorationSetup } from '../../hooks/exploration/useCreateExploration';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { Link } from '../../notification/PanelLinks';
import { SectionMessage } from '../../notification/SectionMessage';
import { AttributesDisplay } from '../AttributesDisplay';
import NodeLink from '../NodeLink';
import { NodeSubselect } from '../NodeSubselect';
import NodeTable from '../NodeTable';
import PropertiesEmptyState from '../PropertiesEmptyState';
import PropertiesSection from '../PropertiesSection';

import { ExpressionPanel } from './output/ExpressionPanel';
import { ForcePanel } from './output/ForcePanel';
import { ResidualOutputSettings } from './output/ResidualOutputSettings';
import { AverageSettings } from './output/shared/AverageSettings';
import { InnerIterationContent } from './output/shared/InnerIterationContent';
import { OutputSurfaces } from './output/shared/OutputSurfaces';
import { PhysicsSelection } from './output/shared/PhysicsSelection';

const useStyles = makeStyles(
  () => createStyles({
    centeredText: {
      width: '80%',
      color: colors.lowEmphasisText,
      alignSelf: 'center',
      textAlign: 'center',
      fontSize: '13px',
    },
    sectionHeading: {
      fontSize: '13px',
      fontWeight: 600,
      color: colors.neutral650,
    },
    stoppingCond: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '8px',
    },
    existingStopCond: {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      overflow: 'hidden',
      gap: '18px',
      color: colors.lowEmphasisText,
    },
  }),
  { name: 'OutputPropPanel' },
);

const {
  SPACE_AREA_AVERAGING,
  SPACE_MASS_FLOW_AVERAGING,
  SPACE_NO_AVERAGING,
  INVALID_SPACE_AVERAGING_TYPE,
} = outputpb.SpaceAveragingType;
const { VOLUME_AVERAGING, VOLUME_MAXIMUM, VOLUME_MINIMUM } = outputpb.VolumeReductionType;

// Options for how the output is averaged.
const spatialAveragingOptions: RadioButtonOption<outputpb.SpaceAveragingType>[] = [
  {
    value: SPACE_AREA_AVERAGING,
    help: 'Average using the area of each face divided by the total area.',
    label: 'Area',
  },
  {
    value: SPACE_MASS_FLOW_AVERAGING,
    help: 'Average using the absolute mass flow at each face divided by the ' +
      'total absolute mass flow.',
    label: 'Mass Flow',
  },
];

interface StoppingConditionSpec {
  cond: feoutputpb.StoppingCondition;
  index: number;
}

// Find all stopping conditions that use this output node
function allStopCondsUsed(
  stopConds: feoutputpb.StoppingConditions,
  node: feoutputpb.OutputNode,
): StoppingConditionSpec[] {
  return stopConds.cond.map((cond, index) => ({ cond, index })).filter(
    (spec) => spec.cond.node?.id === node.id,
  );
}

function getOutputQuantitySize(outputNode: feoutputpb.OutputNode) {
  switch (outputNode.nodeProps.case) {
    case 'force':
    case 'pointProbe':
    case 'surfaceAverage':
    case 'volumeReduction':
      return getQuantitySize(outputNode.nodeProps.value.quantityType);
    default:
      return 1;
  }
}

function getVectorComponent(node: feoutputpb.OutputNode): basepb.Vector3Component | undefined {
  switch (node.nodeProps.case) {
    case 'pointProbe':
    case 'surfaceAverage':
    case 'volumeReduction':
      return node.nodeProps.value.vectorComponent;
    default:
      return undefined;
  }
}

function setVectorComponent(node: feoutputpb.OutputNode, value: basepb.Vector3Component): void {
  switch (node.nodeProps.case) {
    case 'pointProbe':
    case 'surfaceAverage':
    case 'volumeReduction':
      node.nodeProps.value.vectorComponent = value;
      break;
    default:
      throw Error('Undefined nodes props case for vector component');
  }
}

function getReductionTypeName(type: outputpb.VolumeReductionType): string {
  switch (type) {
    case VOLUME_AVERAGING:
      return 'Volume Averaging';
    case VOLUME_MAXIMUM:
      return 'Maximum';
    case VOLUME_MINIMUM:
      return 'Minimum';
    default:
      throw Error('Unrecognized volume reduction type');
  }
}

function isValidNodePropsCase(outputNode: feoutputpb.OutputNode): boolean {
  switch (outputNode.nodeProps.case) {
    case 'basic':
    case 'derived':
    case 'force':
    case 'pointProbe':
    case 'residual':
    case 'surfaceAverage':
    case 'volumeReduction': {
      return true;
    }
    default: {
      return false;
    }
  }
}

interface ComponentSelectProps {
  outputNode: feoutputpb.OutputNode;
  projectId: string;
}

const ComponentSelect = (props: ComponentSelectProps) => {
  const { outputNode, projectId } = props;
  const { updateOutputNode } = useOutput(projectId, outputNode.id);

  const selectedVectorNode = getVectorComponent(outputNode);

  const buildOption = (vectorComponent: basepb.Vector3Component) => ({
    name: selectedVectorNode ? componentSuffix(vectorComponent) : '',
    value: vectorComponent,
    selected: selectedVectorNode === vectorComponent,
  });

  const options: SelectOption<basepb.Vector3Component>[] = [
    basepb.Vector3Component.VECTOR_3_COMPONENT_X,
    basepb.Vector3Component.VECTOR_3_COMPONENT_Y,
    basepb.Vector3Component.VECTOR_3_COMPONENT_Z,
  ].map(buildOption);

  const changeComponent = (component: basepb.Vector3Component) => {
    updateOutputNode((newOutput) => setVectorComponent(newOutput, component));
  };

  return (
    <LabeledInput label="">
      <DataSelect
        asBlock
        onChange={changeComponent}
        options={options}
        size="small"
      />
    </LabeledInput>
  );
};

interface OutputVolumesProps {
  outputNode: feoutputpb.OutputNode;
  projectId: string;
}

const OutputVolumes = (props: OutputVolumesProps) => {
  const { outputNode, projectId } = props;
  const { updateOutputNode } = useOutput(projectId, outputNode.id);
  const [, setOutputNodes] = useOutputNodes(projectId, '', '');
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  const mapVisualizerEntities = useCallback(
    (ids: string[]) => mapVisualizerEntitiesToVolumes(ids, staticVolumes),
    [staticVolumes],
  );

  /** Memoize the nodeFilter object passed to NodeSubselect to avoid infinite looping */
  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType === NodeType.VOLUME) {
      return {
        related: true,
      };
    }
    if (nodeType === NodeType.SURFACE_GROUP) {
      const domains = geometryTags.domainsFromTagEntityGroupId(nodeId);
      if (domains?.length) {
        return {
          related: true,
          disabled: false,
        };
      }
    }
    return defaultNodeFilter(nodeType);
  }, [geometryTags]);

  const setVolumeIds = useCallback(async (volumeIds: string[]) => {
    // NOTE: analyzer is fine with using the node IDs ("volume-") domains here.
    setOutputNodes((oldOutputNodes) => {
      const newOutputNodes = oldOutputNodes.clone();
      const newNode = findOutputNodeById(newOutputNodes, outputNode.id)!;
      newNode.inSurfaces = volumeIds;
      return newOutputNodes;
    });
  }, [setOutputNodes, outputNode.id]);

  return (
    <>
      <NodeSubselect
        id="output-volume-selection"
        independentSelection
        labels={['volumes']}
        mapVisualizerEntities={mapVisualizerEntities}
        nodeFilter={nodeFilter}
        nodeIds={outputNode.inSurfaces}
        onChange={setVolumeIds}
        referenceNodeIds={[outputNode.id]}
        showNotFoundNodes
        title="Volumes"
        visibleTreeNodeTypes={[NodeType.VOLUME, NodeType.TAGS_BODY, NodeType.TAGS_CONTAINER]}
      />
      <LabeledInput
        help="Select the calculation type for multiple volumes"
        label="Calculation">
        <DataSelect
          asBlock
          onChange={(newType) => updateOutputNode((newOutput) => {
            newOutput.calcType = newType;
          })}
          options={calcForceOptions(true, outputNode.calcType)}
          size="small"
        />
      </LabeledInput>
    </>
  );
};

interface VolumeReductionContentProps {
  jobActive: boolean;
  jobId: string;
  outputNode: feoutputpb.OutputNode;
  param: simulationpb.SimulationParam;
  projectId: string;
}

const VolumeReductionContent = (props: VolumeReductionContentProps) => {
  const { jobActive, jobId, outputNode, param, projectId } = props;

  assert(
    outputNode.nodeProps.case === 'volumeReduction',
    'Volume reduction content requires a volume reduction output',
  );
  const nodeId = outputNode.id;
  const volumeReduction = outputNode.nodeProps.value;

  const { updateOutputNode } = useOutput(projectId, nodeId);

  const buildReductionTypeOption = (type: outputpb.VolumeReductionType) => ({
    name: getReductionTypeName(type),
    value: type,
    selected: volumeReduction.props?.reductionType === type,
  });

  const options: SelectOption<outputpb.VolumeReductionType>[] = [
    VOLUME_AVERAGING,
    VOLUME_MAXIMUM,
    VOLUME_MINIMUM,
  ].map(buildReductionTypeOption);

  return (
    <>
      <LabeledInput
        label="Reduction Type">
        <DataSelect
          asBlock
          onChange={(newType) => updateOutputNode((newOutput) => {
            assert(
              newOutput.nodeProps.case === 'volumeReduction',
              'Reduction type may only be set on a volume reduction output',
            );
            if (newOutput.nodeProps.value.props) {
              newOutput.nodeProps.value.props.reductionType = newType;
            }
          })}
          options={options}
          size="small"
        />
      </LabeledInput>
      <LabeledInput
        help=""
        label="Include">
        <Form.MultiCheckBox
          checkBoxProps={getIncludeOptions(outputNode, updateOutputNode)}
        />
      </LabeledInput>
      <InnerIterationContent
        jobActive={jobActive}
        jobId={jobId}
        outputNode={outputNode}
        param={param}
        projectId={projectId}
        toolTip="Display a plot showing inner iteration values."
      />
    </>
  );
};

interface SurfaceAverageContentProps {
  outputNode: feoutputpb.OutputNode;
  projectId: string;
}

const SurfaceAverageContent = (props: SurfaceAverageContentProps) => {
  const { outputNode, projectId } = props;

  assert(outputNode.nodeProps.case === 'surfaceAverage', 'Node must contain surfaceAverageNode');
  const quantity = getOutputQuantity(outputNode);
  const { updateOutputNode } = useOutput(projectId, outputNode.id);

  const surfaceAverageNode = outputNode.nodeProps.value;
  const surfaceAverageProps = surfaceAverageNode.props!;
  if (!surfaceAverageNode) {
    throw Error('Node must contain surfaceAverageNode');
  }

  const noAverage = (
    quantity === QuantityType.ABS_MASS_FLOW ||
    quantity === QuantityType.MASS_FLOW ||
    quantity === QuantityType.AREA ||
    quantity === QuantityType.DISK_ROTATION_RATE
  );
  if (noAverage) {
    surfaceAverageProps.averagingType = SPACE_NO_AVERAGING;
  }

  return (
    <>
      {!noAverage && (
        <LabeledInput
          help="Select the averaging method"
          label="Spatial Averaging">
          <RadioButtonGroup
            kind="secondary"
            name="spatialAveraging"
            onChange={(newType) => updateOutputNode((newOutput) => {
              assert(
                newOutput.nodeProps.case === 'surfaceAverage',
                'Averaging type may only be set on a surface average output',
              );
              if (newOutput.nodeProps.value.props) {
                newOutput.nodeProps.value.props.averagingType = newType;
              }
            })}
            options={spatialAveragingOptions}
            value={surfaceAverageProps.averagingType || INVALID_SPACE_AVERAGING_TYPE}
          />
        </LabeledInput>
      )}
      <LabeledInput label="Include">
        <Form.MultiCheckBox
          checkBoxProps={getIncludeOptions(outputNode, updateOutputNode)}
        />
      </LabeledInput>
    </>
  );
};

interface PointProbeContentProps {
  outputNode: feoutputpb.OutputNode;
  projectId: string;
}

const PointProbeContent = (props: PointProbeContentProps) => {
  const { outputNode, projectId } = props;

  assert(outputNode.nodeProps.case === 'pointProbe', 'Node must contain pointProbeNode');
  const { updateOutputNode } = useOutput(projectId, outputNode.id);

  const includeOptions = getIncludeOptions(outputNode, updateOutputNode);

  // Addresses LC-14934: if a user creates a point probe for a given variable, we want to capture
  // that variable. We do not want this checkbox. However, if an existing project has this box
  // unchecked, we do not want the user to be unable to check this box. Once they do however,
  // the box should disappear. We anticipate that the effect of this change is minimal, as most
  // point probes are used to monitor the given value.
  if (includeOptions[0].checked) {
    return null;
  }

  return (
    <LabeledInput label="Include">
      <Form.MultiCheckBox
        checkBoxProps={includeOptions}
      />
    </LabeledInput>
  );
};

interface DerivedContentProps {
  outputNode: feoutputpb.OutputNode;
  projectId: string;
}

const DerivedContent = (props: DerivedContentProps) => {
  const { outputNode, projectId } = props;

  const { updateOutputNode } = useOutput(projectId, outputNode.id);

  return (
    <LabeledInput label="Include">
      <Form.MultiCheckBox
        checkBoxProps={[createIncludeOption(
          [OutputIncludes.OUTPUT_INCLUDE_BASE],
          'Base Value',
          outputNode,
          updateOutputNode,
        )]}
      />
    </LabeledInput>
  );
};

const getOutputNodeTypeDisplay = (type: feoutputpb.OutputNode_Type) => {
  switch (type) {
    case feoutputpb.OutputNode_Type.DERIVED_OUTPUT_TYPE:
      return 'Custom Output';
    case feoutputpb.OutputNode_Type.GLOBAL_OUTPUT_TYPE:
      return 'Global Output';
    case feoutputpb.OutputNode_Type.POINT_OUTPUT_TYPE:
      return 'Point Output';
    case feoutputpb.OutputNode_Type.SURFACE_OUTPUT_TYPE:
      return 'Surface Output';
    case feoutputpb.OutputNode_Type.VOLUME_OUTPUT_TYPE:
      return 'Volume Output';
    default:
      throw Error('Undefined output type');
  }
};

interface QuantitySelectionProps {
  outputNode: feoutputpb.OutputNode;
  outputNodes: feoutputpb.OutputNodes;
  param: simulationpb.SimulationParam;
  projectId: string;
}

// Returns the menu to select the quantity type
const QuantitySelection = (props: QuantitySelectionProps) => {
  // == Props
  const { outputNode, outputNodes, param, projectId } = props;

  // == Contexts
  const { workflowId, jobId } = useProjectContext();

  // == Recoil
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const experimentConfig = useEnabledExperiments();
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  const { updateOutputNode } = useOutput(projectId, outputNode.id);

  const choiceCategories = choicesForType(outputNode.type);
  const allChoices = protoCategoriesToSelectOptions(choiceCategories, outputNode.choice);

  const selectedOption = allChoices.find((choice) => choice.value === outputNode.choice);

  return (
    <LabeledInput
      help="Select the quantity to output"
      label="Quantity">
      <DataNameSelect
        asBlock
        disabled={!meshReadyState}
        displayNoneOption={false}
        innerMenuPosition="right-down"
        innerMenuPositionTransform={{ top: -9 }}
        locator="Quantity"
        onChange={(newName) => {
          const choice = allChoices.find((item) => item.name === newName)?.value;

          if (choice === undefined) {
            return;
          }

          updateOutputNode((newOutput) => {
            // if the new output quantity is the same as the old one, do nothing.
            if (newOutput.choice !== choice) {
              changeOutputType(
                choice,
                outputNode.type,
                newOutput,
                param,
                experimentConfig,
                geometryTags,
                staticVolumes,
              );
              setDefaultName(newOutput, outputNodes);
            }
          });
        }}
        options={allChoices.map(({ name }) => name)}
        position="below-left"
        positionTransform={{ left: -3, top: 1 }}
        value={selectedOption?.name || 'None'}
      />
    </LabeledInput>
  );
};

interface StoppingConditionsContentProps {
  outputNode: feoutputpb.OutputNode;
  disabled: boolean;
}

const StoppingConditionsContent = (props: StoppingConditionsContentProps) => {
  const { disabled, outputNode } = props;

  const { setSelection } = useSelectionContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  const [
    stoppingConditions,
    setStoppingConditions,
  ] = useStoppingConditions(projectId, workflowId, jobId);
  const localClasses = useStyles();
  const isExplorationSetup = useIsExplorationSetup();
  const usedStoppingConditions = allStopCondsUsed(stoppingConditions, outputNode);

  // Adds a new stopping condition that uses this node and selects the stopping condition node
  const createStopCond = () => {
    const newStopConds = stoppingConditions.clone();
    const newCond = newStopCond();
    if (outputNode.nodeProps.case === 'residual') {
      newCond.threshold = 1e-5;
    }
    newCond.node = outputNode.clone();
    newCond.nAnalysisIters = outputNode.analysisIters;
    newCond.nIterations = outputNode.averageIters;
    newStopConds.cond.push(newCond);
    setStoppingConditions(newStopConds);
    setSelection([STOPPING_CONDITIONS_NODE_ID]);
  };

  return (
    <>
      {!usedStoppingConditions.length && (
        <ActionButton
          disabled={disabled}
          kind="minimal"
          onClick={createStopCond}
          size="small">
          Add
        </ActionButton>
      )}
      {usedStoppingConditions.map((spec) => (
        <div className={localClasses.existingStopCond} key={spec.index}>
          {isExplorationSetup ? `Stopping Condition ${spec.index + 1}` : (
            <NodeLink
              nodeIds={[STOPPING_CONDITIONS_NODE_ID]}
              text={`Stopping Condition ${spec.index + 1}`}
            />
          )}
        </div>
      ))}
    </>
  );
};

interface OutputTypeButtonProps {
  outputNode: feoutputpb.OutputNode;
  outputNodes: feoutputpb.OutputNodes;
  projectId: string;
  param: simulationpb.SimulationParam;
  paramScope: ParamScope;
  label: string;
  help: string;
  type: feoutputpb.OutputNode_Type;
}

const OutputTypeButton = (props: OutputTypeButtonProps) => {
  const { help, label, outputNode, outputNodes, param, paramScope, projectId, type } = props;

  const { updateOutputNode } = useOutput(projectId, outputNode.id);

  const experimentConfig = useEnabledExperiments();
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  return (
    <ActionButton
      kind="secondary"
      onClick={() => {
        updateOutputNode((newOutput) => {
          newOutput.type = type;
          const choices = nodeSelectorChoices(type, paramScope);
          changeOutputType(
            defaultChoice(type, choices, !!findFarfield(param)),
            type,
            newOutput,
            param,
            experimentConfig,
            geometryTags,
            staticVolumes,
          );
          setDefaultFrameId(newOutput, param);
          setDefaultName(newOutput, outputNodes);
        });
      }}
      size="small"
      title={help}>
      {label}
    </ActionButton>
  );
};

interface OutputTypeSelectionProps {
  outputNode: feoutputpb.OutputNode;
  outputNodes: feoutputpb.OutputNodes;
  projectId: string;
  param: simulationpb.SimulationParam;
  paramScope: ParamScope;
}

const OutputTypeSelection = (props: OutputTypeSelectionProps) => (
  <PropertiesEmptyState
    iconName="target"
    subtitle="Select the output type."
    title="New Output">
    <OutputTypeButton
      {...props}
      help=""
      label="Point"
      type={feoutputpb.OutputNode_Type.POINT_OUTPUT_TYPE}
    />
    <OutputTypeButton
      {...props}
      help="Integrated output over surfaces"
      label="Surface"
      type={feoutputpb.OutputNode_Type.POINT_OUTPUT_TYPE}
    />
    <OutputTypeButton
      {...props}
      help="Global outputs"
      label="Global"
      type={feoutputpb.OutputNode_Type.POINT_OUTPUT_TYPE}
    />
  </PropertiesEmptyState>
);

interface DisabledMessageProps {
  outputNode: feoutputpb.OutputNode;
  reason: string;
}

const DisabledMessage = (props: DisabledMessageProps) => {
  const { outputNode, reason } = props;

  const isSetupOrAdvancedView = useIsSetupOrAdvancedView();
  const { setSelection } = useSelectionContext();

  const { simParam, saveParamAsync } = useSimulationConfig();

  const setNewNodes = useSetNewNodes();

  const quantity = getOutputQuantity(outputNode);
  const allFluidPhysics = getFluidPhysics(simParam);

  const addFarfieldBound = async (physicsId: string) => {
    const nodeId = await saveParamAsync((newParam) => {
      const newBoundCond = appendFluidBoundaryCondition(
        newParam,
        physicsId,
        simulationpb.PhysicalBoundary.FARFIELD,
      );
      return newBoundCond.boundaryConditionName;
    });

    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
    setSelection([nodeId]);
  };

  const links: Link[] = [];

  if (
    quantity &&
    outputNode.nodeProps.case !== 'derived' &&
    isSetupOrAdvancedView &&
    needsFarfield(getQuantityTags(quantity)) &&
    !findFarfield(simParam)
  ) {
    const singleFluid = allFluidPhysics.length === 1;
    links.push(
      ...allFluidPhysics.map((physics) => {
        const physicsName = getPhysicsName(physics, simParam);
        const label = singleFluid ?
          'Define far-field' :
          `Define far-field on ${physicsName}`;
        return {
          label,
          onClick: async () => addFarfieldBound(getPhysicsId(physics)),
        };
      }),
    );
  }

  return (
    <SectionMessage
      key="disabled-warning"
      level="warning"
      links={links}>
      {parseString(reason)}
    </SectionMessage>
  );
};

interface SurfaceAverageOutputSettingsProps extends BaseSettingsProps {
  showConvergenceMonitor: boolean;
  warnStoppingCondition?: StoppingConditionSpec;
}

const SurfaceAverageOutputSettings = (props: SurfaceAverageOutputSettingsProps) => {
  const { outputNode, projectId, showConvergenceMonitor, warnStoppingCondition } = props;

  const nodeId = outputNode.id;

  return (
    <>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Options"
          nodeId={nodeId}
          panelName="options">
          <SurfaceAverageContent
            outputNode={outputNode}
            projectId={projectId}
          />
          <AverageSettings
            outputNode={outputNode}
            projectId={projectId}
            showConvergenceMonitor={showConvergenceMonitor}
            warnStoppingCondition={warnStoppingCondition}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Surfaces"
          nodeId={nodeId}
          panelName="surface">
          <OutputSurfaces
            idBase="average"
            outputNode={outputNode}
            projectId={projectId}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </>
  );
};

interface DerivedOutputSettingsProps extends BaseSettingsProps {
  showConvergenceMonitor: boolean;
  warnStoppingCondition?: StoppingConditionSpec;
}

const DerivedOutputSettings = (props: DerivedOutputSettingsProps) => {
  const { outputNode, projectId, showConvergenceMonitor, warnStoppingCondition } = props;

  const nodeId = outputNode.id;

  return (
    <>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Options"
          nodeId={nodeId}
          panelName="options">
          <DerivedContent outputNode={outputNode} projectId={projectId} />
          <AverageSettings
            outputNode={outputNode}
            projectId={projectId}
            showConvergenceMonitor={showConvergenceMonitor}
            warnStoppingCondition={warnStoppingCondition}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </>
  );
};

interface PointProbeOutputSettingsProps extends BaseSettingsProps {
  showConvergenceMonitor: boolean;
  warnStoppingCondition?: StoppingConditionSpec;
}

const PointProbeOutputSettings = (props: PointProbeOutputSettingsProps) => {
  const { outputNode, projectId, showConvergenceMonitor, warnStoppingCondition } = props;

  const { activeNodeTable } = useSelectionContext();

  const nodeId = outputNode.id;

  return (
    <>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Options"
          nodeId={nodeId}
          panelName="options">
          <PointProbeContent
            outputNode={outputNode}
            projectId={projectId}
          />
          <AverageSettings
            outputNode={outputNode}
            projectId={projectId}
            showConvergenceMonitor={showConvergenceMonitor}
            warnStoppingCondition={warnStoppingCondition}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={activeNodeTable.type === NodeTableType.POINTS}
          heading="Geometry"
          nodeId={nodeId}
          panelName="surface">
          <NodeTable
            editable
            key="points"
            nodeIds={outputNode.inSurfaces}
            tableId="output-point-probe-points"
            tableType={NodeTableType.POINTS}
            title="Points"
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </>
  );
};

interface VolumeReductionOutputSettingsProps extends BaseSettingsProps {
  jobActive: boolean;
  jobId: string;
  param: simulationpb.SimulationParam;
  showConvergenceMonitor: boolean;
  warnStoppingCondition?: StoppingConditionSpec;
}

const VolumeReductionOutputSettings = (props: VolumeReductionOutputSettingsProps) => {
  const {
    jobActive, jobId, outputNode, param, projectId,
    showConvergenceMonitor, warnStoppingCondition,
  } = props;

  const { activeNodeTable } = useSelectionContext();

  const nodeId = outputNode.id;

  return (
    <>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Options"
          nodeId={nodeId}
          panelName="options">
          <VolumeReductionContent
            jobActive={jobActive}
            jobId={jobId}
            outputNode={outputNode}
            param={param}
            projectId={projectId}
          />
          <AverageSettings
            outputNode={outputNode}
            projectId={projectId}
            showConvergenceMonitor={showConvergenceMonitor}
            warnStoppingCondition={warnStoppingCondition}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={activeNodeTable.type === NodeTableType.VOLUMES}
          heading="Geometry"
          nodeId={nodeId}
          panelName="volume">
          <OutputVolumes
            outputNode={outputNode}
            projectId={projectId}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </>
  );
};

// A panel displaying the settings for the output.
export const OutputPropPanel = () => {
  const { isTreeModal, selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected output row');

  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  const [outputNodes] = useOutputNodes(projectId, '', '');
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);

  const localClasses = useStyles();
  const commonClasses = useCommonTreePropsStyles();

  const outputNode = findOutputNodeById(outputNodes, node.id);
  assert(!!outputNode, 'No selected output');
  assert(isValidNodePropsCase(outputNode), 'Invalid NodePropsCase');

  const { simParam } = useSimulationConfig();
  const staticVolumes = useStaticVolumes(projectId);

  const [stopConds] = useStoppingConditions(projectId, workflowId, jobId);
  const jobStatusType = useWorkflowState(projectId, workflowId)?.status?.typ;

  const jobActive = jobStatusType === basepb.JobStatusType.Active;
  const outputNodeWarnings = getOutputNodeWarnings(
    outputNode,
    outputNodes,
    simParam,
    entityGroupData,
    paramScope,
    staticVolumes,
    geometryTags,
    outputNodes.referenceValues?.referenceValueType,
    true,
    [],
  );

  const nodePropsCase = outputNode.nodeProps.case;
  const disabled = !!outputNodeWarnings.length || readOnly || isTreeModal;

  const outputType = outputNode.type;

  const quantitySize = getOutputQuantitySize(outputNode);
  const condsUsed = allStopCondsUsed(stopConds, outputNode);
  const showConvergenceMonitor = !isSimulationTransient(simParam);

  const showStopCondWarning = (
    readOnly &&
    condsUsed.length > 0 &&
    isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_MAX_DEV) &&
    (
      condsUsed[0].cond.nIterations !== outputNode.averageIters ||
      condsUsed[0].cond.nAnalysisIters !== outputNode.analysisIters
    )
  );

  const warnStoppingCondition = showStopCondWarning ? condsUsed[0] : undefined;
  const isDerived = (nodePropsCase === 'derived');
  const showStopConds = STOP_COND_OUTPUT_NODES.includes(nodePropsCase);

  // If the output node has no type yet, show the buttons to select the type.
  if (!outputType) {
    return (
      <OutputTypeSelection
        outputNode={outputNode}
        outputNodes={outputNodes}
        param={simParam}
        paramScope={paramScope}
        projectId={projectId}
      />
    );
  }
  const hasPorousInterface = (
    nodePropsCase === 'force' && outputNode.nodeProps.value.props?.porous
  );
  const hasPorousWarning = (
    nodePropsCase === 'force' &&
    outputType === feoutputpb.OutputNode_Type.SURFACE_OUTPUT_TYPE &&
    hasPorousInterface
  );
  const porousWarning: HelpfulIconSpec | undefined = hasPorousWarning ? {
    name: 'warning',
    color: colors.yellow600,
    tooltip: 'This output force includes surfaces that bound a porous volume. The direction ' +
      'of the force is reversed to represent the force imparted by the fluid on the porous ' +
      'media.',
  } : undefined;

  return (
    <div className={commonClasses.properties}>
      <AttributesDisplay
        attributes={[{
          label: 'Type',
          value: getOutputNodeTypeDisplay(outputType),
          icon: porousWarning,
        }]}
      />
      <Divider />
      <PropertiesSection>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          {isDerived ? (
            <>
              <ExpressionPanel
                error={!!outputNodeWarnings.length}
                outputNode={outputNode}
                outputNodes={outputNodes}
                projectId={projectId}
              />
              {!!outputNodeWarnings.length && (
                <div style={{ color: colors.red600 }}>{outputNodeWarnings[0]}</div>)}
            </>
          ) : (
            <>
              {!!outputNodeWarnings.length && (
                <DisabledMessage
                  outputNode={outputNode}
                  reason={outputNodeWarnings[0]}
                />
              )}
              <QuantitySelection
                outputNode={outputNode}
                outputNodes={outputNodes}
                param={simParam}
                projectId={projectId}
              />
              {quantitySize === DIM3D && (
                <ComponentSelect
                  outputNode={outputNode}
                  projectId={projectId}
                />
              )}
              {nodePropsCase === 'residual' && (
                <PhysicsSelection outputNode={outputNode} />
              )}
            </>
          )}
        </div>
      </PropertiesSection>
      {(nodePropsCase === 'force') && (
        <ForcePanel
          outputNode={outputNode}
          param={simParam}
          projectId={projectId}
          showConvergenceMonitor={showConvergenceMonitor}
          warnStoppingCondition={warnStoppingCondition}
        />
      )}
      {(nodePropsCase === 'residual') && (
        <ResidualOutputSettings
          jobActive={jobActive}
          jobId={jobId}
          outputNode={outputNode}
          param={simParam}
          projectId={projectId}
        />
      )}
      {(nodePropsCase === 'surfaceAverage') && (
        <SurfaceAverageOutputSettings
          outputNode={outputNode}
          projectId={projectId}
          showConvergenceMonitor={showConvergenceMonitor}
          warnStoppingCondition={warnStoppingCondition}
        />
      )}
      {(nodePropsCase === 'derived') && (
        <DerivedOutputSettings
          outputNode={outputNode}
          projectId={projectId}
          showConvergenceMonitor={showConvergenceMonitor}
          warnStoppingCondition={warnStoppingCondition}
        />
      )}
      {(nodePropsCase === 'pointProbe') && (
        <PointProbeOutputSettings
          outputNode={outputNode}
          projectId={projectId}
          showConvergenceMonitor={showConvergenceMonitor}
          warnStoppingCondition={warnStoppingCondition}
        />
      )}
      {(nodePropsCase === 'volumeReduction') && (
        <VolumeReductionOutputSettings
          jobActive={jobActive}
          jobId={jobId}
          outputNode={outputNode}
          param={simParam}
          projectId={projectId}
          showConvergenceMonitor={showConvergenceMonitor}
          warnStoppingCondition={warnStoppingCondition}
        />
      )}
      {showStopConds && (
        <>
          <Divider />
          <PropertiesSection>
            <div className={localClasses.stoppingCond}>
              <div className={localClasses.sectionHeading}>Stopping Condition</div>
              <StoppingConditionsContent
                disabled={disabled}
                outputNode={outputNode}
              />
            </div>
          </PropertiesSection>
        </>
      )}
    </div>
  );
};
