// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVGlyphSamplingMode, LCVType } from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';

import { RgbColor } from '../../../designSystem';
import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { GlyphState } from './filterUtils';

/**
 * A filter for computing a glyph (vector)
 */
export class LcvGlyph extends LcvFilter {
  parent: LcvFilter;
  state: GlyphState;
  color: RgbColor | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: GlyphState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'vector_glyph',
        `vector_glyph_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'glyph',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  /**
   * Pass the object params into LCVis
   */
  updateParams() {
    const {
      vector_field,
      glyph_scale_size,
      fixed_size_glyphs,
      every_nth_sampling,
      n_glyphs,
    } = this.state;
    // Filter execution params
    this.setParam(
      'glyph_sampling_mode',
      LCVType.kLCVDataTypeUint,
      every_nth_sampling ?
        LCVGlyphSamplingMode.kLCVGlyphSamplingModeEveryNth :
        LCVGlyphSamplingMode.kLCVGlyphSamplingModeUniform,
    );
    this.setParam('n_glyphs', LCVType.kLCVDataTypeUint, n_glyphs);

    // Filter appearance params. Later these can be split to not be behind the
    // edit/apply process that is used for filter execution params so they can update live
    this.setAppearanceParam('vector_field', LCVType.kLCVDataTypeString, vector_field);
    this.setAppearanceParam('glyph_scale_size', LCVType.kLCVDataTypeFloat, glyph_scale_size);
    this.setAppearanceParam(
      'fixed_size_glyphs',
      LCVType.kLCVDataTypeUint,
      fixed_size_glyphs ? 1 : 0,
    );
  }

  setState(newState: GlyphState) {
    if (deepEqual(this.state, newState)) {
      return;
    }
    this.state = newState;
    this.updateParams();
  }
}
