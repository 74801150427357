// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { CurrentView } from '../../../lib/componentTypes/context';

/**
 * The current view. It is being set separately from each one of the top project pages.
 */
export const currentViewState = atom<CurrentView>({
  key: 'currentView',
  default: CurrentView.SETUP,
});

export const useSetCurrentView = () => useSetRecoilState(currentViewState);

export const useCurrentView = () => useRecoilValue(currentViewState);

export const useIsAnalysisView = () => useRecoilValue(currentViewState) === CurrentView.ANALYSIS;

export const useIsGeometryView = () => useRecoilValue(currentViewState) === CurrentView.GEOMETRY;

export const useIsResultsView = () => useRecoilValue(currentViewState) === CurrentView.RESULTS;

export const useIsSetupView = () => useRecoilValue(currentViewState) === CurrentView.SETUP;

export const useIsAdvancedAnalysisView = () => (
  useRecoilValue(currentViewState) === CurrentView.ADVANCED_ANALYSIS
);

export const useIsSetupOrAdvancedView = () => {
  const currentView = useCurrentView();
  return [
    CurrentView.SETUP,
    CurrentView.ADVANCED_ANALYSIS,
  ].includes(currentView);
};
