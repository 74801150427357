// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { EditSource } from '../../../../lib/visUtils';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useLcVisEnabledState } from '../../../../recoil/lcvis/lcvisEnabledState';
import { useViewStateOverflow } from '../../../../recoil/lcvis/viewStateOverflow';
import { useEditState } from '../../../../recoil/paraviewState';
import { ActionButton } from '../../../Button/ActionButton';
import Form from '../../../Form';
import Checkbox from '../../../Form/CheckBox';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberInput } from '../../../Form/NumberInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import PropertiesSection from '../../PropertiesSection';
import { CommonFilterMessages } from '../shared/CommonFilterMessages';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

// "Glyph" is the name that Paraview calls this filters and how it is
// described throughout our codebase, but to our users it is displayed as
// "Vector".

// Get a best-effort default scale for the glyph using the size of the parent node's
// bounds and the maximum values of the requested data.
function defaultGlyphScale(
  parent: ParaviewRpc.TreeNode,
  data: ParaviewRpc.ArrayInformation | undefined,
): number {
  if (!parent.bounds || !data) {
    // Arbitrary value since we don't have anything better to go off of.
    return 0.0005;
  }
  // Best effort to scale to size of the parent node.  The reason I use the
  // maximum dimension, rather than the volume, for example, is that the parent
  // node of the Glyph may be 3d or 2d (like a clip or slice).
  const maxDim = Math.max(
    parent.bounds[1] - parent.bounds[0],
    parent.bounds[3] - parent.bounds[2],
    parent.bounds[5] - parent.bounds[4],
  );
  // Using the max data value rather than something else does mean that for data
  // with outliers on the upper end, most of the vectors may be pretty small, but
  // there are limits to how well we can do only having the min and max of each dimension.
  const maxDataVal = Math.max(data.range[0][1], data.range[1][1], data.range[2][1]);
  if (maxDataVal === 0) {
    return 0.0005;
  }
  // The constant factor of 8 was chosen using trial-and-error.
  return maxDim / (8 * maxDataVal);
}

/** Create the param filled with default values, to be used when creating a new filter. */
export function newGlyphParam(
  defaultField: ParaviewRpc.ArrayInformation,
  parent: ParaviewRpc.TreeNode,
): ParaviewRpc.GlyphParam {
  return {
    typ: ParaviewRpc.TreeNodeType.GLYPH,
    dataName: defaultField.name,
    everyNth: true,
    fixedLength: false,
    samplePoints: 500,
    scaleFactor: defaultGlyphScale(parent, defaultField),
  };
}

type GlyphProps = Omit<FilterPropertiesPanelProps, 'viewState'>;

// Parameter-input dialog for the glyph filter.
export const GlyphPropPanel = (props: GlyphProps) => {
  const { displayProps, filterNode, nodeId, parentFilterNode } = props;

  const [editState] = useEditState();
  const { updateEditState } = useSelectedFilterNode();
  const onUpdate = (newParam: ParaviewRpc.TreeNodeParam) => (
    updateEditState({
      editSource: EditSource.FORM,
      param: newParam,
    })
  );

  const { projectId, workflowId, jobId } = useProjectContext();
  const [lcVisEnabled] = useLcVisEnabledState(projectId);
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });

  const param = props.param as ParaviewRpc.GlyphParam;
  const readOnly = !editState;

  let data = lcVisEnabled ? lcvisData.data : parentFilterNode.pointData;

  data = data.filter(
    (item: ParaviewRpc.ArrayInformation) => item.dim === 3,
  );

  const empty = !data || data.length <= 0;

  // TODO (vis): backend only supports every nth for now
  // https://luminarycloud.atlassian.net/browse/LC-23090
  const [everyNth, setEveryNth] = useState<boolean>(lcVisEnabled ? true : param.everyNth);
  const [fixedLength, setFixedLength] = useState<boolean>(param.fixedLength);

  return (
    <div>
      <CommonFilterMessages emptyFilter={empty} />
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableEdit={empty}
              displayProps={displayProps}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <Form.LabeledInput label="Vector Field">
            <DataSelect
              asBlock
              disabled={readOnly}
              onChange={(dataName: string) => {
                const scaleFactor = defaultGlyphScale(
                  parentFilterNode,
                  parentFilterNode.pointData.find(({ name }) => name === dataName),
                );
                onUpdate({
                  ...param,
                  dataName,
                  scaleFactor,
                });
              }}
              options={data.map((datum) => ({
                value: datum.name,
                name: datum.name,
                selected: datum.name === param.dataName,
              }))}
              size="small"
            />
          </Form.LabeledInput>
          <Form.LabeledInput label={fixedLength ? 'Vector Size' : 'Scale Factor'}>
            <NumberInput
              asBlock
              disabled={readOnly}
              endAdornment={fixedLength ? 'm' : ''}
              onCommit={(newFactor) => onUpdate({ ...param, scaleFactor: newFactor })}
              size="small"
              value={param.scaleFactor}
            />
            <div style={{ display: 'flex', gap: '4px', padding: '4px 0' }}>
              <ActionButton
                compact
                disabled={readOnly}
                kind="minimal"
                onClick={() => onUpdate({ ...param, scaleFactor: param.scaleFactor * 0.5 })}
                size="small">
                .5x
              </ActionButton>
              <ActionButton
                compact
                disabled={readOnly}
                kind="minimal"
                onClick={() => onUpdate({ ...param, scaleFactor: param.scaleFactor * 2 })}
                size="small">
                2x
              </ActionButton>
            </div>
          </Form.LabeledInput>
          <Form.LabeledInput
            help="If unchecked, the length of vectors is scaled by
                  both the scale factor and the magnitude of the vector. If checked,
                  the length of vectors is fixed."
            label="Fixed Size">
            <Checkbox
              checked={fixedLength}
              disabled={readOnly}
              onChange={(checked: boolean) => {
                setFixedLength(checked);
                onUpdate({ ...param, fixedLength: checked });
              }}
            />
          </Form.LabeledInput>
          <Form.LabeledInput
            help="How vectors are placed.
                  'Uniform Spatial Bounds' will sample the bounding box of the input.
                  'Every Nth Point' will place a vector for every 'N' points in the mesh
                  (e.g., N = 2 will place vectors at every other point in the mesh.)"
            label="Sampling Method">
            <DataSelect
              asBlock
              disabled={readOnly || lcVisEnabled}
              onChange={(value: string) => {
                const isNth = value === 'Nth';
                setEveryNth(isNth);
                onUpdate({ ...param, everyNth: isNth });
              }}
              options={[
                { value: 'Bounds', name: 'Uniform Spatial Bounds', selected: !everyNth },
                { value: 'Nth', name: 'Every Nth Point', selected: everyNth },
              ]}
              size="small"
            />
          </Form.LabeledInput>
          <Form.LabeledInput label={everyNth ? 'N' : 'Number of Points'}>
            <NumberInput
              asBlock
              disabled={readOnly}
              onCommit={(newPointCount) => onUpdate(
                { ...param, samplePoints: Math.round(newPointCount) },
              )}
              size="small"
              value={param.samplePoints}
            />
          </Form.LabeledInput>
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
