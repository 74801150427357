// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/imagerenderer/imagerenderer.proto (package luminary.proto.imagerenderer, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message luminary.proto.imagerenderer.ImageRendererConfig
 */
export const ImageRendererConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.imagerenderer.ImageRendererConfig",
  [],
);

