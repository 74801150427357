// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { useEffect, useMemo } from 'react';

import { Empty } from '@bufbuild/protobuf';
import { ConnectError } from '@connectrpc/connect';
import {
  DefaultValue,
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import { poll } from '../lib/ProjectListPoller';
import { tabLink } from '../lib/TabManager';
import assert from '../lib/assert';
import { projectLink, resultsLink } from '../lib/navigation';
import { Notification, translateNotification, updateNotification } from '../lib/notificationUtils';
import * as rpc from '../lib/rpc';
import { isStorybookEnv } from '../lib/testing/utils';
import { addRpcError } from '../lib/transientNotification';
import { getFullName } from '../lib/user';
import * as notificationpb from '../proto/notification/notification_pb';

import { jobNameMapProtoState } from './jobNameMap';
import { projectListState } from './state';
import { accountInfoState } from './useAccountInfo';
import { TabDescription } from './useTabsState';

const rpcPool = new rpc.StreamingRpcPool<Empty, notificationpb.ListenReply>(
  'ListenNotifications',
  rpc.client.listenNotifications,
);

const COUNT_NOTIFICATIONS_UNITILIAZED = -1;

// Global atom to track the number of project sharing notifications. We cannot use useRef
// because the parent of useNotifications seems to be re-created when switching tabs.
const globalSharingCountNotifications = atom<number>({
  key: 'globalSharingCountNotifications',
  default: COUNT_NOTIFICATIONS_UNITILIAZED,
});

/**
 * notificationState contains the raw ListenReply from the ListenNotifications streaming rpc.
 * It updates whenever a new notification is added or modified.
 */
const notificationsState = atom<notificationpb.ListenReply>({
  key: 'notificationsState',
  effects: [
    ({ setSelf }) => {
      if (!isStorybookEnv()) {
        rpcPool.start(
          'ListenNotifications',
          () => new Empty(),
          (reply: notificationpb.ListenReply) => setSelf(reply),
          (err: ConnectError) => addRpcError('Failed to get notifications list', err),
        );
      } else {
        setSelf(new notificationpb.ListenReply());
      }
    },
  ],
  dangerouslyAllowMutability: true,
});

/** Tracks the read state separately from the rpc notification read state */
export const notificationReadState = atomFamily<boolean, string>({
  key: 'notificationReadSTate',
  default: selectorFamily<boolean, string>({
    key: 'notificationReadStateSelector',
    get: (notificationId: string) => ({ get }) => {
      const reply = get(notificationsState);
      const notifList = reply?.latestNotifications;
      return notifList?.find((item) => `${item.id}` === notificationId)?.read ?? false;
    },
  }),
  effects: (notificationId: string) => [
    ({ onSet }) => {
      onSet((newValue: boolean | DefaultValue) => {
        if (!(newValue instanceof DefaultValue) && !isStorybookEnv()) {
          updateNotification(notificationId, newValue);
        }
      });
    },
  ],
});

export function useNotificationReadState(id: string) {
  return useRecoilState(notificationReadState(id));
}

export function useSetNotificationReadState(id: string) {
  return useSetRecoilState(notificationReadState(id));
}

/**
 * notificationsSelector contains the notification list from the ListenReply.
 */
const notificationsSelector = selector<Notification[]>({
  key: 'notificationsSelector',
  get: ({ get }) => {
    const notifications = get(notificationsState)?.latestNotifications ?? null;
    if (notifications === null) {
      return [];
    }
    const projectList = get(projectListState)?.project;

    const propsList = notifications.map((item) => {
      const notification = item.clone();

      notification.read = get(notificationReadState(`${notification.id}`));
      switch (notification.eventType) {
        case notificationpb.EventType.SIMULATION_COMPLETE: {
          const metadata = notification.metadata;
          // If the notification is a SIMULATION_COMPLETE notification, then the following fields
          // must all be present or an error will occur.
          assert(
            metadata?.meta.case === 'simulationCompleteMetadata',
            'Completed simulation notification metadata expected',
          );
          const { projectId, workflowId, jobId, jobType } = metadata.meta.value;

          const projectName = projectList?.find((project) => project.projectId === projectId)?.name;
          if (!projectName) {
            // This should only happen if the project has been deleted.
            return null;
          }
          let tabName: string = '';
          if (projectId && workflowId && jobId) {
            const jobNameMap = get(jobNameMapProtoState(projectId));
            tabName = jobNameMap.names[jobId] ?? 'Your simulation';
          }

          const pathName = tabLink(jobType, projectId, workflowId, jobId);
          const tab: TabDescription = { tabName, pathName };
          return translateNotification(notification, tab, null, projectName, metadata);
        }
        case notificationpb.EventType.DOE_PROGRESS: {
          const metadata = notification.metadata;
          assert(
            metadata?.meta.case === 'doeProgressMetadata',
            'Exploration progress notification metadata expected',
          );
          const { projectId, workflowId } = metadata.meta.value;

          const projectName = projectList?.find((project) => project.projectId === projectId)?.name;
          if (!projectName) {
            // This should only happen if the project has been deleted.
            return null;
          }
          let doeName: string = '';
          if (projectId && workflowId) {
            const jobNameMap = get(jobNameMapProtoState(projectId));
            doeName = jobNameMap.names[workflowId] ?? 'Your design of experiments';
          }

          const pathName = resultsLink(projectId);
          const tab: TabDescription = { tabName: doeName, pathName };
          return translateNotification(
            notification,
            tab,
            null,
            projectName,
            metadata,
          );
        }
        case notificationpb.EventType.PROJECT_SHARING: {
          const metadata = notification.metadata;
          assert(
            metadata?.meta.case === 'projectSharingMetadata',
            'Project sharing notification metadata expected',
          );
          const { projectId, ownerUserId } = metadata.meta.value;

          const projectName = projectList?.find((project) => project.projectId === projectId)?.name;
          if (!projectName) {
            // This should only happen if the project has been deleted.
            // Can it happen if project state has not propagated to frontend?
            return null;
          }
          const pathName = projectLink(projectId);
          const tab: TabDescription = { tabName: 'Setup', pathName };
          const users = get(accountInfoState)?.user || [];
          const owner = users.find((user) => (user?.lcUserId === ownerUserId));
          const ownerName = owner ? getFullName(owner) : 'Another user';

          return translateNotification(
            notification,
            tab,
            ownerName,
            projectName,
            metadata,
          );
        }
        default:
          return null;
      }
    });
    return propsList.filter((entry) => entry !== null) as Notification[];
  },
});

function getSharingNotificationCount(reply: notificationpb.ListenReply): number {
  return reply.latestNotifications.filter(
    (notif) => notif.eventType === notificationpb.EventType.PROJECT_SHARING,
  ).length;
}

/** Returns the list of notifications to be used by the notification center. */
export const useNotifications = (): Notification[] => {
  const selectorValue = useRecoilValueLoadable(notificationsSelector);
  const reply = useRecoilValue(notificationsState);
  const setProjectListState = useSetRecoilState(projectListState);
  const [sharingCount, setSharingCount] = useRecoilState(globalSharingCountNotifications);
  const currSharingCount = useMemo(() => getSharingNotificationCount(reply), [reply]);
  // Whenever the number of project sharing notifications changes, we need to call
  // the listProjects rpc so that the selector can use the updated project names.
  useEffect(() => {
    if (sharingCount !== currSharingCount) {
      // No need to poll if this is the first time the sharing count is set we are just initializing
      // and it's not a symptom of a new notification.
      if (sharingCount !== COUNT_NOTIFICATIONS_UNITILIAZED) {
        poll((response) => {
          setProjectListState(response);
        });
      }
      setSharingCount(currSharingCount);
    }
  }, [currSharingCount, sharingCount, setProjectListState, setSharingCount]);
  return selectorValue.state === 'hasValue' ? selectorValue.contents : [];
};

/** notificationsOpenState tracks the open/closed state of the NotificationCenter */
const notificationsOpenState = atom<boolean>({
  key: 'notificationsOpenState',
  default: false,
});

export const useNotificationsOpen = () => useRecoilState(notificationsOpenState);

export const useSetNotificationsOpen = () => useSetRecoilState(notificationsOpenState);
