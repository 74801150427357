import React, { useState } from 'react';

import * as EmailValidator from 'email-validator';
import mixpanel from 'mixpanel-browser';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ActionLink } from '../../components/Button/ActionLink';
import AuthForm from '../../components/Form/AuthForm';
import { createStyles, makeStyles } from '../../components/Theme';
import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { CheckBulletIcon } from '../../components/svg/CheckBullet';
import { isHostedEnvironment, isProd } from '../../lib/RuntimeParams';
import { MIXPANEL_TOKEN_DEV, MIXPANEL_TOKEN_PROD } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import * as rpc from '../../lib/rpc';
import * as frontendpb from '../../proto/frontend/frontend_pb';

interface FormData {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
}

const FAQ_PAGE_URL = 'https://www.luminarycloud.com/pricing?faq=1';

export const SIGNUP_ERROR_MESSAGES: { [key: string]: string } = {
  ERROR_DUPLICATE_EMAIL: 'An account with this email already exists.',
  ERROR_INVALID_COUNTRY: 'Signup is not allowed for your country.',
  ERROR_ACCOUNT_CREATION: 'Failed to create your account. Please try again.',
  DEFAULT: 'Unexpected error creating your account. Please try again.',
  ERROR_INVALID_EMAIL: 'Please enter a valid email address.',
  ERROR_INVALID_FIRST_NAME: 'Please enter a valid first name.',
  ERROR_INVALID_LAST_NAME: 'Please enter a valid last name.',
  ERROR_INVALID_COMPANY_NAME: 'Please enter a valid company name.',
};

const RECAPTCHA_SITE_KEY = isProd ?
  '6LeHKmAqAAAAAIlowGFpLGq4UmZ4-75IHzg7q7KN' :
  '6LepzF8qAAAAAPIW6mz67fu6_Agnz7Ur2ELC4xXd';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      alignItems: 'stretch',
      alignSelf: 'center',
    },
    introSection: {
      backgroundColor: colors.neutral50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      flexDirection: 'column',
      padding: '96px',
      width: '432px',
      minHeight: '548px',
      color: colors.neutral800,
    },
    formWrapper: {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
    },
    formSection: {
      backgroundColor: colors.neutral150,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '72px',
      width: '494px',
    },
    title: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 'bold',
    },
    subtitle: {
      color: colors.neutral800,
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '8px',
      marginBottom: '36px',
    },
    feedback: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      color: colors.neutral800,
      gap: '8px',
    },
    feedbackTitle: {
      color: colors.neutral900,
      fontSize: '18px',
      fontWeight: 'bold',
    },
    boxTitle: {
      fontSize: '24px',
      lineHeight: '32px',
      marginTop: 0,
      color: colors.neutral800,
    },
    bulletPoints: {
      display: 'flex',
      flexDirection: 'column',
      gap: '18px',
      marginBottom: '32px',
    },
    bulletItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    bulletIcon: {
      minWidth: '20px',
      alignSelf: 'stretch',
      paddingTop: '4px',
    },
  }),
  { name: 'SignUpPage' },
);

export const SignUpForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles();

  const getErrorMessage =
    (resultCode: string) => SIGNUP_ERROR_MESSAGES[resultCode] ?? SIGNUP_ERROR_MESSAGES.DEFAULT;

  const sendSignupRequest = async (data: FormData) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setError('ReCAPTCHA not available. Please try again later.');

      return;
    }

    setError('');
    setSubmitting(true);

    try {
      const token = await executeRecaptcha('signup');
      // get url params
      const params = new URLSearchParams(window.location.search);
      const urlParams = params?.toString() || '';

      const req = new frontendpb.RegisterUserRequest({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.company,
        recaptchaValue: token,
        referrer: document.referrer ?? '',
        urlParams,
      });

      const reply = await rpc.client.registerUser(req);

      if (reply.success) {
        setSubmitted(true);
        mixpanel.alias(reply.userId);
        mixpanel.identify(reply.userId);
      } else {
        setError(getErrorMessage(reply.resultCode));
      }
    } catch (err) {
      setError('Failed to sign up. Please try again.');

      console.error('Sign up error:', err);
    } finally {
      setSubmitting(false);
    }
  };

  if (submitted) {
    return (
      <div className={classes.feedback}>
        <div className={classes.feedbackTitle}>Email sent!</div>
        Follow the link in the email to activate your account and start using Luminary.
      </div>
    );
  }

  return (
    <div className={classes.formWrapper}>
      <AuthForm
        fields={[
          [
            {
              label: 'First name',
              asBlock: true,
              required: true,
              placeholder: 'Enter your first name',
              name: 'firstName',
            },
            {
              label: 'Last name',
              asBlock: true,
              required: true,
              placeholder: 'Enter your last name',
              name: 'lastName',
            },
          ],
          {
            label: 'Business email',
            asBlock: true,
            required: true,
            placeholder: 'Enter your business email',
            name: 'email',
            validate: (value) => EmailValidator.validate(value),
          },
          {
            label: 'Company',
            asBlock: true,
            required: true,
            placeholder: 'Enter your company',
            name: 'company',
          },
        ]}
        globalError={error}
        onSubmit={sendSignupRequest}
        submit={{
          disabled: submitting,
          label: submitting ? 'Submitting...' : 'Submit',
          showSpinner: submitting,
        }}
      />
    </div>
  );
};

const SignUpPage = () => {
  const classes = useStyles();

  // Add useEffect to track page view
  React.useEffect(() => {
    const mixpaneltoken = isProd ? MIXPANEL_TOKEN_PROD : MIXPANEL_TOKEN_DEV;
    mixpanel.init(mixpaneltoken, { debug: !isHostedEnvironment });
    mixpanel.track('view-signup-form');
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_SITE_KEY}
      scriptProps={{
        appendTo: 'head',
        async: false,
        defer: false,
      }}>
      <AuthPageLayout
        backgroundColor="#1e1e1e"
        centered
        limitWidth={false}
        rightSection={false}>
        <div className={classes.root}>
          <div className={classes.formSection}>
            <div className={classes.title}>Sign up</div>
            <div className={classes.subtitle}>
              Already have an account? <ActionLink href="/login">Log in</ActionLink>
            </div>
            <SignUpForm />
          </div>

          <div className={classes.introSection}>
            <h2 className={classes.boxTitle}>Starter Plan</h2>

            <div className={classes.bulletPoints}>
              {[
                '100 FREE credits worth $500 (an estimated 10 steady-state simulations)',
                'Access to self-guided support',
                'Start simulating immediately',
              ].map((bulletContent) => (
                <div className={classes.bulletItem} key={bulletContent}>
                  <div className={classes.bulletIcon}>
                    <CheckBulletIcon color={colors.primaryInteractive} maxWidth={20} />
                  </div>
                  {bulletContent}
                </div>
              ))}
            </div>

            <ActionLink externalIcon href={FAQ_PAGE_URL}>
              Frequently Asked Questions
            </ActionLink>
          </div>
        </div>

      </AuthPageLayout>
    </GoogleReCaptchaProvider>
  );
};

export default SignUpPage;
