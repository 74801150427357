import { Interceptor } from '@connectrpc/connect';

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-shadow */

// All the eslint disables are to accept `readMessage` and `interceptor`, which are both stolen from
// https://github.com/SafetyCulture/grpc-web-devtools/blob/6d30a442d/public/connect-web-interceptor.js
// and modified to use `safeToJson` because `toJson` was causing errors that weren't caught by
// `wrapFlakyInterceptor`. That wrapper is still needed to catch other errors.

declare global {
  interface Window {
    __CONNECT_WEB_DEVTOOLS__?: Interceptor;
  }
}

const SERIALIZATION_ERROR = '__CONNECT_WEB_DEVTOOLS__ error: failed to serialize object to JSON';

function safeToJson(obj: any): string {
  try {
    return obj.toJson?.();
  } catch (err) {
    console.warn(SERIALIZATION_ERROR, err);
    console.dir(obj);
    return SERIALIZATION_ERROR;
  }
}

/**
 * Reads the message from the stream and posts it to the window.
 * This is a generator function that will be passed to the response stream.
 */
async function* readMessage(req: any, stream: any) {
  for await (const m of stream) {
    if (m) {
      const resp = safeToJson(m);
      window.postMessage({
        type: '__GRPCWEB_DEVTOOLS__',
        methodType: 'server_streaming',
        method: req.method.name,
        request: safeToJson(req.message),
        response: resp,
      }, '*');
    }
    yield m;
  }
}

/**
 * This interceptor will be passed every request and response.
 * We will take that request and response and post a message to the window.
 * This will allow us to access this message in the content script.
 * This is all to make the manifest v3 happy.
 */
const interceptor: Interceptor = (next) => async (req) => {
  try {
    const resp = await next(req);
    if (!resp.stream) {
      window.postMessage({
        type: '__GRPCWEB_DEVTOOLS__',
        methodType: 'unary',
        method: req.method.name,
        request: safeToJson(req.message),
        response: safeToJson(resp.message),
      }, '*');
      return resp;
    }
    return {
      ...resp,
      message: readMessage(req, resp.message),
    };
  } catch (e) {
    window.postMessage({
      type: '__GRPCWEB_DEVTOOLS__',
      methodType: req.stream ? 'server_streaming' : 'unary',
      method: req.method.name,
      request: safeToJson(req.message),
      response: undefined,
      error: {
        message: e.message,
        code: e.code,
      },
    }, '*');
    throw e;
  }
};

// wraps an Interceptor, logging any error it throws but not interrupting the chain
function wrapFlakyInterceptor(interceptor: Interceptor, name: string): Interceptor {
  return (next) => async (req) => {
    const theFlake = interceptor(next);
    try {
      const res = await theFlake(req);
      return res;
    } catch (err) {
      console.warn(`Flaky gRPC interceptor (${name}) threw the following error:`);
      console.warn(err);
      return next(req);
    }
  };
}

export const debugInterceptors: Interceptor[] = [];

if (window.__CONNECT_WEB_DEVTOOLS__ !== undefined) {
  window.__CONNECT_WEB_DEVTOOLS__ = interceptor;
  debugInterceptors.push(wrapFlakyInterceptor(window.__CONNECT_WEB_DEVTOOLS__, 'devtools'));
}
