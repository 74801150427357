// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useRef, useState } from 'react';

import { xxhash32 } from 'hash-wasm';
import { LRUCache } from 'lru-cache';
import { selectorFamily, useRecoilCallback, waitForAll } from 'recoil';

import { createParamScope } from '../lib/ParamScope';
import { getAdValue } from '../lib/adUtils';
import {
  RowDatum,
} from '../lib/jobTableUtils';
import { fromBigInt } from '../lib/number';
import { Logger } from '../lib/observability/logs';
import { createOutputs, getOutputNodeWarnings } from '../lib/outputNodeUtils';
import { getReferenceValues } from '../lib/referenceValueUtils';
import OutputRequestRpcQueue from '../lib/rpcQueue/OutputRequestRpcQueue';
import { getSimulationParam } from '../lib/simulationParamUtils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { ComputeOutputReply } from '../proto/frontend/frontend_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';
import * as outputpb from '../proto/output/output_pb';
import { ReferenceValues } from '../proto/output/reference_values_pb';

import { entityGroupDataSelector } from './entityGroupState';
import { geometryTagsState } from './geometry/geometryTagsState';
import { outputNodesState } from './outputNodes';
import { enabledExperimentsState } from './useExperimentConfig';
import { staticVolumesState } from './volumes';
import { currentConfigSelector } from './workflowConfig';
import { workflowState } from './workflowState';

const logger = new Logger('useOutputResults');

export type OutputResultKey = {
  outputId: string,
  projectId: string,
  jobId: string,
  workflowId: string,
  iteration?: number,
}

// Cache for storing serialized output replies.
const cache = new LRUCache<string, Uint8Array>({
  maxSize: 1e07, // 10MB
  // NOTE: if array is empty, cache.set fails because the external lib expects the size to be at
  // least 1.
  sizeCalculation: (array: Uint8Array) => Math.max(array.length, 1),
});

const OUTPUT_WARNING = 'Output not available for this simulation.';

const rpcQueueMap: { [key: string]: OutputRequestRpcQueue } = {};

enum ReplyStatus {
  'HAS_WARNING',
  'JOB_NOT_RUNNING'
}

interface ReplyState {
  status: ReplyStatus;
  message?: string;
}

/**
 * Send a ComputeOutputBatchRequest RPC
 *
 * If the rpcKey and outputKey are already in the cache, the result is returned from the cache.
 *
 * @param rpcKey key for the rpc queue map
 * @param outputKey key for the output set
 * @param projectId this project's Id
 * @param jobId this job's Id
 * @param minimalEntityGroups entity group map with only groups that are used in the output
 * @param outputList set of outputs to compute
 * @param referenceValues reference values for the outputs
 */
export function sendOutputRequest(
  rpcKey: string,
  outputKey: string,
  projectId: string,
  jobId: string,
  outputList: outputpb.Output[],
  referenceValues: ReferenceValues,
) {
  const cacheKey = `${rpcKey}-${outputKey}`;
  // Check if the result is already in the cache.
  const cachedResult = cache.get(cacheKey);
  if (cachedResult) {
    return new Promise<ComputeOutputReply>(
      (resolve) => resolve(ComputeOutputReply.fromBinary(cachedResult)),
    );
  }
  // Get or create a new queue
  let rpcQueue = rpcQueueMap[rpcKey];
  if (!rpcQueue) {
    const req = new frontendpb.ComputeOutputBatchRequest({
      projectId,
    });
    rpcQueue = new OutputRequestRpcQueue(req);
    rpcQueueMap[rpcKey] = rpcQueue;
  }

  // Create a request for each individual output
  const outputRequests = outputList.map((output) => {
    const request = new frontendpb.OutputRequest({
      jobId,
      referenceValues,
      output,
    });
    return request;
  });

  return new Promise<ComputeOutputReply>((resolve) => rpcQueue.start(
    outputRequests,
    (replyChunk) => {
      const subReply = new frontendpb.ComputeOutputReply();
      replyChunk.forEach((chunk) => {
        subReply.output.push(chunk.output);
        subReply.result.push(chunk.result);
      });
      cache.set(cacheKey, subReply.toBinary());
      resolve(subReply);
    },
    outputKey,
  ));
}

/**
 * State storing the raw reply for a output result key coming from the analyzer.
 * If there are any warnings generated for the requested output node no rpc is send and the
 * state will be null.
 */
const outputReplyState = selectorFamily<ComputeOutputReply | ReplyState, OutputResultKey>({
  key: 'outputReplyState',
  get: (key: OutputResultKey) => async ({ get }) => {
    const { projectId, workflowId, jobId, outputId } = key;
    const recoilKey = { projectId, workflowId, jobId };
    const projectRecoilKey = { projectId, workflowId: '', jobId: '' };

    // We need the per-project (for the actual outputs) output nodes and per-job output nodes
    // (for the reference values) here.
    const [
      experiments,
      staticVolumes,
      entityGroupData,
      jobOutputNodes,
      outputNodes,
      config,
      workflow,
      geometryTags,
    ] = get(waitForAll([
      enabledExperimentsState,
      staticVolumesState(projectId),
      entityGroupDataSelector(projectRecoilKey),
      outputNodesState(recoilKey),
      outputNodesState(projectRecoilKey),
      currentConfigSelector(recoilKey),
      workflowState({ projectId, workflowId }),
      geometryTagsState({ projectId }),
    ]));

    /**  Derived dependencies */
    const params = getSimulationParam(config);
    const referenceValues =
      getReferenceValues(jobOutputNodes, params, experiments, geometryTags, staticVolumes);

    /** Create map for quickly retrieving output nodes for derived dependencies */
    const outputNodeMap = new Map<string, feoutputpb.OutputNode>();
    outputNodes.nodes.forEach((node) => {
      outputNodeMap.set(node.id, node);
    });

    /** Convert the requested output node to a list of individual outputs. */
    const outputNode = outputNodeMap.get(outputId)!;

    // Check if there are any warnings. If that is the case we don't want to do any calculations or
    // send a rpc.
    const outputNodeWarnings = getOutputNodeWarnings(
      outputNode,
      outputNodes,
      params,
      entityGroupData,
      createParamScope(params, experiments),
      staticVolumes,
      geometryTags,
      referenceValues.referenceValueType,
    );
    if (outputNodeWarnings.length > 0) {
      return { status: ReplyStatus.HAS_WARNING, message: outputNodeWarnings[0] };
    }

    const { outputList } = createOutputs(
      outputNode,
      outputNodes,
      params,
      entityGroupData,
      false,
      false,
      [],
    );

    // Set the correct iteration in all outputs. The provided iteration might be negative before any
    // solution has been written. In that case we use the latest iteration.
    const iteration = !key.iteration || key.iteration < 0 ?
      (workflow?.job[jobId].latestIter) : key.iteration;
    if (!iteration) {
      return { status: ReplyStatus.JOB_NOT_RUNNING };
    }
    outputList.forEach((output) => {
      output.range = new outputpb.IterationRange({ end: fromBigInt(iteration) });
    });

    // Create a hash key for each dependency and concatenate them.
    const rpcKey = projectId;

    // Create a key for the output set.
    const outputKey = (
      await Promise.all(
        [
          ...outputList.map((output) => xxhash32(output.toBinary())),
          xxhash32(referenceValues.toBinary()),
          xxhash32(key.jobId),
        ],
      )
    ).reduce((previous, current) => previous + current);

    return sendOutputRequest(
      rpcKey,
      outputKey,
      key.projectId,
      key.jobId,
      outputList,
      referenceValues,
    );
  },
  dangerouslyAllowMutability: true,
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
});

/** Type representing an individual scalar output result. */
export type ScalarOutput = {
  /** Name of the output */
  name: string;
  /** Short name */
  shortName: string;
  /** Base value */
  baseValue?: number;
  /** Ad value */
  adValue?: number;
  /** An optional status message. Will usually be non-null if 'baseValue' is null. */
  status?: string;
}

/**
 * Selector storing the scalar output results for a particular output node. Each output node
 * usually maps to several individual results (e.g. for forces we have the dimensional value and
 * the non-dimensional coefficient). If any value in `OutputResultKey` is invalid (e.g. empty) no
 * rpc will be send but the state will still be populated with the outputs and their names.
 */
export const scalarResult = selectorFamily<ScalarOutput[], OutputResultKey>({
  key: 'scalarResult',
  get: (key: OutputResultKey) => ({ get }) => {
    const projectRecoilKey = { projectId: key.projectId, workflowId: '', jobId: '' };
    const defaultResults = (status?: string) => {
      const [config, outputNodes, entityGroupData] = get(waitForAll([
        currentConfigSelector(projectRecoilKey),
        outputNodesState(projectRecoilKey),
        entityGroupDataSelector(projectRecoilKey),
      ]));
      const params = getSimulationParam(config);

      const results: ScalarOutput[] = [];
      const outputNode = outputNodes.nodes.find(
        (output) => output.id === key.outputId,
      )!;
      const { outputList } = createOutputs(
        outputNode,
        outputNodes,
        params,
        entityGroupData,
        false,
        false,
      );
      outputList.forEach(({ name, shortName }) => {
        results.push({ name, shortName, status });
      });
      return results;
    };

    if (!key.projectId || !key.outputId) {
      throw Error(`Invalid output result key ${key}`);
    }

    if (!key.workflowId || !key.jobId) {
      return defaultResults('Results not available.');
    }
    const computeOutputReply = get(outputReplyState(key));

    if ('status' in computeOutputReply) {
      if (computeOutputReply.status === ReplyStatus.HAS_WARNING) {
        return defaultResults(
          `${OUTPUT_WARNING}${computeOutputReply.message ? ` ${computeOutputReply.message}` : ''}`,
        );
      }
      if (computeOutputReply.status === ReplyStatus.JOB_NOT_RUNNING) {
        return defaultResults();
      }
    }
    const reply = computeOutputReply as ComputeOutputReply;
    const results: ScalarOutput[] = [];
    reply?.output.forEach((output, index) => {
      const { name, shortName, timeAnalysis } = output;
      /** Multiply fractional outputs by 100 to present to user as percentage */
      const percentVal = timeAnalysis === outputpb.TimeAnalysisType.PERCENT_MAX_DEV ||
        timeAnalysis === outputpb.TimeAnalysisType.PERCENT_MAX_DEV_AVG;
      const scale = percentVal ? 100 : 1;
      const outputResult: ScalarOutput = { name, shortName };
      const res = reply.result[index].values[0];
      /** The analyzer reply might not contain a result if an output is not available. */
      if (res) {
        outputResult.baseValue = scale * getAdValue(res);
      } else {
        outputResult.status = OUTPUT_WARNING;
      }
      results.push(outputResult);
    });
    return results;
  },
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
});

export type OutputResultMap = { [key: string]: ScalarOutput[][] };

/**
 * Selector storing a map (keyed by the output node ids in the 'OutputResultKey[]' array) of scalar
 * output results. The output results for each individiual output node are organized in a 2d array
 * where the first dimension represents the individual output and the second dimension the result
 * for an individual entry in 'OutputResultKey[]'. Imagine we have the following entries in the list
 * of keys:
 * [{
 *    outputId: 'output-id-1',
 *    jobId: 'job-id-1',
 *  },
 *  {
 *    outputId: 'output-id-2',
 *    jobId: 'job-id-1',
 *  },
 *  {
 *    outputId: 'output-id-2',
 *    jobId: 'job-id-2',
 *  },
 *  {
 *    outputId: 'output-id-1',
 *    jobId: 'job-id-2',
 *  }] as OutputResultsKey[];
 *
 * The entries in the resulting map would look like this if both output nodes map to two individual
 * output results:
 *
 * {
 *   'output-id-1': [
 *      [Output #1 of 'output-id-1' for 'job-id-1', Output #1 of 'output-id-1' for 'job-id-2'],
 *      [Output #2 of 'output-id-1' for 'job-id-1', Output #2 of 'output-id-1' for 'job-id-2'],
 *    ],
 *   'output-id-2':[
 *      [Output #1 of 'output-id-2' for 'job-id-1', Output #1 of 'output-id-2' for 'job-id-2'],
 *      [Output #2 of 'output-id-2' for 'job-id-1', Output #2 of 'output-id-2' for 'job-id-2'],
 *    ],
 * } as OutputResultMap;
 */
export const scalarResultList = selectorFamily<OutputResultMap, OutputResultKey[]>({
  key: 'scalarResultList',
  get: (keys: OutputResultKey[]) => ({ get }) => {
    const map: OutputResultMap = {};
    const results = get(waitForAll(keys.map((key) => scalarResult(key))));
    keys.forEach((key, resultIndex) => {
      const mapEntry = map[key.outputId];
      if (!mapEntry) {
        map[key.outputId] = [];
      }
      results[resultIndex].forEach((res, index) => {
        if (!map[key.outputId][index]) {
          map[key.outputId][index] = [];
        }
        map[key.outputId][index].push(res);
      });
    });
    return map;
  },
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
});

/**
 * Generates output result keys for given outputs, project and rows
 */
export function getOutputResultsKeys(
  projectId: string,
  outputNodes: feoutputpb.OutputNodes,
  rowData: RowDatum[],
  emptyResults: boolean = false,
): OutputResultKey[] {
  if (!outputNodes?.nodes?.length || !rowData?.length) {
    return [];
  }

  const outputResultKeys: OutputResultKey[] = [];
  rowData.forEach((row) => {
    outputNodes.nodes.forEach((node) => {
      outputResultKeys.push({
        projectId,
        workflowId: emptyResults ? '' : row.workflow.id,
        jobId: emptyResults ? '' : row.job?.jobId ?? '',
        outputId: node.id,
      });
    });
  });

  return outputResultKeys;
}

/**
 * Hook to progressively load output results by processing chunks of rows at a time.
 */
export const useProgressiveOutputResults = (
  projectId: string,
  outputNodes: feoutputpb.OutputNodes,
  rowData: RowDatum[],
): [OutputResultMap, boolean] => {
  const [outputResults, setOutputResults] = useState<OutputResultMap>({});
  const [isLoading, setIsLoading] = useState(true);
  const pendingChunksRef = useRef(0);

  // Number of jobs to process at a time.
  const CHUNK_SIZE = 15;

  const getResults = useRecoilCallback(({ snapshot }) => async (
    rowIndices: number[],
    isEmpty: boolean,
  ) => {
    const chunkData = rowIndices.map((i) => rowData[i]);
    const keys = getOutputResultsKeys(projectId, outputNodes, chunkData, isEmpty);
    return snapshot.getPromise(scalarResultList(keys));
  }, [projectId, outputNodes, rowData]);

  useEffect(() => {
    if (!rowData.length || !outputNodes?.nodes?.length) {
      setIsLoading(false);
      return;
    }

    let mounted = true;
    pendingChunksRef.current = Math.ceil(rowData.length / CHUNK_SIZE);
    setIsLoading(true);

    // Process rows in chunks
    const processChunk = async (startIndex: number) => {
      if (!mounted) {
        return;
      }

      const endIndex = Math.min(startIndex + CHUNK_SIZE, rowData.length);
      const indices = Array.from({ length: endIndex - startIndex }, (_, i) => startIndex + i);

      if (indices.length === 0) {
        return;
      }

      try {
        const results = await getResults(indices, false);

        if (!mounted) {
          return;
        }

        setOutputResults((prev) => {
          const newResults = { ...prev };
          Object.entries(results).forEach(([outputId, resultRows]) => {
            if (!resultRows?.length) {
              return;
            }

            resultRows.forEach((row, rowIndex) => {
              if (!row?.length) {
                return;
              }
              if (!newResults[outputId]) {
                newResults[outputId] = [];
              }
              if (!newResults[outputId][rowIndex]) {
                newResults[outputId][rowIndex] = new Array(rowData.length);
              }

              indices.forEach((dataIndex, i) => {
                if (row[i] !== undefined) {
                  // Add unique identifier to each result object
                  newResults[outputId][rowIndex][dataIndex] = row[i];
                }
              });
            });
          });
          return newResults;
        });

        pendingChunksRef.current -= 1;
        if (pendingChunksRef.current === 0) {
          setIsLoading(false);
        }

        if (endIndex < rowData.length && mounted) {
          await processChunk(endIndex);
        }
      } catch (error) {
        console.error('Error processing chunk:', error);
        pendingChunksRef.current -= 1;
        if (pendingChunksRef.current === 0) {
          setIsLoading(false);
        }
      }
    };

    const initializeResults = async () => {
      try {
        const emptyResults = await getResults([0], true);

        if (!mounted) {
          return;
        }

        // Initialize with empty loading states for all rows
        const initial: OutputResultMap = {};
        Object.entries(emptyResults).forEach(([outputId, resultRows]) => {
          if (!resultRows?.length) {
            return;
          }

          initial[outputId] = resultRows.map(
            (row) => Array(rowData.length).fill(0).map((_, dataIndex) => ({
              name: row[0].name,
              shortName: row[0].shortName,
              status: 'Loading...',
            })),
          );
        });
        setOutputResults(initial);

        // Start loading real results
        await processChunk(0);
      } catch (error) {
        console.error('Error initializing results:', error);
      }
    };

    // Start the initialization process
    initializeResults()
      .catch((error) => {
        logger.error('Error during setting of results:', error);
      });

    return () => {
      mounted = false;
      setOutputResults({});
      setIsLoading(false);
    };
  }, [rowData, getResults, outputNodes?.nodes]);

  return [outputResults, isLoading];
};
