// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { getIconSpecDims } from '../../lib/svgIcon/utils';
import { WithMutuallyExclusiveProps } from '../../lib/typeUtils';
import { IconButton } from '../Button/IconButton';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip, { TooltipProps } from '../Tooltip';
import { EarlyAccessLink } from '../common/EarlyAccessLink';
import { FixedSizeWrapper } from '../layout/FixedSizeWrapper';
import { ChevronDownIcon } from '../svg/ChevronDownIcon';

export const TOOLBAR_ICON_CONTAINER_SIZE = 16;
export const DEFAULT_TOOLBAR_ICON_SIZE = 14;
export const DROPDOWN_ICON_SIZE = 8;
export const DROPDOWN_GAP_SIZE = 8;
export const RIBBON_TOOLBAR_BUTTON_WIDTH = 80;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      minWidth: `${RIBBON_TOOLBAR_BUTTON_WIDTH}px`,
      display: 'inline-flex',
      gap: '3px',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'visible',
      flexDirection: 'column',
    },
    iconText: {
      color: colors.lowEmphasisText,
      textAlign: 'center',
      fontSize: '9px',
      fontWeight: 500,
      lineHeight: '12px',
      display: 'flex',
      gap: '4px',
      padding: '0 6px',

      '&.disabled': {
        color: colors.neutral650,
      },
    },
    button: {
      minWidth: '40px',

      '&.disabled': {
        color: colors.neutral650,
      },
    },
    icon: {
      height: `${TOOLBAR_ICON_CONTAINER_SIZE}px`,
      width: `${TOOLBAR_ICON_CONTAINER_SIZE}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&.dropdown': {
        gap: `${DROPDOWN_GAP_SIZE}px`,
        width: `${TOOLBAR_ICON_CONTAINER_SIZE + DROPDOWN_GAP_SIZE + DROPDOWN_ICON_SIZE}px`,
      },
    },
  }),
  { name: 'RibbonToolbarButton' },
);

export type RibbonToolbarTool = WithMutuallyExclusiveProps<
  {
    key: string;
    icon: SvgIconSpec;
    title?: TooltipProps['title'];
    disabled?: boolean;
    disabledReason?: string;
    engaged?: boolean;
    locator?: string; // populates a data-locator attribute for testing
    earlyAccess?: boolean;
    label: string;
  },
  { onClick: (event: React.MouseEvent | React.KeyboardEvent) => void },
  { items: CommonMenuItem[] }
>;

export const RibbonToolbarButton = (props: RibbonToolbarTool) => {
  const {
    disabled,
    earlyAccess = false,
    engaged,
    icon,
    locator = '',
    onClick,
    label,
    title = '',
    disabledReason,
  } = props;

  const dropdown = !!props.items;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip
        placement="top"
        PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] }}
        title={disabled ? disabledReason : title || ''}>
        <span>
          <IconButton
            className={cx(classes.button, { disabled })}
            data-locator={locator}
            disabled={disabled}
            engaged={engaged && !disabled}
            kind="minimal"
            onClick={onClick}
            size="small">
            <div className={cx(classes.icon, { dropdown })}>
              <FixedSizeWrapper
                height={DEFAULT_TOOLBAR_ICON_SIZE}
                width={DEFAULT_TOOLBAR_ICON_SIZE}>
                <SvgIcon
                  {...getIconSpecDims(icon, DEFAULT_TOOLBAR_ICON_SIZE, DEFAULT_TOOLBAR_ICON_SIZE)}
                  name={icon.name}
                />
              </FixedSizeWrapper>
              {dropdown && <ChevronDownIcon maxWidth={DROPDOWN_ICON_SIZE} />}
            </div>
          </IconButton>
        </span>
      </Tooltip>
      <div className={cx(classes.iconText, { disabled })}>
        {label}
        {earlyAccess && <EarlyAccessLink fit size={8} />}
      </div>
    </div>
  );
};
