// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useCopyCustomField } from '../../hooks/nodeDuplication/useCopyCustomField';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const CustomFieldTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteCustomFieldNode, postDeleteNodeIds } = useNodeDeletion();

  const deleteRow = useCallback(() => {
    if (deleteCustomFieldNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteCustomFieldNode, node.id, postDeleteNodeIds]);

  const duplicateRow = useCopyCustomField();

  const getContextMenuItems = useCallback(() => {
    const disabled = !canDelete(node.type, node.id);
    return [
      duplicateTreeNodeMenuItem(() => duplicateRow(node.id), disabled),
      deleteTreeNodeMenuItem(deleteRow, disabled),
    ];
  }, [canDelete, deleteRow, duplicateRow, node.id, node.type]);

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={getNodeTypeIcon(props.node.type)}
      renaming={renaming}
    />
  );
};
